/* eslint-disable jsx-a11y/media-has-caption */
import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { X } from 'lucide-react';
import { useState } from 'react';

function VideoDrawer({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const [canPlay, setCanPlay] = useState<boolean>(false);

  return (
    <Drawer open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col !pt-4'>
        <DrawerHeader className='relative flex justify-between items-center'>
          <DrawerTitle className='text-center text-2xl leading-[34px] font-semibold grow'>
            카사요 브랜드 필름
          </DrawerTitle>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            className='absolute right-5 top-0 bottom-[3px] my-auto h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'
          >
            <X className='h-7 w-7' />
          </Button>
        </DrawerHeader>
        <div className='px-4 pb-8 mt-2'>
          <div className='relative rounded-[6px] border bg-[#F7F7FB]'>
            {canPlay === false && (
              <div className='absolute rounded-[6px] flex justify-center items-center w-full h-full bg-black'></div>
            )}
            <video
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '6px',
              }}
              onCanPlay={() => {
                setCanPlay(true);
              }}
              autoPlay={true}
              controls={true}
              playsInline={true}
              preload='auto'
            >
              <source src='/assets/video/carsayo.mp4' type='video/mp4' />
            </video>
          </div>
          <button
            onClick={() => {
              setIsOpen(false);
            }}
            className='w-full py-4 mt-4 flex justify-center items-center bg-[#111111] text-white rounded-[6px] disabled:cursor-not-allowed disabled:opacity-50 disabled:pointer-events-none'
          >
            종료
          </button>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default VideoDrawer;
