/* eslint-disable @typescript-eslint/no-unused-vars */
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { getCustomerQuoteListApi } from 'apis/quote';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { ChargeInformationFee } from '../../../components/purchase/ChargeInformationFee';
import { getMember } from '../../../apis/authentication';

export default function SellingLanding() {
  const navigate = useNavigate();
  const { topTabberState, setTopTabbarState, setLoadingDim } = useSystemStore();

  const [isOpenInformationFeeDrawer, setIsOpenInformationFeeDrawer] =
    useState(false);

  const goBack = () => {
    navigate('/main', { replace: true });
  };

  const goNext = async () => {
    setLoadingDim(true);

    const searchedSellCarOrderList = await getCustomerQuoteListApi();
    if (
      searchedSellCarOrderList.filter((el) => {
        if (
          el.type === 'sellCar' &&
          (el.state === 'submitted' ||
            el.state === 'progressing' ||
            el.state === 'holded')
        )
          return true;
        else return false;
      }).length > 0
    ) {
      CarsayoToast.error('진행중인 주문이 있으면 추가 주문할 수 없습니다.');
      navigate('/main', { replace: true });
      return;
    }

    navigate('/selling/main', { replace: true });
  };

  const checkAvaliableOrder = async () => {
    const member = await getMember();
    if (
      member?.customerInfo &&
      member.customerInfo.count.avaliableOrder.selling <= 0
    ) {
      setIsOpenInformationFeeDrawer(true);
    }
  };

  useEffect(() => {
    window.native.onBackPressed = goBack;
    checkAvaliableOrder();
  }, []);

  useLayoutEffect(() => {
    setTopTabbarState({
      ...topTabberState,
      className: 'fixed bg-transparent text-white',
    });

    return () => {
      setLoadingDim(false);
    };
  }, []);

  return (
    <div className='h-screen bg-sell-bg bg-cover [background-position:-540px_0]'>
      <div className='flex h-full min-h-screen flex-col py-14 text-white'>
        <div className='px-8 pl-20'>
          <div className='mt-[60px] border-l border-white pl-2'>
            <div>#카사요</div>
            <div className='mt-1 text-[28px] font-semibold leading-[38px]'>
              신차 구매에서
              <br />
              내차 판매까지!
            </div>
            <div className='mt-4'>
              판매 / 매입 통합 쇼핑 플랫폼을 경험하세요.
            </div>
          </div>
        </div>
        <div
          onClick={goNext}
          className='mx-auto mt-auto flex h-[52px] w-[240px] items-center justify-center gap-1 border border-white text-base font-medium bg-[#11111199]'
        >
          내차 가격 확인하기
          <img src='/assets/images/v2/right.svg' alt='' />
        </div>
      </div>

      <ChargeInformationFee
        isOpen={isOpenInformationFeeDrawer}
        setIsOpen={setIsOpenInformationFeeDrawer}
        purchaseType={'selling'}
      />
    </div>
  );
}
