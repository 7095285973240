import { Button } from 'components/ui/button';
import {
  DomesticBrandList,
  ImportedBrandList,
  SuperCarBrandList,
} from 'mocks/carMakerList';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import usePurchaseStore from './store';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { CarMakerId } from '@carsayo/types';

export default function Buy() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { setTopTabbarTitle } = useSystemStore();
  const { purchaseOrderDTO, setPurchaseOrderDTO } = usePurchaseStore();

  const purchaseType: 'leaseRent' | 'newCar' =
    searchParams.get('purchaseType') === 'leaseRent' ? 'leaseRent' : 'newCar';

  const goBack = () => {
    navigate('/main', { replace: true });
  };
  const goNext = () => {
    navigate('/purchase/model', { replace: true });
  };

  useEffect(() => {
    window.native.onBackPressed = goBack;
  }, []);

  useLayoutEffect(() => {
    setTopTabbarTitle('브랜드를 선택해주세요');
  }, []);

  const [checkedBrand, setCheckedBrand] = useState(
    purchaseOrderDTO?.orderData?.carMaker?.id,
  );
  const handleBrandRadioChange = (brandId: CarMakerId) => {
    setCheckedBrand(brandId);
    if (!purchaseOrderDTO) return;
    setPurchaseOrderDTO({
      id: purchaseOrderDTO.id,
      type: purchaseOrderDTO.type,
      update: {
        carMakerId: brandId,
      },
    });
  };

  return (
    <div className='bg-[#F1F1F5] flex flex-col h-full'>
      <div className='flex-auto overflow-y-scroll'>
        <div className='bg-white px-4 py-6'>
          <div className='text-lg font-semibold'>국산 브랜드</div>
          <div className='brandAllCheck mt-4 grid grid-cols-3 gap-x-[21.5px] gap-y-4'>
            {DomesticBrandList.map((brand) => (
              <label
                key={brand.name_en}
                className='group relative h-[88px] rounded-xl border bg-[#F7F7FB] py-4 has-[:checked]:border-primary'
              >
                <input
                  type='radio'
                  name='brand'
                  className='absolute h-0 w-0 hidden'
                  defaultChecked={brand.id === checkedBrand}
                  onChange={() => handleBrandRadioChange(brand.id)}
                />
                <div className='mx-auto h-9 w-9'>
                  <img
                    src={`/assets/images/v2/brand/${brand.name_en}.png`}
                    alt={brand.name_en}
                  />
                </div>
                <div className='mt-2 text-center text-xs font-semibold'>
                  {brand.name}
                </div>
              </label>
            ))}
          </div>
        </div>
        <div className='mt-3 bg-white px-4 py-6'>
          <div className='text-lg font-semibold'>수입 브랜드</div>
          <div className='incBrandAllCheck mt-4 grid grid-cols-3 gap-x-[21.5px] gap-y-4'>
            {ImportedBrandList.map((brand) => (
              <label
                key={brand.name_en}
                className='group relative h-[88px] rounded-xl border bg-[#F7F7FB] py-4 has-[:checked]:border-primary'
              >
                <input
                  type='radio'
                  name='brand'
                  className='absolute h-0 w-0 hidden'
                  defaultChecked={brand.id === checkedBrand}
                  onChange={() => handleBrandRadioChange(brand.id)}
                />
                <div className='mx-auto h-9 w-9'>
                  <img
                    src={`/assets/images/v2/brand/${brand.name_en}.png`}
                    alt={brand.name_en}
                  />
                </div>
                <div className='mt-2 text-center text-xs font-semibold'>
                  {brand.name}
                </div>
              </label>
            ))}
          </div>
        </div>
        {/* 슈퍼카 브랜드 - 리스렌트 전용 */}
        {(purchaseType === 'leaseRent' ||
          purchaseOrderDTO?.type === 'leaseRent') && (
          <div className='mt-3 bg-white px-4 py-6'>
            <div className='text-lg font-semibold'>슈퍼카 브랜드</div>
            <div className='text-[#767676]'>
              · 리스 및 장기렌트 신청은 출고 1개월 전 신청이 가능합니다.
            </div>
            <div className='incBrandAllCheck mt-4 grid grid-cols-3 gap-x-[21.5px] gap-y-4'>
              {SuperCarBrandList.map((brand) => (
                <label
                  key={brand.name_en}
                  className='group relative h-[88px] rounded-xl border bg-[#F7F7FB] py-4 has-[:checked]:border-primary'
                >
                  <input
                    type='radio'
                    name='brand'
                    className='absolute h-0 w-0 hidden'
                    defaultChecked={brand.id === checkedBrand}
                    onChange={() => handleBrandRadioChange(brand.id)}
                  />
                  <div className='mx-auto h-9 w-9'>
                    <img
                      src={`/assets/images/v2/brand/${brand.name_en}.png`}
                      alt={brand.name_en}
                    />
                  </div>
                  <div className='mt-2 text-center text-xs font-semibold'>
                    {brand.name}
                  </div>
                </label>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className='flex-none grid grid-cols-2 bg-[#F7F7FB] px-4 py-6'>
        <Button
          variant='outline'
          className='rounded-r-none bg-white'
          onClick={goBack}
        >
          이전
        </Button>
        <Button
          className='rounded-l-none'
          onClick={async () => {
            if (purchaseOrderDTO?.orderData?.carMaker) {
              goNext();
            } else {
              CarsayoToast.error('제조사가 선택되지 않았습니다');
            }
          }}
        >
          다음
        </Button>
      </div>
    </div>
  );
}
