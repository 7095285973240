/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from 'components/ui/dialog';
import { cn } from 'utils';
import { Check } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { ChevronRight } from 'lucide-react';
import { Textarea } from 'components/ui/textarea';
import useSellingStore from '../store';

export default function StepComponent2({
  setIsAccidentHistoryDetailOpen,
}: {
  setIsAccidentHistoryDetailOpen: (value: boolean) => void;
}) {
  const { sellingOrderDTO, setSellingOrderDTO } = useSellingStore();
  const ref = useRef<HTMLDivElement>(null);
  const textRef_장단점 = useRef<HTMLTextAreaElement>(null);
  const textRef_정비수리 = useRef<HTMLTextAreaElement>(null);
  const [textCount_장단점, setTextCount_장단점] = useState<number>(0);
  const [textCount_정비수리, setTextCount_정비수리] = useState<number>(0);
  const [isTireOptionDialogOpen, setIsTireOptionDialogOpen] = useState(false);
  const [isScratchOptionDialogOpen, setIsScratchOptionDialogOpen] =
    useState(false);
  const [isRepairOptionDialogOpen, setIsRepairOptionDialogOpen] =
    useState(false);
  const [isMeritDialogOpen, setIsMeritDialogOpen] = useState(false);

  const handleTireOptionDialogOpenChange = (open: boolean) => {
    if (open) {
      if (
        sellingOrderDTO &&
        sellingOrderDTO.orderData.additionalInfo.carState.타이어교체.selected
      ) {
        setIsTireOptionDialogOpen(false);
        handleClickEvents.타이어교체.remove();
      } else {
        setIsTireOptionDialogOpen(true);
      }
    }
  };

  const handleScratchOptionDialogOpenChange = (open: boolean) => {
    if (open) {
      if (
        sellingOrderDTO &&
        sellingOrderDTO.orderData.additionalInfo.carState.외관스크래치.selected
      ) {
        setIsScratchOptionDialogOpen(false);
        handleClickEvents.외관스크래치.remove();
      } else {
        setIsScratchOptionDialogOpen(true);
      }
    }
  };

  const handleRepairOptionDialogOpenChange = (open: boolean) => {
    if (open) {
      if (
        sellingOrderDTO &&
        sellingOrderDTO.orderData.additionalInfo.carState.정비수리필요.selected
      ) {
        setIsRepairOptionDialogOpen(false);
        handleClickEvents.정비수리필요.remove();
      } else {
        setIsRepairOptionDialogOpen(true);
      }
    }
  };

  const handleMeritDialogOpenChange = (open: boolean) => {
    if (open) {
      if (
        sellingOrderDTO &&
        sellingOrderDTO.orderData.additionalInfo.carState.차량장단점.selected
      ) {
        setIsMeritDialogOpen(false);
        handleClickEvents.차량장단점.remove();
      } else {
        setIsMeritDialogOpen(true);
      }
    }
  };

  const handleClickEvents = {
    타이어교체: {
      remove: async () => {
        if (!sellingOrderDTO) return;
        setSellingOrderDTO({
          ...sellingOrderDTO,
          update: {
            additionalInfo: {
              ...sellingOrderDTO.orderData.additionalInfo,
              carState: {
                ...sellingOrderDTO.orderData.additionalInfo.carState,
                타이어교체: {
                  selected: false,
                  content: '',
                },
              },
            },
          },
        });
      },
    },
    외관스크래치: {
      remove: async () => {
        if (!sellingOrderDTO) return;
        setSellingOrderDTO({
          ...sellingOrderDTO,
          update: {
            additionalInfo: {
              ...sellingOrderDTO.orderData.additionalInfo,
              carState: {
                ...sellingOrderDTO.orderData.additionalInfo.carState,
                외관스크래치: {
                  selected: false,
                  content: '',
                },
              },
            },
          },
        });
      },
    },
    정비수리필요: {
      remove: async () => {
        if (!sellingOrderDTO) return;
        setSellingOrderDTO({
          ...sellingOrderDTO,
          update: {
            additionalInfo: {
              ...sellingOrderDTO.orderData.additionalInfo,
              carState: {
                ...sellingOrderDTO.orderData.additionalInfo.carState,
                정비수리필요: {
                  selected: false,
                },
              },
            },
          },
        });
      },
      submit: async () => {
        if (!sellingOrderDTO) return;
        setSellingOrderDTO({
          ...sellingOrderDTO,
          update: {
            additionalInfo: {
              ...sellingOrderDTO.orderData.additionalInfo,
              carState: {
                ...sellingOrderDTO.orderData.additionalInfo.carState,
                정비수리필요: {
                  ...sellingOrderDTO.orderData.additionalInfo.carState
                    .정비수리필요,
                  additional: textRef_정비수리.current?.value,
                },
              },
            },
          },
        });
      },
    },
    차량장단점: {
      remove: async () => {
        if (!sellingOrderDTO) return;
        setSellingOrderDTO({
          ...sellingOrderDTO,
          update: {
            additionalInfo: {
              ...sellingOrderDTO.orderData.additionalInfo,
              carState: {
                ...sellingOrderDTO.orderData.additionalInfo.carState,
                차량장단점: {
                  selected: false,
                },
              },
            },
          },
        });
      },
      submit: async () => {
        if (!sellingOrderDTO) return;
        setSellingOrderDTO({
          ...sellingOrderDTO,
          update: {
            additionalInfo: {
              ...sellingOrderDTO.orderData.additionalInfo,
              carState: {
                ...sellingOrderDTO.orderData.additionalInfo.carState,
                차량장단점: {
                  selected: true,
                  content: textRef_장단점.current?.value,
                },
              },
            },
          },
        });
      },
    },
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }

    if (!sellingOrderDTO) return;

    if (
      !sellingOrderDTO.orderData.additionalInfo.carState?.차량장단점?.content ||
      sellingOrderDTO.orderData.additionalInfo.carState.차량장단점.content
        .length === 0
    )
      return;
    setTextCount_장단점(
      /** 한글은 3byte, 영문은 1byte */
      sellingOrderDTO.orderData.additionalInfo.carState?.차량장단점.content.replace(
        /[\0-\x7f]|([0-\u07ff]|(.))/g,
        '$&$1$2',
      ).length,
    );

    if (
      !sellingOrderDTO.orderData.additionalInfo.carState?.정비수리필요
        ?.content ||
      sellingOrderDTO.orderData.additionalInfo.carState.정비수리필요.content
        .length === 0
    )
      return;
    setTextCount_정비수리(
      /** 한글은 3byte, 영문은 1byte */
      sellingOrderDTO.orderData.additionalInfo.carState?.정비수리필요.content.replace(
        /[\0-\x7f]|([0-\u07ff]|(.))/g,
        '$&$1$2',
      ).length,
    );
  }, []);

  return (
    <div ref={ref} className='rounded border border-[#0075FF] bg-[#0075FF]/10'>
      <div className='flex h-[70px] items-center border-b border-[#0075FF] px-4 text-title font-semibold text-white'>
        차량의 상태를 알려주세요
      </div>
      <div className='space-y-4 px-4 py-6'>
        <div className='flex items-center text-white'>
          <div
            onClick={() => {
              setIsAccidentHistoryDetailOpen(true);
            }}
          >
            보험 처리 전체보기
          </div>
          <ChevronRight className='h-4 w-4' strokeWidth='1.5' />
        </div>

        {/* 타이어 교체 필요 */}
        <Dialog
          open={isTireOptionDialogOpen}
          onOpenChange={handleTireOptionDialogOpenChange}
        >
          <DialogTrigger asChild>
            <div className='flex h-[72px] items-center gap-2 border border-white px-4'>
              <div className='relative h-6 w-6'>
                <input
                  type='radio'
                  name='distance'
                  checked={
                    sellingOrderDTO?.orderData?.additionalInfo?.carState
                      ?.타이어교체?.selected
                  }
                  onChange={() => {}}
                  className={cn(
                    'flex h-6 w-6 appearance-none items-center justify-center rounded-full bg-[#0075FF]/10',
                    sellingOrderDTO?.orderData?.additionalInfo?.carState
                      ?.타이어교체?.selected && 'text-white',
                  )}
                />
                {sellingOrderDTO?.orderData?.additionalInfo?.carState
                  ?.타이어교체?.selected && (
                  <Check className='absolute left-1.5 top-1.5 h-3 w-3 text-white transition group-has-[:checked]:opacity-100' />
                )}
              </div>{' '}
              <div className='flex flex-col w-full'>
                <div
                  className={cn(
                    'text-base font-medium text-[#9EA3B2]',
                    sellingOrderDTO?.orderData?.additionalInfo?.carState
                      ?.타이어교체?.selected && 'text-white',
                  )}
                >
                  타이어 교체 필요
                </div>
                {sellingOrderDTO?.orderData?.additionalInfo?.carState
                  ?.타이어교체?.content && (
                  <div className='leading-3 text-[12px] font-medium text-[#9EA3B2]'>
                    {
                      sellingOrderDTO?.orderData?.additionalInfo?.carState
                        ?.타이어교체?.content
                    }
                  </div>
                )}
              </div>
            </div>
          </DialogTrigger>

          <DialogContent
            className='w-[311px] gap-0 border-0 p-0'
            onOpenAutoFocus={() => setIsTireOptionDialogOpen(false)}
          >
            <div className='space-y-4 px-4 py-6 pb-8'>
              <div className='text-center font-semibold'>
                타이어 교체 필요여부를 알려주세요!
              </div>
              <div className='text-[#555555]'>
                일반적인 교체 주기는 3~4만 KM입니다
              </div>
              {['필요없음', '앞 타이어 교체 필요', '뒤 타이어 교체 필요'].map(
                (item, index) => (
                  <label
                    key={index}
                    className='flex h-[54px] items-center gap-2 rounded border px-4'
                  >
                    <div className='relative leading-[0] before:absolute before:left-1.5 before:top-1.5 before:h-2.5 before:w-2.5 before:rounded-full before:bg-[#111111] before:opacity-0 before:transition-all has-[:checked]:before:opacity-100'>
                      <input
                        type='radio'
                        name='car'
                        onChange={() => {
                          if (!sellingOrderDTO) return;
                          setSellingOrderDTO({
                            ...sellingOrderDTO,
                            update: {
                              additionalInfo: {
                                ...sellingOrderDTO.orderData.additionalInfo,
                                carState: {
                                  ...sellingOrderDTO.orderData.additionalInfo
                                    .carState,
                                  타이어교체: {
                                    selected: true,
                                    content: item,
                                  },
                                },
                              },
                            },
                          });
                        }}
                        checked={
                          sellingOrderDTO?.orderData.additionalInfo.carState
                            ?.타이어교체?.selected &&
                          sellingOrderDTO?.orderData.additionalInfo.carState
                            ?.타이어교체?.content === item
                        }
                        value={item}
                        className='h-[22px] w-[22px] appearance-none rounded-full border outline-none transition-all checked:border-[#111111]'
                      />
                    </div>
                    <div>{item}</div>
                  </label>
                ),
              )}
            </div>
            <div className='grid grid-cols-1'>
              <DialogClose
                onClick={() => setIsTireOptionDialogOpen(false)}
                className='h-12 rounded-none border-0 bg-[#0075FF] text-sm font-semibold text-white'
              >
                확인
              </DialogClose>
            </div>
          </DialogContent>
        </Dialog>

        {/* 외판 스크래치 */}
        <Dialog
          open={isScratchOptionDialogOpen}
          onOpenChange={handleScratchOptionDialogOpenChange}
        >
          <DialogTrigger asChild>
            <div className='flex h-[72px] items-center gap-2 border border-white px-4'>
              <div className='relative h-6 w-6'>
                <input
                  type='radio'
                  name='distance'
                  checked={
                    sellingOrderDTO?.orderData?.additionalInfo?.carState
                      ?.외관스크래치?.selected
                  }
                  className={cn(
                    'flex h-6 w-6 appearance-none items-center justify-center rounded-full bg-[#0075FF]/10',
                    sellingOrderDTO?.orderData?.additionalInfo?.carState
                      ?.외관스크래치?.selected && 'text-white',
                  )}
                  onChange={() => {}}
                />
                {sellingOrderDTO?.orderData?.additionalInfo?.carState
                  ?.외관스크래치?.selected && (
                  <Check className='absolute left-1.5 top-1.5 h-3 w-3 text-white' />
                )}
              </div>

              <div className='flex flex-col w-full'>
                <div
                  className={cn(
                    'text-base font-medium text-[#9EA3B2]',
                    sellingOrderDTO?.orderData?.additionalInfo?.carState
                      ?.외관스크래치?.selected && 'text-white',
                  )}
                >
                  외판 스크래치
                </div>
                {sellingOrderDTO?.orderData?.additionalInfo?.carState
                  ?.외관스크래치?.content && (
                  <div className='leading-3 text-[12px] font-medium text-[#9EA3B2]'>
                    {
                      sellingOrderDTO?.orderData?.additionalInfo?.carState
                        ?.외관스크래치?.content
                    }
                  </div>
                )}
              </div>
            </div>
          </DialogTrigger>

          <DialogContent
            onOpenAutoFocus={() => setIsScratchOptionDialogOpen(false)}
            className='w-[311px] gap-0 border-0 p-0'
          >
            <div className='space-y-4 px-4 py-6 pb-8'>
              <div className='text-center font-semibold'>
                외판 스크래치는 몇 판인가요?
              </div>
              {['없음', '1~2판', '3~4판', '5~6판'].map((item) => (
                <label
                  key={item}
                  className='flex h-[54px] items-center gap-2 rounded border px-4'
                >
                  <div className='relative leading-[0] before:absolute before:left-1.5 before:top-1.5 before:h-2.5 before:w-2.5 before:rounded-full before:bg-[#111111] before:opacity-0 before:transition-all has-[:checked]:before:opacity-100'>
                    <input
                      type='radio'
                      name='car'
                      onClick={() => {
                        if (!sellingOrderDTO) return;
                        setSellingOrderDTO({
                          ...sellingOrderDTO,
                          update: {
                            additionalInfo: {
                              ...sellingOrderDTO.orderData.additionalInfo,
                              carState: {
                                ...sellingOrderDTO.orderData.additionalInfo
                                  .carState,
                                외관스크래치: {
                                  selected: true,
                                  content: item,
                                },
                              },
                            },
                          },
                        });
                      }}
                      checked={
                        sellingOrderDTO?.orderData.additionalInfo.carState
                          ?.외관스크래치?.selected &&
                        sellingOrderDTO?.orderData.additionalInfo.carState
                          ?.외관스크래치?.content === item
                      }
                      value={item}
                      className='h-[22px] w-[22px] appearance-none rounded-full border outline-none transition-all checked:border-[#111111]'
                    />
                  </div>
                  <div>{item}</div>
                </label>
              ))}
            </div>
            <div className='grid grid-cols-1'>
              <DialogClose
                onClick={() => setIsScratchOptionDialogOpen(false)}
                className='h-12 rounded-none border-0 bg-[#0075FF] text-sm font-semibold text-white'
              >
                확인
              </DialogClose>
            </div>
          </DialogContent>
        </Dialog>

        {/* 장비 수리 필요 항목 */}
        <Dialog
          open={isRepairOptionDialogOpen}
          onOpenChange={handleRepairOptionDialogOpenChange}
        >
          <DialogTrigger asChild>
            <div className='flex h-[72px] items-center gap-2 border border-white px-4'>
              <div className='relative h-6 w-6'>
                <input
                  type='radio'
                  name='distance'
                  checked={
                    sellingOrderDTO?.orderData?.additionalInfo?.carState
                      ?.정비수리필요?.selected
                  }
                  className={cn(
                    'flex h-6 w-6 appearance-none items-center justify-center rounded-full bg-[#0075FF]/10',
                    sellingOrderDTO?.orderData?.additionalInfo?.carState
                      ?.정비수리필요?.selected && 'text-white',
                  )}
                  onChange={() => {}}
                />
                {sellingOrderDTO?.orderData?.additionalInfo?.carState
                  ?.정비수리필요?.selected && (
                  <Check className='absolute left-1.5 top-1.5 h-3 w-3 text-white' />
                )}
              </div>

              <div className='flex flex-col w-full'>
                <div
                  className={cn(
                    'text-base font-medium text-[#9EA3B2]',
                    sellingOrderDTO?.orderData?.additionalInfo?.carState
                      ?.정비수리필요?.selected && 'text-white',
                  )}
                >
                  정비 수리 필요 항목
                </div>
                {sellingOrderDTO?.orderData?.additionalInfo?.carState
                  ?.정비수리필요?.content && (
                  <div className='leading-3 text-[12px] font-medium text-[#9EA3B2]'>
                    {
                      sellingOrderDTO?.orderData?.additionalInfo?.carState
                        ?.정비수리필요?.content
                    }
                  </div>
                )}
              </div>
            </div>
          </DialogTrigger>

          <DialogContent
            onOpenAutoFocus={() => setIsRepairOptionDialogOpen(false)}
            className='w-[311px] gap-0 border-0 p-0'
          >
            <div className='space-y-4 px-4 py-6 pb-8'>
              <div className='text-center font-semibold'>
                정비 및 수리 필요사항이 있다면 알려주세요
              </div>
              {['필요없음', '정비/수리 필요'].map((item) => (
                <label
                  key={item}
                  className='flex h-[54px] items-center gap-2 rounded border px-4'
                >
                  <div className='relative leading-[0] before:absolute before:left-1.5 before:top-1.5 before:h-2.5 before:w-2.5 before:rounded-full before:bg-[#111111] before:opacity-0 before:transition-all has-[:checked]:before:opacity-100'>
                    <input
                      type='radio'
                      name='car'
                      onClick={() => {
                        if (!sellingOrderDTO) return;
                        if (textRef_정비수리.current)
                          textRef_정비수리.current.value = '';
                        setSellingOrderDTO({
                          ...sellingOrderDTO,
                          update: {
                            additionalInfo: {
                              ...sellingOrderDTO.orderData.additionalInfo,
                              carState: {
                                ...sellingOrderDTO.orderData.additionalInfo
                                  .carState,
                                정비수리필요: {
                                  selected: true,
                                  content: item,
                                },
                              },
                            },
                          },
                        });
                      }}
                      checked={
                        sellingOrderDTO?.orderData.additionalInfo.carState
                          ?.정비수리필요?.selected &&
                        sellingOrderDTO?.orderData.additionalInfo.carState
                          ?.정비수리필요?.content === item
                      }
                      value={item}
                      className='h-[22px] w-[22px] appearance-none rounded-full border outline-none transition-all checked:border-[#111111]'
                    />
                  </div>
                  <div>{item}</div>
                </label>
              ))}
              <div className='relative border bg-white'>
                <Textarea
                  className='h-[158px] border-0 focus:border-0'
                  ref={textRef_정비수리}
                  onChange={(e) => {
                    setTextCount_정비수리(
                      /** 한글은 3byte, 영문은 1byte */
                      e.target.value.replace(
                        /[\0-\x7f]|([0-\u07ff]|(.))/g,
                        '$&$1$2',
                      ).length,
                    );
                  }}
                  defaultValue={
                    sellingOrderDTO?.orderData?.additionalInfo?.carState
                      ?.정비수리필요?.additional
                      ? sellingOrderDTO.orderData.additionalInfo.carState
                          .정비수리필요?.additional
                      : ''
                  }
                  placeholder='예) 헤드라이트 커버 및 전구 교체 필요'
                />
                <div className='absolute bottom-4 right-2.5 text-[#767676]'>
                  {textCount_정비수리}/1000
                </div>
              </div>
            </div>
            <div className='grid grid-cols-2'>
              <DialogClose
                onClick={() => setIsRepairOptionDialogOpen(false)}
                className='h-12 rounded-none border-0 bg-[#F7F7FB] text-sm font-semibold'
              >
                취소
              </DialogClose>
              <DialogClose
                onClick={() => {
                  setIsRepairOptionDialogOpen(false);
                  handleClickEvents.정비수리필요.submit();
                }}
                className='rounded-none border-0 bg-[#0075FF] text-sm font-semibold text-white'
              >
                수정하기
              </DialogClose>
            </div>
          </DialogContent>
        </Dialog>

        {/* 내 차의 장/단점 */}
        <Dialog
          open={isMeritDialogOpen}
          onOpenChange={handleMeritDialogOpenChange}
        >
          <DialogTrigger asChild>
            <div className='flex h-[72px] items-center gap-2 border border-white px-4'>
              <div className='relative h-6 w-6'>
                <input
                  type='radio'
                  name='distance'
                  checked={
                    sellingOrderDTO?.orderData?.additionalInfo?.carState
                      ?.차량장단점?.selected
                  }
                  className={cn(
                    'flex h-full w-6 appearance-none items-center justify-center rounded-full bg-[#0075FF]/10',
                    sellingOrderDTO?.orderData?.additionalInfo?.carState
                      ?.차량장단점?.selected && 'text-white',
                  )}
                  onChange={() => {}}
                />
                {sellingOrderDTO?.orderData?.additionalInfo?.carState
                  ?.차량장단점?.selected && (
                  <Check className='absolute left-1.5 top-1.5 h-3 w-3 text-white' />
                )}
              </div>
              <div className='flex flex-col w-full pr-8'>
                <div
                  className={cn(
                    'text-base font-medium text-[#9EA3B2]',
                    sellingOrderDTO?.orderData?.additionalInfo?.carState
                      ?.차량장단점?.selected && 'text-white',
                  )}
                >
                  내 차의 장/단점
                </div>
                {sellingOrderDTO?.orderData?.additionalInfo?.carState
                  ?.외관스크래치?.content && (
                  <div className='leading-3 text-[12px] font-medium text-[#9EA3B2] w-full truncate overflow-hidden whitespace-nowrap'>
                    {
                      sellingOrderDTO?.orderData?.additionalInfo?.carState
                        ?.차량장단점?.content
                    }
                  </div>
                )}
              </div>
            </div>
          </DialogTrigger>
          <DialogContent
            onOpenAutoFocus={() => setIsMeritDialogOpen(false)}
            className='w-[311px] gap-0 border-0 p-0'
          >
            <div className='space-y-4 px-4 py-6 pb-8'>
              <div className='text-center font-semibold'>
                내 차의 장/단점을 알려주세요
              </div>
              <div className='relative border bg-white'>
                <Textarea
                  ref={textRef_장단점}
                  onChange={(e) => {
                    setTextCount_장단점(
                      /** 한글은 3byte, 영문은 1byte */
                      e.target.value.replace(
                        /[\0-\x7f]|([0-\u07ff]|(.))/g,
                        '$&$1$2',
                      ).length,
                    );
                  }}
                  defaultValue={
                    sellingOrderDTO?.orderData?.additionalInfo?.carState
                      ?.차량장단점?.content
                      ? sellingOrderDTO.orderData.additionalInfo.carState
                          .차량장단점?.content
                      : ''
                  }
                  className='h-[158px] border-0 focus:border-0'
                  placeholder='예) 타이어 미쉐린으로 교체한지 3개월'
                />
                <div className='absolute bottom-4 right-2.5 text-[#767676]'>
                  {textCount_장단점}
                  /1000
                </div>
              </div>
            </div>
            <div className='grid grid-cols-2'>
              <DialogClose
                onClick={() => setIsMeritDialogOpen(false)}
                className='h-12 rounded-none border-0 bg-[#F7F7FB] text-sm font-semibold'
              >
                취소
              </DialogClose>
              <DialogClose
                onClick={() => {
                  setIsMeritDialogOpen(false);
                  handleClickEvents.차량장단점.submit();
                }}
                className='rounded-none border-0 bg-[#0075FF] text-sm font-semibold text-white'
              >
                수정하기
              </DialogClose>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
