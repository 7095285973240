import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { JSX } from 'react';
import useMemberStore from './useMemberStore';
import { Member } from '@carsayo/types';

export type TabbarState = 'fixed' | 'hidden';
export type TabberMode = 'main' | 'mainType_2' | 'subpage';

export type TopTabberState = {
  title?: string;
  state: TabbarState;
  mode: TabberMode;
  className?: string;
  leftElement?: 'back' | JSX.Element;
  rightElement?: 'home' | JSX.Element;
};

interface SystemState {
  /** 이 값이 true로 시작할 경우 /static path로 리다이렉트되어 들어왔을 때입니다.
   * @notice 로그인되어있지 않아도 회원가입 페이지로 이동하지 않습니다.
   */
  isStatic: boolean;
  isLoadingDim: boolean;
  isBottomTabbar: boolean;
  isSideMenu: boolean;

  topTabberState: TopTabberState;
  /** 앱에 무조건 존재하는값. ViewPoint.tsx - viewRef.current */
  viewPointRef: HTMLDivElement;
}

interface SystemAction {
  setLoadingDim: (setLoadingState: boolean) => void;

  setTopTabbarTitle: (title: string) => void;
  setTopTabbarState: (topTabberState: TopTabberState) => void;

  setIsBottomTabbar: (isExist: boolean) => void;
  setIsSideMenu: (isExist: boolean) => void;
  setViewRef: (ref: HTMLDivElement) => void;
}

const useSystemStore = create<SystemState & SystemAction>()(
  devtools(
    immer((set, get) => ({
      isStatic: window.location.pathname.startsWith('/static'),
      isLoadingDim: false,
      isBottomTabbar: true,
      isSideMenu: false,
      topTabberState: {
        state: 'fixed',
        mode: 'main',
      },
      viewPointRef: undefined as any,

      setLoadingDim: (setLoadingState: boolean) => {
        set((state) => {
          state.isLoadingDim = setLoadingState;
        });
      },

      setIsSideMenu: (isShown: boolean) => {
        set((state) => {
          state.isSideMenu = isShown;
        });
      },

      setIsBottomTabbar: (isShown: boolean) => {
        // 카매니저 바텀 탭바는 없습니다.
        const loginMember: Member | null =
          useMemberStore.getState().loginMember;
        if (loginMember?.type === 'dealer') isShown = false;

        set((state) => {
          state.isBottomTabbar = isShown;
        });
      },

      setTopTabbarTitle: (title: string) => {
        set((state) => {
          state.topTabberState = {
            ...state.topTabberState,
            title: title,
          };
        });
      },
      setTopTabbarState: (topTabberState: TopTabberState) => {
        set((state) => {
          state.topTabberState = {
            title: topTabberState.title
              ? topTabberState.title
              : state.topTabberState.title,
            mode: topTabberState.mode,
            state: topTabberState.state,
            className: topTabberState.className,
            leftElement: topTabberState.leftElement,
            rightElement: topTabberState.rightElement,
          };
        });
      },
      /** 불변 값 초기 설정. ViewPoint.tsx
       * @notice 처음 정의된 내용에서 변경되어서는 안되므로 사용하면 안됩니다.
       * @description 앱 최 상위 div의 ref값인 viewRef.current 값을 저장합니다.
       */
      setViewRef: (ref: HTMLDivElement) =>
        set((state) => {
          state.viewPointRef = ref as any;
        }),
    })),
    {
      name: 'systemStore',
    },
  ),
);

export default useSystemStore;
