import { Location } from 'react-router-dom';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface HistoryData extends Location {
  scroll?: number;
  // data?: any;
  restore?: () => void;
}

interface HistoryInterface {
  history: HistoryData[];
}

interface HistoryAction {
  setHistory: (history: HistoryData[]) => void;
  addHistory: (history: HistoryData) => void;
  moveHistory: (index: number) => void;
  resetHistory: () => void;
}
const useHistoryStore = create<HistoryInterface & HistoryAction>()(
  devtools(
    immer((set, get) => ({
      history: [],
      setHistory: (history: HistoryData[]) => {
        set((state) => {
          state.history = history;
        });
      },
      addHistory: (history: HistoryData) => {
        const getCurrentState = get();
        set((state) => {
          state.history = [...getCurrentState.history, history];
        });
      },
      moveHistory: (index: number) => {
        const getCurrentState = get();
        set((state) => {
          state.history = [...getCurrentState.history.slice(0, index + 1)];
        });
      },
      resetHistory: () => {
        set((state) => {
          state.history = [];
        });
      },
    })),
    {
      name: 'historyStore',
    },
  ),
);

export default useHistoryStore;
