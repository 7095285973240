/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef } from 'react';
import usePayment from 'store/usePayment';

const CarsayoPayment = () => {
  const paymentFormRef = useRef<HTMLFormElement>(null);

  const { nicepaymentInitData } = usePayment();

  const nicepayStart = async () => {
    if (nicepaymentInitData && paymentFormRef.current) {
      if (paymentFormRef.current) {
        window.goPay(paymentFormRef.current);
      }
    }
  };

  useEffect(() => {
    if (nicepaymentInitData) nicepayStart();
  }, [nicepaymentInitData]);

  return (
    <div className='hidden'>
      {nicepaymentInitData && (
        <form
          ref={paymentFormRef}
          name='payForm'
          method='post'
          acceptCharset='euc-kr'
          action={nicepaymentInitData.ReturnURL}
          target='_self'
          className='hidden'
        >
          <input
            type='text'
            name='PayMethod'
            value={nicepaymentInitData.PayMethod}
          />
          <input
            type='text'
            name='GoodsName'
            value={nicepaymentInitData.GoodsName}
          />
          <input type='text' name='Amt' value={nicepaymentInitData.Amt} />
          <input type='text' name='MID' value={nicepaymentInitData.MID} />
          <input type='text' name='Moid' value={nicepaymentInitData.Moid} />
          <input
            type='text'
            name='BuyerName'
            value={nicepaymentInitData.BuyerName}
          />
          <input
            type='text'
            name='BuyerEmail'
            value={nicepaymentInitData.BuyerEmail}
          />
          <input
            type='text'
            name='BuyerTel'
            value={nicepaymentInitData.BuyerTel}
          />
          <input
            type='text'
            name='ReturnURL'
            value={nicepaymentInitData.ReturnURL}
          />
          <input
            type='text'
            name='VbankExpDate'
            value={nicepaymentInitData.VbankExpDate}
          />

          <input name='NpLang' value='KO' />
          <input name='GoodsCl' value='1' />
          <input name='TransType' value='0' />
          <input name='CharSet' value={nicepaymentInitData.CharSet} />

          <input name='EdiDate' value={nicepaymentInitData.EdiDate} />
          <input
            type='hidden'
            name='SignData'
            value={nicepaymentInitData.SignData}
          />
        </form>
      )}
    </div>
  );
};
export default CarsayoPayment;
