import { Check } from 'lucide-react';
import useSellingStore from '../store';
import { useEffect, useRef } from 'react';

export default function StepComponent5() {
  const { sellingOrderDTO, setSellingOrderDTO } = useSellingStore();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div ref={ref} className='rounded border border-[#0075FF] bg-[#0075FF]/10'>
      <div className='flex h-[70px] items-center border-b border-[#0075FF] px-4 text-title font-semibold text-white'>
        구매 방식을 알려주세요
      </div>
      <div className='space-y-4 px-4 py-6'>
        <label className='group flex h-[72px] items-center gap-2 border border-white px-4'>
          <div className='relative h-6 w-6'>
            <input
              type='radio'
              name='region'
              onClick={() => {
                if (!sellingOrderDTO) return;
                setSellingOrderDTO({
                  ...sellingOrderDTO,
                  update: {
                    boughtMethod: 'cash',
                  },
                });
              }}
              checked={sellingOrderDTO?.orderData.boughtMethod === 'cash'}
              className='flex h-6 w-6 appearance-none items-center justify-center rounded-full bg-[#0075FF]/10'
            />
            <Check className='absolute left-1.5 top-1.5 h-3 w-3 text-white opacity-0 group-has-[:checked]:opacity-100' />
          </div>
          <div className='text-base font-medium text-[#9EA3B2] group-has-[:checked]:text-white'>
            현금 또는 할부
          </div>
        </label>
        <label className='group flex h-[72px] items-center gap-2 border border-white px-4'>
          <div className='relative h-6 w-6'>
            <input
              type='radio'
              name='region'
              onClick={() => {
                if (!sellingOrderDTO) return;
                setSellingOrderDTO({
                  ...sellingOrderDTO,
                  update: {
                    boughtMethod: 'operatingLease',
                  },
                });
              }}
              checked={
                sellingOrderDTO?.orderData.boughtMethod === 'operatingLease'
              }
              className='flex h-6 w-6 appearance-none items-center justify-center rounded-full bg-[#0075FF]/10'
            />
            <Check className='absolute left-1.5 top-1.5 h-3 w-3 text-white opacity-0 group-has-[:checked]:opacity-100' />
          </div>
          <div className='text-base font-medium text-[#9EA3B2] group-has-[:checked]:text-white'>
            운용 리스
          </div>
        </label>
        <label className='group flex h-[72px] items-center gap-2 border border-white px-4'>
          <div className='relative h-6 w-6'>
            <input
              type='radio'
              name='region'
              onClick={() => {
                if (!sellingOrderDTO) return;
                setSellingOrderDTO({
                  ...sellingOrderDTO,
                  update: {
                    boughtMethod: 'capitalLease',
                  },
                });
              }}
              checked={
                sellingOrderDTO?.orderData.boughtMethod === 'capitalLease'
              }
              className='flex h-6 w-6 appearance-none items-center justify-center rounded-full bg-[#0075FF]/10'
            />
            <Check className='absolute left-1.5 top-1.5 h-3 w-3 text-white opacity-0 group-has-[:checked]:opacity-100' />
          </div>
          <div className='text-base font-medium text-[#9EA3B2] group-has-[:checked]:text-white'>
            금융 리스
          </div>
        </label>
      </div>
    </div>
  );
}
