import { PaymentState } from '@carsayo/types';
import dayjs from 'dayjs';

export const PaidItem = ({
  method,
  amount,
  paid_at,
  status,
  canceled_at,
}: {
  method: string;
  amount: number;
  paid_at: Date | null;
  status: PaymentState;
  canceled_at?: Date | null;
}) => {
  const getMethodType = (method: string) => {
    switch (method) {
      case 'CARD':
        return '신용카드';
      case 'BANK':
        return '계좌이체';
      case 'CELLPHONE':
        return '휴대폰 결제';
      case 'VBANK':
        return '가상계좌';
      default:
        return '';
    }
  };

  return (
    <div className='border-t'>
      <div className='flex h-[52px] items-center border-b'>
        <div className='w-[100px] shrink-0 bg-[#F7F7FB] p-4'>결제방법</div>
        <div className='p-4 text-[#767676]'>{getMethodType(method)}</div>
      </div>
      <div className='flex h-[52px] items-center border-b'>
        <div className='w-[100px] shrink-0 bg-[#F7F7FB] p-4'>입금금액</div>
        <div className='p-4 text-[#767676]'>{amount.toLocaleString()}원</div>
      </div>
      <div className='flex h-[52px] items-center border-b'>
        <div className='w-[100px] shrink-0 bg-[#F7F7FB] p-4'>결제일시</div>
        <div className='p-4 text-[#767676]'>
          {dayjs(paid_at).format('YYYY년 MM월 DD일 HH:mm')}
        </div>
      </div>
      {status === 'canceled' && (
        <div className='flex h-[52px] items-center border-b'>
          <div className='w-[100px] shrink-0 bg-[#F7F7FB] p-4'>취소일시</div>
          <div className='p-4 text-[#767676]'>
            {canceled_at !== null &&
              dayjs(canceled_at).format('YYYY년 MM월 DD일 HH:mm')}
          </div>
        </div>
      )}
      {status === 'cancelFailed' && (
        <div className='border-b p-4 text-[#292D32] text-sm'>
          결제가 오래 전 발생하여 환불 처리가 지연되고 있습니다. 문제가 계속될
          경우 카사요 고객센터로 문의해 주세요.
        </div>
      )}
    </div>
  );
};
