import { DealerOrderDetail } from '@carsayo/types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getManagerQuoteDetailApi } from 'apis/quote';

export const useDealerOrderDetail = ({ id }: { id: string }) => {
  return useQuery({
    queryKey: ['dealerOrderDetail', id],
    queryFn: async () => {
      const order: DealerOrderDetail = await getManagerQuoteDetailApi(id);
      return order;
    },
    placeholderData: (previousData, previousQuery) => previousData,
    gcTime: 5 * 60 * 1000, // 5 min
    staleTime: 60 * 1000, // 1 min
  });
};

/** Hook */
export const useUpdateDealerOrderDetail = () => {
  const queryClient = useQueryClient();

  const updateDealerOrderDetail = ({
    id,
    data,
  }: {
    id: string;
    data: DealerOrderDetail;
  }) => {
    queryClient.setQueryData(['dealerOrderDetail', id], data);
  };
  return updateDealerOrderDetail;
};
