import React from 'react';

interface MyComponentProps {
  fill?: string;
  width?: string;
  height?: string;
}

const EventMenuIcon: React.FC<MyComponentProps> = ({ fill, width, height }) => {
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2126_8067)'>
        <path d='M16 0H0V16H16V0Z' fill='white' />
        <path
          d='M10.68 1.33325C8.88266 1.33325 8.26666 3.16792 8.06399 3.77325C8.03466 3.86392 8.00266 3.95192 7.97066 4.04259C7.93866 3.95192 7.90933 3.86392 7.87733 3.77325C7.67466 3.17059 7.05599 1.33325 5.26133 1.33325C3.79999 1.33325 2.61067 2.52259 2.61067 3.98392C2.61067 5.44525 3.79999 6.63459 5.26133 6.63459H10.6773C12.1387 6.63459 13.328 5.44525 13.328 3.98392C13.328 2.52259 12.1387 1.33325 10.6773 1.33325H10.68ZM5.26399 5.30125C4.53599 5.30125 3.94666 4.70925 3.94666 3.98392C3.94666 3.25859 4.53866 2.66659 5.26399 2.66659C5.67733 2.66659 6.16266 2.84795 6.61599 4.19728C6.74399 4.58128 6.88533 4.97059 7.00799 5.30392H5.26399V5.30125ZM10.68 5.30125H8.93599C9.05866 4.96792 9.19733 4.57859 9.328 4.19459C9.78133 2.84525 10.2667 2.66393 10.68 2.66393C11.408 2.66393 11.9973 3.25595 11.9973 3.98128C11.9973 4.70661 11.4053 5.29861 10.68 5.29861V5.30125Z'
          fill='#F26166'
        />
        <path
          d='M12.376 5.72534H3.57067C2.53975 5.72534 1.70401 6.56108 1.70401 7.59201V12.7307C1.70401 13.7616 2.53975 14.5973 3.57067 14.5973H12.376C13.4069 14.5973 14.2427 13.7616 14.2427 12.7307V7.59201C14.2427 6.56108 13.4069 5.72534 12.376 5.72534Z'
          fill='#FFD142'
        />
        <path
          d='M8.98669 5.14136H6.95203V14.632H8.98669V5.14136Z'
          fill='#FFAC00'
        />
        <path
          d='M13.008 4.97339H2.93333C2.05333 4.97339 1.33333 5.69339 1.33333 6.57339V7.90139C1.33333 8.19472 1.57333 8.43472 1.86666 8.43472H14.0747C14.368 8.43472 14.608 8.19472 14.608 7.90139V6.57339C14.608 5.69339 13.888 4.97339 13.008 4.97339Z'
          fill='#FFEC74'
        />
        <path
          d='M12.232 4.90674H9.95202C9.87736 4.90674 9.81602 4.9654 9.81602 5.0374V10.3654C9.81602 10.4107 9.84002 10.4534 9.88269 10.4774C9.90402 10.4907 9.92802 10.4961 9.95202 10.4961C9.97336 10.4961 9.99736 10.4907 10.016 10.4801L11.0667 9.93607C11.0667 9.93607 11.08 9.92807 11.0907 9.92807C11.1014 9.92807 11.1174 9.93607 11.1174 9.93607L12.168 10.4801C12.1894 10.4907 12.2107 10.4961 12.232 10.4961C12.256 10.4961 12.28 10.4907 12.3014 10.4774C12.3414 10.4534 12.368 10.4107 12.368 10.3654V5.0374C12.368 4.9654 12.3067 4.90674 12.232 4.90674Z'
          fill='#F26166'
        />
      </g>
      <defs>
        <clipPath id='clip0_2126_8067'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
export default EventMenuIcon;
