import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCarsayoBannerStore from 'store/useCarsayoBanner';
import {
  BannerCategoryId,
  BannerContent,
  BannerCategoryData,
} from '@carsayo/types';

interface CarsayoBannerInterface {
  bannerCategoryId: BannerCategoryId;
  className?: string;
  pagination?: 'bullet-tr' | 'bullet-bl';
}
const CarsayoBanner = ({
  bannerCategoryId,
  className,
  pagination,
}: CarsayoBannerInterface) => {
  const [bannerData, setBannerData] = useState<BannerContent[]>([]);
  const [bannerSize, setBannerSize] = useState<{
    width: number;
    height: number;
  }>();

  const { getBannerContent } = useCarsayoBannerStore();

  const navigate = useNavigate();

  const getBannerData = async () => {
    const bannerData = await getBannerContent(bannerCategoryId);
    setBannerData(bannerData);
  };

  const getBannerSize = () => {
    const targetBannerInfo = BannerCategoryData.filter((el) => {
      return el.id === bannerCategoryId;
    });

    if (targetBannerInfo[0]) {
      setBannerSize({
        height: targetBannerInfo[0].proper_height,
        width: targetBannerInfo[0].proper_width,
      });
    }

    return;
  };

  useLayoutEffect(() => {
    getBannerSize();
    getBannerData();
  }, []);

  return (
    <>
      {bannerSize && (
        <div
          className={`w-full ${className ? className : ''}`}
          style={{
            height: `${bannerSize?.height}px`,
          }}
        >
          {bannerData.length > 0 ? (
            <Swiper
              modules={[Pagination, Autoplay]}
              spaceBetween={0}
              slidesPerView={1}
              autoplay={true}
              loop={bannerData.length > 2 ? true : false}
              pagination={pagination ? { clickable: true } : undefined}
              className={`${pagination ? pagination : ''} max-w-[900px] h-full`}
            >
              {bannerData &&
                bannerData.map((el, index) => {
                  return (
                    <SwiperSlide
                      key={`carsayoBanner_${index}`}
                      className='flex items-center justify-center'
                      onClick={() => {
                        if (el.redirectPath) navigate(el.redirectPath);
                      }}
                    >
                      <img
                        className={`object-cover min-w-[100%] min-h-[100%]`}
                        style={{
                          height: `${bannerSize?.height}px`,
                        }}
                        src={el.bannerFile.url}
                      ></img>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          ) : (
            <SwiperSlide
              className='flex items-center justify-center'
              onClick={() => {
                if (bannerData[0]?.redirectPath)
                  navigate(bannerData[0].redirectPath);
              }}
            >
              <img
                className={`object-cover min-w-[100%] min-h-[100%]`}
                style={{
                  height: `${bannerSize?.height}px`,
                }}
                src={bannerData[0]?.bannerFile?.url}
              ></img>
            </SwiperSlide>
          )}
        </div>
      )}
    </>
  );
};

export default CarsayoBanner;
