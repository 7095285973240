import { SellingOrderDTO } from '@carsayo/types';
import { getSelling, updateSelling } from 'apis/selling';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface SellingState {
  /** 현재 선택되어 있는 주문 */
  sellingOrderDTO?: SellingOrderDTO;

  /** 현재 작성중인 주문 리스트 */
  sellingOrderDTOList?: SellingOrderDTO[];
}
interface SellingAction {
  initSellingStore: () => void;
  removeSellingOrderDTO: () => void;
  setSellingOrderDTO: (sellingOrderDTO: SellingOrderDTO) => void;
  /** 이 함수는 setSellingOrderDTO와 같으나 로컬에서만 데이터를 수정합니다 */
  setSellingOrderDTO_InLocal: (sellingOrderDTO: SellingOrderDTO) => void;
  resetSellingStore: () => void;
}

const useSellingStore = create<SellingState & SellingAction>()(
  devtools(
    immer((set, get) => ({
      sellingOrderDTO: undefined,
      sellingOrderDTOList: undefined,

      initSellingStore: async () => {
        const writingOrderList = await getSelling();
        set((state) => {
          state.sellingOrderDTOList = writingOrderList;
        });
      },

      setSellingOrderDTO: async (sellingOrderDTO: SellingOrderDTO) => {
        const updatedSelling = await updateSelling(sellingOrderDTO);

        set((state) => {
          state.sellingOrderDTO = updatedSelling;
        });
      },
      setSellingOrderDTO_InLocal: (sellingOrderDTO: SellingOrderDTO) => {
        set((state) => {
          state.sellingOrderDTO = sellingOrderDTO;
        });
      },
      removeSellingOrderDTO: () => {
        set((state) => {
          state.sellingOrderDTO = undefined;
        });
      },
      resetSellingStore: () => {
        set((state) => {
          state.sellingOrderDTO = undefined;
          state.sellingOrderDTOList = undefined;
        });
      },
    })),
  ),
);

export default useSellingStore;
