// 만원, 억원만 변환
export default function numberToKorean(amount: number): string {
  if (amount >= 100000000) {
    return Math.floor(amount / 100000000).toLocaleString() + '억 원';
  } else if (amount >= 10000) {
    return Math.floor(amount / 10000).toLocaleString() + '만원';
  } else {
    return amount.toString().toLocaleString() + '원';
  }
}
