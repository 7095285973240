/** 카사요 어드민에서 등록한 팝업을 관련 전역상태를 통해 띄웁니다 */

import {
  PopupCategoryId,
  PopupCategoryIdDTO,
  PopupContent,
} from '@carsayo/types';
import { api } from 'apis';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type CarsayoPopupType = 'bottom' | 'middle';
interface CarsayoPopupState {
  isPopupOpen: boolean;
  /** 한 번 노출된 팝업은 두번 다시 노출되지 않게 하기 위한 메모리
   * @notice 앱이 다시 실행될 경우 그땐 보여요
   * @notice "오늘 하루 보지 않기"와는 독립적입니다.
   */
  openedPopupIdList: Set<PopupCategoryId>;
  currentPopupData: {
    id: PopupCategoryId;
    type: CarsayoPopupType;
    title: string | null;
    htmlContent: string;
    redirectPath: string | null;
  } | null;
}

interface BottomPopupAction {
  openCarsayoPopup: (
    popupCategoryId: PopupCategoryId,
    type: CarsayoPopupType,
  ) => void;
  closeCarsayoPopup: () => void;
}

const getcurrentPopupData = async (
  reqData: PopupCategoryIdDTO,
): Promise<PopupContent> => {
  try {
    const response = await api.get(`popup?categoryId=${reqData.categoryId}`);
    const data: PopupContent = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

const useCarsayoPopupStore = create<CarsayoPopupState & BottomPopupAction>()(
  devtools(
    immer((set, get) => ({
      isPopupOpen: false,

      currentPopupData: null,
      openedPopupIdList: new Set<PopupCategoryId>(),

      openCarsayoPopup: async (
        popupCategoryId: PopupCategoryId,
        type: CarsayoPopupType,
      ) => {
        // 앱이 켜져 있는 동안 보여졌던 팝업인지 확인
        const getCurrentState = get();
        if (getCurrentState.openedPopupIdList.has(popupCategoryId)) return;
        set((state) => {
          state.openedPopupIdList =
            getCurrentState.openedPopupIdList.add(popupCategoryId);
        });

        // 오늘 하루 보지 않기 확인
        const notShowPopupToday = localStorage.getItem(
          `notShowPopupToday_[${popupCategoryId}]`,
        );
        if (notShowPopupToday && new Date(notShowPopupToday) > new Date()) {
          return;
        } else {
          localStorage.removeItem(`notShowPopupToday_[${popupCategoryId}]`);
        }

        const currentPopupData = await getcurrentPopupData({
          categoryId: popupCategoryId,
        }).catch(() => {
          console.info(
            `E-KTF32AT [${popupCategoryId}] 팝업이 설정되지 않았습니다`,
          );
          return;
        });
        if (!currentPopupData) return;

        set((state) => {
          state.isPopupOpen = true;
          state.currentPopupData = {
            id: popupCategoryId,
            type: type,
            title: currentPopupData.title,
            htmlContent: currentPopupData.content_html,
            redirectPath: currentPopupData.redirectPath,
          };
        });
      },
      closeCarsayoPopup: () => {
        set((state) => {
          state.isPopupOpen = false;
          state.currentPopupData = null;
        });
      },
    })),
    {
      name: 'carsayoPopupStore',
    },
  ),
);

export default useCarsayoPopupStore;
