import usePurchaseStore from '../store';

export default function PurchasingCarInfo() {
  const { purchaseOrderDTO } = usePurchaseStore();

  return (
    <div className='flex-none relative z-10 px-4 py-8 shadow-[0_5px_10px_0_rgba(0,0,0,.06)]'>
      <div className='text-xl font-semibold'>
        {purchaseOrderDTO?.orderData?.carMaker?.name}{' '}
        {purchaseOrderDTO?.orderData?.carModel?.name}
      </div>
      <div className='mt-1.5'>
        {purchaseOrderDTO?.orderData?.carGrade?.name}
      </div>
      <div className='mx-auto mt-2 h-[120px] w-[186px]'>
        <img src={purchaseOrderDTO?.orderData?.carModel?.image_url} alt='' />
      </div>
    </div>
  );
}
