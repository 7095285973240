/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChevronLeft, CornerDownRight } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'components/ui/button';
import { ReactNode, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import useSellingStore from './store';
import dayjs from 'dayjs';

import SellingAccidentHistoryDetail from './detail/insurance';

import StepComponent0 from './detail/stepComponent0';
import StepComponent1 from './detail/StepComponent1';
import StepComponent2 from './detail/stepComponent2';
import StepComponent3 from './detail/stepComponent3';
import StepComponent4 from './detail/stepComponent4';
import StepComponent5 from './detail/stepComponent5';

import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import useSystemStore from 'store/useSystemStore';
import SellingCarEstimatePrice from './detail/price';

export default function SellingDetail() {
  const navigate = useNavigate();

  const [step, setStep] = useState<number>(0);
  const { topTabberState, setTopTabbarState } = useSystemStore();
  const [isAccidentHistoryDetailOpen, setIsAccidentHistoryDetailOpen] =
    useState(false);
  const [isEstimatePriceOpen, setIsEstimatePriceOpen] = useState(false);
  const { sellingOrderDTO, setSellingOrderDTO } = useSellingStore();

  const StepComponents: { [key: number]: ReactNode } = {
    0: <StepComponent0 />,
    1: <StepComponent1 />,
    2: (
      <StepComponent2
        setIsAccidentHistoryDetailOpen={setIsAccidentHistoryDetailOpen}
      />
    ),
    3: (
      <StepComponent3
        setIsAccidentHistoryDetailOpen={setIsAccidentHistoryDetailOpen}
      />
    ),
    4: <StepComponent4 />,
    5: <StepComponent5 />,
  };
  const StepComponent = StepComponents[step];

  const StepValidation: { [key: number]: () => boolean } = {
    0: () => {
      if (!sellingOrderDTO?.orderData.drivenDistance) {
        CarsayoToast.error('주행거리를 입력해 주세요');
        return false;
      }
      return true;
    },
    1: () => {
      if (
        sellingOrderDTO?.orderData.additionalInfo.optionSelect.optionList
          .length === 0 &&
        sellingOrderDTO?.orderData.additionalInfo.optionSelect.옵션없음 ===
          false &&
        sellingOrderDTO?.orderData.additionalInfo.optionSelect.직접입력
          .selected === false
      ) {
        CarsayoToast.error('옵션 내용을 선택해 주세요');
        return false;
      }
      return true;
    },
    2: () => {
      if (
        !sellingOrderDTO?.orderData.additionalInfo.carState.타이어교체.selected
      ) {
        CarsayoToast.error('타이어 상태를 선택해 주세요');
        return false;
      }
      if (
        !sellingOrderDTO?.orderData.additionalInfo.carState.외관스크래치
          .selected
      ) {
        CarsayoToast.error('외관 상태를 선택해 주세요');
        return false;
      }
      if (
        !sellingOrderDTO?.orderData.additionalInfo.carState.정비수리필요
          .selected
      ) {
        CarsayoToast.error('정비수리 필요 여부를 선택해 주세요');
        return false;
      }

      return true;
    },
    3: () => {
      if (
        sellingOrderDTO?.orderData.additionalInfo.accident.판금교환.selected ===
          false &&
        sellingOrderDTO?.orderData.additionalInfo.accident.모름.selected ===
          false &&
        sellingOrderDTO?.orderData.additionalInfo.accident.사고없음.selected ===
          false
      ) {
        CarsayoToast.error('사고 내역을 선택해 주세요');
        return false;
      }
      return true;
    },

    4: () => {
      if (!sellingOrderDTO?.orderData.sellRegionId) {
        CarsayoToast.error('판매 지역을 선택해 주세요');
        return false;
      }
      return true;
    },
    5: () => {
      if (!sellingOrderDTO?.orderData.boughtMethod) {
        CarsayoToast.error('구매 방식을 선택해 주세요');
        return false;
      }
      return true;
    },
  };

  const goBack = () => {
    if (step > 0) {
      setStep(step - 1);
      return;
    }
    navigate('/selling/main', { replace: true });
  };

  useEffect(() => {
    if (sellingOrderDTO?.orderData.currentStep) {
      setStep(sellingOrderDTO.orderData.currentStep);
    }
  }, [sellingOrderDTO?.orderData.currentStep]);

  useEffect(() => {
    setTopTabbarState({
      ...topTabberState,
      className: 'bg-[#181A20] text-white',
    });
    window.native.onBackPressed = goBack;
    return () => {
      // removeSellingOrderDTO();
    };
  }, []);

  return (
    <div className='min-h-full bg-[#181A20] pb-[var(--safeAreaBottomPadding)]'>
      <div className='px-4 pt-4'>
        <Accordion
          type='single'
          defaultValue='item-1'
          collapsible
          className='w-full'
        >
          <AccordionItem value='item-1'>
            <AccordionTrigger className='flex h-[98px] items-center gap-4 rounded-md border border-[#0075FF] bg-[#0066FF]/10 px-4 text-white'>
              <div className='flex h-[56px] w-[56px] items-center justify-center overflow-hidden rounded-full bg-[#181A20]'>
                <img
                  src={
                    sellingOrderDTO?.orderData.usedCarInfo.carInfo.modelimage
                  }
                  alt=''
                  className='h-10 w-10'
                />
              </div>
              <div>
                <div className='text-[#9EA3B2]'>
                  {sellingOrderDTO?.orderData.usedCarInfo.carInfo.makername}{' '}
                  {sellingOrderDTO?.orderData.usedCarInfo.carInfo.modelname}
                </div>
                <div className='mt-3 text-left text-base font-semibold text-white'>
                  {sellingOrderDTO?.orderData.usedCarInfo.carName}
                </div>
              </div>
            </AccordionTrigger>
            <div className='text-white'>
              <div className='py-4'>
                {sellingOrderDTO?.orderData.usedCarInfo.carInfo.year && (
                  <div className='flex h-9 items-center'>
                    <div className='w-[100px] shrink-0 text-[#9EA3B2]'>
                      연식
                    </div>
                    <div className='grow'>
                      {sellingOrderDTO?.orderData.usedCarInfo.carInfo.year}년형
                    </div>
                  </div>
                )}
                {sellingOrderDTO?.orderData.usedCarInfo.carInfo.firstdate && (
                  <div className='flex h-9 items-center'>
                    <div className='w-[100px] shrink-0 text-[#9EA3B2]'>
                      최초등록
                    </div>
                    <div className='grow'>
                      {dayjs(
                        sellingOrderDTO?.orderData.usedCarInfo.carInfo
                          .firstdate,
                      ).format('YYYY년 MM월 DD일')}
                    </div>
                  </div>
                )}
              </div>
              <AccordionContent>
                <div className='py-4 border-t-[1px] border-[#E5E5EC]'>
                  {sellingOrderDTO?.orderData.usedCarInfo.carInfo
                    .seriesname && (
                    <div className='flex h-9 items-center'>
                      <div className='w-[100px] shrink-0 text-[#9EA3B2]'>
                        등급
                      </div>
                      <div className='grow'>
                        {
                          sellingOrderDTO?.orderData.usedCarInfo.carInfo
                            .seriesname
                        }
                      </div>
                    </div>
                  )}
                  {sellingOrderDTO?.orderData.usedCarInfo.carInfo.cargubun && (
                    <div className='flex h-9 items-center'>
                      <div className='w-[100px] shrink-0 text-[#9EA3B2]'>
                        차체형태
                      </div>
                      <div className='grow'>
                        {
                          sellingOrderDTO?.orderData.usedCarInfo.carInfo
                            .cargubun
                        }
                      </div>
                    </div>
                  )}
                  {sellingOrderDTO?.orderData.usedCarInfo.carInfo.enType && (
                    <div className='flex h-9 items-center'>
                      <div className='w-[100px] shrink-0 text-[#9EA3B2]'>
                        엔진타입
                      </div>
                      <div className='grow'>
                        {sellingOrderDTO?.orderData.usedCarInfo.carInfo.enType}
                      </div>
                    </div>
                  )}
                  {sellingOrderDTO?.orderData.usedCarInfo.carInfo.gearbox && (
                    <div className='flex h-9 items-center'>
                      <div className='w-[100px] shrink-0 text-[#9EA3B2]'>
                        변속기
                      </div>
                      <div className='grow'>
                        {sellingOrderDTO?.orderData.usedCarInfo.carInfo.gearbox}
                      </div>
                    </div>
                  )}
                  {sellingOrderDTO?.orderData.usedCarInfo.carInfo
                    .displacement && (
                    <div className='flex h-9 items-center'>
                      <div className='w-[100px] shrink-0 text-[#9EA3B2]'>
                        배기량
                      </div>
                      <div className='grow'>
                        {sellingOrderDTO?.orderData.usedCarInfo.carInfo.displacement.toLocaleString()}
                        cc
                      </div>
                    </div>
                  )}
                  {sellingOrderDTO?.orderData.usedCarInfo.carInfo.fuel && (
                    <div className='flex h-9 items-center'>
                      <div className='w-[100px] shrink-0 text-[#9EA3B2]'>
                        연료
                      </div>
                      <div className='grow'>
                        {sellingOrderDTO?.orderData.usedCarInfo.carInfo.fuel}
                      </div>
                    </div>
                  )}
                  {sellingOrderDTO?.orderData.usedCarInfo.carInfo
                    .stdmileage && (
                    <div className='flex h-9 items-center'>
                      <div className='w-[100px] shrink-0 text-[#9EA3B2]'>
                        연비
                      </div>
                      <div className='grow'>
                        {
                          sellingOrderDTO?.orderData.usedCarInfo.carInfo
                            .stdmileage
                        }
                      </div>
                    </div>
                  )}
                  {sellingOrderDTO?.orderData.usedCarInfo.carInfo.seating && (
                    <div className='flex h-9 items-center'>
                      <div className='w-[100px] shrink-0 text-[#9EA3B2]'>
                        승차인원
                      </div>
                      <div className='grow'>
                        {sellingOrderDTO?.orderData.usedCarInfo.carInfo.seating}
                        명
                      </div>
                    </div>
                  )}
                  {/* {sellingOrderDTO?.orderData.usedCarInfo.carInfo.color && (
                    <div className='flex h-9 items-center'>
                      <div className='w-[100px] shrink-0 text-[#9EA3B2]'>
                        색상
                      </div>
                      <div className='grow'>
                        {sellingOrderDTO?.orderData.usedCarInfo.carInfo.color}
                      </div>
                    </div>
                  )} */}
                  {/* {sellingOrderDTO?.orderData.usedCarInfo.carInfo.newprice && (
                    <div className='flex h-9 items-center'>
                      <div className='w-[100px] shrink-0 text-[#9EA3B2]'>
                        신차가격
                      </div>
                      <div className='grow'>
                        {sellingOrderDTO?.orderData.usedCarInfo.carInfo.newprice.toLocaleString()}
                        원
                      </div>
                    </div>
                  )} */}
                </div>

                {sellingOrderDTO?.orderData.accidentHistory && (
                  <div className='pt-4 border-t-[1px] border-[#E5E5EC]'>
                    {Number(
                      sellingOrderDTO?.orderData.accidentHistory
                        ?.MY_CHANGE_COUNT,
                    ) > 1 ? (
                      <Accordion type='single' collapsible>
                        <AccordionItem value='item-2'>
                          <AccordionTrigger className='flex h-9 py-0 items-center text-left'>
                            <div className='w-[100px] shrink-0 text-[#9EA3B2]'>
                              거래이력
                            </div>
                            <div className='grow'>
                              소유자 변경 이력{' '}
                              {Number(
                                sellingOrderDTO?.orderData.accidentHistory
                                  ?.MY_CHANGE_COUNT,
                              )}
                              건
                            </div>
                          </AccordionTrigger>
                          <AccordionContent className='pb-0'>
                            {sellingOrderDTO?.orderData.accidentHistory.RESP_MY_CHANGE_DATA.map(
                              (el, index) => {
                                return (
                                  <div
                                    key={`changeHistory_${index}`}
                                    className='flex h-6 py-0 items-center text-left pl-[110px]'
                                  >
                                    <CornerDownRight className='h-4 w-4 text-white mr-2' />{' '}
                                    {dayjs(el.MY_CHANGE_CHANGE_DATE).format(
                                      'YYYY년 MM월',
                                    )}{' '}
                                    {el.MY_CHANGE_CHANGE_NAME}
                                  </div>
                                );
                              },
                            )}
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    ) : (
                      Number(
                        sellingOrderDTO?.orderData.accidentHistory
                          ?.MY_CHANGE_COUNT,
                      ) === 1 && (
                        <div className='flex h-9 items-center'>
                          <div className='w-[100px] shrink-0 text-[#9EA3B2]'>
                            거래이력
                          </div>
                          <div className='grow'>
                            {dayjs(
                              sellingOrderDTO.orderData.accidentHistory
                                .RESP_MY_CHANGE_DATA[0].MY_CHANGE_CHANGE_DATE,
                            ).format('YYYY년 MM월')}{' '}
                            신차로 구입
                          </div>
                        </div>
                      )
                    )}

                    <div className='flex h-9 items-center'>
                      <div className='w-[100px] shrink-0 text-[#9EA3B2]'>
                        주행거리
                      </div>
                      {Number(
                        sellingOrderDTO.orderData.accidentHistory.LAST_MILEAGE,
                      ) > 0 ? (
                        <div className='grow'>
                          {Number(
                            sellingOrderDTO?.orderData.accidentHistory
                              .LAST_MILEAGE,
                          ).toLocaleString()}
                          km{' '}
                          <span className='text-[12px] text-[#9EA3B2] font-normal tracking-[-0.3px] leading-5'>
                            (
                            {dayjs(
                              sellingOrderDTO?.orderData.accidentHistory
                                .LAST_MILEAGE_DATE,
                            ).format('YYYY년 MM월 기준')}
                            )
                          </span>
                        </div>
                      ) : (
                        <div className='grow'>기록없음</div>
                      )}
                    </div>

                    {
                      <div className='flex h-9 items-center'>
                        <div className='w-[100px] shrink-0 text-[#9EA3B2]'>
                          렌트이력
                        </div>
                        <div className='grow'>
                          {
                            sellingOrderDTO.orderData.accidentHistory
                              .RENT_CAR_HISTORY
                          }
                        </div>
                      </div>
                    }

                    {Number(
                      sellingOrderDTO?.orderData.accidentHistory?.MY_COUNT,
                    ) > 0 ? (
                      <Accordion type='single' collapsible>
                        <AccordionItem value='item-2'>
                          <AccordionTrigger className='flex h-9 py-0 items-center text-left'>
                            <div className='w-[100px] shrink-0 text-[#9EA3B2]'>
                              보험이력
                            </div>
                            <div className='grow'>
                              내 차 보험처리{' '}
                              {Number(
                                sellingOrderDTO?.orderData.accidentHistory
                                  ?.MY_COUNT,
                              )}
                              건
                            </div>
                          </AccordionTrigger>
                          <AccordionContent className='pb-0'>
                            {sellingOrderDTO?.orderData.accidentHistory.RESP_MY_DATA.map(
                              (el, index) => {
                                return (
                                  <div
                                    key={`repcost_${index}`}
                                    className='flex h-6 py-0 items-center text-left pl-[110px]'
                                  >
                                    <CornerDownRight className='h-4 w-4 text-white mr-2' />{' '}
                                    수리비용{' '}
                                    {Number(el.MY_REPAIR_COST).toLocaleString()}
                                    원
                                    <span className='text-[12px] text-[#9EA3B2] font-normal tracking-[-0.3px] leading-5 pl-1'>
                                      (
                                      {dayjs(el.MY_ACCIDENT_DATE).format(
                                        'YYYY년 MM월',
                                      )}
                                      )
                                    </span>
                                  </div>
                                );
                              },
                            )}
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    ) : (
                      Number(
                        sellingOrderDTO?.orderData.accidentHistory
                          ?.MY_CHANGE_COUNT,
                      ) === 1 && (
                        <div className='flex h-9 items-center'>
                          <div className='w-[100px] shrink-0 text-[#9EA3B2]'>
                            보험이력
                          </div>
                          <div className='grow'>없음</div>
                        </div>
                      )
                    )}
                  </div>
                )}
              </AccordionContent>
            </div>
          </AccordionItem>
        </Accordion>
      </div>

      {/* 이게 프로그레쓰바임 */}
      <div className='relative h-1 bg-white'>
        <div
          className='absolute inset-0 right-auto bg-[#0075FF]'
          style={{
            width: `${sellingOrderDTO?.orderData?.progress}%`,
          }}
        ></div>
      </div>

      <div className='p-4'>{StepComponent}</div>

      <div className='p-4'>
        <div className='mt-4 grid grid-cols-2'>
          <Button
            variant='outline'
            className='rounded-r-none bg-white text-sm font-semibold'
            onClick={goBack}
          >
            이전
          </Button>
          <Button
            className='rounded-l-none bg-[#0075FF] text-sm font-semibold'
            onClick={() => {
              if (!sellingOrderDTO) return;
              if (StepValidation[step] && StepValidation[step]() === false)
                return;

              if (Object.keys(StepComponents).length > step + 1) {
                setStep(step + 1);

                if (
                  sellingOrderDTO.orderData.currentStep &&
                  sellingOrderDTO.orderData.currentStep > step
                )
                  return;

                setSellingOrderDTO({
                  ...sellingOrderDTO,
                  update: {
                    progress: Math.floor(
                      ((step + 1) / Object.keys(StepComponents).length) * 100,
                    ),
                    currentStep: step + 1,
                  },
                });
              } else {
                setIsEstimatePriceOpen(true);
              }
            }}
          >
            다음
          </Button>
        </div>
      </div>

      {/* 보험 처리 전체보기 */}
      {sellingOrderDTO?.orderData.accidentHistory && (
        <SellingAccidentHistoryDetail
          isOpen={isAccidentHistoryDetailOpen}
          setIsOpen={setIsAccidentHistoryDetailOpen}
          accidentHistory={sellingOrderDTO.orderData.accidentHistory}
        />
      )}

      {/* 시세 확인하기 */}
      {sellingOrderDTO?.orderData && (
        <SellingCarEstimatePrice
          isOpen={isEstimatePriceOpen}
          setIsOpen={setIsEstimatePriceOpen}
          sellingOrderInfo={sellingOrderDTO.orderData}
          mode={'sellingCar'}
        />
      )}
    </div>
  );
}
