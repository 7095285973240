import { useLayoutEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import signupStore from './store';
import useMemberStore from 'store/useMemberStore';
import { isLogin } from 'apis/authentication';

export default function SignupIndex() {
  const navigate = useNavigate();
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const { resetSignupStore } = signupStore();
  const { loginMember } = useMemberStore();

  const checkLogin = async () => {
    if (loginMember) {
      navigate('/main', { replace: true });
    } else {
      const {
        data: { isLogined },
      } = await isLogin();
      if (isLogined) navigate('/main', { replace: true });
    }
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'hidden',
      mode: 'subpage',
      leftElement: 'back',
    });
    setIsBottomTabbar(false);
    checkLogin();

    return () => {
      resetSignupStore();
    };
  }, []);

  return <Outlet />;
}
