/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import { Button } from 'components/ui/button';
import { useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import usePurchaseStore from './store';
import { cn } from 'utils';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';

export default function Detail() {
  const navigate = useNavigate();

  const { setTopTabbarTitle } = useSystemStore();
  const { purchaseOrderDTO, setPurchaseOrderDTO, catalogue } =
    usePurchaseStore();

  const setData = async () => {
    if (!purchaseOrderDTO) return;
    await setPurchaseOrderDTO({
      id: purchaseOrderDTO.id,
      type: purchaseOrderDTO.type,
      update: {
        progress: 25,
        currentPath: '/purchase/detail',

        carColorId: null,
        isNotSelectColor: null,

        carOptionIdList: [],

        additionalInfo: null,

        additionalRequest: null,
        otherCompanyEstimateFileId: null,
      },
    });
  };

  const goBack = () => {
    navigate('/purchase/model', { replace: true });
  };
  const goNext = () => {
    navigate('/purchase/color', { replace: true });
  };

  useEffect(() => {
    window.native.onBackPressed = goBack;
  }, []);

  useLayoutEffect(() => {
    setTopTabbarTitle('세부 모델 선택');
    setData();
  }, []);

  return (
    <div className='flex flex-col h-full'>
      <div className='flex-none relative z-10 flex min-h-[152px] items-center justify-between px-4 shadow-[0_5px_10px_0_rgba(0,0,0,.06)]'>
        <div>
          <div className='flex items-center gap-1'>
            <div className='flex h-[18px] items-center border px-1 text-[11px]'>
              {purchaseOrderDTO?.orderData?.carMaker?.name}
            </div>
            <div className='flex h-[18px] items-center border px-1 text-[11px]'>
              {purchaseOrderDTO?.orderData?.carModel?.attribute.isPreorder ===
              true
                ? '사전예약'
                : purchaseOrderDTO?.orderData?.carModel?.car_category?.name}
            </div>
          </div>
          <div className='mt-2 text-title font-semibold'>
            {purchaseOrderDTO?.orderData?.carModel?.name}
          </div>
          {purchaseOrderDTO?.orderData?.carModel && (
            <div className='mt-1.5 text-desc'>
              {purchaseOrderDTO?.orderData?.carModel.price.min === 0 ? (
                <div> </div>
              ) : (
                <>
                  {purchaseOrderDTO?.orderData?.carModel.price.min !==
                    purchaseOrderDTO?.orderData?.carModel.price.max && (
                    <>
                      {Math.floor(
                        purchaseOrderDTO?.orderData?.carModel.price.min / 10000,
                      ).toLocaleString()}{' '}
                      ~{' '}
                    </>
                  )}
                  {Math.floor(
                    purchaseOrderDTO?.orderData?.carModel.price.max / 10000,
                  ).toLocaleString()}
                  만원
                </>
              )}
            </div>
          )}
        </div>
        <div className='h-[84px] w-[130px]'>
          <img src={purchaseOrderDTO?.orderData?.carModel?.image_url} alt='' />
        </div>
      </div>
      <div className='flex-auto overflow-y-scroll px-4 py-8'>
        <div className='text-lg font-semibold'>세부 모델 선택</div>
        <div className='mt-4'>
          {purchaseOrderDTO && (
            <Accordion
              defaultValue={
                purchaseOrderDTO.orderData?.carTrim?.id
                  ? `${purchaseOrderDTO.orderData?.carTrim.id}`
                  : undefined
              }
              type='single'
              collapsible
              className='w-full space-y-4'
            >
              {catalogue?.trimIncludeGrade &&
                catalogue.trimIncludeGrade.data.map((el, index) => (
                  <AccordionItem key={index} value={`${el.id}`}>
                    <AccordionTrigger className='min-h-[70px] rounded-t-md border bg-[#F7F7FB] p-4'>
                      <div className='text-title'>{el.name}</div>
                    </AccordionTrigger>
                    <AccordionContent className='rounded-b-md border border-t-0 pb-0'>
                      <div className='space-y-4 p-4'>
                        {el.grade.map((el2, index2) => {
                          return (
                            <div
                              key={index2}
                              className={cn(
                                'flex h-[52px] leading-4 items-center justify-between rounded-md border px-4',
                                purchaseOrderDTO?.orderData?.carGrade?.id ===
                                  el2.id
                                  ? 'border-primary'
                                  : '',
                              )}
                              onClick={async () => {
                                if (!purchaseOrderDTO) return;
                                setPurchaseOrderDTO({
                                  id: purchaseOrderDTO.id,
                                  type: purchaseOrderDTO.type,
                                  update: {
                                    carTrimId: el.id,
                                    carGradeId: el2.id,
                                  },
                                });
                              }}
                            >
                              <div>{el2.name} </div>
                              {el2.price > 0 ? (
                                <div className='flex-none ml-3 text-[#555555]'>
                                  {Math.floor(
                                    el2.price / 10000,
                                  ).toLocaleString()}
                                  만원
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                ))}
            </Accordion>
          )}
        </div>
      </div>
      <div className='flex-none grid grid-cols-2 bg-[#F7F7FB] px-4 py-6'>
        <Button
          variant='outline'
          className='rounded-r-none bg-white'
          onClick={goBack}
        >
          이전
        </Button>
        <Button
          className='rounded-l-none'
          onClick={async () => {
            if (purchaseOrderDTO?.orderData?.carGrade) {
              goNext();
            } else {
              CarsayoToast.error('세부 모델이 선택되지 않았습니다');
            }
          }}
        >
          다음
        </Button>
      </div>
    </div>
  );
}
