import { ModalDatePickerProps } from 'react-mobile-datepicker-ts';
import DatePicker from 'react-mobile-datepicker-ts';
import CarsayoBottomSheet from './CarsayoBottomSheet';
import dayjs from 'dayjs';
import CarsayoInput from './CarsayoInput';
import CalendarIcon from '../../assets/images/calendarIcon.svg';
// import { X } from 'lucide-react';
// import { Drawer, DrawerContent, DrawerHeader } from 'components/ui/drawer';
// import { Button } from 'components/ui/button';

interface CarsayoDatePicker2Interface
  extends Omit<
    ModalDatePickerProps,
    'isOpen' | 'onCancel' | 'showHeader' | 'showFooter'
  > {
  isOpen: boolean;
  onOpen: () => void;
  onCancel: () => void;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
}

const CarsayoDatePicker2 = ({
  className,
  isOpen,
  onOpen,
  onCancel,
  placeholder,
  disabled,
  ...rest
}: CarsayoDatePicker2Interface) => {
  const minDate = dayjs().subtract(20, 'year').startOf('day').toDate();
  const maxDate = dayjs().add(20, 'year').endOf('day').toDate();

  return (
    <>
      <CarsayoInput
        value={rest.value ? dayjs(rest.value).format('YYYY-MM-DD') : undefined}
        onClick={onOpen}
        postFix={<img src={CalendarIcon} alt='' />}
        style={{
          borderColor: '#f0f0f4',
          borderRadius: '0 ',
        }}
        className={`${className && className}`}
        placeholder={placeholder}
        readOnly
        disabled={disabled}
      />
      {/* <Drawer open={isOpen}>
        <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col !pt-4 min-h-[35.6vh]'>
          <DrawerHeader className='relative flex justify-between items-center'>
            <Button
              onClick={onCancel}
              className='absolute right-5 top-0 bottom-[3px] my-auto h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'
            >
              <X className='h-7 w-7' />
            </Button>
          </DrawerHeader>
          <DatePicker
            isOpen={isOpen}
            min={minDate}
            max={maxDate}
            showHeader={false}
            showFooter={false}
            {...rest}
          />
        </DrawerContent>
      </Drawer> */}
      <CarsayoBottomSheet isOpen={isOpen} onClose={onCancel}>
        <DatePicker
          isOpen={isOpen}
          min={minDate}
          max={maxDate}
          showHeader={false}
          showFooter={false}
          {...rest}
        />
      </CarsayoBottomSheet>
    </>
  );
};

export default CarsayoDatePicker2;
