import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { getMember, isLogin } from 'apis/authentication';
import { useLayoutEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useMemberStore from 'store/useMemberStore';
import useSystemStore from 'store/useSystemStore';
import useQuoteStore from 'store/useQuoteStore';

export default function QuoteIndex() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsBottomTabbar } = useSystemStore();
  const { loginMember } = useMemberStore();
  const { resetQuoteStore } = useQuoteStore();

  const checkPath = () => {
    if (!loginMember) return;
    if (location.pathname === '/quote') {
      if (loginMember.type === 'customer')
        navigate('/quote/customer', { replace: true });
      else navigate('/quote/manager', { replace: true });
    }
  };

  const checkAcceptedManager = async () => {
    if (!loginMember) {
      const {
        data: { isLogined },
      } = await isLogin();
      if (isLogined === false) {
        navigate('/main', { replace: true });
        return;
      } else {
        const member = await getMember();
        if (
          member.dealerInfo?.signupState === 'rejected' ||
          member.dealerInfo?.signupState === 'submitted'
        ) {
          CarsayoToast.error('인증된 카매니저만 사용할 수 있습니다');
          navigate('/main', { replace: true });
          return;
        }
      }
    } else {
      if (
        loginMember?.dealerInfo?.signupState === 'submitted' ||
        loginMember?.dealerInfo?.signupState === 'rejected'
      ) {
        CarsayoToast.error('인증된 카매니저만 사용할 수 있습니다');
        navigate('/main', { replace: true });
        return;
      }
    }
  };

  useLayoutEffect(() => {
    checkPath();
    checkAcceptedManager();
    setIsBottomTabbar(true);
    return () => {
      resetQuoteStore();
      // window.native.onBackPressed = () => {};
    };
  }, []);

  return <Outlet />;
}
