import usePurchaseStore from '../store';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NumberFormatValues, Timeout } from 'react-number-format/types/types';
import dayjs from 'dayjs';
import CustomizedSelects, {
  CustomizedMenuItems,
} from '../../../../components/ui/select_v2';
import { Button } from '../../../../components/ui/button';
import { Calendar, ChevronDown } from 'lucide-react';
import { NumericFormat } from 'react-number-format';
import { cn } from '../../../../utils';
import { SelectSigungu } from '../../../../components/view/buy/selectSigungu';
import CarsayoBottomSheet from '../../../../components/common/CarsayoBottomSheet';
import DatePicker from 'react-mobile-datepicker-ts';
import { getRegion } from '../../../../utils/getSidogungu';
import { PriceDetail } from './priceDetail';
import { CarsayoToast } from '../../../../components/common/v2/CarsayoToast';
import { useNavigate } from 'react-router-dom';
import {
  DutyFreeCondition,
  SidoData,
  SigunguData,
  BuyerType,
  SigunguId,
} from '@carsayo/types';

export type HalbooFormValues = {
  purchaseDateType: 'input' | 'null';
  purchaseDate: string | null | undefined;
  prepaidPrincipalAmount: string | number;
  isRegistrationFee: 'true' | 'false' | '';
  installmentPrincipalType: 'input' | 'null';
  installmentPrincipal: string | number;
  dutyFreeCondition: DutyFreeCondition | '';
  deliveryRegion: string;
  residenceRegion: string;
};

export function HalbooForm({ purchasePrice }: { purchasePrice: number }) {
  const { purchaseOrderDTO, setPurchaseOrderDTO } = usePurchaseStore();
  const [isOpen, setIsOpen] = useState(false);
  const [target, setTarget] = useState<'거주' | '인수'>('거주');

  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const residenceRegionData =
    purchaseOrderDTO && purchaseOrderDTO.orderData
      ? purchaseOrderDTO.orderData.additionalInfo.할부.residenceRegion
      : 0;
  const deliveryRegionData =
    purchaseOrderDTO && purchaseOrderDTO.orderData
      ? purchaseOrderDTO.orderData.additionalInfo.할부.deliveryRegion
      : 0;

  const handleClose = () => {
    setDatePickerOpen(false);
    setValue(
      'purchaseDate',
      dayjs(
        purchaseOrderDTO?.orderData?.additionalInfo.할부.purchaseDate,
      ).format('YYYYMMDD'),
      {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      },
    );
  };

  const { control, handleSubmit, getValues, setValue, formState } =
    useForm<HalbooFormValues>({
      mode: 'onSubmit',
      criteriaMode: 'firstError',
      shouldFocusError: true,
      defaultValues: {
        purchaseDateType:
          purchaseOrderDTO?.orderData &&
          (purchaseOrderDTO.orderData.additionalInfo.할부.purchaseDate ===
            null ||
            purchaseOrderDTO.orderData.additionalInfo.할부.purchaseDate ===
              undefined)
            ? 'null'
            : 'input',
        purchaseDate:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.할부.purchaseDate !==
            null &&
          purchaseOrderDTO.orderData.additionalInfo.할부.purchaseDate !==
            'null' &&
          purchaseOrderDTO.orderData.additionalInfo.할부.purchaseDate !==
            'input'
            ? purchaseOrderDTO.orderData.additionalInfo.할부.purchaseDate
            : null,
        prepaidPrincipalAmount:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.할부.prepaidPrincipalAmount
            ? purchaseOrderDTO.orderData.additionalInfo.할부
                .prepaidPrincipalAmount
            : '',
        isRegistrationFee:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.할부.isRegistrationFee !==
            undefined
            ? (purchaseOrderDTO.orderData.additionalInfo.할부.isRegistrationFee.toString() as
                | 'true'
                | 'false'
                | '')
            : '',
        installmentPrincipalType:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.할부
            .installmentPrincipal === null
            ? 'null'
            : 'input',
        installmentPrincipal:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.할부.installmentPrincipal
            ? purchaseOrderDTO.orderData.additionalInfo.할부
                .installmentPrincipal
            : '',
        dutyFreeCondition:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.할부.dutyFreeCondition
            ? purchaseOrderDTO.orderData.additionalInfo.할부.dutyFreeCondition
            : '',
        deliveryRegion: getRegion(deliveryRegionData as number),
        residenceRegion: getRegion(residenceRegionData as number),
      },
    });

  // 선수금 인풋
  const typingTimeoutRef_1 = useRef<Timeout | null>(null);

  // 할부원금 인풋
  const [_, Set할부원금Value] = useState<number | null>(
    purchaseOrderDTO?.orderData?.additionalInfo.할부.installmentPrincipal
      ? purchaseOrderDTO?.orderData?.additionalInfo.할부.installmentPrincipal
      : null,
  );
  const typingTimeoutRef_2 = useRef<Timeout | null>(null);

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const errorObj = Object.keys(
      formState.errors,
    ) as (keyof HalbooFormValues)[];
    const firstErrorKey = errorObj[0];
    const errorElement = document.querySelector(`[name=${firstErrorKey}]`);
    if (errorElement) {
      if (errorElement.getAttribute('type') !== 'hidden') {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        if (errorElement.nextElementSibling) {
          errorElement.nextElementSibling.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }
    }
  }, [formState.errors]);

  const setDefaultValue = function async() {
    if (!purchaseOrderDTO || !purchaseOrderDTO.orderData) return;

    let isUpdate = false;
    const updateData = {
      ...purchaseOrderDTO.orderData.additionalInfo.할부,
    };

    if (
      purchaseOrderDTO?.orderData?.additionalInfo.할부.purchaseDate ===
      undefined
    ) {
      isUpdate = true;
      updateData.purchaseDate = null;
    }
    if (!purchaseOrderDTO?.orderData?.additionalInfo.할부.installmentTerm) {
      isUpdate = true;
      updateData.installmentTerm = 12;
    }
    if (
      purchaseOrderDTO?.orderData?.additionalInfo.할부.buyerType === undefined
    ) {
      isUpdate = true;
      updateData.buyerType = 'individual';
    }

    if (isUpdate) {
      setPurchaseOrderDTO({
        id: purchaseOrderDTO.id,
        type: purchaseOrderDTO.type,
        update: {
          additionalInfo: {
            ...purchaseOrderDTO.orderData.additionalInfo,
            할부: {
              ...purchaseOrderDTO.orderData.additionalInfo.할부,
              ...updateData,
            },
          },
        },
      });
    }
  };

  const handleInputChange = {
    선수금: (values: NumberFormatValues) => {
      // useForm setValue
      setValue(
        'prepaidPrincipalAmount',
        values.value !== '' ? values.value : '',
        {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        },
      );

      if (!values.value) return;

      if (typingTimeoutRef_1.current) {
        clearTimeout(typingTimeoutRef_1.current);
      }

      typingTimeoutRef_1.current = setTimeout(() => {
        if (!purchaseOrderDTO?.orderData) return;
        setPurchaseOrderDTO({
          id: purchaseOrderDTO.id,
          type: purchaseOrderDTO.type,
          update: {
            additionalInfo: {
              ...purchaseOrderDTO.orderData.additionalInfo,
              할부: {
                ...purchaseOrderDTO.orderData.additionalInfo.할부,
                prepaidPrincipalAmount: values.floatValue,
              },
            },
          },
        });
        // Call your specific method here
        // yourSpecificMethod(value);
      }, 500); // Adjust the timeout delay as needed
    },
    할부원금: (values: NumberFormatValues) => {
      // useForm setValue
      setValue(
        'installmentPrincipal',
        values.value !== '' ? values.value : '',
        {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        },
      );

      if (!values.value) return;
      Set할부원금Value(values.floatValue ? values.floatValue : null);
      // useForm setValue
      setValue('installmentPrincipal', values.value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });

      if (typingTimeoutRef_2.current) {
        clearTimeout(typingTimeoutRef_2.current);
      }

      typingTimeoutRef_2.current = setTimeout(() => {
        if (!purchaseOrderDTO?.orderData) return;
        setPurchaseOrderDTO({
          id: purchaseOrderDTO.id,
          type: purchaseOrderDTO.type,
          update: {
            additionalInfo: {
              ...purchaseOrderDTO.orderData.additionalInfo,
              할부: {
                ...purchaseOrderDTO.orderData.additionalInfo.할부,
                installmentPrincipal: values.floatValue,
              },
            },
          },
        });

        // Call your specific method here
        // yourSpecificMethod(value);
      }, 500); // Adjust the timeout delay as needed
    },
    구입시기: (date: Date) => {
      if (!purchaseOrderDTO?.orderData) return;
      setPurchaseOrderDTO({
        id: purchaseOrderDTO.id,
        type: purchaseOrderDTO.type,
        update: {
          additionalInfo: {
            ...purchaseOrderDTO.orderData.additionalInfo,
            할부: {
              ...purchaseOrderDTO.orderData.additionalInfo.할부,
              purchaseDate: dayjs(date).format('YYYYMMDD'),
            },
          },
        },
      });
    },
  };

  const handlePurchaseDateSelectChange = async (value: string) => {
    if (purchaseOrderDTO && purchaseOrderDTO.orderData) {
      // useForm setValue
      setValue('purchaseDateType', value.toString() as 'input' | 'null', {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });

      if (value === 'null') {
        setValue('purchaseDate', null, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      }
      setPurchaseOrderDTO({
        id: purchaseOrderDTO.id,
        type: purchaseOrderDTO.type,
        update: {
          additionalInfo: {
            ...purchaseOrderDTO.orderData.additionalInfo,
            할부: {
              ...purchaseOrderDTO.orderData.additionalInfo.할부,
              purchaseDate: value === 'null' ? null : '',
            },
          },
        },
      });
    }
  };

  const handleRegistrationFeeSelectChange = async (value: string) => {
    if (purchaseOrderDTO && purchaseOrderDTO.orderData) {
      // useForm setValue
      setValue('isRegistrationFee', value.toString() as 'true' | 'false', {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });

      setPurchaseOrderDTO({
        id: purchaseOrderDTO.id,
        type: purchaseOrderDTO.type,
        update: {
          additionalInfo: {
            ...purchaseOrderDTO.orderData.additionalInfo,
            할부: {
              ...purchaseOrderDTO.orderData.additionalInfo.할부,
              isRegistrationFee: value === 'true' ? true : false,
            },
          },
        },
      });
    }
  };

  const handleInstallmentPrincipalSelectChange = async (value: string) => {
    // useForm setValue
    setValue('installmentPrincipalType', value as 'input' | 'null', {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    if (value === 'null') {
      setValue('installmentPrincipal', '', {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
    if (purchaseOrderDTO && purchaseOrderDTO.orderData) {
      if (value === 'null' && ref.current !== null) {
        Set할부원금Value(null);
      }

      setPurchaseOrderDTO({
        id: purchaseOrderDTO.id,
        type: purchaseOrderDTO.type,
        update: {
          additionalInfo: {
            ...purchaseOrderDTO.orderData.additionalInfo,
            할부: {
              ...purchaseOrderDTO.orderData.additionalInfo.할부,
              installmentPrincipal: value === 'null' ? null : 0,
            },
          },
        },
      });
    }
  };

  const handleDutyFreeConditionSelectChange = async (value: string) => {
    // useForm setValue
    setValue('dutyFreeCondition', value as DutyFreeCondition, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    if (purchaseOrderDTO && purchaseOrderDTO.orderData) {
      setPurchaseOrderDTO({
        id: purchaseOrderDTO.id,
        type: purchaseOrderDTO.type,
        update: {
          additionalInfo: {
            ...purchaseOrderDTO.orderData.additionalInfo,
            할부: {
              ...purchaseOrderDTO.orderData.additionalInfo.할부,
              dutyFreeCondition: value as DutyFreeCondition,
            },
          },
        },
      });
    }
  };

  const handleRegionClick = (target: '거주' | '인수') => {
    setTarget(target);
    setIsOpen(true);
  };

  const handleResidenceRegionChange = (value: SigunguId) => {
    if (!purchaseOrderDTO?.orderData) return;

    // useForm setValue
    setValue(
      target === '거주' ? 'residenceRegion' : 'deliveryRegion',
      getRegion(value),
      {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      },
    );

    if (target === '거주') {
      setPurchaseOrderDTO({
        id: purchaseOrderDTO.id,
        type: purchaseOrderDTO.type,
        update: {
          additionalInfo: {
            ...purchaseOrderDTO.orderData.additionalInfo,
            할부: {
              ...purchaseOrderDTO.orderData.additionalInfo.할부,
              residenceRegion: value,
            },
          },
        },
      });
    } else {
      setPurchaseOrderDTO({
        id: purchaseOrderDTO.id,
        type: purchaseOrderDTO.type,
        update: {
          additionalInfo: {
            ...purchaseOrderDTO.orderData.additionalInfo,
            할부: {
              ...purchaseOrderDTO.orderData.additionalInfo.할부,
              deliveryRegion: value,
            },
          },
        },
      });
    }
  };
  const navigate = useNavigate();
  const goBack = () => {
    navigate('/purchase/option', { replace: true });
  };
  const goNext = () => {
    navigate('/purchase/estimate', { replace: true });
  };
  const confirm = () => {
    if (!purchaseOrderDTO?.orderData) {
      return;
    }
    const currentAdditionalInfo = purchaseOrderDTO.orderData.additionalInfo;

    if (currentAdditionalInfo.method === '할부') {
      if (
        currentAdditionalInfo.할부.deliveryRegion === undefined ||
        currentAdditionalInfo.할부.installmentTerm === undefined ||
        currentAdditionalInfo.할부.buyerType === undefined ||
        currentAdditionalInfo.할부.residenceRegion === undefined ||
        currentAdditionalInfo.할부.dutyFreeCondition === undefined ||
        currentAdditionalInfo.할부.prepaidPrincipalAmount === undefined ||
        currentAdditionalInfo.할부.isRegistrationFee === undefined ||
        currentAdditionalInfo.할부.installmentPrincipal === undefined ||
        currentAdditionalInfo.할부.installmentPrincipal === 0 ||
        currentAdditionalInfo.할부.purchaseDate === undefined ||
        currentAdditionalInfo.할부.purchaseDate === ''
      ) {
        CarsayoToast.error('아직 모든 내용이 입력되지 않았습니다.');
        return;
      }
    }

    goNext();
    return;
  };

  useLayoutEffect(() => {
    setDefaultValue();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(confirm)}>
        <div>
          <div className='mt-4 space-y-4 px-4 pb-6'>
            {purchaseOrderDTO?.orderData && (
              <>
                <div>
                  <div>할부 기간</div>
                  <div className='mt-2'>
                    <CustomizedSelects
                      value={
                        purchaseOrderDTO.orderData.additionalInfo.할부
                          .installmentTerm
                          ? String(
                              purchaseOrderDTO.orderData.additionalInfo.할부
                                .installmentTerm,
                            )
                          : ''
                      }
                      onValueChange={async (value) => {
                        if (!purchaseOrderDTO.orderData) return;
                        setPurchaseOrderDTO({
                          id: purchaseOrderDTO.id,
                          type: purchaseOrderDTO.type,
                          update: {
                            additionalInfo: {
                              ...purchaseOrderDTO.orderData.additionalInfo,
                              할부: {
                                ...purchaseOrderDTO.orderData.additionalInfo
                                  .할부,
                                installmentTerm: Number(value),
                              },
                            },
                          },
                        });
                      }}
                      placeholder=''
                    >
                      <CustomizedMenuItems value='12'>
                        12개월
                      </CustomizedMenuItems>
                      <CustomizedMenuItems value='24'>
                        24개월
                      </CustomizedMenuItems>
                      <CustomizedMenuItems value='36'>
                        36개월
                      </CustomizedMenuItems>
                      <CustomizedMenuItems value='48'>
                        48개월
                      </CustomizedMenuItems>
                      <CustomizedMenuItems value='60'>
                        60개월
                      </CustomizedMenuItems>
                      <CustomizedMenuItems value='72'>
                        72개월
                      </CustomizedMenuItems>
                    </CustomizedSelects>
                  </div>
                </div>

                {/* 구입 시기 선택 */}
                <div>
                  <div>구입 시기</div>
                  <div className='flex mt-2'>
                    <Controller
                      name='purchaseDateType'
                      control={control}
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          onValueChange={handlePurchaseDateSelectChange}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='null'>
                            1개월 이내
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='input'>
                            직접 선택
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />
                    <Controller
                      control={control}
                      name='purchaseDate'
                      rules={{
                        required: getValues('purchaseDateType') !== 'null',
                      }}
                      render={({ field, fieldState, formState }) => {
                        return (
                          <Button
                            disabled={getValues('purchaseDateType') === 'null'}
                            onClick={() => {
                              setDatePickerOpen(true);
                            }}
                            className={cn(
                              'flex px-4 rounded-none text-black font-normal ml-4 h-[52px] w-full border border-input bg-background py-2 outline-none ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:border-[#111111] disabled:cursor-not-allowed disabled:opacity-50 text-sm placeholder:text-sm',
                              getValues('purchaseDateType') !== 'null' &&
                                formState.errors.purchaseDate
                                ? 'border-[#DC0000] focus:border-[#DC0000]'
                                : '',
                            )}
                          >
                            {/*{ purchaseOrderDTO?.orderData?.additionalInfo.할부.purchaseDate*/}
                            {/*  ? dayjs(field.value).format('YYYY년 MM월 DD일')*/}
                            {/*  : '미선택'}*/}

                            {purchaseOrderDTO?.orderData?.additionalInfo.할부
                              .purchaseDate
                              ? dayjs(
                                  purchaseOrderDTO.orderData.additionalInfo.할부
                                    .purchaseDate,
                                ).format('YYYY년 MM월 DD일')
                              : '미선택'}

                            <div className='flex-auto'></div>
                            <Calendar className='flex-none w-5 h-5 text-gray-500' />
                          </Button>
                        );
                      }}
                    />
                  </div>
                </div>

                {/* 구입 주체 */}
                <div>
                  <div>구입 주체</div>
                  <div className='mt-2'>
                    <CustomizedSelects
                      value={
                        purchaseOrderDTO.orderData.additionalInfo.할부
                          .buyerType !== undefined
                          ? String(
                              purchaseOrderDTO.orderData.additionalInfo.할부
                                .buyerType,
                            )
                          : ''
                      }
                      onValueChange={async (value) => {
                        if (!purchaseOrderDTO.orderData) return;
                        setPurchaseOrderDTO({
                          id: purchaseOrderDTO.id,
                          type: purchaseOrderDTO.type,
                          update: {
                            additionalInfo: {
                              ...purchaseOrderDTO.orderData.additionalInfo,
                              할부: {
                                ...purchaseOrderDTO.orderData.additionalInfo
                                  .할부,
                                buyerType: value as BuyerType,
                              },
                            },
                          },
                        });
                      }}
                      placeholder=''
                    >
                      <CustomizedMenuItems value='individual'>
                        개인
                      </CustomizedMenuItems>
                      <CustomizedMenuItems value='sole_proprietor'>
                        개인사업자
                      </CustomizedMenuItems>
                      <CustomizedMenuItems value='corporation'>
                        법인사업자
                      </CustomizedMenuItems>
                    </CustomizedSelects>
                  </div>
                </div>

                <div>
                  <div>선수금 (인도금)</div>
                  <div className='flex mt-2'>
                    {/*purchaseOrderDTO.orderData.additionalInfo.할부
                .prepaidPrincipalAmount
                ? purchaseOrderDTO.orderData.additionalInfo.할부
                .prepaidPrincipalAmount
                : ''
  */}
                    <Controller
                      control={control}
                      name='prepaidPrincipalAmount'
                      rules={{ required: '선수금 (인도금)을 입력해주세요.' }}
                      render={({ field, fieldState, formState }) => (
                        <div className='w-full mr-4'>
                          <NumericFormat
                            getInputRef={field.ref}
                            placeholder='선수금을 입력하세요'
                            onValueChange={handleInputChange.선수금}
                            name='prepaidPrincipalAmount'
                            thousandSeparator=','
                            suffix=' 원'
                            defaultValue={field.value}
                            className={cn(
                              'flex h-[52px] w-full border border-input bg-background px-3 py-2 text-base outline-none ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:border-[#111111] disabled:cursor-not-allowed disabled:opacity-50 text-sm placeholder:text-sm',
                              formState.errors.prepaidPrincipalAmount &&
                                'border-[#DC0000] focus:border-[#DC0000]',
                            )}
                            autoComplete='off'
                          />
                          {formState.errors.prepaidPrincipalAmount && (
                            <p className='text-[0.813rem] text-[#DC0000]'>
                              {formState.errors.prepaidPrincipalAmount.message}
                            </p>
                          )}
                        </div>
                      )}
                    />

                    <div>
                      <Controller
                        control={control}
                        name='isRegistrationFee'
                        rules={{ required: true }}
                        render={({ field, fieldState, formState }) => (
                          <CustomizedSelects
                            value={field.value}
                            name={'isRegistrationFee'}
                            classes={
                              formState.errors.isRegistrationFee
                                ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                                : ''
                            }
                            ref={field.ref}
                            onValueChange={handleRegistrationFeeSelectChange}
                            placeholder='선택해주세요'
                          >
                            <CustomizedMenuItems value='true'>
                              등록비 포함
                            </CustomizedMenuItems>
                            <CustomizedMenuItems value='false'>
                              등록비 별도
                            </CustomizedMenuItems>
                          </CustomizedSelects>
                        )}
                      />
                    </div>

                    {/* <Input
                  type='number'
                  placeholder='선수금을 입력하세요'
                  onChange={handleInputChange.선수금}
                  defaultValue={
                    purchaseOrderDTO.orderData.additionalInfo.할부
                      .prepaidPrincipalAmount
                      ? purchaseOrderDTO.orderData.additionalInfo.할부
                          .prepaidPrincipalAmount
                      : ''
                  }
                  className='text-sm placeholder:text-sm'
                  autoComplete='off'
                ></Input> */}
                  </div>
                </div>

                <div>
                  <div>할부원금</div>
                  <div className='flex mt-2'>
                    <Controller
                      control={control}
                      name='installmentPrincipalType'
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          ref={field.ref}
                          onValueChange={handleInstallmentPrincipalSelectChange}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='input'>
                            입력
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='null'>
                            생략
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />

                    <Controller
                      control={control}
                      rules={{
                        required: {
                          value:
                            getValues('installmentPrincipalType') !== 'null',
                          message: '할부원금을 입력해주세요.',
                        },
                      }}
                      name='installmentPrincipal'
                      render={({ field, fieldState, formState }) => (
                        <div className='ml-4 w-full '>
                          <NumericFormat
                            value={field.value}
                            name='installmentPrincipal'
                            placeholder={
                              getValues('installmentPrincipalType') === 'null'
                                ? '선수금으로 계산'
                                : '숫자만 입력하세요'
                            }
                            onValueChange={handleInputChange.할부원금}
                            thousandSeparator=','
                            suffix=' 원'
                            getInputRef={field.ref}
                            disabled={
                              getValues('installmentPrincipalType') === 'null'
                            }
                            className={cn(
                              'flex h-[52px] w-full border border-input bg-background px-3 py-2 text-base outline-none ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:border-[#111111] disabled:cursor-not-allowed disabled:opacity-50 text-sm placeholder:text-sm',
                              getValues('installmentPrincipalType') ===
                                'input' && formState.errors.installmentPrincipal
                                ? 'border-[#DC0000] focus:border-[#DC0000]'
                                : '',
                            )}
                            autoComplete='off'
                          />
                          {getValues('installmentPrincipalType') !== 'null' &&
                            formState.errors.installmentPrincipal && (
                              <p className='text-[0.813rem] text-[#DC0000]'>
                                {formState.errors.installmentPrincipal.message}
                              </p>
                            )}
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>면세 조건</div>
                  <div className='mt-2'>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name='dutyFreeCondition'
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          ref={field.ref}
                          name={'dutyFreeCondition'}
                          onValueChange={handleDutyFreeConditionSelectChange}
                          classes={
                            formState.errors.dutyFreeCondition
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : ''
                          }
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='normal'>
                            일반인 (해당 없음)
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='manyChild'>
                            다자녀 (취득세 감면)
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='isSevereDisability'>
                            중증 장애 (1~3급)
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='isMildDisability'>
                            경증 장애 (시각 4급)
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='isVisualDisability'>
                            시각 장애 (일반 4급)
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='isNationalMerit'>
                            국가유공자
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='isAgentOrangeDisabled'>
                            장애인 (고엽제)
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='isMay18Disabled'>
                            장애인 (5.18 민주화)
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>인수(탁송) 지역</div>
                  <div className='mt-2'>
                    <Controller
                      control={control}
                      name='deliveryRegion'
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => (
                        <div
                          ref={field.ref}
                          className={cn(
                            'flex h-[52px] items-center border border-input px-3 py-2 text-sm text-[#555555]',
                            formState.errors.deliveryRegion
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : '',
                          )}
                          onClick={() => handleRegionClick('인수')}
                        >
                          {/* useForm 시용 위한 비노출 input */}
                          <input
                            type='hidden'
                            value={field.value}
                            name='deliveryRegion'
                          />
                          {purchaseOrderDTO?.orderData?.additionalInfo.할부
                            .deliveryRegion ? (
                            <div>
                              {purchaseOrderDTO?.orderData?.additionalInfo.할부
                                .deliveryRegion !== undefined &&
                                SidoData.find((el1) => {
                                  return (
                                    purchaseOrderDTO.orderData &&
                                    el1.id ===
                                      (purchaseOrderDTO?.orderData
                                        ?.additionalInfo.할부.deliveryRegion !==
                                        undefined &&
                                        SigunguData.find((el) => {
                                          return (
                                            purchaseOrderDTO.orderData &&
                                            el.id ===
                                              purchaseOrderDTO.orderData
                                                .additionalInfo.할부
                                                .deliveryRegion
                                          );
                                        })?.sidoId)
                                  );
                                })?.name}{' '}
                              {purchaseOrderDTO?.orderData?.additionalInfo.할부
                                .deliveryRegion !== undefined &&
                                SigunguData.find((el) => {
                                  return (
                                    purchaseOrderDTO.orderData &&
                                    el.id ===
                                      purchaseOrderDTO.orderData.additionalInfo
                                        .할부.deliveryRegion
                                  );
                                })?.name}
                            </div>
                          ) : (
                            <div>선택해 주세요</div>
                          )}
                          <ChevronDown className='ml-auto h-5 w-5 text-[#111111]' />
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>거주(등록) 지역</div>
                  <div className='mt-2'>
                    <Controller
                      control={control}
                      name='residenceRegion'
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => {
                        return (
                          <div
                            ref={field.ref}
                            className={cn(
                              'flex h-[52px] items-center border border-input px-3 py-2 text-sm text-[#555555]',
                              formState.errors.residenceRegion
                                ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                                : '',
                            )}
                            onClick={() => handleRegionClick('거주')}
                          >
                            {/* useForm 시용 위한 비노출 input */}
                            <input
                              type='hidden'
                              value={field.value}
                              name='residenceRegion'
                            />
                            {purchaseOrderDTO?.orderData?.additionalInfo.할부
                              .residenceRegion ? (
                              <div>
                                {purchaseOrderDTO?.orderData?.additionalInfo
                                  .할부.residenceRegion !== undefined &&
                                  SidoData.find((el1) => {
                                    return (
                                      purchaseOrderDTO.orderData &&
                                      el1.id ===
                                        (purchaseOrderDTO?.orderData
                                          ?.additionalInfo.할부
                                          .residenceRegion !== undefined &&
                                          SigunguData.find((el) => {
                                            return (
                                              purchaseOrderDTO.orderData &&
                                              el.id ===
                                                purchaseOrderDTO.orderData
                                                  .additionalInfo.할부
                                                  .residenceRegion
                                            );
                                          })?.sidoId)
                                    );
                                  })?.name}{' '}
                                {purchaseOrderDTO?.orderData?.additionalInfo
                                  .할부.residenceRegion !== undefined &&
                                  SigunguData.find((el) => {
                                    return (
                                      purchaseOrderDTO.orderData &&
                                      el.id ===
                                        purchaseOrderDTO.orderData
                                          .additionalInfo.할부.residenceRegion
                                    );
                                  })?.name}
                              </div>
                            ) : (
                              <div>선택해 주세요</div>
                            )}
                            <ChevronDown className='ml-auto h-5 w-5 text-[#111111]' />
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <SelectSigungu
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              setRegion={handleResidenceRegionChange}
            />
            {/* 구입 시기 DatePicker */}
            <CarsayoBottomSheet isOpen={isDatePickerOpen} onClose={handleClose}>
              <DatePicker
                isOpen={isDatePickerOpen}
                min={dayjs().subtract(1, 'year').startOf('day').toDate()}
                max={dayjs().add(20, 'year').endOf('day').toDate()}
                onChange={handleInputChange.구입시기}
                showHeader={false}
                showFooter={false}
                value={
                  purchaseOrderDTO?.orderData?.additionalInfo?.할부
                    ?.purchaseDate
                    ? dayjs(
                        purchaseOrderDTO?.orderData?.additionalInfo?.할부
                          ?.purchaseDate,
                      ).toDate()
                    : new Date()
                }
              />
            </CarsayoBottomSheet>
          </div>

          <div className='sticky bottom-0 w-full h-full flex-none bg-[#F7F7FB] px-4 py-6'>
            {purchasePrice > 0 && (
              <div className='flex items-center justify-between'>
                <div className='text-[#555555]'>총 예상 금액</div>
                <div
                  onClick={() => setIsDetailOpen(true)}
                  className='flex items-center gap-2 font-semibold'
                >
                  {(
                    Number(purchaseOrderDTO?.orderData?.carGrade?.price) +
                    Number(
                      purchaseOrderDTO?.orderData?.carColor?.price
                        ? purchaseOrderDTO?.orderData?.carColor?.price
                        : 0,
                    ) +
                    Number(
                      purchaseOrderDTO?.orderData?.carOptionList &&
                        purchaseOrderDTO?.orderData?.carOptionList.length > 0
                        ? purchaseOrderDTO.orderData.carOptionList
                            .map((el) => {
                              return el.price;
                            })
                            .reduce((prev, curr) => {
                              return prev + curr;
                            })
                        : 0,
                    ) +
                    0
                  ).toLocaleString()}
                  원 <img src='/assets/images/v2/coin.svg' alt='' />
                </div>
              </div>
            )}
            <div className='mt-4 grid grid-cols-2'>
              <Button
                variant='outline'
                className='rounded-r-none bg-white'
                onClick={goBack}
              >
                이전
              </Button>

              <Button className='rounded-l-none' type='submit'>
                다음
              </Button>
              {/*<input*/}
              {/*  type='submit'*/}
              {/*  className='rounded-l-none'*/}
              {/*  // onClick={confirm}*/}
              {/*/>다음*/}
            </div>
          </div>
          {purchaseOrderDTO && purchaseOrderDTO.orderData && (
            <PriceDetail
              isOpen={isDetailOpen}
              setIsOpen={setIsDetailOpen}
              purchaseCarInfo={purchaseOrderDTO.orderData}
            />
          )}
        </div>
      </form>
    </div>
  );
}
