/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button } from 'components/ui/button';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from 'utils';
import PurchasingCarInfo from './components/carInfo';
import usePurchaseStore from './store';
import OptionDescription from './components/optionDescription';
import { CarOptionDescription } from '@carsayo/types';
import { getCarOptionDescription } from 'apis/purchase';
import { tr } from 'date-fns/locale';

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      'peer inline-flex h-3 w-9 shrink-0 cursor-pointer items-center rounded-full border-transparent bg-[#111111] transition-colors focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
      className,
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        'pointer-events-none block h-5 w-5 rounded-full bg-white shadow-[1px_1px_3px_0_rgba(0,0,0,.10)] ring-0 transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0 data-[state=checked]:bg-primary',
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export default function Option() {
  const navigate = useNavigate();

  const { setTopTabbarTitle } = useSystemStore();
  const { purchaseOrderDTO, setPurchaseOrderDTO, catalogue } =
    usePurchaseStore();

  const [carOptionDescriptionOpen, setCarOptionDescriptionOpen] =
    useState<boolean>(false);
  const [carOptionDescription, setCarOptionDescription] =
    useState<CarOptionDescription | null>(null);

  const setData = async () => {
    if (!purchaseOrderDTO) return;
    await setPurchaseOrderDTO({
      id: purchaseOrderDTO.id,
      type: purchaseOrderDTO.type,
      update: {
        progress: 55,
        currentPath: '/purchase/option',
      },
    });
  };

  const goBack = () => {
    navigate('/purchase/color', { replace: true });
  };
  const goNext = () => {
    navigate('/purchase/method', { replace: true });
  };

  const openOptionDescription = async (descriptionId: number) => {
    setCarOptionDescription(
      await getCarOptionDescription({ carOptionDescriptionId: descriptionId }),
    );
    setCarOptionDescriptionOpen(true);
    return;
  };

  useEffect(() => {
    window.native.onBackPressed = goBack;
  }, []);

  useLayoutEffect(() => {
    setTopTabbarTitle('옵션 선택');
    setData();
  }, []);

  return (
    <div className='flex flex-col h-full'>
      <div className='flex-auto overflow-y-scroll'>
        <PurchasingCarInfo></PurchasingCarInfo>
        <div className='px-4 py-8'>
          <div className='text-lg font-semibold'>옵션 선택</div>
          <div className='mt-4 space-y-4'>
            {catalogue?.option &&
            catalogue.option.data.filter((el) => {
              return el.price > 0;
            }).length > 0 ? (
              catalogue.option.data
                .filter((el) => {
                  return el.price > 0 && !el.name.includes('외장색상');
                })
                .map((el, index) => (
                  <label
                    id='option'
                    key={el.id}
                    className={cn(
                      'flex gap-2 justify-between rounded-md border bg-[#F7F7FB] pl-4 pr-2 py-6 text-right',
                      purchaseOrderDTO?.orderData?.carOptionList &&
                        purchaseOrderDTO.orderData.carOptionList.find((el2) => {
                          return el2.id === el.id;
                        }) !== undefined &&
                        'border-primary',
                    )}
                  >
                    <Switch
                      checked={
                        purchaseOrderDTO?.orderData?.carOptionList &&
                        purchaseOrderDTO.orderData.carOptionList.find((el2) => {
                          return el2.id === el.id;
                        }) !== undefined
                      }
                      onClick={() => {
                        if (!purchaseOrderDTO?.orderData) return;

                        const currentOptionIdSet = new Set(
                          purchaseOrderDTO.orderData.carOptionList.map((el) => {
                            return el.id;
                          }),
                        );

                        if (currentOptionIdSet.has(el.id)) {
                          currentOptionIdSet.delete(el.id);
                        } else {
                          currentOptionIdSet.add(el.id);
                        }

                        setPurchaseOrderDTO({
                          id: purchaseOrderDTO.id,
                          type: purchaseOrderDTO.type,
                          update: {
                            carOptionIdList: Array.from(currentOptionIdSet),
                          },
                        });
                      }}
                      id='option'
                      className='bg-[#111111] my-1'
                    />
                    <div className='flex-auto'>
                      <div className='text-title text-[#111] font-normal leading-4'>
                        {el.name}
                      </div>
                      <div className='mt-2 text-desc text-[#555555]'>
                        + {el.price.toLocaleString()}원
                      </div>
                    </div>
                    {catalogue.option &&
                    catalogue.option.data.find((el) => {
                      if (el.descriptionId !== null) return true;
                    }) ? (
                      <div
                        className={cn(
                          'flex justify-center items-center flex-none px-2',
                          !el.descriptionId && 'invisible',
                        )}
                        onClick={(event) => {
                          event.preventDefault();
                          if (el.descriptionId)
                            openOptionDescription(el.descriptionId);
                        }}
                      >
                        <img
                          className='w-6'
                          src='/assets/images/v2/info-circle.svg'
                          alt=''
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </label>
                ))
            ) : (
              <div className='rounded-md border bg-[#F7F7FB] py-6'>
                <div className='mx-auto h-[41px] w-[50px]'>
                  <img
                    src='/assets/images/v2/nooption.png'
                    alt='no option icon'
                  />
                </div>
                <div className='mt-6 text-center text-title text-[#55555]'>
                  추가 옵션이 없습니다.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='flex-none grid grid-cols-2 bg-[#F7F7FB] px-4 py-6'>
        <Button
          variant='outline'
          className='rounded-r-none bg-white'
          onClick={goBack}
        >
          이전
        </Button>
        <Button
          className='rounded-l-none'
          onClick={async () => {
            if (!purchaseOrderDTO) return;
            await setPurchaseOrderDTO({
              id: purchaseOrderDTO.id,
              type: purchaseOrderDTO.type,
              update: {
                progress: 50,
                carOptionIdList:
                  catalogue?.option &&
                  catalogue.option.data.filter((el) => {
                    return el.price > 0;
                  }).length === 0
                    ? null
                    : undefined,
              },
            });
            goNext();
          }}
        >
          다음
        </Button>
      </div>

      {carOptionDescription && (
        <OptionDescription
          isOpen={carOptionDescriptionOpen}
          setIsOpen={setCarOptionDescriptionOpen}
          carOptionDescription={carOptionDescription}
        ></OptionDescription>
      )}
    </div>
  );
}
