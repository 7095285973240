import React from 'react';

interface MyComponentProps {
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
}

const CalendarIcon: React.FC<MyComponentProps> = ({
  fill,
  stroke,
  width,
  height,
}) => {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox='0 0 20 20'
      fill={fill || 'none'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.6668 5.83331H3.3335V16.6666H16.6668V5.83331Z'
        stroke={stroke || '#7273F9'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.6668 5.83331H3.3335V9.99998H16.6668V5.83331Z'
        stroke={stroke || '#7273F9'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.8335 3.33331V5.83331'
        stroke={stroke || '#7273F9'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.1665 3.33331V5.83331'
        stroke={stroke || '#7273F9'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export default CalendarIcon;
