import { niceAuthRoutine } from 'apis/authentication';
import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';

export interface CarsayoNiceAuthCallback {
  status: 'success' | 'failed';
  message?: string;
  token?: string;
}

interface CarsayoNiceAuthInterface {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  callback: (params: CarsayoNiceAuthCallback) => void;
}

const CarsayoNiceAuth = ({
  isOpen,
  setOpen,
  callback,
}: CarsayoNiceAuthInterface) => {
  const formRef = useRef<HTMLFormElement>(null);
  /** 본인인증 성공 boolean값 저장 */
  const handleVoidEvents = {
    identification: async () => {
      const option = `status=no, menubar=no, toolbar=no, resizable=no, fullscreen=yes`;
      try {
        const authRoutine = niceAuthRoutine();
        const response = (await authRoutine.next()).value;
        const popup = window.open(``, 'nicePopup', option);

        if (formRef.current) {
          const token_version_id_input = formRef.current.querySelector(
            'input[name="token_version_id"]',
          ) as HTMLInputElement;
          const integrity_value_input = formRef.current.querySelector(
            'input[name="integrity_value"]',
          ) as HTMLInputElement;
          const enc_data_input = formRef.current.querySelector(
            'input[name="enc_data"]',
          ) as HTMLInputElement;
          if (
            token_version_id_input &&
            integrity_value_input &&
            enc_data_input
          ) {
            token_version_id_input.value = response?.token_version_id;
            integrity_value_input.value = response?.integrity_value;
            enc_data_input.value = response?.enc_data;
          }
          formRef.current.target = 'nicePopup';

          const timer = setInterval(async function () {
            if (popup?.closed && response.id) {
              const identity_id = (await authRoutine.next()).value as
                | {
                    identityVerifyToken: string;
                  }
                | undefined;

              callback({
                status: identity_id?.identityVerifyToken ? 'success' : 'failed',
                message: identity_id?.identityVerifyToken
                  ? undefined
                  : '본인인증이 완료되지 않았습니다.',
                token: identity_id?.identityVerifyToken,
              });

              setOpen(false);
              clearInterval(timer);
            } else if (!popup) {
              callback({
                status: 'failed',
                message: '본인인증이 완료되지 않았습니다.',
              });

              setOpen(false);
              clearInterval(timer);
            }
          }, 1000);
          formRef.current.submit();
        }
      } catch (error) {
        return;
      }
    },
  };

  useEffect(() => {
    if (isOpen) {
      handleVoidEvents.identification();
    }
  }, [isOpen]);

  return (
    <form
      ref={formRef}
      action='https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb'
      className='hidden'
    >
      <input type='hidden' id='m' name='m' value='service' />
      <input
        type='hidden'
        id='token_version_id'
        name='token_version_id'
        value=''
      />
      <input type='hidden' id='enc_data' name='enc_data' value='' />
      <input
        type='hidden'
        id='integrity_value'
        name='integrity_value'
        value=''
      />
    </form>
  );
};

export default CarsayoNiceAuth;
