import ReactGA from 'react-ga4';

export const ga4_event = ({
  action,
  category,
  label,
  value,
}: {
  category: string;
  action: string;
  label?: string;
  value?: number;
}) => {
  if (process.env.REACT_APP_NODE_ENV !== 'production') return;
  ReactGA.event({
    action: action,
    category: category,
    label: label,
    value: value,
  });
};
