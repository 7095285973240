/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { SigunguId, SidoId, SidoData, SigunguData } from '@carsayo/types';
import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { ChevronRight, X } from 'lucide-react';
import { useState } from 'react';

export function SelectSigungu({
  isOpen,
  setIsOpen,
  setRegion,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setRegion: (value: SigunguId) => void;
}) {
  const [selected, setSelected] = useState<SidoId | null>(null);

  const handleSelected = (item: { sigunguId: SigunguId } | SidoId) => {
    if (!isOpen) return;
    if (typeof item === 'number') {
      setSelected(item);
      if (item === 8) {
        // 세종시
        setRegion(76);
        setIsOpen(false);
      }
    } else {
      setRegion(item.sigunguId);
      setIsOpen(false);
    }
  };

  return (
    <Drawer
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
        setSelected(null);
      }}
    >
      <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
        <DrawerHeader className='relative'>
          <DrawerTitle>지역 선택</DrawerTitle>
          <DrawerClose asChild>
            <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
              <X className='h-7 w-7' />
            </Button>
          </DrawerClose>
        </DrawerHeader>
        <div className='mx-4 border-b pb-4'>
          {selected && (
            <div className='flex h-[54px] items-center bg-[#F7F7FB] p-4'>
              <div className='w-2.5'>·</div>
              <div>
                {
                  SidoData.find((el) => {
                    return el.id === selected;
                  })?.name
                }
              </div>
              <div
                className='ml-auto flex items-center'
                onClick={() => setSelected(null)}
              >
                초기화
                <ChevronRight className='h-4 w-4' strokeWidth='1.5' />
              </div>
            </div>
          )}
        </div>
        <div className='grow overflow-y-auto p-4 pb-10'>
          <div className='grid grid-cols-3 gap-[21.5px] overflow-y-auto'>
            {selected
              ? SigunguData.filter((el) => {
                  return el.sidoId === selected;
                }).map((item, index) => (
                  <label
                    key={index}
                    className='relative flex items-center justify-center rounded-lg border py-3 text-sm font-semibold has-[:checked]:border-primary'
                    onClick={() => handleSelected({ sigunguId: item.id })}
                  >
                    <input
                      type='radio'
                      name='region'
                      value={index}
                      className='w-0 appearance-none'
                    />
                    {item.name}
                  </label>
                ))
              : SidoData.map((item, index) => (
                  <label
                    key={item.name}
                    className='relative flex items-center justify-center rounded-lg border py-3 text-sm font-semibold has-[:checked]:border-primary'
                    onClick={() => handleSelected(item.id)}
                  >
                    <input
                      type='radio'
                      name='region'
                      value={item.name}
                      className='w-0 appearance-none'
                    />
                    {item.name}
                  </label>
                ))}
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
