import classNames from 'classnames';
import { InputHTMLAttributes, useId } from 'react';

interface CarsayoRadioInterface
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'type' | 'className' | 'id'
  > {
  label: string;
  className?: string;
}

const CarsayoRadio = ({ label, className, ...rest }: CarsayoRadioInterface) => {
  const id = useId();
  return (
    <label className='flex flex-row gap-2' htmlFor={id}>
      <input
        id={id}
        type='radio'
        name='progressRadio'
        className={classNames(
          className && className,
          `appearance-none hover:cursor-pointer w-[22px] h-[22px] shrink-0 !border-[#E5E5EC] rounded-full !text-[10px] text-[#fff] focus:ring-transparent disabled:opacity-50 disabled:pointer-events-none`,
          'checked:!bg-radio-active checked:!bg-[length:22px_22px]',
          '!bg-radio-default !bg-[length:22px_22px] !ml-0',
        )}
        {...rest}
      />
      {label}
    </label>
  );
};

export default CarsayoRadio;
