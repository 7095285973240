import React from 'react';

interface MyComponentProps {
  fill?: string;
  width?: string;
  height?: string;
}

const BuyNewCarIcon: React.FC<MyComponentProps> = ({ fill, width, height }) => {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.9454 17.9684H2.75001C2.64368 17.9684 2.59771 17.8822 2.64943 17.7759L4.23564 14.4569C4.28738 14.3506 4.39368 14.2644 4.47128 14.2644H16.2213C16.3017 14.2644 16.408 14.3506 16.4569 14.4569L18.0431 17.7759C18.0948 17.8822 18.0488 17.9684 17.9425 17.9684H17.9454Z'
        fill='#E2E9F5'
      />
      <path
        d='M16.1236 14.2442H4.54315C4.40808 14.2442 4.35061 14.1322 4.41381 13.9971L5.39658 11.9454C5.46268 11.8103 5.59775 11.6982 5.69831 11.6982H14.9684C15.069 11.6982 15.204 11.8103 15.2701 11.9454L16.2529 13.9971C16.319 14.1322 16.2586 14.2442 16.1236 14.2442Z'
        fill='#E2E9F5'
      />
      <path
        d='M13.8535 15.8937H0.706901C0.589084 15.8937 0.540234 15.7989 0.594831 15.6811L2.0115 13.115C2.06897 12.9972 2.18391 12.9023 2.27299 12.9023H12.4799C12.5661 12.9023 12.6839 12.9972 12.7414 13.115L13.9684 15.6811C14.0259 15.7989 13.9742 15.8937 13.8563 15.8937H13.8535Z'
        fill='#E2E9F5'
      />
      <path
        d='M6.71553 15.8937H19.8649C19.9828 15.8937 20.0316 15.7989 19.977 15.6811L18.5604 13.115C18.5029 12.9972 18.3879 12.9023 18.2989 12.9023H8.09197C8.00577 12.9023 7.88793 12.9972 7.83047 13.115L6.60347 15.6811C6.546 15.7989 6.5977 15.8937 6.71553 15.8937Z'
        fill='#E2E9F5'
      />
      <path
        d='M16.842 15.8937H3.82187C3.66957 15.8937 3.60347 15.7672 3.67817 15.6149L4.84197 13.1782C4.9138 13.0259 5.069 12.8994 5.18107 12.8994H15.4828C15.5977 12.8994 15.75 13.0259 15.8219 13.1782L16.9857 15.6149C17.0575 15.7672 16.9943 15.8937 16.842 15.8937Z'
        fill='#818EB8'
      />
      <path
        d='M19.3132 8.47423H19.2874C18.8937 8.47136 18.5776 8.1495 18.5776 7.75583V7.7386C18.5776 7.64376 18.5 7.56616 18.4052 7.56616C18.3103 7.56616 18.2328 7.64376 18.2328 7.7386V7.76156C18.2299 8.1524 17.9138 8.47136 17.523 8.47423H17.4971C17.4023 8.47423 17.3247 8.5518 17.3247 8.64663C17.3247 8.74146 17.4023 8.81906 17.4971 8.81906H17.5144C17.908 8.81906 18.2299 9.13803 18.2328 9.5317V9.5547C18.2328 9.6495 18.3103 9.7271 18.4052 9.7271C18.5 9.7271 18.5776 9.6495 18.5776 9.5547V9.53743C18.5776 9.1409 18.8994 8.81906 19.296 8.81906H19.3132C19.408 8.81906 19.4856 8.74146 19.4856 8.64663C19.4856 8.5518 19.408 8.47423 19.3132 8.47423Z'
        fill='#FFD142'
      />
      <path
        d='M14.0345 3.09769C14.217 3.09769 14.3649 2.94973 14.3649 2.76723C14.3649 2.58472 14.217 2.43677 14.0345 2.43677C13.852 2.43677 13.704 2.58472 13.704 2.76723C13.704 2.94973 13.852 3.09769 14.0345 3.09769Z'
        fill='#8EC1F6'
      />
      <path
        d='M17.2385 6.39064C17.421 6.39064 17.569 6.2427 17.569 6.06017C17.569 5.87767 17.421 5.72974 17.2385 5.72974C17.056 5.72974 16.908 5.87767 16.908 6.06017C16.908 6.2427 17.056 6.39064 17.2385 6.39064Z'
        fill='#8EC1F6'
      />
      <path
        d='M4.81754 4.71509L4.02301 6.09125L4.46844 6.34845L5.26301 4.97225L4.81754 4.71509Z'
        fill='#FF8A70'
      />
      <path
        d='M17.6882 3.09204L17.2427 3.34924L18.0373 4.72541L18.4827 4.46824L17.6882 3.09204Z'
        fill='#FF8A70'
      />
      <path
        d='M15.4943 10.48H4.54599C4.22855 10.48 3.97125 10.7373 3.97125 11.0547V13.0317C3.97125 13.3491 4.22855 13.6064 4.54599 13.6064H15.4943C15.8117 13.6064 16.069 13.3491 16.069 13.0317V11.0547C16.069 10.7373 15.8117 10.48 15.4943 10.48Z'
        fill='#4849BE'
      />
      <path
        d='M17.1954 10.1983C17.1954 9.87365 17.0345 9.58628 16.7931 9.40525V9.34492C16.7931 9.34492 16.7931 9.31905 16.7931 9.23572V9.22422C16.7931 8.46562 16.4052 8.15525 16.4052 8.15525C16.4052 8.15525 15.9512 7.47998 15.3362 6.62365C14.8103 5.89375 13.6437 5.85352 13.6437 5.85352H9.60056C8.78736 5.85352 8.25862 6.48572 8.25862 6.48572L6.21552 8.52882C6.21552 8.52882 4.52586 8.75872 3.84482 8.99145C3.16378 9.22422 2.96838 9.61502 2.96838 9.61502C2.84769 9.77882 2.77298 9.97998 2.77298 10.1983V11.2443H17.1925V10.1983H17.1954Z'
        fill='#7273F9'
      />
      <path
        d='M10.7845 9.66677H10.4425C10.319 9.66677 10.2184 9.56621 10.2184 9.44264C10.2184 9.31907 10.319 9.21851 10.4425 9.21851H10.7845C10.908 9.21851 11.0086 9.31907 11.0086 9.44264C11.0086 9.56621 10.908 9.66677 10.7845 9.66677Z'
        fill='#4849BE'
      />
      <path
        d='M16.9224 9.88232H16.796C16.6373 9.88232 16.5086 10.011 16.5086 10.1697V10.4254C16.5086 10.5841 16.6373 10.7128 16.796 10.7128H16.9224C17.0811 10.7128 17.2098 10.5841 17.2098 10.4254V10.1697C17.2098 10.011 17.0811 9.88232 16.9224 9.88232Z'
        fill='#E5E5FE'
      />
      <path
        d='M3.2471 9.6582H3.03446C2.87575 9.6582 2.7471 9.78687 2.7471 9.94557V10.3048C2.7471 10.4635 2.87575 10.5921 3.03446 10.5921H3.2471C3.40579 10.5921 3.53446 10.4635 3.53446 10.3048V9.94557C3.53446 9.78687 3.40579 9.6582 3.2471 9.6582Z'
        fill='#E5E5FE'
      />
      <path
        d='M2.77588 11.2444V11.8737C2.77588 12.4197 3.22128 12.8651 3.76727 12.8651H16.204C16.75 12.8651 17.1954 12.4197 17.1954 11.8737V11.2444H2.77588Z'
        fill='#4849BE'
      />
      <path
        d='M16.204 12.9742H3.76436C3.15804 12.9742 2.66666 12.48 2.66666 11.8765V10.1984C2.66666 9.97423 2.73562 9.75869 2.86781 9.56906C2.87643 9.55179 3.08907 9.13799 3.80459 8.89376C4.43966 8.67536 5.90516 8.46559 6.16092 8.43113L8.17816 6.41386C8.19826 6.39089 8.77299 5.75009 9.59769 5.75009H13.6408C13.681 5.74719 14.7672 5.70696 15.4195 6.56329C16.0546 7.39663 16.4914 8.09489 16.4943 8.10353C16.5086 8.12076 16.954 8.71273 16.8994 9.24146C16.8994 9.25009 16.8994 9.25869 16.8994 9.26446C16.8994 9.29029 16.8994 9.32193 16.9023 9.35926C17.158 9.56906 17.3046 9.87363 17.3046 10.2041V11.8823C17.3046 12.4886 16.8103 12.98 16.2069 12.98L16.204 12.9742ZM3.06896 9.64949C3.06896 9.64949 3.06033 9.66963 3.05459 9.67823C2.94252 9.83343 2.88217 10.0116 2.88217 10.1984V11.8765C2.88217 12.365 3.27873 12.7587 3.76436 12.7587H16.204C16.6925 12.7587 17.0862 12.3622 17.0862 11.8765V10.1984C17.0862 9.91963 16.954 9.66386 16.727 9.49433C16.7011 9.47423 16.6839 9.44259 16.6839 9.40813V9.40239C16.6724 9.37939 16.6667 9.35066 16.6667 9.30756C16.6667 9.27593 16.6695 9.22996 16.6839 9.19549C16.7155 8.75583 16.319 8.22709 16.3132 8.22133C16.3046 8.20696 15.8707 7.51446 15.2443 6.68973C14.6609 5.92536 13.6523 5.96273 13.6437 5.96273H9.59482C8.87069 5.96273 8.33906 6.55466 8.33332 6.56043L6.28446 8.60926C6.26722 8.62653 6.24712 8.63799 6.22412 8.64089C6.20689 8.64089 4.53736 8.87076 3.87356 9.09779C3.31896 9.28743 3.10919 9.58629 3.06608 9.65526L3.06896 9.64949Z'
        fill='#7273F9'
      />
      <path
        d='M11.0661 8.48856V6.61499H9.93391C9.91954 6.61499 9.90804 6.61499 9.89368 6.61499L11.0603 8.48569H11.0632L11.0661 8.48856Z'
        fill='#E5E5FE'
      />
      <path
        d='M9.8937 6.62085C9.26726 6.63522 9.06036 6.98578 9.06036 6.98578L7.68393 8.49155H11.0604L9.8937 6.62085Z'
        fill='#C7C7FD'
      />
      <path
        d='M11.0661 8.59767H7.68391C7.64081 8.59767 7.60344 8.57181 7.58621 8.53444C7.56898 8.49421 7.57757 8.45111 7.60631 8.41951L8.98274 6.91377C8.98274 6.91377 9.22414 6.51147 9.93678 6.51147H11.069C11.1293 6.51147 11.1782 6.56031 11.1782 6.62067V8.49421C11.1782 8.55457 11.1293 8.60341 11.069 8.60341L11.0661 8.59767ZM7.92818 8.38214H10.9569V6.72411H9.93391C9.35058 6.72411 9.15804 7.02584 9.15231 7.04021L7.92818 8.38214Z'
        fill='#C7C7FD'
      />
      <path
        d='M12.2011 6.61795C12.796 6.61795 13.5316 6.61795 13.5316 6.61795C13.5316 6.61795 14.2845 6.60645 14.635 7.07485C15.046 7.62368 15.3333 8.03748 15.3333 8.03748C15.3333 8.03748 15.5402 8.33058 15.4483 8.49151H12.204V6.61795H12.2011Z'
        fill='#C7C7FD'
      />
      <path
        d='M14.6322 7.0719C14.2845 6.6064 13.5287 6.615 13.5287 6.615H13.1667L15.0489 7.64377C14.9339 7.4857 14.7931 7.2903 14.6293 7.0719H14.6322Z'
        fill='#E5E5FE'
      />
      <path
        d='M15.4425 8.59779H12.1983C12.1379 8.59779 12.0891 8.54896 12.0891 8.48863V6.61506C12.0891 6.55469 12.1379 6.50586 12.1983 6.50586H13.5259C13.5575 6.50873 14.3391 6.50299 14.7155 7.00586C15.1236 7.55183 15.4109 7.96849 15.4138 7.97136C15.454 8.02883 15.6523 8.32769 15.5316 8.54033C15.5115 8.57483 15.477 8.59493 15.4368 8.59493L15.4425 8.59779ZM12.3075 8.38229H15.3592C15.3563 8.30183 15.296 8.17826 15.2414 8.09779C15.2385 8.09206 14.9511 7.67826 14.546 7.13803C14.2327 6.71849 13.5431 6.72999 13.5316 6.72426H12.3103V8.38229H12.3075Z'
        fill='#C7C7FD'
      />
      <path
        d='M13.4511 14.9397C14.3446 14.9397 15.069 14.2154 15.069 13.3219C15.069 12.4284 14.3446 11.7041 13.4511 11.7041C12.5577 11.7041 11.8333 12.4284 11.8333 13.3219C11.8333 14.2154 12.5577 14.9397 13.4511 14.9397Z'
        fill='#333331'
      />
      <path
        d='M13.4511 13.842C13.7384 13.842 13.9713 13.6091 13.9713 13.3219C13.9713 13.0346 13.7384 12.8018 13.4511 12.8018C13.1639 12.8018 12.931 13.0346 12.931 13.3219C12.931 13.6091 13.1639 13.842 13.4511 13.842Z'
        fill='#85858B'
      />
      <path
        d='M6.29883 14.9397C7.19233 14.9397 7.91666 14.2154 7.91666 13.3219C7.91666 12.4284 7.19233 11.7041 6.29883 11.7041C5.40536 11.7041 4.68103 12.4284 4.68103 13.3219C4.68103 14.2154 5.40536 14.9397 6.29883 14.9397Z'
        fill='#333331'
      />
      <path
        d='M6.29885 13.842C6.58611 13.842 6.81898 13.6091 6.81898 13.3219C6.81898 13.0346 6.58611 12.8018 6.29885 12.8018C6.01161 12.8018 5.77875 13.0346 5.77875 13.3219C5.77875 13.6091 6.01161 13.842 6.29885 13.842Z'
        fill='#85858B'
      />
      <path
        d='M11.6494 11.4913C11.6092 11.4913 11.5776 11.4597 11.5776 11.4195V9.20684C11.5776 9.16661 11.6092 9.13501 11.6494 9.13501C11.6897 9.13501 11.7213 9.16661 11.7213 9.20684V11.4195C11.7213 11.4597 11.6897 11.4913 11.6494 11.4913Z'
        fill='#4849BE'
      />
      <path
        d='M7.60346 5.25276H7.5776C7.1839 5.24989 6.86783 4.92806 6.86783 4.53436V4.51713C6.86783 4.42229 6.79023 4.34473 6.6954 4.34473C6.60056 4.34473 6.523 4.42229 6.523 4.51713V4.54013C6.52013 4.93093 6.20403 5.24989 5.81323 5.25276H5.78736C5.69253 5.25276 5.61493 5.33036 5.61493 5.42519C5.61493 5.51999 5.69253 5.59759 5.78736 5.59759H5.8046C6.19826 5.59759 6.52013 5.91656 6.523 6.31023V6.33323C6.523 6.42806 6.60056 6.50563 6.6954 6.50563C6.79023 6.50563 6.86783 6.42806 6.86783 6.33323V6.31599C6.86783 5.91943 7.18966 5.59759 7.5862 5.59759H7.60346C7.69826 5.59759 7.77586 5.51999 7.77586 5.42519C7.77586 5.33036 7.69826 5.25276 7.60346 5.25276Z'
        fill='#FFD142'
      />
      <path
        d='M5.41598 2.66675H4.91599V3.16675H5.41598V2.66675Z'
        fill='#2CBD80'
      />
      <path
        d='M15.8128 5.02246L15.4593 5.37599L15.8128 5.72956L16.1664 5.37599L15.8128 5.02246Z'
        fill='#2CBD80'
      />
      <path
        d='M11.6753 3.49427H10.9109C10.5 3.49427 10.1638 3.15805 10.1638 2.74713C10.1638 2.33621 10.5 2 10.9109 2C11.4167 2 11.592 2.51724 11.6495 2.68678C11.7385 2.95115 11.8506 3.23563 11.8506 3.23851C11.8736 3.29598 11.865 3.36207 11.8305 3.4138C11.796 3.46553 11.7385 3.49713 11.6753 3.49713V3.49427ZM10.9109 2.37644C10.7069 2.37644 10.5403 2.5431 10.5403 2.74713C10.5403 2.95115 10.7069 3.11782 10.9109 3.11782H11.4023C11.3678 3.02299 11.3276 2.91379 11.2931 2.80747C11.1638 2.42816 11.0288 2.37644 10.9109 2.37644Z'
        fill='#F26166'
      />
      <path
        d='M12.4397 3.4942H11.6753C11.6121 3.4942 11.5546 3.4626 11.5201 3.41087C11.4856 3.35913 11.477 3.29305 11.5 3.23558C11.5 3.23558 11.6121 2.94822 11.7012 2.68385C11.7586 2.51431 11.931 1.99707 12.4397 1.99707C12.8506 1.99707 13.1868 2.33328 13.1868 2.7442C13.1868 3.15512 12.8506 3.49133 12.4397 3.49133V3.4942ZM11.9483 3.11776H12.4397C12.6437 3.11776 12.8104 2.95109 12.8104 2.74707C12.8104 2.54305 12.6437 2.37638 12.4397 2.37638C12.3219 2.37638 12.1868 2.4281 12.0575 2.80742C12.0201 2.91661 11.9828 3.02581 11.9483 3.11776Z'
        fill='#F26166'
      />
      <path
        d='M12.8678 3.23853H10.4828C10.1653 3.23853 9.90802 3.49582 9.90802 3.81325V5.16382C9.90802 5.48122 10.1653 5.73852 10.4828 5.73852H12.8678C13.1852 5.73852 13.4425 5.48122 13.4425 5.16382V3.81325C13.4425 3.49582 13.1852 3.23853 12.8678 3.23853Z'
        fill='#FFD142'
      />
      <path d='M11.9627 3.07471H11.3879V5.75H11.9627V3.07471Z' fill='#FFAC00' />
      <path
        d='M13.0948 3.02588H10.2558C10.0086 3.02588 9.8046 3.2299 9.8046 3.47703V3.8506C9.8046 3.93393 9.87356 4.00003 9.95403 4.00003H13.3937C13.477 4.00003 13.5431 3.93107 13.5431 3.8506V3.47703C13.5431 3.2299 13.3391 3.02588 13.092 3.02588H13.0948Z'
        fill='#FFEC74'
      />
      <path
        d='M12.8764 3.00849H12.2328C12.2126 3.00849 12.1954 3.02573 12.1954 3.04585V4.54872C12.1954 4.54872 12.2011 4.57459 12.2126 4.58032C12.2184 4.58319 12.2241 4.58609 12.2328 4.58609C12.2385 4.58609 12.2442 4.58609 12.25 4.58032L12.546 4.42802C12.546 4.42802 12.5488 4.42802 12.5517 4.42802H12.5603L12.8563 4.58032C12.8563 4.58032 12.8678 4.58609 12.8736 4.58609C12.8793 4.58609 12.8879 4.58609 12.8937 4.58032C12.9052 4.57459 12.9109 4.56309 12.9109 4.54872V3.04297C12.9109 3.02286 12.8937 3.00562 12.8736 3.00562L12.8764 3.00849Z'
        fill='#F26166'
      />
    </svg>
  );
};
export default BuyNewCarIcon;
