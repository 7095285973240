import { useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';

export default function MypageIndex() {
  const { setIsBottomTabbar } = useSystemStore();

  useLayoutEffect(() => {
    setIsBottomTabbar(true);
  }, []);

  return <Outlet />;
}
