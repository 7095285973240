import { Member } from '@carsayo/types';
import { getMember } from 'apis/authentication';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface MemberInterface {
  loginMember: Member | null;
}

interface MemberAction {
  setLoginMember: (member: Member) => void;
  refreshLoginMember: () => Promise<void>;
  resetLoginMember: () => void;
}
const useMemberStore = create<MemberInterface & MemberAction>()(
  devtools(
    immer((set) => ({
      loginMember: null,
      setLoginMember: (member: Member) => {
        // 회원 탈퇴 신청중인 카매니저일 경우
        if (member.dealerInfo?.signout?.state === 'submitted') {
          if (window.location.pathname !== '/signoutDealer') {
            window.location.replace('/signoutDealer');
            return;
          }
        }

        set((state) => {
          state.loginMember = member;
        });
      },
      refreshLoginMember: async () => {
        const freshMember = await getMember();
        set((state) => {
          state.loginMember = freshMember;
        });
      },
      resetLoginMember: () => {
        set((state) => {
          state.loginMember = null;
        });
      },
    })),
    {
      name: 'memberStore',
    },
  ),
);

export default useMemberStore;
