import { Button } from 'components/ui/button';

export default function EventDetail() {
  return (
    <div className='px-4 py-8'>
      <div className='text-lg font-semibold'>
        만원의 기적! 오직 카사요에서만
      </div>
      <div className='mt-3 text-title'>
        타사 견적보다 더 저렴하지 않다면 환불해 드립니다.
      </div>
      <div className='mt-2 text-[#767676]'>
        지난달 실적 산정 시 가족카드 이용실적도 동일한 기준으로 본인카드
        이용실적에 합산 실적 조건, 한도 제한 없이 모든 가맹점 1% 결제일 할인
        주유 건당 5,000원 할인
      </div>
      <div className='mt-6'>
        <img src='/images/event1.png' alt='' />
      </div>
      <div className='mt-6 h-[1px] bg-[#F1F1F5]'></div>
      <div className='mt-6 text-lg font-semibold'>국민은행 카드</div>
      <div className='mt-3 text-title'>부가혜택 및 통합할인한도</div>
      <div className='mt-2 text-[#767676]'>
        할인서비스는 전월 이용실적 30만원 이상 시 제공됩니다. 사용등록일로부터
        다음 달 말일까지 톡톡카드 이용실적이 없는 경우에도 해당서비스가
        제공됩니다.
      </div>
      <div className='mt-6 text-title'>부가혜택 및 통합할인한도</div>
      <div className='mt-2 text-[#767676]'>
        할인서비스는 전월 이용실적 30만원 이상 시 제공됩니다. 사용등록일로부터
        다음 달 말일까지 톡톡카드 이용실적이 없는 경우에도 해당서비스가
        제공됩니다.
      </div>

      <ul className='mt-2 list-outside list-disc bg-[#F7F7FB] p-4 pl-8 text-[#767676]'>
        <li>해당 상품은 제품 안전 인증 대상이 아닙니다.</li>
        <li>
          그 밖의 자세한 사항은 업체가 등록한 상품의 상세 설명을 참 고 하시기
          바랍니다.
        </li>
        <li>
          제품 안전 인증 정보에 대해서는 업체가 등록한 것으로 정보 에 대한
          일체의 책임은 업체에게 있습니다.
        </li>
      </ul>
      <Button variant='outline' className='mt-8 rounded-none'>
        목록으로
      </Button>
    </div>
  );
}
