import React from 'react';

interface MyComponentProps {
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
}

const TrashCanIcon: React.FC<MyComponentProps> = ({
  fill,
  stroke,
  width,
  height,
}) => {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox='0 0 20 20'
      fill={fill || 'none'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='6.6665'
        y='3.33331'
        width='6.66667'
        height='2.5'
        stroke={stroke || '#7273F9'}
        strokeWidth='1.25'
        strokeLinejoin='round'
      />
      <rect
        x='5'
        y='5.83331'
        width='10'
        height='10.8333'
        stroke={stroke || '#7273F9'}
        strokeWidth='1.25'
        strokeLinejoin='round'
      />
      <path
        d='M3.3335 5.83331H16.6668'
        stroke={stroke || '#7273F9'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.3335 10V12.5'
        stroke={stroke || '#7273F9'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.6665 10V12.5'
        stroke={stroke || '#7273F9'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export default TrashCanIcon;
