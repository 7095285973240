/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useRef } from 'react';
import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { encodeFileToBase64 } from 'utils/convertImage';

type Props = {
  imageFile?: File;
  isOpen: boolean;
  title: string;
  buttonText: string;
  setIsOpen: (isOpen: boolean) => void;
  onCrop: (file: File) => void;
};
export const CarsayoImageCropper = ({
  isOpen,
  title,
  buttonText,
  imageFile,
  setIsOpen,
  onCrop,
}: Props) => {
  const [imageBase64ImageSrc, setImageBase64ImageSrc] = useState<string>('');

  const cropperRef = useRef<ReactCropperElement>(null);

  const confirm = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper && imageFile && cropper.getImageData().height) {
      cropper
        .getCroppedCanvas({ maxWidth: 300, maxHeight: 300 })
        .toBlob((blob: Blob | null) => {
          if (blob)
            onCrop(new File([blob], imageFile.name, { type: imageFile.type }));
          setIsOpen(false);
        });
    } else {
      console.error('Failed to upload cropped image');
      setIsOpen(false);
    }
  };

  const setImageToBase64 = async () => {
    if (imageFile) setImageBase64ImageSrc(await encodeFileToBase64(imageFile));
  };

  useEffect(() => {
    setImageToBase64();
  }, [imageFile]);

  useEffect(() => {
    if (isOpen === false) {
      setImageBase64ImageSrc('');
    }
  }, [isOpen]);

  return (
    <>
      <Drawer open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
        <DrawerContent className='px-4'>
          <div className='flex flex-col mx-auto w-full max-w-sm'>
            <DrawerHeader className='relative flex-none'>
              <DrawerTitle>{title}</DrawerTitle>
              <DrawerClose asChild>
                <Button className='absolute right-5 top-[14px] h-7 w-7 border-0 bg-transparent p-0'>
                  <img src='/assets/images/v2/close.svg' alt='' />
                </Button>
              </DrawerClose>
            </DrawerHeader>

            <Cropper
              src={imageBase64ImageSrc}
              className='flex-auto'
              style={{
                maxHeight: 'calc(100dvh - 220px)',
                height: 'auto',
                width: 'auto',
              }}
              // Cropper.js options
              viewMode={1}
              aspectRatio={1 / 1}
              guides={false}
              ref={cropperRef}
            />

            <DrawerFooter className='pb-8 pt-6 px-0 flex-none'>
              <Button onClick={confirm}>{buttonText}</Button>
            </DrawerFooter>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
};
