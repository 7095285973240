import {
  SelectOrderDTO,
  Order_BidInfo,
  ReviewOrderDTO,
  OrderReview,
} from '@carsayo/types';
import { api } from 'apis';

/** 카매니저 - 입찰 조회
 */
export const getDealerBid = async (
  reqData: SelectOrderDTO,
): Promise<Order_BidInfo | null> => {
  try {
    const response = await api.post('order/dealer/getBid', {
      ...reqData,
    });
    const data: Order_BidInfo | null = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

/** 일반회원 - 리뷰 작성
 */
export const reviewOrder = async (
  reqData: ReviewOrderDTO,
): Promise<OrderReview> => {
  try {
    const response = await api.post('order/process/customer/review', {
      ...reqData,
    });
    const data: OrderReview = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};
