import usePurchaseStore from '../store';
import { useEffect, useLayoutEffect, useState } from 'react';
import CustomizedSelects, {
  CustomizedMenuItems,
} from '../../../../components/ui/select_v2';
import getPercentagePrice from '../../../../utils/getPercentagePrice';
import { cn } from '../../../../utils';
import { ChevronDown } from 'lucide-react';
import { SelectSigungu } from '../../../../components/view/buy/selectSigungu';
import { Controller, useForm } from 'react-hook-form';
import { getRegion } from '../../../../utils/getSidogungu';
import { CarsayoToast } from '../../../../components/common/v2/CarsayoToast';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/ui/button';
import { PriceDetail } from './priceDetail';
import { HalbooFormValues } from './HalbooForm';
import { BuyerType, SidoData, SigunguData } from '@carsayo/types';

export type LeaseFormValues = {
  contractTerm: string; // 계약기간
  price_deposit: string; // 보증금
  price_advancedPayment: string; // 선납금
  price_residual: string; // 잔존가치
  agreeDistace: string; // 약정거리
  buyerType: BuyerType | ''; // 구입주체
  isIncludingCarTax: string; // 자동차세
  deliveryRegion: string; // 인수지역
};

export const LeaseForm = ({ purchasePrice }: { purchasePrice: number }) => {
  const { purchaseOrderDTO, setPurchaseOrderDTO } = usePurchaseStore();
  const [isOpen, setIsOpen] = useState(false);

  const { control, handleSubmit, setValue, formState } =
    useForm<LeaseFormValues>({
      mode: 'onSubmit',
      criteriaMode: 'firstError',
      shouldFocusError: true,
      defaultValues: {
        contractTerm:
          purchaseOrderDTO &&
          purchaseOrderDTO.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.리스.contractTerm
            ? String(
                purchaseOrderDTO.orderData.additionalInfo.리스.contractTerm,
              )
            : '',
        deliveryRegion: getRegion(
          purchaseOrderDTO?.orderData?.additionalInfo.리스
            .deliveryRegion as number,
        ),
        price_deposit:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.리스.price_deposit !==
            undefined
            ? String(
                purchaseOrderDTO.orderData.additionalInfo.리스.price_deposit,
              )
            : '',
        price_advancedPayment:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.리스
            .price_advancedPayment !== undefined
            ? String(
                purchaseOrderDTO.orderData.additionalInfo.리스
                  .price_advancedPayment,
              )
            : '',
        price_residual:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.리스.price_residual !==
            undefined
            ? String(
                purchaseOrderDTO.orderData.additionalInfo.리스.price_residual,
              )
            : '',
        agreeDistace:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.리스.agreeDistace !==
            undefined
            ? String(
                purchaseOrderDTO.orderData.additionalInfo.리스.agreeDistace,
              )
            : '',
        buyerType:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.리스.buyerType !== undefined
            ? (String(
                purchaseOrderDTO.orderData.additionalInfo.리스.buyerType,
              ) as BuyerType)
            : '',
        isIncludingCarTax:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.리스.isIncludingCarTax !==
            undefined
            ? String(
                purchaseOrderDTO.orderData.additionalInfo.리스
                  .isIncludingCarTax,
              )
            : '',
      },
    });

  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const navigate = useNavigate();
  const goBack = () => {
    navigate('/purchase/option', { replace: true });
  };
  const goNext = () => {
    navigate('/purchase/estimate', { replace: true });
  };

  const confirm = (data: LeaseFormValues) => {
    if (purchaseOrderDTO && purchaseOrderDTO.orderData) {
      const currentAdditionalInfo = purchaseOrderDTO.orderData.additionalInfo;
      if (currentAdditionalInfo.method === '리스') {
        if (
          currentAdditionalInfo.리스.agreeDistace === undefined ||
          currentAdditionalInfo.리스.contractTerm === undefined ||
          currentAdditionalInfo.리스.price_advancedPayment === undefined ||
          currentAdditionalInfo.리스.price_deposit === undefined ||
          currentAdditionalInfo.리스.price_residual === undefined ||
          currentAdditionalInfo.리스.deliveryRegion === undefined ||
          currentAdditionalInfo.리스.buyerType === undefined ||
          currentAdditionalInfo.리스.isIncludingCarTax === undefined
        ) {
          CarsayoToast.error('아직 모든 내용이 입력되지 않았습니다.');
          return;
        }
      }
    }

    goNext();
    return;
  };

  const setDefaultValue = function async() {
    if (!purchaseOrderDTO || !purchaseOrderDTO.orderData) return;

    let isUpdate = false;
    const updateData = {
      ...purchaseOrderDTO.orderData.additionalInfo.리스,
    };

    if (!purchaseOrderDTO?.orderData?.additionalInfo.리스.contractTerm) {
      isUpdate = true;
      updateData.contractTerm = 24;
    }

    if (isUpdate) {
      setPurchaseOrderDTO({
        id: purchaseOrderDTO.id,
        type: purchaseOrderDTO.type,
        update: {
          additionalInfo: {
            ...purchaseOrderDTO.orderData.additionalInfo,
            리스: {
              ...purchaseOrderDTO.orderData.additionalInfo.리스,
              ...updateData,
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    const errorObj = Object.keys(
      formState.errors,
    ) as (keyof HalbooFormValues)[];
    const firstErrorKey = errorObj[0];
    const errorElement = document.querySelector(`[name=${firstErrorKey}]`);
    if (errorElement) {
      if (errorElement.getAttribute('type') !== 'hidden') {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        if (errorElement.nextElementSibling) {
          errorElement.nextElementSibling.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }
    }
  }, [formState.errors]);

  useLayoutEffect(() => {
    setDefaultValue();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(confirm)}>
        <div>
          <div className='mt-4 space-y-4 px-4 pb-6'>
            {purchaseOrderDTO?.orderData && (
              <>
                <div>
                  <div>계약 기간</div>
                  <div className='mt-2'>
                    <Controller
                      name='contractTerm'
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => {
                        return (
                          <CustomizedSelects
                            value={field.value}
                            name={'contractTerm'}
                            classes={
                              formState.errors.contractTerm
                                ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                                : ''
                            }
                            onValueChange={async (value) => {
                              setValue('contractTerm', value, {
                                shouldValidate: true,
                                shouldDirty: true,
                                shouldTouch: true,
                              });
                              if (!purchaseOrderDTO.orderData) return;
                              setPurchaseOrderDTO({
                                id: purchaseOrderDTO.id,
                                type: purchaseOrderDTO.type,
                                update: {
                                  additionalInfo: {
                                    ...purchaseOrderDTO.orderData
                                      .additionalInfo,
                                    리스: {
                                      ...purchaseOrderDTO.orderData
                                        .additionalInfo.리스,
                                      // 계약기간 바뀌면 잔존가치 최대로 강제변경
                                      price_residual: 100,
                                      contractTerm: Number(value),
                                    },
                                  },
                                },
                              });
                            }}
                            placeholder='선택해주세요'
                          >
                            <CustomizedMenuItems value='24'>
                              24개월
                            </CustomizedMenuItems>
                            <CustomizedMenuItems value='36'>
                              36개월
                            </CustomizedMenuItems>
                            <CustomizedMenuItems value='48'>
                              48개월
                            </CustomizedMenuItems>
                            <CustomizedMenuItems value='60'>
                              60개월
                            </CustomizedMenuItems>
                          </CustomizedSelects>
                        );
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div>보증금</div>
                  <div className='mt-2'>
                    <Controller
                      name='price_deposit'
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          name={'price_deposit'}
                          classes={
                            formState.errors.price_deposit
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : ''
                          }
                          onValueChange={async (value) => {
                            setValue('price_deposit', value, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  리스: {
                                    ...purchaseOrderDTO.orderData.additionalInfo
                                      .리스,
                                    price_deposit: Number(value),
                                  },
                                },
                              },
                            });
                          }}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='0'>
                            0%
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='10'>
                            10%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  10,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='15'>
                            15%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  15,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='20'>
                            20%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  20,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='30'>
                            30%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  30,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='40'>
                            40%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  40,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='50'>
                            50%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  50,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='60'>
                            60%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  60,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>선납금</div>
                  <div className='mt-2'>
                    <Controller
                      control={control}
                      name='price_advancedPayment'
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          name={'price_advancedPayment'}
                          classes={
                            formState.errors.price_advancedPayment
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : ''
                          }
                          onValueChange={async (value) => {
                            setValue('price_advancedPayment', value, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  리스: {
                                    ...purchaseOrderDTO.orderData.additionalInfo
                                      .리스,
                                    price_advancedPayment: Number(value),
                                  },
                                },
                              },
                            });
                          }}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='0'>
                            0%
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='10'>
                            10%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  10,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='20'>
                            20%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  20,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='30'>
                            30%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  30,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='40'>
                            40%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  40,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='50'>
                            50%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  50,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='60'>
                            60%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  60,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>잔존가치</div>
                  <div className='mt-2'>
                    <Controller
                      control={control}
                      name='price_residual'
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          name={'price_residual'}
                          classes={
                            formState.errors.price_residual
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : ''
                          }
                          onValueChange={async (value) => {
                            setValue('price_residual', value, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  리스: {
                                    ...purchaseOrderDTO.orderData.additionalInfo
                                      .리스,
                                    price_residual: Number(value),
                                  },
                                },
                              },
                            });
                          }}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems
                            disabled={
                              purchaseOrderDTO.orderData &&
                              purchaseOrderDTO.orderData.additionalInfo.리스
                                .contractTerm !== 24
                            }
                            value='10'
                          >
                            10%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  10,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems
                            disabled={
                              !(
                                (purchaseOrderDTO.orderData &&
                                  purchaseOrderDTO.orderData.additionalInfo.리스
                                    .contractTerm === 24) ||
                                (purchaseOrderDTO.orderData &&
                                  purchaseOrderDTO.orderData.additionalInfo.리스
                                    .contractTerm === 60)
                              )
                            }
                            value='15'
                          >
                            15%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  15,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems
                            disabled={
                              !(
                                (purchaseOrderDTO.orderData &&
                                  purchaseOrderDTO.orderData.additionalInfo.리스
                                    .contractTerm === 24) ||
                                (purchaseOrderDTO.orderData &&
                                  purchaseOrderDTO.orderData.additionalInfo.리스
                                    .contractTerm === 60) ||
                                (purchaseOrderDTO.orderData &&
                                  purchaseOrderDTO.orderData.additionalInfo.리스
                                    .contractTerm === 48)
                              )
                            }
                            value='20'
                          >
                            20%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  30,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='30'>
                            30%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  30,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='40'>
                            40%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  40,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='50'>
                            50%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  50,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='100'>
                            최대
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>약정거리</div>
                  <div className='mt-2'>
                    <Controller
                      name='agreeDistace'
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          name={'agreeDistace'}
                          classes={
                            formState.errors.agreeDistace
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : ''
                          }
                          onValueChange={async (value) => {
                            setValue('agreeDistace', value, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  리스: {
                                    ...purchaseOrderDTO.orderData.additionalInfo
                                      .리스,
                                    agreeDistace: Number(value),
                                  },
                                },
                              },
                            });
                          }}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='10000'>
                            10,000km
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='20000'>
                            20,000km
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='30000'>
                            30,000km
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='40000'>
                            40,000km
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>구입 주체</div>
                  <div className='mt-2'>
                    <Controller
                      control={control}
                      name='buyerType'
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          name={'buyerType'}
                          classes={
                            formState.errors.buyerType
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : ''
                          }
                          onValueChange={async (value) => {
                            setValue('buyerType', value as BuyerType, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  리스: {
                                    ...purchaseOrderDTO.orderData.additionalInfo
                                      .리스,
                                    buyerType: value as BuyerType,
                                  },
                                },
                              },
                            });
                          }}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='individual'>
                            개인
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='sole_proprietor'>
                            개인사업자
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='corporation'>
                            법인사업자
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>자동차세</div>
                  <div className='mt-2'>
                    <Controller
                      control={control}
                      name='isIncludingCarTax'
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          name={'isIncludingCarTax'}
                          classes={
                            formState.errors.isIncludingCarTax
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : ''
                          }
                          onValueChange={async (value) => {
                            setValue('isIncludingCarTax', value, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  리스: {
                                    ...purchaseOrderDTO.orderData.additionalInfo
                                      .리스,
                                    isIncludingCarTax:
                                      value === 'false' ? false : true,
                                  },
                                },
                              },
                            });
                          }}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='false'>
                            미포함
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='true'>
                            포함
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>인수(탁송) 지역</div>
                  <div className='mt-2'>
                    <Controller
                      control={control}
                      name='deliveryRegion'
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => (
                        <div
                          className={cn(
                            'flex h-[52px] items-center border border-input px-3 py-2 text-sm text-[#555555]',
                            formState.errors.deliveryRegion
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : '',
                          )}
                          onClick={() => {
                            setIsOpen(true);
                          }}
                        >
                          {/* useForm 시용 위한 비노출 input */}
                          <input
                            type='hidden'
                            value={field.value}
                            name='deliveryRegion'
                          />

                          {purchaseOrderDTO?.orderData?.additionalInfo.리스
                            .deliveryRegion ? (
                            <div>
                              {purchaseOrderDTO?.orderData?.additionalInfo.리스
                                .deliveryRegion !== undefined &&
                                SidoData.find((el1) => {
                                  return (
                                    purchaseOrderDTO.orderData &&
                                    el1.id ===
                                      (purchaseOrderDTO?.orderData
                                        ?.additionalInfo.리스.deliveryRegion !==
                                        undefined &&
                                        SigunguData.find((el) => {
                                          return (
                                            purchaseOrderDTO.orderData &&
                                            el.id ===
                                              purchaseOrderDTO.orderData
                                                .additionalInfo.리스
                                                .deliveryRegion
                                          );
                                        })?.sidoId)
                                  );
                                })?.name}{' '}
                              {purchaseOrderDTO?.orderData?.additionalInfo.리스
                                .deliveryRegion !== undefined &&
                                SigunguData.find((el) => {
                                  return (
                                    purchaseOrderDTO.orderData &&
                                    el.id ===
                                      purchaseOrderDTO.orderData.additionalInfo
                                        .리스.deliveryRegion
                                  );
                                })?.name}
                            </div>
                          ) : (
                            <div>선택해 주세요</div>
                          )}
                          <ChevronDown className='ml-auto h-5 w-5 text-[#111111]' />
                        </div>
                      )}
                    />
                  </div>
                </div>
                <SelectSigungu
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  setRegion={(value) => {
                    // useForm setValue
                    setValue('deliveryRegion', getRegion(value), {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });

                    if (!purchaseOrderDTO?.orderData) return;
                    setPurchaseOrderDTO({
                      id: purchaseOrderDTO.id,
                      type: purchaseOrderDTO.type,
                      update: {
                        additionalInfo: {
                          ...purchaseOrderDTO.orderData.additionalInfo,
                          리스: {
                            ...purchaseOrderDTO.orderData.additionalInfo.리스,
                            deliveryRegion: value,
                          },
                        },
                      },
                    });
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div className='sticky bottom-0 w-full h-full flex-none bg-[#F7F7FB] px-4 py-6'>
          {purchasePrice > 0 && (
            <div className='flex items-center justify-between'>
              <div className='text-[#555555]'>총 예상 금액</div>
              <div
                onClick={() => setIsDetailOpen(true)}
                className='flex items-center gap-2 font-semibold'
              >
                {(
                  Number(purchaseOrderDTO?.orderData?.carGrade?.price) +
                  Number(
                    purchaseOrderDTO?.orderData?.carColor?.price
                      ? purchaseOrderDTO?.orderData?.carColor?.price
                      : 0,
                  ) +
                  Number(
                    purchaseOrderDTO?.orderData?.carOptionList &&
                      purchaseOrderDTO?.orderData?.carOptionList.length > 0
                      ? purchaseOrderDTO.orderData.carOptionList
                          .map((el) => {
                            return el.price;
                          })
                          .reduce((prev, curr) => {
                            return prev + curr;
                          })
                      : 0,
                  ) +
                  0
                ).toLocaleString()}
                원 <img src='/assets/images/v2/coin.svg' alt='' />
              </div>
            </div>
          )}
          <div className='mt-4 grid grid-cols-2'>
            <Button
              variant='outline'
              className='rounded-r-none bg-white'
              onClick={goBack}
            >
              이전
            </Button>

            <Button className='rounded-l-none' type='submit'>
              다음
            </Button>
            {/*<input*/}
            {/*  type='submit'*/}
            {/*  className='rounded-l-none'*/}
            {/*  // onClick={confirm}*/}
            {/*/>다음*/}
          </div>
        </div>
        {purchaseOrderDTO && purchaseOrderDTO.orderData && (
          <PriceDetail
            isOpen={isDetailOpen}
            setIsOpen={setIsDetailOpen}
            purchaseCarInfo={purchaseOrderDTO.orderData}
          />
        )}
      </form>
    </div>
  );
};
