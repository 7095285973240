import { CarMakerData } from '@carsayo/types';

export const DomesticBrandList = CarMakerData.filter((el) => {
  return el.type === 'domestic';
});
/** 슈퍼카 브랜드 제외 */
export const ImportedBrandList = CarMakerData.filter((el) => {
  return el.type === 'imported' && el.isSuperCar === false;
});
export const SuperCarBrandList = CarMakerData.filter((el) => {
  return el.type === 'imported' && el.isSuperCar === true;
});
