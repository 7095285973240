import { DealerOrderListData } from '@carsayo/types';
import dayjs from 'dayjs';
import { HeartIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { cn } from 'utils';
import {
  convertBuyType,
  orderCarOwnerConvert,
  orderCarStateConvert,
  remainingTime,
} from 'utils/convert';
import numberToKorean from 'utils/numberToKorean';
import {
  parseDealerOrderListDataState,
  parseOrderState,
  parseSigunguId,
} from 'utils/typeConvert';

type OrderCardProps = {
  el: DealerOrderListData;
  className?: string;
  option?: {
    bidEndTime?: boolean;
    /** navigate replace option */
    replace?: boolean;
  };

  toggleBookmark?: () => void;
};

function OrderListCardNewCar({ params }: { params: OrderCardProps }) {
  const navigate = useNavigate();

  return (
    <div
      className={cn(
        'flex h-[236px] shrink-0 flex-col rounded-lg text-white active:opacity-95 bg-[linear-gradient(107.38deg,#2D60FF_2.61%,#539BFF_101.2%)]',
        params.className,
      )}
      onClick={() => {
        navigate(`/quote/manager/detail/${params.el.id}`, {
          replace: params.option?.replace,
        });
      }}
    >
      <div className='grow p-4'>
        <div className='flex items-center'>
          <div>
            {params.el.state === 'submitted' && params.option?.bidEndTime
              ? dayjs(params.el.bid_ended_at).fromNow()
              : parseDealerOrderListDataState(params.el).text}
          </div>
          <div className='ml-auto flex justify-center items-center gap-2'>
            <div>
              {params.el.newCar?.buyerType &&
                `${convertBuyType[params.el.newCar.buyerType]}`}
            </div>
            <div
              className={cn(
                params.el.state === 'submitted' && params.el.isBidded === false
                  ? 'w-[23px] h-[23px] flex justify-center items-center'
                  : 'hidden',
              )}
              // eslint-disable-next-line no-undef
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.preventDefault();
                e.stopPropagation();
                params.toggleBookmark && params.toggleBookmark();
              }}
            >
              <HeartIcon
                width={14}
                height={14}
                fill={params.el.isBookmarked ? '#DC0000' : '#fff'}
                stroke={params.el.isBookmarked ? '#DC0000' : '#111'}
              />
            </div>
          </div>
        </div>
        <div className='mt-3 text-title font-semibold'>{params.el.brand}</div>
        <div className='mt-0.5'>{params.el.title}</div>
        <div className='mt-7 flex items-center gap-[20px]'>
          <div>
            <div className='text-desc text-white/70'>신청일</div>
            <div className='text-[14px] leading-6'>
              {dayjs(params.el.submitted_at).format('YYYY-MM-DD')}
            </div>
          </div>
          <div>
            <div className='text-desc text-white/70'>구입 시기</div>
            <div className='text-[14px] leading-6'>
              {params.el.newCar?.purchaseDate === null
                ? '1개월 이내'
                : dayjs(params.el.newCar?.purchaseDate).format('YYYY-MM-DD')}
            </div>
          </div>
          <div>
            <div className='text-desc text-white/70'>구입 방식</div>
            <div className='text-[14px] leading-6'>
              {!params.el.newCar?.installmentTerm
                ? '일시불'
                : `할부 ${params.el.newCar?.installmentTerm}개월`}
            </div>
          </div>
        </div>
      </div>
      <div className='flex h-[50px] items-center bg-gradient-to-b from-white/15 to-white/0 px-4'>
        {params.el.newCar?.deliveryRegion && (
          <div className='flex items-center gap-2'>
            <div className='text-desc text-white/70'>인수 지역</div>
            <div className='text-desc truncate'>
              {parseSigunguId(params.el.newCar?.deliveryRegion)?.sido.name}{' '}
              {parseSigunguId(params.el.newCar?.deliveryRegion)?.name}
            </div>
          </div>
        )}
        <div className='ml-auto flex items-center'>
          <img src='/assets/images/v2/ico-casayo.svg' alt='' />
          <div className='ml-0.5 font-semibold'>{params.el.bidNumber}</div>
        </div>
      </div>
    </div>
  );
}

function OrderListCardLeaseRent({ params }: { params: OrderCardProps }) {
  const navigate = useNavigate();

  return (
    <div
      className={cn(
        'flex h-[236px] shrink-0 flex-col rounded-lg text-white active:opacity-95 bg-[linear-gradient(107.38deg,#2D60FF_2.61%,#539BFF_101.2%)]',
        params.className,
      )}
      onClick={() => {
        navigate(`/quote/manager/detail/${params.el.id}`, {
          replace: params.option?.replace,
        });
      }}
    >
      <div className='grow p-4'>
        <div className='flex items-center'>
          <div>
            {params.el.state === 'submitted' && params.option?.bidEndTime
              ? dayjs(params.el.bid_ended_at).fromNow()
              : parseDealerOrderListDataState(params.el).text}
          </div>
          <div className='ml-auto flex justify-center items-center gap-2'>
            <div>
              {params.el.leaseRent?.buyerType &&
                `${convertBuyType[params.el.leaseRent.buyerType]}`}
            </div>
            <div
              className={cn(
                params.el.state === 'submitted' && params.el.isBidded === false
                  ? 'w-[23px] h-[23px] flex justify-center items-center'
                  : 'hidden',
              )}
              // eslint-disable-next-line no-undef
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.preventDefault();
                e.stopPropagation();
                params.toggleBookmark && params.toggleBookmark();
              }}
            >
              <HeartIcon
                width={14}
                height={14}
                fill={params.el.isBookmarked ? '#DC0000' : '#fff'}
                stroke={params.el.isBookmarked ? '#DC0000' : '#111'}
              />
            </div>
          </div>
        </div>
        <div className='mt-3 text-title font-semibold'>{params.el.brand}</div>
        <div className='mt-0.5'>{params.el.title}</div>
        <div className='mt-7 flex items-center gap-[20px]'>
          <div>
            <div className='text-desc text-white/70'>신청일</div>
            <div className='text-[14px] leading-6'>
              {dayjs(params.el.submitted_at).format('YYYY-MM-DD')}
            </div>
          </div>
          <div>
            <div className='text-desc text-white/70'>구입 시기</div>
            <div className='text-[14px] leading-6'>
              {params.el.leaseRent?.purchaseDate === null
                ? '1개월 이내'
                : dayjs(params.el.leaseRent?.purchaseDate).format('YYYY-MM-DD')}
            </div>
          </div>
          <div>
            <div className='text-desc text-white/70'>구입 방식</div>
            <div className='text-[14px] leading-6'>
              {params.el.type === 'lease' ? '리스' : '장기렌트'}
            </div>
          </div>
        </div>
      </div>
      <div className='flex h-[50px] items-center bg-gradient-to-b from-white/15 to-white/0 px-4'>
        {params.el.leaseRent?.deliveryRegion && (
          <div className='flex items-center gap-2'>
            <div className='text-desc text-white/70'>인수 지역</div>
            <div className='text-desc truncate'>
              {parseSigunguId(params.el.leaseRent?.deliveryRegion)?.sido.name}{' '}
              {parseSigunguId(params.el.leaseRent?.deliveryRegion)?.name}
            </div>
          </div>
        )}
        <div className='ml-auto flex items-center'>
          <img src='/assets/images/v2/ico-casayo.svg' alt='' />
          <div className='ml-0.5 font-semibold'>{params.el.bidNumber}</div>
        </div>
      </div>
    </div>
  );
}

function OrderListCardSellCar({ params }: { params: OrderCardProps }) {
  const navigate = useNavigate();

  return (
    <div
      className={cn(
        'flex shrink-0 flex-col rounded-t-lg border-b border-dotted pb-2 active:bg-gray-50',
        params.className,
      )}
      onClick={() => {
        navigate(`/quote/manager/detail/${params.el.id}`, {
          replace: params.option?.replace,
        });
      }}
    >
      <div className={cn('bg-[#F7F8FA], w-full relative')}>
        <div
          className={cn(
            `absolute left-[8px] top-[8px] py-[4px] px-[8px] text-[11px] leading-[16px] bg-white border-[1px] border-[#2F66BA]`,
            `${parseOrderState(params.el.state).themaColor.text.color} ${parseOrderState(params.el.state).themaColor.box.border}`,
          )}
        >
          {params.el.state === 'submitted' && params.option?.bidEndTime
            ? remainingTime(params.el.submitted_at ?? dayjs().toDate())
            : parseOrderState(params.el.state).text}
        </div>
        <div
          className={cn(
            params.el.state === 'submitted' && params.el.isBidded === false
              ? 'absolute top-[8px] right-[8px] w-[23px] h-[23px] flex justify-center items-center bg-white'
              : 'hidden',
          )}
          // eslint-disable-next-line no-undef
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();
            e.stopPropagation();
            params.toggleBookmark && params.toggleBookmark();
          }}
        >
          <HeartIcon
            width={14}
            height={14}
            fill={params.el.isBookmarked ? '#DC0000' : 'transparent'}
            stroke={params.el.isBookmarked ? '#DC0000' : '#111'}
          />
        </div>
        <img
          src={
            typeof params.el.imageUrl === 'string'
              ? params.el.imageUrl
              : params.el.imageUrl?.[0]
                ? params.el.imageUrl?.[0]
                : ''
          }
          alt='차량 이미지'
          className={cn('object-cover w-full h-[188px] rounded-t-xl')}
        />
      </div>

      <div className='flex flex-col'>
        <div className='pt-2 px-2'>
          <div className='text-title font-semibold flex justify-between items-center'>
            <h4>{params.el.brand}</h4>
            <ul className='flex justify-center items-center gap-1'>
              {params.el.sellCar?.tag.carState &&
                params.el.sellCar.tag.carState !== 'none' && (
                  <li className='px-1 py-[2px] text-[11px] text-[#8a8afb]'>
                    {`#${params.el.sellCar.tag.carState && orderCarStateConvert[params.el.sellCar.tag.carState]}`}
                  </li>
                )}
              {params.el.sellCar?.tag.ownerShip &&
                params.el.sellCar.tag.ownerShip !== 'none' && (
                  <li className='px-1 py-[2px] text-[11px] text-[#8a8afb]'>
                    {`#${params.el.sellCar.tag.ownerShip && orderCarOwnerConvert[params.el.sellCar.tag.ownerShip]}`}
                  </li>
                )}
            </ul>
          </div>
          <div className='text-gray-600 text-[13px] py-0.5'>
            {params.el.title}
          </div>
        </div>
        {params.el.sellCar && (
          <div className='px-2 flex gap-1 font-medium text-desc text-gray-900 '>
            <div className='after:ml-1.5 after:content-["•"]'>
              {params.el.sellCar.year}년식
            </div>
            <div className='after:ml-1.5 after:content-["•"]'>
              {params.el.sellCar.drivenDistance.toLocaleString()}km
            </div>
            <div>{params.el.sellCar.carName}</div>
          </div>
        )}
        <div className='flex flex-col pt-2 px-2'>
          <div className='flex'>
            <div className='grid grid-cols-2 gap-y-0.5 gap-x-2'>
              {params.el.sellCar && params.el.isAccepted && (
                <div className='flex items-center gap-1'>
                  <div className='w-12 text-desc text-gray-600 text-[14px]'>
                    닉네임
                  </div>
                  <div className='text-desc truncate'>
                    {params.el.member.name_nick}
                  </div>
                </div>
              )}
              {params.el.sellCar?.purchasePrice && (
                <div className='flex items-center gap-1'>
                  <div className='w-12 text-desc text-gray-600 text-[14px]'>
                    {params.el.isAccepted ? '낙찰가' : '입찰가'}
                  </div>
                  <div className='text-desc truncate'>
                    {numberToKorean(params.el.sellCar.purchasePrice)}
                  </div>
                </div>
              )}
              {params.el.sellCar && (
                <div className='flex items-center gap-1'>
                  <div className='w-12 text-desc text-gray-600 text-[14px]'>
                    판매지역
                  </div>
                  <div className='text-desc truncate'>
                    {parseSigunguId(params.el.sellCar?.sellRegion)?.sido.name}{' '}
                    {parseSigunguId(params.el.sellCar?.sellRegion)?.name}
                  </div>
                </div>
              )}
            </div>

            {params.el.state === 'submitted' && (
              <div className='ml-auto flex items-center'>
                <img src='/assets/images/v2/ico-casayo_black.svg' alt='' />
                <div className='ml-0.5 font-semibold'>
                  {params.el.bidNumber}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function OrderListCard({
  el,
  className,
  option,
  toggleBookmark,
}: OrderCardProps) {
  const params = {
    el,
    className,
    option,
    toggleBookmark,
  };

  return el.type === 'newCar' ? (
    <OrderListCardNewCar params={params} />
  ) : el.type === 'sellCar' ? (
    <OrderListCardSellCar params={params} />
  ) : (
    <OrderListCardLeaseRent params={params} />
  );
}
