import { cn } from 'utils';
import { InsuranceCompanyInfo } from '../data/company';
import useDeviceStore from 'store/useDeviceStore';

export default function InsuranceBanner({
  className,
  companyInfo,
}: {
  className?: string;
  companyInfo: InsuranceCompanyInfo;
}) {
  const { myDevice } = useDeviceStore();
  console.info('device', myDevice);
  return (
    <div
      className={cn(
        'flex justify-center py-8 px-4 bg-opacity-10 rounded-t-2xl',
        companyInfo.design.color.bg,
        className,
      )}
    >
      <div className='flex items-center justify-between w-full'>
        <div className='flex flex-col h-12 justify-between py-0.5'>
          <span
            className={`text-[14px] font-normal ${companyInfo.id === '한화손해보험' && (myDevice === 'browserM' || myDevice === 'ios') ? 'font-HanwhaM' : companyInfo.id === '한화손해보험' && 'font-HanwhaW'}`}
          >
            {companyInfo.data.banner.title}
          </span>
          <span
            className={`text-[18px] tracking-tight font-semibold break-keep ${companyInfo.id === '한화손해보험' && (myDevice === 'browserM' || myDevice === 'ios') ? 'font-HanwhaM' : companyInfo.id === '한화손해보험' && 'font-HanwhaW'}`}
          >
            {companyInfo.data.banner.subtitle}
          </span>
        </div>
        <img
          className='w-12 h-12'
          src={companyInfo.design.logo.svg}
          alt='보험사 로고'
        />
      </div>
    </div>
  );
}
