import React from 'react';

interface MyComponentProps {
  fill?: string;
  width?: string;
  height?: string;
}

const HomeMenuIcon: React.FC<MyComponentProps> = ({ fill, width, height }) => {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.3519 18.1688H2.35853C1.91186 18.1688 1.5452 17.8082 1.5452 17.3688V5.47703C1.5452 5.03769 1.91186 4.67703 2.35853 4.67703L9.78521 2.6377L17.3519 4.67375C17.7985 4.67375 18.1652 5.03441 18.1652 5.47375V17.3688C18.1652 17.8082 17.7985 18.1688 17.3519 18.1688Z'
        fill='#E4E3E8'
      />
      <path
        d='M18.4718 4.50319L10.1252 1.79827C10.0585 1.77532 9.99182 1.76549 9.92516 1.76221C9.90182 1.76221 9.87849 1.76221 9.85516 1.76221C9.83183 1.76221 9.80849 1.76221 9.78516 1.76221C9.71849 1.76549 9.65182 1.7786 9.58516 1.79827L1.2385 4.50319C0.801835 4.64417 0.565168 5.10975 0.708501 5.53926C0.825168 5.88352 1.1485 6.10319 1.4985 6.10319C1.58517 6.10319 1.67183 6.09008 1.7585 6.06057L9.85516 3.43762L17.9518 6.06057C18.0385 6.09008 18.1252 6.10319 18.2118 6.10319C18.5618 6.10319 18.8885 5.88352 19.0018 5.53926C19.1452 5.10975 18.9085 4.64745 18.4718 4.50319Z'
        fill='#7891CF'
      />
      <path
        d='M2.88852 7.54907V16.2835C2.88852 16.6442 3.18852 16.9392 3.55518 16.9392H16.1585C16.5252 16.9392 16.8252 16.6442 16.8252 16.2835V7.54907H2.88852Z'
        fill='#2B3E7F'
      />
      <path
        d='M15.2819 12.946C15.2819 12.946 15.3519 12.3264 14.7319 11.9821C14.0619 11.6149 13.5752 11.6018 13.4519 11.3985C13.3319 11.1952 13.0019 10.1887 13.0019 10.1887C13.0019 10.1887 12.7319 8.79195 11.3785 8.76244C10.2485 8.73949 8.7052 8.69359 7.58853 8.76244C6.1552 8.85096 6.02853 10.1756 6.02853 10.1756C6.02853 10.1756 5.8052 11.1919 5.6852 11.3952C5.5652 11.5985 5.51853 11.6969 5.0052 11.9788C4.4652 12.2772 4.4552 12.9428 4.4552 12.9428C4.4552 12.9428 4.35186 13.687 4.4552 14.651C4.56186 15.6378 5.8452 15.5821 5.8452 15.5821H13.9485C13.9485 15.5821 15.2419 15.6411 15.2752 14.651C15.3152 13.5624 15.2752 12.9428 15.2752 12.9428L15.2819 12.946Z'
        fill='#7273F9'
      />
      <path
        d='M9.60853 14.3263C9.5152 14.3263 9.44186 14.2542 9.44186 14.1624V12.3329C9.44186 12.2411 9.5152 12.1689 9.60853 12.1689C9.70186 12.1689 9.7752 12.2411 9.7752 12.3329V14.1624C9.7752 14.2542 9.70186 14.3263 9.60853 14.3263Z'
        fill='#6162EE'
      />
      <path
        d='M8.89516 9.64114C8.4385 9.64114 8.40183 9.64441 8.04516 9.66737C7.1085 9.72638 7.0285 10.5887 7.0285 10.5887C7.0285 10.5887 6.95183 11.1854 6.94516 11.228C6.92516 11.3362 6.9385 11.4477 7.1085 11.4477H8.88516C8.88516 11.4477 9.1085 11.4641 9.1085 11.1788C9.1085 10.9264 9.09516 9.89687 9.09516 9.89687C9.09516 9.89687 9.11849 9.6313 8.89516 9.64114Z'
        fill='white'
      />
      <path
        d='M10.2318 9.64114C10.6885 9.64114 10.7252 9.64441 11.0818 9.66737C12.0185 9.72638 12.0985 10.5887 12.0985 10.5887C12.0985 10.5887 12.1752 11.1854 12.1818 11.228C12.2018 11.3362 12.1885 11.4477 12.0185 11.4477C11.8485 11.4477 10.2418 11.4477 10.2418 11.4477C10.2418 11.4477 10.0185 11.4641 10.0185 11.1788C10.0185 10.8936 10.0318 9.89687 10.0318 9.89687C10.0318 9.89687 10.0085 9.6313 10.2318 9.64114Z'
        fill='white'
      />
      <path
        d='M7.52521 16.9393C8.34441 16.9393 9.00854 16.2861 9.00854 15.4803C9.00854 14.6745 8.34441 14.0212 7.52521 14.0212C6.70597 14.0212 6.04184 14.6745 6.04184 15.4803C6.04184 16.2861 6.70597 16.9393 7.52521 16.9393Z'
        fill='#333331'
      />
      <path
        d='M7.52519 15.9492C7.78842 15.9492 8.00186 15.7392 8.00186 15.4803C8.00186 15.2214 7.78842 15.0115 7.52519 15.0115C7.26192 15.0115 7.04852 15.2214 7.04852 15.4803C7.04852 15.7392 7.26192 15.9492 7.52519 15.9492Z'
        fill='#6E6E6D'
      />
      <path
        d='M12.0152 16.9393C12.8344 16.9393 13.4985 16.2861 13.4985 15.4803C13.4985 14.6745 12.8344 14.0212 12.0152 14.0212C11.196 14.0212 10.5319 14.6745 10.5319 15.4803C10.5319 16.2861 11.196 16.9393 12.0152 16.9393Z'
        fill='#333331'
      />
      <path
        d='M12.0152 15.9492C12.2784 15.9492 12.4918 15.7392 12.4918 15.4803C12.4918 15.2214 12.2784 15.0115 12.0152 15.0115C11.7519 15.0115 11.5385 15.2214 11.5385 15.4803C11.5385 15.7392 11.7519 15.9492 12.0152 15.9492Z'
        fill='#6E6E6D'
      />
      <path
        d='M14.6985 13.6344C14.5152 13.6344 14.3652 13.4868 14.3652 13.3065V13.0049C14.3652 12.8245 14.5152 12.677 14.6985 12.677C14.8819 12.677 15.0319 12.8245 15.0319 13.0049V13.3065C15.0319 13.4868 14.8819 13.6344 14.6985 13.6344Z'
        fill='#BBBCFC'
      />
      <path
        d='M16.8218 7.66082V7.32639C16.8218 6.96574 16.5218 6.67065 16.1552 6.67065H3.55518C3.18852 6.67065 2.88852 6.96574 2.88852 7.32639V7.66082H16.8252H16.8218Z'
        fill='#7891CF'
      />
      <path
        d='M9.85516 6.09024C10.3173 6.09024 10.6918 5.72178 10.6918 5.26729C10.6918 4.81276 10.3173 4.44434 9.85516 4.44434C9.39309 4.44434 9.01849 4.81276 9.01849 5.26729C9.01849 5.72178 9.39309 6.09024 9.85516 6.09024Z'
        fill='#A9B5CF'
      />
      <path
        d='M16.8352 7.90013H2.85853C2.7652 7.90013 2.69186 7.828 2.69186 7.7362C2.69186 7.6444 2.7652 7.57227 2.85853 7.57227H16.8352C16.9285 7.57227 17.0019 7.6444 17.0019 7.7362C17.0019 7.828 16.9285 7.90013 16.8352 7.90013Z'
        fill='#E9EDF3'
      />
      <path
        d='M15.3419 9.16555H15.3185C14.9519 9.16227 14.6552 8.86719 14.6519 8.50653V8.49014C14.6519 8.40489 14.5785 8.33276 14.4919 8.33276C14.4052 8.33276 14.3319 8.40489 14.3319 8.49014V8.50981C14.3319 8.87047 14.0319 9.16227 13.6685 9.16555H13.6452C13.5585 9.16555 13.4852 9.23768 13.4852 9.32293C13.4852 9.40817 13.5585 9.4803 13.6452 9.4803H13.6619C14.0319 9.4803 14.3319 9.77539 14.3352 10.136V10.1557C14.3352 10.241 14.4085 10.3131 14.4952 10.3131C14.5819 10.3131 14.6552 10.241 14.6552 10.1557V10.1393C14.6552 9.77539 14.9552 9.47703 15.3285 9.47703H15.3452C15.4319 9.47703 15.5052 9.40489 15.5052 9.31965C15.5052 9.2344 15.4319 9.16227 15.3452 9.16227L15.3419 9.16555Z'
        fill='#FFEC74'
      />
      <path
        d='M4.84183 10.913H4.82517C4.54183 10.913 4.31183 10.6835 4.31183 10.4015V10.3884C4.31183 10.3228 4.25517 10.2671 4.1885 10.2671C4.12183 10.2671 4.06517 10.3228 4.06517 10.3884V10.4048C4.06517 10.6835 3.83517 10.9097 3.55183 10.9097H3.53517C3.4685 10.9097 3.41183 10.9655 3.41183 11.031C3.41183 11.0966 3.4685 11.1523 3.53517 11.1523H3.5485C3.83517 11.1523 4.06517 11.3786 4.0685 11.6605V11.6769C4.0685 11.7425 4.12517 11.7982 4.19183 11.7982C4.2585 11.7982 4.31517 11.7425 4.31517 11.6769V11.6638C4.31517 11.3818 4.5485 11.1523 4.83517 11.1523H4.8485C4.91517 11.1523 4.97183 11.0966 4.97183 11.031C4.97183 10.9655 4.91517 10.9097 4.8485 10.9097L4.84183 10.913Z'
        fill='#FFEC74'
      />
      <path
        d='M16.3685 10.8148H16.3519C16.0685 10.8148 15.8385 10.5853 15.8385 10.3034V10.2903C15.8385 10.2247 15.7819 10.1689 15.7152 10.1689C15.6485 10.1689 15.5919 10.2247 15.5919 10.2903V10.3067C15.5919 10.5853 15.3619 10.8116 15.0785 10.8116H15.0619C14.9952 10.8116 14.9385 10.8673 14.9385 10.9329C14.9385 10.9985 14.9952 11.0542 15.0619 11.0542H15.0752C15.3619 11.0542 15.5919 11.2804 15.5952 11.5624V11.5788C15.5952 11.6444 15.6519 11.7001 15.7185 11.7001C15.7852 11.7001 15.8419 11.6444 15.8419 11.5788V11.5657C15.8419 11.2837 16.0752 11.0542 16.3619 11.0542H16.3752C16.4419 11.0542 16.4985 10.9985 16.4985 10.9329C16.4985 10.8673 16.4419 10.8116 16.3752 10.8116L16.3685 10.8148Z'
        fill='#FFEC74'
      />
    </svg>
  );
};
export default HomeMenuIcon;
