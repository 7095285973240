import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { toastify } from 'utils/Toastify';
import * as Sentry from '@sentry/react';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

function getAxiosErrorMsg(url?: string): string {
  if (!url) {
    return 'Unknown error occurred';
  }
  if (url.includes('/login')) {
    return 'Failed to log in. Please check your credentials and try again.';
  } else if (url.includes('/register')) {
    return 'Failed to register. Please check the information provided.';
  } else if (url.includes('/data')) {
    return 'Data retrieval failed. Please try again later.';
  }

  return 'An unexpected error occurred while processing your request.';
}

interface ErrorResponseData {
  error?: string;
  message?: string;
  path?: string;
  statusCode?: number;
  timestamp?: string;
}

export function logAxiosError(error: AxiosError) {
  console.info('에러 확인', error);
  const errorMessage =
    '[Axios] ' + getAxiosErrorMsg(error.response?.config.url);

  const errorResponseData: ErrorResponseData = error.response?.data || {};
  Sentry.withScope((scope: Sentry.Scope) => {
    scope.setTags({
      api: error.response?.config.url,
      'api.request.method': error.config?.method?.toUpperCase(),
      'api.request.data': error.config?.data || '',
      'api.response.status': (error.response?.status || '').toString(),
      'api.response.statusText': error.response?.statusText || '',
      'api.response.data': errorResponseData.error || '',
      'api.response.message': errorResponseData.message
        ? errorResponseData.message
        : '',
    });
    scope.setContext('axiosArror', {
      method: error.config?.method?.toUpperCase(),
      path: errorResponseData.path || '',
      status: (error.response?.status || '').toString(),
      data: errorResponseData.error || '',
      message: errorResponseData.message || '',
    });
    scope.setLevel('error');
    error.message = errorMessage;
    Sentry.captureException(error, { fingerprint: [errorMessage] });
  });
}

//TODO: {{domain}}/firebase-message/setToken
//TODO: FCM

const captureError = (error: any) => {
  console.error(error);
  const errorMessage: string = error?.response?.data?.error
    ? error.response.data.error
    : '에러가 발생했습니다\n잠시 후 다시 시도해 주세요';

  toastify.error(errorMessage);
  process.env.REACT_APP_NODE_ENV === 'development' && logAxiosError(error);
  console.error(errorMessage);

  // Swal.fire({
  //   icon: 'error',
  //   text: errorMessage,
  //   showCancelButton: true,
  //   confirmButtonText: '확인',
  //   confirmButtonColor: '3085d6',
  //   cancelButtonText: '뒤로가기',
  //   cancelButtonColor: '#d33',
  //   allowOutsideClick: false,
  //   heightAuto: false,
  // }).then((result) => {
  //   if (result.isDismissed) {
  //     window.history.back();
  //   }
  // });

  return 0;
  // return Sentry.captureException(
  //   new Error(`sentry error capture request : ${error}`),
  // );
};

api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    try {
      //
    } catch (error) {
      captureError(error);
    }
    // config.headers['Cache-Control'] = 'no-cache';
    return config;
  },
  (error) => {
    captureError(error);
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response: AxiosResponse<any, any>) => {
    try {
      //
    } catch (error) {
      captureError(error);
    }
    return response;
  },
  (error) => {
    captureError(error);
    return Promise.reject(error);
  },
);
