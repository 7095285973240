/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { SidoId, SidoData } from '@carsayo/types';
import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { X } from 'lucide-react';

export function SelectSido({
  isOpen,
  setIsOpen,
  selectSido,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  selectSido: (value: SidoId) => void;
}) {
  return (
    <Drawer
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
      }}
    >
      <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
        <DrawerHeader className='relative'>
          <DrawerTitle>지역 선택</DrawerTitle>
          <DrawerClose asChild>
            <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
              <X className='h-7 w-7' />
            </Button>
          </DrawerClose>
        </DrawerHeader>
        <div className='grow overflow-y-auto p-4 pb-10'>
          <div className='grid grid-cols-3 gap-[21.5px] overflow-y-auto'>
            {SidoData.map((item) => (
              <label
                key={item.name}
                className='relative flex items-center justify-center rounded-lg border py-3 text-sm font-semibold has-[:checked]:border-primary'
                onClick={() => {
                  selectSido(item.id);
                  setIsOpen(false);
                }}
              >
                <input
                  type='radio'
                  name='region'
                  value={item.name}
                  className='w-0 appearance-none'
                />
                {item.name}
              </label>
            ))}
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
