import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import Bowser from 'bowser';

interface DeviceStore {
  isAndroid: boolean;
  isIOS: boolean;
  myDevice?: 'android' | 'ios' | 'browserW' | 'browserM';
}
interface DeviceAction {
  setDeviceType: () => void;
}
const useDeviceStore = create<DeviceStore & DeviceAction>()(
  devtools(
    persist(
      immer((set, get) => ({
        isAndroid: false,
        isIOS: false,
        setDeviceType: () => {
          const userAgent = Bowser.getParser(window.navigator.userAgent);
          const sUsrAg = window.navigator.userAgent.toLowerCase();

          // 네이티브 함수가 정의되어 있을 경우 안드로이드 혹은 iOS 환경으로 인식
          if (window?.android) {
            set({ isAndroid: true, myDevice: 'android' });
            return;
          } else if (window?.webkit?.messageHandlers) {
            set({ isIOS: true, myDevice: 'ios' });
            return;
          }

          if (
            userAgent.getOS().name?.includes('Android') &&
            userAgent.getBrowser().name?.includes('WebView')
          ) {
            set({ isAndroid: true, myDevice: 'android' });
          } else if (
            (userAgent.getOS().name?.includes('iOS') ||
              userAgent.getOS().name?.includes('Mac')) &&
            userAgent.getEngine().name?.includes('WebKit')
          ) {
            set({ isIOS: true, myDevice: 'ios' });
          } else {
            if (
              sUsrAg.indexOf('mac') !== -1 ||
              sUsrAg.indexOf('iphone') !== -1 ||
              sUsrAg.indexOf('ipad') !== -1 ||
              sUsrAg.indexOf('ipod') !== -1
            ) {
              set({ myDevice: 'browserM' });
            } else {
              set({ myDevice: 'browserW' });
            }
          }
        },
      })),
      {
        name: 'deviceStore',
      },
    ),
  ),
);

export default useDeviceStore;
