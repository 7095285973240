import { OrderCommissionListData } from '@carsayo/types';
import { api } from 'apis';
import SentryErrorReturns from 'utils/sentryErrorReturns';

// 카매니저 수수료 견적함 리스트 검색
export const getManagerCommissionList = async () => {
  try {
    const response = await api.get('order/commission/dealer/list');
    const data: OrderCommissionListData[] = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};
