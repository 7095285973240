import {
  TermCategoryId,
  CarMakerId,
  SidoId,
  MemberDealerType,
} from '@carsayo/types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type SelectedLeaseRentType = 'lease' | 'rent' | 'leaseRent';
export type SelectedCarFrom = 'domestic' | 'imported' | 'whole';

interface SignupInfo {
  phoneNumber?: string;
  niceAuthToken?: string;
  agreeTermList?: TermCategoryId[];

  dealer?: {
    type?: MemberDealerType;
    email?: string;
    experenceYear?: number;
    isCarsayoEmployee?: boolean;
    selectedCarMakerList?: CarMakerId[];
    selectedCarFrom?: SelectedCarFrom;
    workingSido?: SidoId;
    organization?: string;
    joinReason?: string;
    /** 리스렌트 회원가입시 리스인지 렌트인지 선택 */
    leaseRentType?: SelectedLeaseRentType;
    /** 매매업종사업증 유효기간 시작일
     * @notice 중고차 딜러 전용 필수 옵션
     */
    tradingCertificate_term_start?: Date;
    /** 매매업종사업증 유효기간 종료일
     * @notice 중고차 딜러 전용 필수 옵션
     */
    tradingCertificate_term_finish?: Date;
  };
}

interface SignupState {
  signupInfo?: SignupInfo;
}
interface SignupAction {
  setSignupStore: (data: SignupInfo) => void;
  resetSignupStore: () => void;
}

const signupStore = create<SignupState & SignupAction>()(
  devtools(
    immer((set) => ({
      signupInfo: undefined,
      setSignupStore: (data: SignupInfo) => {
        set((state) => {
          state.signupInfo = data;
        });
      },
      resetSignupStore: () => {
        set((state) => {
          state.signupInfo = undefined;
        });
      },
    })),
  ),
);

export default signupStore;
