import { ChevronLeft } from 'lucide-react';
import { useLayoutEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';

export default function PromotionIndex() {
  const { setIsBottomTabbar, setTopTabbarState } = useSystemStore();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      className: 'fixed bg-transparent text-white z-[300]',
      mode: 'subpage',
      leftElement: (
        <ChevronLeft
          onClick={() => {
            navigate(-1);
          }}
          className='h-7 w-7 cursor-pointer'
        ></ChevronLeft>
      ),
      title: '프리미엄 혜택',
    });
    setIsBottomTabbar(false);
  }, []);

  return <Outlet />;
}
