/* eslint-disable @typescript-eslint/no-unused-vars */
// 카사요 어드민에서 설정한 팝업과 연동되는 모듈입니다
import { ChangeEvent, useEffect, useRef } from 'react';
import useSystemStore from 'store/useSystemStore';
import useFileUploader from 'store/useFileUploader';
import { getFileInfo, uploadFileToS33 } from 'apis/authentication';
import useDeviceStore from 'store/useDeviceStore';
import { CarsayoToast } from '../v2/CarsayoToast';
import { FileInfo } from '@carsayo/types';

// TODO: 드래그 이벤트 추가
const FileUploader = () => {
  const { uploadInfo, uploadCallback } = useFileUploader();
  const { isAndroid } = useDeviceStore();
  const { setLoadingDim } = useSystemStore();

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (uploadInfo.fileType !== 'blank') {
      fileInputRef.current?.click();
    }
  }, [uploadInfo]);

  return (
    <input
      className='hidden'
      id='CarsayoFileUploader'
      type='file'
      capture={
        isAndroid && uploadInfo.acceptFile.includes('image') ? true : false
      }
      ref={fileInputRef}
      onChange={async (data: ChangeEvent<HTMLInputElement>) => {
        setLoadingDim(true);

        const file = data.target.files && data.target.files[0];
        if (!file) return;

        try {
          const fileUploadData = await uploadFileToS33({
            file: file,
            fileType: uploadInfo.fileType,
            fileName: uploadInfo.fileName,
            isPublic: uploadInfo.isPublic,
          });

          const fileInfo: FileInfo = await getFileInfo({
            fileId: fileUploadData.fileId,
          });

          if (uploadCallback) uploadCallback(fileInfo, file);
          if (fileInputRef?.current) fileInputRef.current.value = '';
        } catch (e: any) {
          CarsayoToast.error(
            '파일 업로드에 실패했습니다\n잠시 후 다시 시도해 주세요',
          );
          // eslint-disable-next-line no-empty
        }

        setLoadingDim(false);
      }}
      accept={`${uploadInfo.acceptFile.includes('image') ? 'image/*,' : ''}${uploadInfo.acceptFile.includes('pdf') ? 'application/pdf,' : ''}${uploadInfo.acceptFile.includes('zip') ? 'application/zip,' : ''}`}
    />
  );
};
export default FileUploader;
