import { MemberDealerType, PaymentState } from '@carsayo/types';

export const parseDealerType = (dealerType: MemberDealerType): string => {
  switch (dealerType) {
    case 'leaseRent':
      return '리스/렌트 매니저';
    case 'newcar':
      return '신차 매니저';
    case 'usedcar':
      return '중고차 매니저';
    default:
      throw new Error('잘못된 고객 유형입니다.');
  }
};

export const parsePaymentState = (paymentState: PaymentState): string => {
  switch (paymentState) {
    case 'init':
      return '';
    case 'paid':
      return '결제 완료';
    case 'canceled':
      return '결제 취소';
    case 'cancelFailed':
      return '취소 실패';
    case 'virtualAccountWaiting':
      return '가상계좌 대기';
    case 'used':
      return '결제 완료';
    default:
      throw new Error('잘못된 결제 상태입니다.');
  }
};

export const splitFileNameAndExtension = (file: File) => {
  const fileType = file.type;
  const fileName = file.name;
  const lastSlashIndex = fileType.lastIndexOf('/');
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex === -1) {
    // 파일 이름에 확장자가 없는 경우
    if (lastSlashIndex !== -1) {
      const fileNameOnly = fileName.slice(0, lastDotIndex);
      const fileExtension = fileType.slice(lastSlashIndex + 1);
      return { fileNameOnly, fileExtension };
    } else {
      console.error('정상적인 파일이 아닙니다.');
      return { fileNameOnly: fileType, fileExtension: '' };
    }
  } else {
    const fileNameOnly = fileName.slice(0, lastDotIndex);
    const fileExtension = fileName.slice(lastDotIndex + 1);
    return { fileNameOnly, fileExtension };
  }
};

export const parseStringPhoneNumber = (phone: string) => {
  const parsePhone = phone.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3');
  return parsePhone;
};

export const addDotToYYYYMMDD = (YYYYMMDD: string) => {
  return YYYYMMDD.replace(/(\d{4})(\d{2})(\d{2})/g, '$1.$2.$3');
};
