import { MemberSettingUpdateDTO } from '@carsayo/types';
import { updateMessageReceiveSetting, updateTerm } from 'apis/setting';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { Switch } from 'components/ui/switch';
import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMemberStore from 'store/useMemberStore';
import useSystemStore from 'store/useSystemStore';
import getTermToastMessage from 'utils/getTermToastMessage';

export default function MypageAlert() {
  const navigate = useNavigate();

  const { setTopTabbarState } = useSystemStore();
  const { loginMember, setLoginMember } = useMemberStore();
  const [memberSettingUpdateDTO, setMemberSettingUpdateDTO] =
    useState<MemberSettingUpdateDTO>({
      marketing_email: loginMember?.setting.marketing_email,
      marketing_push: loginMember?.setting.marketing_push,
      marketing_sms: loginMember?.setting.marketing_sms,
      push_cs: loginMember?.setting.push_cs,
      push_event: loginMember?.setting.push_event,
      push_lease: loginMember?.setting.push_lease,
      push_newCar: loginMember?.setting.push_newCar,
      push_notice: loginMember?.setting.push_notice,
      push_sellCar: loginMember?.setting.push_sellCar,
    });

  const handleClickEvents = {
    whole: async () => {
      const state = !(
        memberSettingUpdateDTO.push_lease &&
        memberSettingUpdateDTO.push_newCar &&
        memberSettingUpdateDTO.push_sellCar &&
        memberSettingUpdateDTO.push_cs &&
        memberSettingUpdateDTO.push_notice
      );
      const updatedMember = await updateMessageReceiveSetting({
        push_lease: state,
        push_newCar: state,
        push_sellCar: state,
        push_notice: state,
        push_cs: state,
      });

      setLoginMember(updatedMember);
      setMemberSettingUpdateDTO({
        ...memberSettingUpdateDTO,
        push_lease: state,
        push_newCar: state,
        push_sellCar: state,
        push_notice: state,
        push_cs: state,
      });

      return;
    },
    newCar: async () => {
      const state = !memberSettingUpdateDTO.push_newCar;
      const updatedMember = await updateMessageReceiveSetting({
        push_newCar: state,
      });

      setLoginMember(updatedMember);
      setMemberSettingUpdateDTO({
        ...memberSettingUpdateDTO,
        push_newCar: state,
      });

      return;
    },
    leaseRent: async () => {
      const state = !memberSettingUpdateDTO.push_lease;
      const updatedMember = await updateMessageReceiveSetting({
        push_lease: state,
      });

      setLoginMember(updatedMember);
      setMemberSettingUpdateDTO({
        ...memberSettingUpdateDTO,
        push_lease: state,
      });

      return;
    },
    sellCar: async () => {
      const state = !memberSettingUpdateDTO.push_sellCar;
      const updatedMember = await updateMessageReceiveSetting({
        push_sellCar: state,
      });

      setLoginMember(updatedMember);
      setMemberSettingUpdateDTO({
        ...memberSettingUpdateDTO,
        push_sellCar: state,
      });

      return;
    },
    notice: async () => {
      const state = !memberSettingUpdateDTO.push_notice;
      const updatedMember = await updateMessageReceiveSetting({
        push_notice: state,
      });

      setLoginMember(updatedMember);
      setMemberSettingUpdateDTO({
        ...memberSettingUpdateDTO,
        push_notice: state,
      });

      return;
    },
    cs: async () => {
      const state = !memberSettingUpdateDTO.push_cs;
      const updatedMember = await updateMessageReceiveSetting({
        push_cs: state,
      });

      setLoginMember(updatedMember);
      setMemberSettingUpdateDTO({
        ...memberSettingUpdateDTO,
        push_cs: state,
      });

      return;
    },

    /** 마케팅 푸시 수신 약관 동의를 같이 합니다.
     * @notice 알람, 내 정보 페이지 두 곳에서 유사한 코드 사용
     */
    marketing_push: async () => {
      const state = !memberSettingUpdateDTO.marketing_push;

      // 회원 정보 먼저 수정
      const updatedMember = await updateMessageReceiveSetting({
        marketing_push: state,
      });

      // 약관 동의 내역 변경
      const data = await updateTerm({
        updateTerms: [
          {
            termCategoryId: 95100,
            isAgree: state,
          },
        ],
      });

      if (
        data.success.filter((el) => {
          return el.termCategoryId === 95100 && el.isAgree === state;
        }).length > 0
      ) {
        CarsayoToast.success(
          getTermToastMessage({
            state: state,
            termTitle: '광고성 앱 푸시 수신',
          }),
        );
      } else {
        await updateMessageReceiveSetting({
          marketing_push: !state,
        });
        return;
      }

      setLoginMember(updatedMember);
      setMemberSettingUpdateDTO({
        ...memberSettingUpdateDTO,
        marketing_push: state,
      });

      return;
    },

    /** 마케팅 푸시 수신 약관 동의를 같이 합니다. */
    marketing_sms: async () => {
      const state = !memberSettingUpdateDTO.marketing_sms;

      // 회원 정보 먼저 수정
      const updatedMember = await updateMessageReceiveSetting({
        marketing_sms: state,
      });

      // 약관 동의 내역 변경
      const data = await updateTerm({
        updateTerms: [
          {
            termCategoryId: 95200,
            isAgree: state,
          },
        ],
      });

      if (
        data.success.filter((el) => {
          return el.termCategoryId === 95200 && el.isAgree === state;
        }).length > 0
      ) {
        CarsayoToast.success(
          getTermToastMessage({
            state: state,
            termTitle: '광고성 문자 메시지 수신',
          }),
        );
      } else {
        await updateMessageReceiveSetting({
          marketing_sms: !state,
        });
        return;
      }

      setLoginMember(updatedMember);
      setMemberSettingUpdateDTO({
        ...memberSettingUpdateDTO,
        marketing_sms: state,
      });

      return;
    },

    /** 마케팅 푸시 수신 약관 동의를 같이 합니다. */
    marketing_email: async () => {
      const state = !memberSettingUpdateDTO.marketing_email;

      // 회원 정보 먼저 수정
      const updatedMember = await updateMessageReceiveSetting({
        marketing_email: state,
      });

      // 약관 동의 내역 변경
      const data = await updateTerm({
        updateTerms: [
          {
            termCategoryId: 95300,
            isAgree: state,
          },
        ],
      });

      if (
        data.success.filter((el) => {
          return el.termCategoryId === 95300 && el.isAgree === state;
        }).length > 0
      ) {
        CarsayoToast.success(
          getTermToastMessage({
            state: state,
            termTitle: '광고성 이메일 수신',
          }),
        );
      } else {
        await updateMessageReceiveSetting({
          marketing_email: !state,
        });
        return;
      }

      setLoginMember(updatedMember);
      setMemberSettingUpdateDTO({
        ...memberSettingUpdateDTO,
        marketing_email: state,
      });

      return;
    },
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '알림 설정',
      rightElement: 'home',
      leftElement: 'back',
    });
  }, []);

  return (
    <div className='px-4 pb-10'>
      <div className='flex h-14 items-center justify-between border-b border-[#F1F1F5]'>
        <div className='text-base font-semibold'>서비스 알림</div>
        <Switch
          checked={
            memberSettingUpdateDTO.push_lease &&
            memberSettingUpdateDTO.push_newCar &&
            memberSettingUpdateDTO.push_sellCar &&
            memberSettingUpdateDTO.push_cs &&
            memberSettingUpdateDTO.push_notice
          }
          onClick={handleClickEvents.whole}
        />
      </div>
      <div className='px-5 pb-8'>
        <div className='flex h-[52px] items-center font-semibold'>견적</div>
        <div className='text-xs text-[#505050]'>
          카사요의 전체 주문 및 진행에 대한 전반적인 상황(견적발송, 낙찰 등)에
          대해 실시간 알림을 제공합니다.
        </div>
        <div className='mt-4 border-t'>
          <div className='flex h-[52px] items-center justify-between border-b'>
            <div>리스 / 장기렌트</div>
            <Switch
              checked={memberSettingUpdateDTO.push_lease}
              onClick={handleClickEvents.leaseRent}
            />
          </div>
          <div className='flex h-[52px] items-center justify-between border-b'>
            <div>신차</div>
            <Switch
              checked={memberSettingUpdateDTO.push_newCar}
              onClick={handleClickEvents.newCar}
            />
          </div>
          <div className='flex h-[52px] items-center justify-between border-b'>
            <div>내 차 팔기</div>
            <Switch
              checked={memberSettingUpdateDTO.push_sellCar}
              onClick={handleClickEvents.sellCar}
            />
          </div>
        </div>
      </div>
      <div className='px-5 pb-8'>
        <div className='flex h-[52px] items-center font-semibold'>부가</div>
        <div className='text-xs text-[#505050]'>
          카사요의 공지사항, 문의 내역, 서비스 평가 등 전반적인 이용관련 상황에
          대해 실시간 알림을 제공합니다.
        </div>
        <div className='mt-4 border-t'>
          <div className='flex h-[52px] items-center justify-between border-b'>
            <div>공지사항</div>
            <Switch
              checked={memberSettingUpdateDTO.push_notice}
              onClick={handleClickEvents.notice}
            />
          </div>
          <div className='flex h-[52px] items-center justify-between border-b'>
            <div>고객센터</div>
            <Switch
              checked={memberSettingUpdateDTO.push_cs}
              onClick={handleClickEvents.cs}
            />
          </div>
        </div>
      </div>

      <div className='flex h-14 items-center justify-between border-b border-[#F1F1F5]'>
        <div className='text-base font-semibold'>이벤트 • 혜택 알림</div>
      </div>
      <div className='px-5 pb-8'>
        <div className='flex h-[52px] items-center font-semibold'>마케팅</div>
        <div className='text-xs text-[#505050]'>
          마케팅 정보 수신시 카사요의 이벤트 및 프로모션과 같은 다양한 혜택에
          대해 알려드립니다.
        </div>
        <div className='mt-4 border-t'>
          <div className='flex h-[52px] items-center justify-between border-b'>
            <div
              className='underline'
              onClick={() => {
                navigate('/mypage/termDetail/95100');
              }}
            >
              앱 푸시
            </div>
            <Switch
              checked={memberSettingUpdateDTO.marketing_push}
              onClick={handleClickEvents.marketing_push}
            />
          </div>
          <div className='flex h-[52px] items-center justify-between border-b'>
            <div
              className='underline active:text-gray-900'
              onClick={() => {
                navigate('/mypage/termDetail/95200');
              }}
            >
              문자 메시지
            </div>
            <Switch
              checked={memberSettingUpdateDTO.marketing_sms}
              onClick={handleClickEvents.marketing_sms}
            />
          </div>
          <div className='flex h-[52px] items-center justify-between border-b'>
            <div
              className='underline'
              onClick={() => {
                navigate('/mypage/termDetail/95300');
              }}
            >
              이메일
            </div>
            <Switch
              checked={memberSettingUpdateDTO.marketing_email}
              onClick={handleClickEvents.marketing_email}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
