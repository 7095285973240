/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import InsuranceBanner from './component/banner';
import {
  InsuranceCompany,
  InsuranceCompanyData,
  InsuranceCompanyInfo,
} from './data/company';
import { ArrowUpToLine, ChevronLeft, ChevronRight } from 'lucide-react';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import InterestFree from './subpage/interestFree';
import { InterestFreeSubpageState } from './data/type';
import { InsuranceCard } from './data/card';
import { useLocation, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import {
  addHistoryInsuranceAdLinkClick,
  addHistoryInsuranceCooconClick,
} from 'apis/history';
import useDeviceStore from 'store/useDeviceStore';

export default function InsuranceMain() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isBottomTabbar, setTopTabbarState, setIsBottomTabbar } =
    useSystemStore();
  const { myDevice } = useDeviceStore();

  /** Subpage is opened or not */
  const [interestFreeSubpageState, setInterestFreeSubpageState] = useState<
    InterestFreeSubpageState | undefined
  >();

  const outerDivRef = useRef<HTMLDivElement>(null);
  const scrollTopRef = useRef<SVGSVGElement>(null);
  const companySelecteRef = useRef<null[] | HTMLDivElement[]>([]);

  const handleClickEvents = {
    moveToCompany: (insuranceCompany: InsuranceCompany) => {
      const targetIndex =
        Object.keys(InsuranceCompanyData).indexOf(insuranceCompany);
      companySelecteRef.current[targetIndex]?.scrollIntoView({
        inline: 'center',
        block: 'center',
        behavior: 'smooth',
      });
    },
    openCooconInsurance: () => {
      addHistoryInsuranceCooconClick({
        from: `app${location.pathname}`,
        target: '쿠콘',
      });
      if (process.env.REACT_APP_NODE_ENV === 'development') {
        window.open(
          'https://insu-dev.coocon.co.kr/bridge.act?org_cd=RUQBBRKU&browser_type=2',
        );
        return;
      }
      window.open(
        'https://insu.coocon.net/bridge.act?org_cd=RUQBBRKU&browser_type=2',
      );
    },
    showInterestFree: (data: InterestFreeSubpageState) => {
      navigate({ search: 'interestFree' });
      setInterestFreeSubpageState(data);
    },
    redirectExternalPage: (insuranceCompanyInfo: InsuranceCompanyInfo) => {
      handleClickEvents.moveToCompany(insuranceCompanyInfo.id);
    },
  };

  useEffect(() => {
    if (location.search === '' && interestFreeSubpageState)
      setInterestFreeSubpageState(undefined);
  }, [location.search]);

  useEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: (
        <ChevronLeft
          onClick={() => {
            navigate(-1);
          }}
          className='h-7 w-7 cursor-pointer'
        ></ChevronLeft>
      ),
      title: interestFreeSubpageState ? '무이자 혜택' : '자동차 보험',
    });
  }, [interestFreeSubpageState]);

  useEffect(() => {
    if (!outerDivRef.current) return;

    function handleScrollTopButton() {
      if (!outerDivRef.current || !scrollTopRef.current) return;
      if (outerDivRef.current.scrollTop > 2500) {
        if (scrollTopRef.current.style.display !== 'block')
          scrollTopRef.current.style.display = 'block';
      } else {
        if (scrollTopRef.current.style.display !== 'none') {
          scrollTopRef.current.style.display = 'none';
        }
      }
    }

    outerDivRef.current.addEventListener('scroll', handleScrollTopButton);
    return () => {
      if (!outerDivRef.current) return;
      outerDivRef.current.removeEventListener(
        'scrollend',
        handleScrollTopButton,
      );
    };
  }, []);

  useLayoutEffect(() => {
    setIsBottomTabbar(false);

    if (isBottomTabbar === true) {
      return () => {
        setIsBottomTabbar(true);
      };
    }
  }, []);

  return (
    <div ref={outerDivRef} className='flex flex-col h-full overflow-y-scroll'>
      <InterestFree
        interestFreeSubpageState={interestFreeSubpageState}
        setInterestFreeSubpageState={setInterestFreeSubpageState}
      ></InterestFree>
      {/* 자동차 보험 비교하기 */}
      <section className='flex items-center flex-col py-6 pb-2 px-4 bg-[#F2F2F2]'>
        <div className='flex w-full'>
          <div className='py-0.5 flex flex-col justify-between flex-grow'>
            <span className='text-[18px] font-medium tracking-tight'>
              먼저 자동차 보험 비교하고
            </span>
            <span className='text-[14px] font-normal tracking-tight'>
              가입하고 싶다면
            </span>
          </div>
        </div>

        <div className='flex flex-col items-center'>
          <div className='mt-8 text-white bg-[#3A474E] px-4 py-2 font-medium tracking-tight rounded shadow-all-select-btn'>
            <span className='text-[#EDAA46]'>9개 보험사 한번에 비교하고</span>{' '}
            부담을 덜어보세요 !
          </div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='11'
            height='6'
            viewBox='0 0 11 6'
            fill='none'
          >
            <path d='M0.5 0L5.5 6L10.5 0H0.5Z' fill='#3A474E' />
          </svg>
        </div>

        <Button
          className='mt-2 rounded-none bg-[#534CBB]'
          onClick={handleClickEvents.openCooconInsurance}
        >
          자동차 보험 비교하기
        </Button>

        <div className='w-full pt-2.5 flex gap-2 justify-between text-[12px] text-[#00000080]'>
          <div>손해보험협회 심의필 제129622호(2024.08.01~2025.07.31)</div>
          <div className='w-6 flex flex-col justify-end'>
            <div>쿠콘</div>
          </div>
        </div>
      </section>

      {/* 다이렉트 자동차보험 가입 */}
      <section className='flex items-center flex-col mt-4 pt-4 py-2 bg-white'>
        <div className='flex items-center justify-between w-full pb-2 px-4'>
          <div className='flex gap-2 items-center'>
            <img
              className='h-[22px]'
              src='/assets/images/static/handle.png'
              alt='핸들 아이콘'
            />
            <span className='text-[18px] font-semibold tracking-tight'>
              다이렉트 자동차보험 가입
            </span>
          </div>
          <Button className='w-12 h-8 text-[#6100FF] bg-[#EEE3FF] rounded-sm'>
            AD
          </Button>
        </div>

        <div className='bg-white flex flex-none px-2 py-4 h-fit overflow-x-auto max-w-full'>
          {Object.keys(InsuranceCompanyData).map((el) => {
            const targetCompanyName = el as InsuranceCompany;
            const targetCompanyData = InsuranceCompanyData[targetCompanyName];
            return (
              <div
                key={targetCompanyName}
                onClick={() => {
                  handleClickEvents.redirectExternalPage(targetCompanyData);
                }}
                className={
                  'flex flex-none cursor-pointer hover:bg-gray-50 h-fit gap-2.5 mx-1 px-4 py-2.5 rounded-full border border-gray-300 flex-shrink-0'
                }
              >
                <img
                  className='w-5 h-5'
                  src={targetCompanyData.design.logo.svg}
                  alt='보험사 로고'
                />
                <div>{targetCompanyName}</div>
              </div>
            );
          })}
        </div>
      </section>

      {/* 보험사별 혜택 영역 */}
      <section className='flex items-center flex-col mt-4 py-6 px-4 bg-[#F2F2F2] gap-4'>
        {Object.keys(InsuranceCompanyData).map((el, index) => {
          const targetCompanyName = el as InsuranceCompany;
          const targetCompanyData = InsuranceCompanyData[targetCompanyName];
          return (
            <div
              key={targetCompanyName}
              ref={(el) => (companySelecteRef.current[index] = el)}
              className='bg-white p-4 w-full rounded-2xl shadow-sm active:bg-gray-50'
              onClick={() => {
                addHistoryInsuranceAdLinkClick({
                  from: `app${location.pathname}`,
                  target: targetCompanyData.id,
                });
                window.open(
                  myDevice === 'ios' || myDevice === 'android'
                    ? targetCompanyData.data.mUrl
                    : targetCompanyData.data.url,
                );
              }}
            >
              <InsuranceBanner
                className='w-full mb-2'
                companyInfo={targetCompanyData}
              ></InsuranceBanner>

              {/* Description */}
              <div className='flex py-4 border-b'>
                <div
                  className={`py-0.5 flex flex-col justify-between flex-grow`}
                >
                  {targetCompanyData.data.description.title && (
                    <span
                      className={`text-[18px] font-semibold tracking-[-0.65px] pb-2`}
                    >
                      {targetCompanyData.data.description.title}
                    </span>
                  )}
                  <span
                    className={`text-[14px] font-normal tracking-[-0.65px] text-gray-900 break-keep`}
                  >
                    {targetCompanyData.data.description.content}
                  </span>
                </div>
              </div>

              {/* InterestFree */}
              {targetCompanyData.data.interestFree && (
                <div className='flex flex-col py-4 border-b'>
                  <div className='flex justify-between pb-4'>
                    <span className={`text-[16px] font-medium tracking-tight`}>
                      무이자 혜택
                    </span>
                    <div
                      className='flex items-center cursor-pointer'
                      onClick={() => {
                        if (!targetCompanyData.data.interestFree) return;
                        handleClickEvents.showInterestFree({
                          card: Object.keys(
                            targetCompanyData.data.interestFree,
                          )?.[0] as InsuranceCard,
                          company: targetCompanyData.id,
                        });
                      }}
                    >
                      <span className='text-[14px] font-normal tracking-tight'>
                        자세히 보기
                      </span>
                      <ChevronRight className='h-5 w-5' />
                    </div>
                  </div>

                  <div className='py-0.5 flex flex-wrap text-[13px] gap-2'>
                    {Object.keys(targetCompanyData.data.interestFree).map(
                      (el) => {
                        return (
                          <div
                            key={el}
                            className='bg-[#F7F7FB] w-fit flex justify-center items-center py-1 px-2'
                          >
                            {el}
                          </div>
                        );
                      },
                    )}
                  </div>

                  <Button
                    className='mt-6 mb-2 rounded-none bg-[#6100FF]'
                    onClick={() => {
                      handleClickEvents.redirectExternalPage(targetCompanyData);
                    }}
                  >
                    자동차 보험 가입하기
                  </Button>
                </div>
              )}

              <div className='mt-4 mb-2 flex items-center justify-center w-full gap-6'>
                <div className='items-center'>
                  <span
                    className={`text-[13px] text-gray-400 font-medium leading-tight tracking-tight break-keep`}
                  >
                    {targetCompanyData.data.footer.content}
                  </span>
                </div>
                <Button className='w-12 h-8 text-[#6100FF] bg-[#EEE3FF] rounded-sm'>
                  AD
                </Button>
              </div>
            </div>
          );
        })}
      </section>

      {/* 안내사항 영역 */}
      <section className='flex flex-col gap-4 py-6 px-4 bg-white tracking-tighter'>
        <span className='text-[18px] font-semibold tracking-tight'>
          안내사항
        </span>

        <ul className='list-decimal pl-6 pr-3 text-[#767676] break-all'>
          <li>
            차 보험 광고 (차 보험 가입하기)는 카사요(주)가 제휴 보험회사의
            다이렉트 자동차 보험 상품을 광고하는 서비스입니다. 본 광고 서비스를
            통하여 카사요(주)는 상품 판매 및 중개에 관여하지 않습니다.
          </li>
          <li>
            차 보험 가입 혜택을 받으려면 아래 조건을 충족해야합니다.
            <ul className='list-disc pl-4 text-[#767676]'>
              <li>
                캐롯손해보험, 악사다이렉트자동차보험 가입이력이 없는 신규 가입자
              </li>
              <li>연보험료 30만원 이상 결제자</li>
            </ul>
          </li>

          <li>
            한 보험사의 차 보험 가입 광고로 받을 수 있는 혜택은 최대
            30,000원입니다.
            <ul className='list-disc pl-4 text-[#767676]'>
              <li>
                예를 들어, 삼성화재의 차 보험료 조회를 통해 7,000원 상당의
                혜택을 이미 수령했다면, 삼성화재 다이렉트 차 보험 가입 광고를
                통해서는 최대 23,000원의 혜택을 수령하실 수 있습니다.
              </li>
            </ul>
          </li>
          <li>
            차 보험 가입 광고의 상세 내용은 각 보험사(캐롯손해보험,
            악사다이렉트, 삼성화재 다이렉트) 페이지에서 확인할 수 있습니다.
          </li>
          <li>
            차 보험 가입 이후 포인트 지급 관련 문의는 각 보험사(캐롯손해보험,
            악사다이렉트, 삼성화재다이렉트)에 문의해주세요.
          </li>
        </ul>
      </section>

      {!interestFreeSubpageState && (
        <ArrowUpToLine
          ref={scrollTopRef}
          onClick={() => {
            outerDivRef.current?.scroll({
              top: 0,
              behavior: 'smooth',
            });
          }}
          style={{
            display: 'none',
          }}
          className='p-2.5 w-10 h-10 active:bg-gray-100 rounded-full mb-[var(--safeAreaBottomPadding)] fixed left-1/2 transform -translate-x-1/2 bottom-8 bg-white text-gray-700 shadow-sm shadow-gray-500 border'
        >
          맨위로가기
        </ArrowUpToLine>
      )}
    </div>
  );
}
