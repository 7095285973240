import { PostList } from '@carsayo/types';
import { useQuery } from '@tanstack/react-query';
import { getPostList } from 'apis/board';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

interface AccordionInterface {
  expanded: boolean;
  setExpanded: (isOpen: boolean) => void;
}

export default function NoticeAccordion({
  expanded,
  setExpanded,
}: AccordionInterface) {
  const useNoticeList = () => {
    return useQuery({
      queryKey: ['mainNoticeList'],
      queryFn: async () => {
        const searchedList: PostList = await getPostList({
          boardKey: 'notice',
          skip: 0,
          take: 3,
          option: null,
          search: null,
        });
        return searchedList;
      },
      gcTime: 5 * 60 * 1000, // 5 min
      staleTime: 5 * 60 * 1000, // 5 min
    });
  };
  const { data: noticeList } = useNoticeList();

  return (
    <>
      {(noticeList?.importantPost && noticeList.importantPost.length > 0) ||
      (noticeList?.searchedPost && noticeList.searchedPost.length > 0) ? (
        <>
          <motion.div className='flex h-[38px] items-center gap-2'>
            {noticeList && noticeList.importantPost[0] && (
              <div className='h-[22px] bg-[#B71C1C] px-1 text-[13px] leading-[22px] text-white'>
                중요
              </div>
            )}
            <Link to={'/mypage/notice'}>
              {noticeList?.importantPost[0]
                ? noticeList.importantPost[0].title
                : noticeList?.searchedPost[0].title}
            </Link>
            <div
              className='ml-auto'
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              {expanded ? (
                <img src='/assets/images/v2/chevronTop.svg' alt='chevronTop' />
              ) : (
                <img
                  src='/assets/images/v2/chevronBottom.svg'
                  alt='chevronBottom'
                />
              )}
            </div>
          </motion.div>
          <AnimatePresence>
            {expanded &&
              noticeList?.importantPost.map((el, index) => {
                if (index === 0) return <div key={el.id} />;
                return (
                  <motion.div
                    key={`notice_important_${el.id}`}
                    initial='collapsed'
                    animate='open'
                    exit='collapsed'
                    variants={{
                      open: { opacity: 1, height: '38px' },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    className='flex h-[38px] items-center gap-2'
                  >
                    <div className='h-[22px] bg-[#B71C1C] px-1 text-[13px] leading-[22px] text-white'>
                      중요
                    </div>
                    <Link to={'/mypage/notice'}>{el.title}</Link>
                  </motion.div>
                );
              })}
            {expanded &&
              noticeList?.searchedPost.map((el, index) => {
                if (index === 0) return <div key={el.id} />;
                return (
                  <motion.div
                    key={`notice_searchPost_${el.id}`}
                    initial='collapsed'
                    animate='open'
                    exit='collapsed'
                    variants={{
                      open: { opacity: 1, height: '38px' },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    className='flex h-[38px] items-center gap-2'
                  >
                    <Link to={'/mypage/notice'}>{el.title}</Link>
                  </motion.div>
                );
              })}
          </AnimatePresence>
        </>
      ) : (
        <div>공지가 없습니다.</div>
      )}
    </>
  );
}
