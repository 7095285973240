/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AnimatePresence, motion } from 'framer-motion';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import IconHome from 'assets/images/v2/home.svg';

import useMemberStore from 'store/useMemberStore';
import useSystemStore from 'store/useSystemStore';

import { ChevronRight, X } from 'lucide-react';
import { useEffect } from 'react';
import sumObjectValues from 'utils/sumObjectValues';
import useDealerMainStateStore from 'pages/v2/mainPage/useDealerMainStateStore';
import { OrderState } from '@carsayo/types';

const SideMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { loginMember } = useMemberStore();
  const { isSideMenu, setIsSideMenu } = useSystemStore();
  const { orderTab, setOrderTab } = useDealerMainStateStore();

  const handleVoidEvents = {
    closeSideMenu: () => {
      navigate(-1);
    },
  };

  const handleClickEvents = {
    moveTo: {
      main: () => {
        navigate('/main', { replace: true });
      },
      signupForm: () => {
        navigate('/quote/manager', { replace: true });
      },
      quote: () => {
        navigate('/quote/manager', { replace: true });
      },
      mypage: () => {
        navigate('/mypage', { replace: true });
      },
      mypage_info: () => {
        navigate('/mypage/info', { replace: true });
      },
    },
    quoteMenu: (menu: OrderState | 'whole' | 'bidding' | 'marking') => () => {
      setOrderTab({
        page: 0,
        type: menu,
        isTabSlide: true,
      });
      handleVoidEvents.closeSideMenu();
    },
  };

  useEffect(() => {
    if (location.search === '' && isSideMenu) setIsSideMenu(false);
  }, [location.search]);

  return (
    <AnimatePresence>
      {isSideMenu && (
        <motion.div
          initial='collapsed'
          animate='open'
          exit='collapsed'
          variants={{
            open: { opacity: 1, width: '100%' },
            collapsed: { opacity: 0, width: 0 },
          }}
          className='top-0 bg-white fixed flex flex-col h-full z-[5000] truncate'
          style={{ paddingTop: 'env(safe-area-inset-top, 0)' }}
        >
          <div className='flex flex-none h-14 items-center justify-between bg-white px-4'>
            <div onClick={handleClickEvents.moveTo.main}>
              <img src={IconHome} alt='carsayoIcon' />
            </div>
            <div className='grow text-center text-xl font-semibold'>
              전체 메뉴
            </div>
            <div
              className='cursor-pointer'
              onClick={handleVoidEvents.closeSideMenu}
            >
              <X></X>
            </div>
          </div>
          <div className='flex flex-auto flex-col h-auto overflow-y-scroll'>
            <div className='flex flex-none h-14 items-center px-4 text-base font-medium'>
              {loginMember?.name_real} 매니저님
            </div>
            <div className='p-4'>
              <div className='grid h-[52px] grid-cols-2 items-center rounded-lg bg-[#0075FF]/10'>
                <Link
                  to={'/mypage/dealerSignupForm'}
                  replace={true}
                  className='text-center font-semibold'
                >
                  카 매니저 인증
                </Link>
                <Link
                  to={'/mypage/commission'}
                  replace={true}
                  className='text-center font-semibold'
                >
                  수수료 정산
                </Link>
              </div>
            </div>
            <div className='h-3 bg-[#F1F1F5]'></div>
            <div>
              <div className='px-4 flex h-[52px] items-center font-semibold'>
                제공 서비스
              </div>
              <div
                onClick={handleClickEvents.moveTo.mypage}
                className='px-4 flex h-[52px] active:bg-gray-50 items-center'
              >
                <img src='/assets/images/v2/menu-icon7.svg' alt='' />
                <div className='mx-3'>마이 페이지</div>
                <ChevronRight className='ml-auto h-4 w-4' strokeWidth='1.5' />
              </div>
              <div
                onClick={handleClickEvents.moveTo.mypage_info}
                className='px-4 flex h-[52px] active:bg-gray-50 items-center'
              >
                <img src='/assets/images/v2/menu-icon8.svg' alt='' />
                <div className='mx-3'>내정보 수정</div>
                <ChevronRight className='ml-auto h-4 w-4' strokeWidth='1.5' />
              </div>
              {/* <div className='px-4 flex h-[52px] active:bg-gray-50 items-center'>
                <img src='/assets/images/v2/menu-icon9.svg' alt='' />
                <div className='mx-3'>리뷰 관리</div>
                <ChevronRight className='ml-auto h-4 w-4' strokeWidth='1.5' />
              </div> */}
            </div>
            <div className='h-3 bg-[#F1F1F5]'></div>
            <div className='mb-4'>
              <div className='px-4 flex h-[52px] items-center font-semibold'>
                주문 현황
              </div>
              <div
                className='px-4 flex h-[52px] active:bg-gray-50 items-center'
                onClick={handleClickEvents.quoteMenu('whole')}
              >
                <img src='/assets/images/v2/menu-icon1.svg' alt='' />
                <div className='mx-3'>전체</div>
                <div className='ml-auto flex items-center gap-1'>
                  <div className='text-title font-semibold text-[#7273F9]'>
                    {loginMember?.dealerInfo?.count.order &&
                      sumObjectValues(loginMember.dealerInfo.count.order)}
                  </div>
                  <div className='text-xs text-[#555555]'>건</div>
                </div>
                <ChevronRight className='ml-6 h-4 w-4' strokeWidth='1.5' />
              </div>
              <div
                className='px-4 flex h-[52px] active:bg-gray-50 items-center'
                onClick={handleClickEvents.quoteMenu('marking')}
              >
                <img src='/assets/images/v2/menu-icon-bookmark.svg' alt='' />
                <div className='mx-3'>북마크</div>
                <div className='ml-auto flex items-center gap-1'>
                  <div className='text-title font-semibold text-[#7273F9]'>
                    {loginMember?.dealerInfo?.count.order?.bookmarked
                      ? Number(loginMember?.dealerInfo?.count.order?.bookmarked)
                      : 0}
                  </div>
                  <div className='text-xs text-[#555555]'>건</div>
                </div>
                <ChevronRight className='ml-6 h-4 w-4' strokeWidth='1.5' />
              </div>
              <div
                className='px-4 flex h-[52px] active:bg-gray-50 items-center'
                onClick={handleClickEvents.quoteMenu('bidding')}
              >
                <img src='/assets/images/v2/menu-icon2.svg' alt='' />
                <div className='mx-3'>입찰중</div>
                <div className='ml-auto flex items-center gap-1'>
                  <div className='text-title font-semibold text-[#7273F9]'>
                    {Number(loginMember?.dealerInfo?.count.order?.bidding)}
                  </div>
                  <div className='text-xs text-[#555555]'>건</div>
                </div>
                <ChevronRight className='ml-6 h-4 w-4' strokeWidth='1.5' />
              </div>
              <div
                className='px-4 flex h-[52px] active:bg-gray-50 items-center'
                onClick={handleClickEvents.quoteMenu('progressing')}
              >
                <img src='/assets/images/v2/menu-icon4.svg' alt='' />
                <div className='mx-3'>진행중</div>
                <div className='ml-auto flex items-center gap-1'>
                  <div className='text-title font-semibold text-[#7273F9]'>
                    {Number(loginMember?.dealerInfo?.count.order?.progressing)}
                  </div>
                  <div className='text-xs text-[#555555]'>건</div>
                </div>
                <ChevronRight className='ml-6 h-4 w-4' strokeWidth='1.5' />
              </div>
              <div
                className='px-4 flex h-[52px] active:bg-gray-50 items-center'
                onClick={handleClickEvents.quoteMenu('holded')}
              >
                <img src='/assets/images/v2/menu-icon6.svg' alt='' />
                <div className='mx-3'>보류</div>
                <div className='ml-auto flex items-center gap-1'>
                  <div className='text-title font-semibold text-[#7273F9]'>
                    {Number(loginMember?.dealerInfo?.count.order?.holded)}
                  </div>
                  <div className='text-xs text-[#555555]'>건</div>
                </div>
                <ChevronRight className='ml-6 h-4 w-4' strokeWidth='1.5' />
              </div>
              <div
                className='px-4 flex h-[52px] active:bg-gray-50 items-center'
                onClick={handleClickEvents.quoteMenu('finished')}
              >
                <img src='/assets/images/v2/menu-icon5.svg' alt='' />
                <div className='mx-3'>계약완료</div>
                <div className='ml-auto flex items-center gap-1'>
                  <div className='text-title font-semibold text-[#7273F9]'>
                    {Number(loginMember?.dealerInfo?.count.order?.finished)}
                  </div>
                  <div className='text-xs text-[#555555]'>건</div>
                </div>
                <ChevronRight className='ml-6 h-4 w-4' strokeWidth='1.5' />
              </div>
              <div
                className='px-4 flex h-[52px] active:bg-gray-50 items-center'
                onClick={handleClickEvents.quoteMenu('canceled')}
              >
                <img src='/assets/images/v2/menu-icon-x.svg' alt='' />
                <div className='mx-3'>취소</div>
                <div className='ml-auto flex items-center gap-1'>
                  <div className='text-title font-semibold text-[#7273F9]'>
                    {Number(loginMember?.dealerInfo?.count.order?.canceled)}
                  </div>
                  <div className='text-xs text-[#555555]'>건</div>
                </div>
                <ChevronRight className='ml-6 h-4 w-4' strokeWidth='1.5' />
              </div>
              <div
                className='px-4 flex h-[52px] active:bg-gray-50 items-center'
                onClick={handleClickEvents.quoteMenu('failBid')}
              >
                <img src='/assets/images/v2/menu-icon3.svg' alt='' />
                <div className='mx-3'>유찰</div>
                <div className='ml-auto flex items-center gap-1'>
                  <div className='text-title font-semibold text-[#7273F9]'>
                    {Number(loginMember?.dealerInfo?.count.order?.failBid)}
                  </div>
                  <div className='text-xs text-[#555555]'>건</div>
                </div>
                <ChevronRight className='ml-6 h-4 w-4' strokeWidth='1.5' />
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SideMenu;
