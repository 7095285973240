import {
  Sido,
  SidoData,
  SigunguId,
  Sigungu,
  SigunguData,
  SidoId,
  OrderProcessState,
  OrderState,
  DealerOrderListData,
} from '@carsayo/types';

export const parseSidoId = (SidoId: SidoId): Sido | undefined => {
  const targetData = SidoData.find((el) => {
    return el.id === SidoId;
  });
  return targetData;
};

export const parseSigunguId = (sigunguId: SigunguId): Sigungu | null => {
  let targetSigungu: (typeof SigunguData)[number] | undefined = undefined;
  const targetData = SidoData.find((el1) => {
    return (
      el1.id ===
      SigunguData.find((el2) => {
        if (el2.id === sigunguId) {
          targetSigungu = el2;
          return true;
        }
      })?.sidoId
    );
  });

  if (!targetData || !targetSigungu) return null;

  return {
    id: (targetSigungu as (typeof SigunguData)[number]).id,
    name: (targetSigungu as (typeof SigunguData)[number]).name,
    sido: {
      id: targetData.id as SidoId,
      name: targetData.name,
      name_short: targetData.name_short,
    },
  };
};

export const parseOrderProcessState = (
  orderProcess: OrderProcessState,
): {
  /** @example #FFFFFF */
  color: string;
  text: string;
} => {
  switch (orderProcess) {
    case 'bidding':
      return {
        color: '#107700',
        text: '입찰중',
      };
    case 'progressing':
      return {
        color: '#107700',
        text: '진행중',
      };
    case 'submitted':
      return {
        color: '#107700',
        text: '입찰중',
      };
    case 'nonAccepted':
      return {
        color: '#B71C1C',
        text: '유찰',
      };
    case 'accepted':
      return {
        color: '#2F66BA',
        text: '진행중',
      };
    case 'holded':
      return {
        color: '#FFAA00',
        text: '보류',
      };
    case 'failBid':
      return {
        color: '#B71C1C',
        text: '입찰종료',
      };
    case 'canceled':
      return {
        color: '#B71C1C',
        text: '취소',
      };
    case 'finished':
      return {
        color: '#2F66BA',
        text: '종료',
      };
    case 'resumed':
      return {
        color: '#2F66BA',
        text: '주문재개',
      };
    case 'setMeeting':
      return {
        color: '#107700',
        text: '미팅날짜',
      };
    case 'deleted':
      return {
        color: '#2F66BA',
        text: '제거됨',
      };
  }

  return {
    color: '#2F66BA',
    text: '중단',
  };
};

/** 주문 상태를 파싱합니다
 * @notice 주문 상태를 사용할 때에는 (텍스트로 변환 등) 무조건 이 메소드를 거쳐 주세요
 */
export const parseOrderState = (
  orderState: OrderState,
): {
  text: string;
  themaColor: {
    text: {
      color: string;
    };
    box: {
      bg: string;
      text: 'text-black' | 'text-white';
      border: string;
    };
  };
} => {
  if (orderState === 'submitted') {
    return {
      text: '입찰중',
      themaColor: {
        text: {
          color: 'text-lime-700',
        },
        box: {
          bg: 'bg-lime-700',
          text: 'text-white',
          border: 'border-lime-700',
        },
      },
    };
  } else if (orderState === 'progressing') {
    return {
      text: '진행중',
      themaColor: {
        text: {
          color: 'text-blue-600',
        },
        box: {
          bg: 'bg-blue-600',
          text: 'text-white',
          border: 'border-blue-600',
        },
      },
    };
  } else if (orderState === 'holded') {
    return {
      text: '보류',
      themaColor: {
        text: {
          color: 'text-amber-600',
        },
        box: {
          bg: 'bg-amber-600',
          text: 'text-white',
          border: 'border-amber-600',
        },
      },
    };
  } else if (orderState === 'canceled') {
    return {
      text: '취소',
      themaColor: {
        text: {
          color: 'text-rose-600',
        },
        box: {
          bg: 'bg-rose-600',
          text: 'text-white',
          border: 'border-rose-600',
        },
      },
    };
  } else if (orderState === 'finished') {
    return {
      text: '계약완료',
      themaColor: {
        text: {
          color: 'text-fuchsia-600',
        },
        box: {
          bg: 'bg-fuchsia-600',
          text: 'text-white',
          border: 'border-fuchsia-600',
        },
      },
    };
  } else if (orderState === 'failBid') {
    return {
      text: '유찰',
      themaColor: {
        text: {
          color: 'text-gray-400',
        },
        box: {
          bg: 'bg-gray-400',
          text: 'text-white',
          border: 'border-gray-400',
        },
      },
    };
  } else if (orderState === 'deleted') {
    return {
      text: '삭제',
      themaColor: {
        text: {
          color: 'text-gray-400',
        },
        box: {
          bg: 'bg-gray-400',
          text: 'text-white',
          border: 'border-gray-400',
        },
      },
    };
  } else {
    // orderState === 'nonAccepted'
    return {
      text: '입찰종료',
      themaColor: {
        text: {
          color: 'text-gray-400',
        },
        box: {
          bg: 'bg-gray-400',
          text: 'text-white',
          border: 'border-gray-400',
        },
      },
    };
  }
};

export const parseDealerOrderListDataState = (
  dealerOrderListData: DealerOrderListData,
): {
  /**
   * @example bg-green-900
   */
  text: string;
  textColor: 'text-black' | 'text-white';
  bgColor: string;
} => {
  if (
    dealerOrderListData.state === 'submitted' &&
    dealerOrderListData.isBidded === true
  ) {
    return {
      text: '입찰중',
      textColor: 'text-white',
      bgColor: 'bg-lime-700',
    };
  }

  if (
    dealerOrderListData.state === 'submitted' &&
    dealerOrderListData.isBidded === false
  ) {
    return {
      text: '입찰중',
      textColor: 'text-white',
      bgColor: 'bg-lime-600',
    };
  }

  if (
    dealerOrderListData.state === 'progressing' &&
    dealerOrderListData.isAccepted === true
  ) {
    return {
      text: '진행중',
      textColor: 'text-white',
      bgColor: 'bg-blue-600',
    };
  }

  if (
    dealerOrderListData.state === 'holded' &&
    dealerOrderListData.isAccepted === true
  ) {
    return {
      text: '보류',
      textColor: 'text-white',
      bgColor: 'bg-amber-600',
    };
  }

  if (
    dealerOrderListData.state === 'canceled' &&
    dealerOrderListData.isAccepted === true
  ) {
    return {
      text: '취소',
      textColor: 'text-white',
      bgColor: 'bg-rose-600',
    };
  }

  if (
    dealerOrderListData.state === 'finished' &&
    dealerOrderListData.isAccepted === true
  ) {
    return {
      text: '계약완료',
      textColor: 'text-white',
      bgColor: 'bg-fuchsia-600',
    };
  }

  /** 입찰했으나, 다른 사람이 낙찰됨 */
  if (
    dealerOrderListData.state !== 'submitted' &&
    dealerOrderListData.isAccepted === false &&
    dealerOrderListData.isBidded === true
  ) {
    return {
      text: '유찰',
      textColor: 'text-white',
      bgColor: 'bg-gray-400',
    };
  }

  /** 주문이 시간이 지나 종료됨
   * @notice 입찰 여부 관계 없음
   */
  if (dealerOrderListData.state === 'failBid') {
    return {
      text: '입찰종료',
      textColor: 'text-white',
      bgColor: 'bg-gray-400',
    };
  }

  /** 이거 나오면 에러입니다 */
  return {
    text: '주문진행',
    textColor: 'text-white',
    bgColor: 'bg-yellow-600',
  };
};
