import React from 'react';

interface MyComponentProps {
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
}

const PencilIcon: React.FC<MyComponentProps> = ({
  fill,
  stroke,
  width,
  height,
}) => {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox='0 0 20 20'
      fill={fill || 'none'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.16683 13.3333L3.3335 16.6666L6.66683 15.8333L16.6668 5.83331L14.1668 3.33331L4.16683 13.3333Z'
        stroke={stroke || '#7273F9'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 16.6667H16.6667'
        stroke={stroke || '#7273F9'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export default PencilIcon;
