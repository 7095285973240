import { isLogin } from 'apis/authentication';
import { useLayoutEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useMemberStore from 'store/useMemberStore';
import useSystemStore from 'store/useSystemStore';

export default function AuthIndex() {
  const navigate = useNavigate();
  const { setTopTabbarState, setIsBottomTabbar, setLoadingDim } =
    useSystemStore();
  const { loginMember } = useMemberStore();

  const checkLogin = async () => {
    if (loginMember) {
      navigate('/main', { replace: true });
    } else {
      const {
        data: { isLogined },
      } = await isLogin();
      if (isLogined) navigate('/main', { replace: true });
    }
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: 'back',
    });
    setIsBottomTabbar(false);
    checkLogin();
    return () => {
      setLoadingDim(false);
    };
  }, []);

  return <Outlet />;
}
