/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { X } from 'lucide-react';
import { CarOptionDescription } from '@carsayo/types';

export default function OptionDescription({
  isOpen,
  setIsOpen,
  carOptionDescription,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  carOptionDescription: CarOptionDescription;
}) {
  return (
    <Drawer
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
      }}
    >
      {
        <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
          <DrawerHeader className='relative'>
            <DrawerTitle>옵션 상세보기</DrawerTitle>
            <DrawerClose asChild>
              <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
                <X className='h-7 w-7' />
              </Button>
            </DrawerClose>
          </DrawerHeader>
          <div className='grow overflow-y-auto px-4'>
            <div className='py-4'>
              <div className='text-[16px] font-medium leading-4 tracking-[-0.4px] text-[#111]'>
                {carOptionDescription.name}
              </div>
              {carOptionDescription.content !== '' && (
                <div className='py-2'>{carOptionDescription.content}</div>
              )}
            </div>

            {carOptionDescription.include.length > 0 && (
              <div className='border-t py-4 flex flex-col gap-8'>
                {carOptionDescription.include.map((el, index) => {
                  return (
                    <div key={index}>
                      <div className='py-2 flex flex-col gap-2'>
                        <div className='text-[16px] font-medium leading-4 tracking-[-0.4px] text-[#111]'>
                          {el.name}
                        </div>
                        {el.content !== '' && <div>{el.content}</div>}
                      </div>

                      {el.imgSrc && (
                        <img
                          className='border border-gray-100 rounded-md'
                          src={el.imgSrc}
                        ></img>
                      )}
                    </div>
                  );
                })}
              </div>
            )}

            <div className='pb-8 pt-4 mb-[var(--safeAreaBottomPadding)]'>
              <Button
                onClick={() => {
                  setIsOpen(false);
                }}
                className='rounded'
                variant='default'
                size='lg'
              >
                확인
              </Button>
            </div>
          </div>
        </DrawerContent>
      }
    </Drawer>
  );
}
