import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import { Button } from 'components/ui/button';
import { useLayoutEffect, useState } from 'react';
import useSystemStore from 'store/useSystemStore';
import { getPostList, getPost } from 'apis/board';
import ReactHtmlParser from 'react-html-parser';
import { PostList, PostDetailInfo, GetPostListDTO } from '@carsayo/types';

const takeCount: number = 20;

export default function MypageFaq() {
  const { setTopTabbarState } = useSystemStore();
  const [postList, setPostList] = useState<PostList>();
  const [postDetailInfo, setPostDetailInfo] = useState<{
    [key: number]: PostDetailInfo;
  }>({});

  const [searchDTO, setSearchDTO] = useState<GetPostListDTO>({
    boardKey: 'faq',
    take: takeCount,
    option: {},
    search: null,
  });

  /** 추후 Infinite Scrolling 추가할 때 쓰세요 */
  const [isDataLoadDone, setIsDataLoadDone] = useState<boolean>(false);

  const handleVoidEvents = {
    getPostList: async () => {
      const searchedPostList: PostList = await getPostList(searchDTO);

      if (searchedPostList.searchedPost.length < takeCount) {
        setIsDataLoadDone(true);
      } else {
        setSearchDTO({
          ...searchDTO,
          skip:
            (searchDTO.skip ? searchDTO.skip : 0) +
            searchedPostList.searchedPost.length,
        });
      }

      if (postList) {
        searchedPostList.searchedPost = postList.searchedPost.concat(
          searchedPostList.searchedPost,
        );
      }
      setPostList(searchedPostList);

      return;
    },
    getPost: async (postId: number) => {
      if (postDetailInfo[postId]) return;
      const post: PostDetailInfo = await getPost({
        boardKey: 'faq',
        postId: postId,
      });
      setPostDetailInfo({ ...postDetailInfo, [postId]: post });
    },
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '자주 묻는 질문',
      rightElement: 'home',
      leftElement: 'back',
    });
    handleVoidEvents.getPostList();
  }, []);

  return (
    <div className='px-4 pb-10'>
      {postList &&
        postList.searchedPost.map((el, index) => {
          return (
            <Accordion
              key={`faq-${index}`}
              type='single'
              collapsible
              className='w-full'
            >
              <AccordionItem value={`normalPost-${index}`}>
                <AccordionTrigger
                  onClick={() => handleVoidEvents.getPost(el.id)}
                  className='flex items-center gap-4 border-b border-[#F1F1F5] py-4 text-left'
                >
                  <div>Q.</div>
                  <div className='font-semibold'>{el.title}</div>
                </AccordionTrigger>
                <AccordionContent className='whitespace-pre-line py-5'>
                  {ReactHtmlParser(postDetailInfo?.[el.id]?.content_html)}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          );
        })}

      {postList && !isDataLoadDone && (
        <Button
          onClick={handleVoidEvents.getPostList}
          variant='outline'
          className='mt-8'
        >
          더보기
        </Button>
      )}
    </div>
  );
}
