export const elapsedTime = (date: Date): string => {
  const start = new Date(date);
  const end = new Date();

  const seconds = Math.floor((end.getTime() - start.getTime()) / 1000);

  if (seconds < 60) return '방금 전';
  else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes}분 전`;
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return `${hours}시간 전`;
  } else {
    const days = Math.floor(seconds / 86400);
    return `${days}일 전`;
  }
};

/** 날짜 YYYYMMDD 으로 변환  */
export const dateTranslator = (date: Date) => {
  const transDate = date.toISOString();
  const newDate = new Date(transDate);
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요
  const day = String(newDate.getDate()).padStart(2, '0');

  const translateValue = `${year}${month}${day}`;
  return translateValue;
};
