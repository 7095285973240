import React from 'react';

interface MyComponentProps {
  fill?: string;
  width?: string;
  height?: string;
}

const ApplicationMenuIcon: React.FC<MyComponentProps> = ({
  fill,
  width,
  height,
}) => {
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_2132_8412'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='16'
        height='16'
      >
        <path d='M16 0H0V16H16V0Z' fill='white' />
      </mask>
      <g mask='url(#mask0_2132_8412)'>
        <path
          d='M9.68839 0.696897C8.75994 -0.232299 7.24324 -0.232299 6.31479 0.696897L0 6.87351L7.71065 13.607L16 6.87351L9.68839 0.696897Z'
          fill='#FF8E00'
        />
        <path
          d='M12.3975 1.19972H2.34023C1.90461 1.19972 1.54849 1.55612 1.54849 1.99209V12.4169C1.54849 12.8529 1.90461 13.2093 2.34023 13.2093H13.6566C14.0922 13.2093 14.4483 12.8529 14.4483 12.4169V3.25223L12.3943 1.19653L12.3975 1.19972Z'
          fill='white'
        />
        <path
          d='M9.73927 3.21411H6.20667C5.50426 3.21411 4.93481 3.78401 4.93481 4.48698V8.02239C4.93481 8.72536 5.50426 9.29526 6.20667 9.29526H9.73927C10.4417 9.29526 11.0111 8.72536 11.0111 8.02239V4.48698C11.0111 3.78401 10.4417 3.21411 9.73927 3.21411Z'
          fill='#E1E6EF'
        />
        <path
          d='M9.34499 11.3095C8.42289 11.8186 7.50715 11.8186 6.58824 11.3095L0 6.87354V13.6293C0 14.9308 1.06518 16 2.36884 16H13.6312C14.9348 16 16 14.934 16 13.6293V6.87354L9.34499 11.3095Z'
          fill='#FFD142'
        />
        <path
          d='M1.20507 14.9436C1.15102 14.9436 1.09696 14.9149 1.06835 14.8672C1.02383 14.7908 1.04609 14.6954 1.1224 14.6476L5.67884 11.911C5.75515 11.8664 5.85054 11.8919 5.89823 11.9651C5.94275 12.0414 5.92049 12.1369 5.84418 12.1846L1.28774 14.9213C1.2623 14.9372 1.23369 14.9436 1.20507 14.9436Z'
          fill='#FFBD18'
        />
        <path
          d='M14.709 14.9436C14.6804 14.9436 14.6518 14.9372 14.6264 14.9213L10.0699 12.1846C9.99363 12.1401 9.97137 12.0414 10.0159 11.9651C10.0604 11.8887 10.159 11.8664 10.2353 11.911L14.7917 14.6476C14.868 14.6922 14.8903 14.7908 14.8458 14.8672C14.8172 14.9181 14.7631 14.9436 14.709 14.9436Z'
          fill='#FFBD18'
        />
        <path
          d='M7.8887 8.06684C7.69474 8.06684 7.51032 7.97456 7.38949 7.82181L5.64068 5.81067C5.42447 5.53382 5.47534 5.13287 5.75197 4.91648C6.0286 4.70009 6.42924 4.75101 6.64545 5.02786L7.89506 6.40256L11.0079 3.57997C11.2241 3.30312 11.6248 3.25538 11.9014 3.47495C12.178 3.69134 12.2257 4.0923 12.0063 4.36915L8.39108 7.82818C8.27025 7.98092 8.08583 8.07002 7.89188 8.07002L7.8887 8.06684Z'
          fill='#7273F9'
        />
        <path
          d='M13.186 3.25539C12.7504 3.25539 12.3943 2.89898 12.3943 2.46303V1.19971L14.4483 3.25539H13.186Z'
          fill='#CED5E2'
        />
      </g>
    </svg>
  );
};
export default ApplicationMenuIcon;
