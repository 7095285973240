import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog';

type Props = {
  title: string;
  desc: string;
  isOpen: boolean;
  isCloseButton?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onConfirm?: () => void;
};

export default function Confirm({
  title,
  desc,
  isOpen,
  isCloseButton,
  setIsOpen,
  onConfirm,
}: Props) {
  return (
    <Dialog open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
      <DialogContent className='max-w-[311px] gap-0 border-none p-0'>
        <DialogHeader className='flex min-h-[164px] flex-col items-center justify-center space-y-2 text-center'>
          <DialogTitle className='text-sm font-semibold'>{title}</DialogTitle>
          <div className='text-center text-[#767676] whitespace-pre'>
            {desc}
          </div>
        </DialogHeader>
        <DialogFooter className='h-[48px]'>
          {isCloseButton && (
            <Button
              className='h-[48px] rounded-none border-none bg-[#F7F7FB] text-[#111111]'
              onClick={() => setIsOpen(false)}
            >
              취소
            </Button>
          )}
          <Button
            className='h-[48px] rounded-none border-none bg-[#111111]'
            onClick={() => {
              if (onConfirm) onConfirm();
              setIsOpen(false);
            }}
          >
            확인
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
