import { ChangeEvent, useLayoutEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { cn } from 'utils';
import {
  checkExistPhoneNumber,
  checkPhone,
  checkPhoneAuth,
} from 'apis/authentication';
import { isValidPhoneNumberFormat } from 'utils/telNumber';
import useMemberStore from 'store/useMemberStore';
import useSystemStore from 'store/useSystemStore';
import { api } from 'apis';

/** Dev temp */
const testCustomerLogin =
  process.env.REACT_APP_NODE_ENV === 'development'
    ? {
        '11111111': 'https://dev.be.carsayo.net/member/test/customerlogin',
        '11111112':
          'https://dev.be.carsayo.net/member/test/customerlogin?key=10',
        '11111113':
          'https://dev.be.carsayo.net/member/test/customerlogin?key=20',
        '11111114':
          'https://dev.be.carsayo.net/member/test/customerlogin?key=30',
        '11111115':
          'https://dev.be.carsayo.net/member/test/customerlogin?key=40',
        '11111116':
          'https://dev.be.carsayo.net/member/test/customerlogin?key=50',
        '11111117':
          'https://dev.be.carsayo.net/member/test/customerlogin?key=60',
        '11111118':
          'https://dev.be.carsayo.net/member/test/customerlogin?key=70',
        '11111119':
          'https://dev.be.carsayo.net/member/test/customerlogin?key=80',
      }
    : {};
const testDealerLogin =
  process.env.REACT_APP_NODE_ENV === 'development'
    ? {
        '11111111': 'https://dev.be.carsayo.net/member/test/dealerlogin/newcar',
        '11111112':
          'https://dev.be.carsayo.net/member/test/dealerlogin/newcar?key=10',
        '11111113':
          'https://dev.be.carsayo.net/member/test/dealerlogin/newcar?key=20',
        '22222221':
          'https://dev.be.carsayo.net/member/test/dealerlogin/usedcar',
        '22222222':
          'https://dev.be.carsayo.net/member/test/dealerlogin/usedcar?key=10',
        '22222223':
          'https://dev.be.carsayo.net/member/test/dealerlogin/usedcar?key=20',
        '33333331':
          'https://dev.be.carsayo.net/member/test/dealerlogin/leaseRent',
        '33333332':
          'https://dev.be.carsayo.net/member/test/dealerlogin/leaseRent?key=10',
        '33333333':
          'https://dev.be.carsayo.net/member/test/dealerlogin/leaseRent?key=20',
        '33333334':
          'https://dev.be.carsayo.net/member/test/dealerlogin/leaseRent?key=30',
        '33333335':
          'https://dev.be.carsayo.net/member/test/dealerlogin/leaseRent?key=40',
        '33333336':
          'https://dev.be.carsayo.net/member/test/dealerlogin/leaseRent?key=50',
      }
    : {
        // 여기 구글, 애플 테스트용 계정 강제 로그인 추가합니다!!!
      };
const testProductionDealerLogin =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? {
        '0803308877': 'https://api.carsayo.net/member/test/apple',
        '0807882570': 'https://api.carsayo.net/member/test/google',
        '01015661571': 'https://api.carsayo.net/member/test/carsayo/customer',
      }
    : {};

export default function Login() {
  const [searchParams] = useSearchParams();
  const initTab = searchParams.get('tab');

  const [tab, setTab] = useState<'customer' | 'dealer'>(
    initTab === 'customer'
      ? 'customer'
      : initTab === 'dealer'
        ? 'dealer'
        : 'customer',
  );

  const [inputPhoneNumber, setInputPhoneNumber] = useState('');
  const [isValidPhoneNumber, setValidPhoneNumber] = useState(false);

  const defaultErrorMessage = { phone: '', code: '' };
  const [errorMessage, setErrorMessage] = useState<{
    phone: string;
    code: string;
  }>(defaultErrorMessage);

  const [code, setCode] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [resendActivate, setResendActivate] = useState<boolean>(false);
  const { setLoginMember } = useMemberStore();
  const { setTopTabbarTitle, setLoadingDim } = useSystemStore();
  const navigate = useNavigate();

  const handleVoidEvents = {
    reset: () => {
      setInputPhoneNumber('');
      setValidPhoneNumber(false);
      setCode('');
      setIsSending(false);
      setErrorMessage(defaultErrorMessage);
    },
  };
  const handleClickEvents = {
    /** 인증 문자 보내는거임 */
    checkPhoneNumber: async () => {
      if (tab === 'dealer') {
        if (Object.keys(testDealerLogin).includes(inputPhoneNumber)) {
          const memberData = await api.get<any>(
            (testDealerLogin as any)[inputPhoneNumber],
          );
          setLoginMember(memberData.data);
          navigate('/main', { replace: true });
          return;
        }
        if (Object.keys(testProductionDealerLogin).includes(inputPhoneNumber)) {
          const memberData = await api.get<any>(
            (testProductionDealerLogin as any)[inputPhoneNumber],
          );
          setLoginMember(memberData.data);
          navigate('/main', { replace: true });
        }
      } else {
        if (Object.keys(testCustomerLogin).includes(inputPhoneNumber)) {
          const memberData = await api.get<any>(
            (testCustomerLogin as any)[inputPhoneNumber],
          );
          setLoginMember(memberData.data);
          navigate('/main', { replace: true });
          return;
        }
      }

      setLoadingDim(true);
      const checkPhoneNumberData = await checkExistPhoneNumber({
        phoneNumber: inputPhoneNumber,
      });

      if (checkPhoneNumberData.isMember) {
        if (checkPhoneNumberData.type === tab) {
          setResendActivate(false);
          setErrorMessage(defaultErrorMessage);
          setIsSending(true);
          await checkPhone(inputPhoneNumber, tab).catch(() => {
            setErrorMessage({
              ...errorMessage,
              phone: '메시지 전송에 실패했습니다. 잠시 후 다시 시도해 주세요.',
            });
            setLoadingDim(false);
          });
          setTimeout(() => {
            setResendActivate(true);
          }, 8000);
        } else if (checkPhoneNumberData.type === 'customer') {
          setErrorMessage({
            ...errorMessage,
            phone: '일반회원으로 가입된 전화번호입니다.',
          });
        } else {
          setErrorMessage({
            ...errorMessage,
            phone: '카매니저로 가입된 전화번호입니다.',
          });
        }
      } else {
        setErrorMessage({
          ...errorMessage,
          phone: '등록된 전화번호가 아닙니다.',
        });
      }
      setLoadingDim(false);
    },
    checkAuthCode: async () => {
      await checkPhoneAuth({
        code: code,
        phoneNumber: inputPhoneNumber,
        type: tab,
      })
        .then((el) => {
          setLoginMember(el);
          navigate('/main');
        })
        .catch(() => {
          setErrorMessage({
            ...errorMessage,
            code: '인증 번호가 일치하지 않습니다. 다시 입력해 주세요.',
          });
        });
    },
  };
  const handleChangeEvents = {
    phoneNumber: (event: ChangeEvent<HTMLInputElement>) => {
      const isValid = isValidPhoneNumberFormat(event.target.value);
      setInputPhoneNumber(event.target.value);
      setValidPhoneNumber(isValid);
    },
  };

  useLayoutEffect(() => {
    setTopTabbarTitle('로그인');
  }, []);

  return (
    <div className='flex min-h-full grow flex-col p-4 pb-10'>
      <div className='grid grid-cols-2 gap-2'>
        <Button
          size='lg'
          variant={tab === 'customer' ? 'default' : 'outline'}
          className='rounded-none'
          onClick={() => {
            if (tab === 'customer') return;
            handleVoidEvents.reset();
            setTab('customer');
          }}
        >
          일반 회원
        </Button>
        <Button
          size='lg'
          variant={tab === 'dealer' ? 'default' : 'outline'}
          className='rounded-none'
          onClick={() => {
            if (tab === 'dealer') return;
            handleVoidEvents.reset();
            setTab('dealer');
          }}
        >
          카 매니저
        </Button>
      </div>

      <div className='mt-6 text-lg font-semibold'>
        휴대폰 번호를 인증해주세요.
      </div>
      <div className='mt-2 text-desc text-[#767676]'>
        카사요는 휴대폰 번호로 가입 및 로그인해요. 번호는 안전하게 보관되며
        어디에도 공개되지 않습니다.
      </div>
      <Input
        type='number'
        value={inputPhoneNumber}
        disabled={isSending}
        onChange={handleChangeEvents.phoneNumber}
        className='mt-4'
        placeholder='휴대폰 번호를 입력해주세요'
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.keyCode === 13) {
            if (isValidPhoneNumber) handleClickEvents.checkPhoneNumber();
          }
        }}
      />
      {errorMessage.phone && (
        <div className='text-[13px] leading-[18px] tracking-[-0.325px] text-[#DC0000] pt-2'>
          {errorMessage.phone}
        </div>
      )}
      <Button
        className={cn(isSending ? 'mt-6' : 'mt-auto')}
        disabled={
          (!(
            (tab === 'dealer' &&
              Object.keys(testDealerLogin).includes(inputPhoneNumber)) ||
            (tab === 'dealer' &&
              Object.keys(testProductionDealerLogin).includes(
                inputPhoneNumber,
              )) ||
            (tab === 'customer' &&
              Object.keys(testCustomerLogin).includes(inputPhoneNumber))
          ) &&
            !isValidPhoneNumber) ||
          (isSending && !resendActivate)
        }
        onClick={handleClickEvents.checkPhoneNumber}
      >
        {isSending ? '인증 문자 다시 받기' : '인증 문자 받기'}
      </Button>

      {isSending && (
        <>
          <Input
            type='number'
            value={code}
            autoComplete='one-time-code'
            onChange={(e) => {
              if (e.target.value.length < 7) setCode(e.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.keyCode === 13) {
                if (code.length === 6) handleClickEvents.checkAuthCode();
              }
            }}
            className='mt-10'
            placeholder='인증 번호를 입력해주세요'
          />
          <div className='mt-2 text-[#767676]'>
            어떠한 경우에도 타인에게 공유하지 마세요!
          </div>
          {errorMessage.code && (
            <div className='text-[13px] leading-[18px] tracking-[-0.325px] text-[#DC0000] pt-2'>
              {errorMessage.code}
            </div>
          )}
          <div className='flex'>
            <Button
              className='mt-6'
              disabled={code.length !== 6}
              onClick={handleClickEvents.checkAuthCode}
            >
              인증 번호 확인
            </Button>
          </div>
          <div
            onClick={handleClickEvents.checkPhoneNumber}
            className='mt-6 text-center text-xs underline underline-offset-4'
          >
            인증 번호가 오지 않나요?
          </div>
        </>
      )}
    </div>
  );
}
