import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { useLayoutEffect, useState } from 'react';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { Input } from 'components/ui/input';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Link, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import useMemberStore from 'store/useMemberStore';
import { updateNickname } from 'apis/setting';
import { parseStringPhoneNumber } from 'utils/textConvert';
import Confirm from 'components/ui/confirm';
import { logoutApi } from 'apis/authentication';
import { getPostList } from 'apis/board';
import dayjs from 'dayjs';
import EditIcon from 'assets/images/v2/edit.svg';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { useQueryClient } from '@tanstack/react-query';
import { PostList } from '@carsayo/types';

const passwordPattern = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\s|]+$/;
const formSchema = z.object({
  nickname: z
    .string()
    .min(1, {
      message: '닉네임을 입력해주세요',
    })
    .max(13, {
      message: '닉네임 길이는 최대 10자리 입니다',
    })
    .regex(passwordPattern, {
      message: '특수문자는 사용하실 수 없습니다',
    }),
});
function EditDrawer({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) {
  const { setLoginMember } = useMemberStore();
  const { setLoadingDim } = useSystemStore();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      nickname: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setLoadingDim(true);

    await updateNickname({
      nickName: data.nickname,
    })
      .then((member) => {
        setLoginMember(member);
        setLoadingDim(false);
        setIsOpen(false);
      })
      .catch((e) => {
        form.setError('nickname', { message: e });
        setLoadingDim(false);
      });
  };
  return (
    <Drawer open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
      <DrawerContent>
        <div className='mx-auto w-full max-w-sm'>
          <DrawerHeader className='relative'>
            <DrawerTitle>닉네임 변경</DrawerTitle>
            <DrawerClose asChild>
              <Button className='absolute right-5 top-[14px] h-7 w-7 border-0 bg-transparent p-0'>
                <img src='/assets/images/v2/close.svg' alt='' />
              </Button>
            </DrawerClose>
          </DrawerHeader>
          <div className='p-4 pb-10'>
            <Form {...form}>
              <form
                id='nicknameForm'
                onSubmit={form.handleSubmit(onSubmit)}
                className='space-y-6'
              >
                <FormField
                  control={form.control}
                  name='nickname'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>닉네임</FormLabel>
                      <FormControl>
                        <Input placeholder='닉네임 입력' {...field}></Input>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button type='submit' form='nicknameForm'>
                  변경하기
                </Button>
              </form>
            </Form>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default function MypageMain() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLogoutOpen, setIsLogoutOpen] = useState(false);
  const { setLoadingDim, setTopTabbarState } = useSystemStore();
  const { loginMember, resetLoginMember } = useMemberStore();

  const [isNewNotice, setIsNewNotice] = useState<boolean>();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const handleClickEvents = {
    call: (callNumber: string) => {
      const telUrl = 'tel:' + callNumber;
      window.location.href = telUrl;
    },
    email: (address: string) => {
      const telUrl = 'mailto:' + address;
      window.location.href = telUrl;
    },
    logout: async () => {
      setLoadingDim(true);
      try {
        await logoutApi();
        queryClient.resetQueries();
        resetLoginMember();
        setLoadingDim(false);
        navigate('/main');
      } catch (e) {
        CarsayoToast.error(`${e}`);
        setLoadingDim(false);
      }
    },
  };

  const handleVoidEvents = {
    getNewestNotice: async () => {
      const searchedPostList: PostList = await getPostList({
        boardKey: 'notice',
        skip: 0,
        take: 1,
        option: null,
        search: null,
      });
      if (searchedPostList.searchedPost[0]) {
        const checkedNotice: string | null =
          localStorage.getItem('checkedNotice');
        if (checkedNotice === String(searchedPostList.searchedPost[0].id))
          return;

        if (
          dayjs(searchedPostList.searchedPost[0].created_at).isAfter(
            dayjs().add(-7, 'day'),
          )
        ) {
          setIsNewNotice(true);
        }
      }

      return;
    },
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '마이페이지',
      rightElement: 'home',
      leftElement: 'back',
    });
    // setTopTabbarState({
    //   state: 'hidden',
    //   mode: 'subpage',
    //   rightElement: 'home',
    //   leftElement: 'back',
    // });

    handleVoidEvents.getNewestNotice();
  }, []);

  return (
    <div className='bg-[#F1F1F5]'>
      <div className='inset-0 bottom-auto z-10 bg-white p-4 shadow-[0_5px_10px_0_rgba(0,0,0,.6)]'>
        <div className='flex items-center justify-between'>
          <div
            className='flex items-center gap-2 text-lg font-semibold'
            onClick={() => setIsOpen(true)}
          >
            {loginMember?.name_nick}
            <img src={EditIcon} alt='' className='object-contain' />
          </div>
        </div>
        <div className='mt-4 flex gap-3'>
          <div className='flex h-10 w-10 items-center justify-center overflow-hidden rounded-full'>
            <img
              src={loginMember?.profileImage_url}
              alt=''
              className='h-10 w-10 object-cover'
            />
          </div>
          <div>
            <Link to='/mypage/info' className='flex items-center font-semibold'>
              {loginMember?.name_real
                ? loginMember.name_real
                : loginMember?.name_nick}
              님
              <div className='flex h-3 w-3 items-center justify-center'>
                <img
                  src='/assets/images/v2/chevronRight.svg'
                  alt=''
                  className='inline-block'
                />
              </div>
            </Link>
            <div className='mt-0.5 text-desc'>
              {loginMember?.email
                ? loginMember.email
                : loginMember?.phoneNumber
                  ? parseStringPhoneNumber(loginMember.phoneNumber)
                  : ''}
            </div>
          </div>
          {loginMember?.dealerInfo && (
            <div className='ml-auto'>
              {loginMember.dealerInfo.signupState === 'accepted' && (
                <div className='flex h-5 items-center border border-[#04B014] px-1 text-[11px] text-[#04B014]'>
                  승인 완료
                </div>
              )}
              {loginMember.dealerInfo.signupState === 'rejected' && (
                <div className='flex h-5 items-center border border-[#ff0000] px-1 text-[11px] text-[#ff0000]'>
                  승인 거부
                </div>
              )}
              {loginMember.dealerInfo.signupState === 'submitted' && (
                <div className='flex h-5 items-center border border-[#000000] px-1 text-[11px] text-[#000000]'>
                  승인 대기
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className='bg-white p-4'>
        <div className='h-[50px] leading-[50px] text-[#555555]'>정보 설정</div>
        <Link to='/mypage/info' className='flex h-[52px] items-center'>
          <div className='flex items-center gap-1.5'>
            <div className='text-desc font-semibold'>내 정보</div>
          </div>
          <div className='ml-auto flex h-5 w-5 items-center justify-center'>
            <img src='/assets/images/v2/arrowRightMy.svg' alt='' />
          </div>
        </Link>
        <Link to='/mypage/alert' className='flex h-[52px] items-center'>
          <div className='flex items-center gap-1.5'>
            <div className='text-desc font-semibold'>알림 설정</div>
          </div>
          <div className='ml-auto flex h-5 w-5 items-center justify-center'>
            <img src='/assets/images/v2/arrowRightMy.svg' alt='' />
          </div>
        </Link>
        {loginMember?.type === 'customer' && (
          <Link to='/mypage/payment' className='flex h-[52px] items-center'>
            <div className='flex items-center gap-1.5'>
              <div className='text-desc font-semibold'>결제 내역</div>
            </div>
            <div className='ml-auto flex h-5 w-5 items-center justify-center'>
              <img src='/assets/images/v2/arrowRightMy.svg' alt='' />
            </div>
          </Link>
        )}
        {loginMember?.dealerInfo && (
          <>
            {' '}
            <Link
              to='/mypage/commission'
              className='flex h-[52px] items-center'
            >
              <div className='flex items-center gap-1.5'>
                <div className='text-desc font-semibold'>수수료 정산</div>
              </div>
              <div className='ml-auto flex h-5 w-5 items-center justify-center'>
                <img src='/assets/images/v2/arrowRightMy.svg' alt='' />
              </div>
            </Link>
            <Link
              to='/mypage/dealerRegionBrand'
              className='flex h-[52px] items-center'
            >
              <div className='flex items-center gap-1.5'>
                <div className='text-desc font-semibold'>카매니저 설정</div>
              </div>
              <div className='ml-auto flex h-5 w-5 items-center justify-center'>
                <img src='/assets/images/v2/arrowRightMy.svg' alt='' />
              </div>
            </Link>
          </>
        )}
      </div>
      <div className='mt-3 bg-white p-4'>
        <div className='h-[50px] leading-[50px] text-[#555555]'>고객지원</div>
        <Link to='/mypage/notice' className='flex h-[52px] items-center'>
          <div className='flex items-center gap-1.5'>
            <div className='text-desc font-semibold'>공지사항</div>
            {isNewNotice && (
              <div className='h-2 w-2 rounded-full bg-destructive'></div>
            )}
          </div>
          <div className='ml-auto flex h-5 w-5 items-center justify-center'>
            <img src='/assets/images/v2/arrowRightMy.svg' alt='' />
          </div>
        </Link>
        <Link to='/mypage/faq' className='flex h-[52px] items-center'>
          <div className='flex items-center gap-1.5'>
            <div className='text-desc font-semibold'>자주 묻는 질문</div>
          </div>
          <div className='ml-auto flex h-5 w-5 items-center justify-center'>
            <img src='/assets/images/v2/arrowRightMy.svg' alt='' />
          </div>
        </Link>
        <Link to='/mypage/termList' className='flex h-[52px] items-center'>
          <div className='flex items-center gap-1.5'>
            <div className='text-desc font-semibold'>약관 / 정책</div>
          </div>
          <div className='ml-auto flex h-5 w-5 items-center justify-center'>
            <img src='/assets/images/v2/arrowRightMy.svg' alt='' />
          </div>
        </Link>
      </div>
      <div className='mt-3 bg-white p-4'>
        <div className='mt-4 text-desc text-[#111111]'>
          불편 신고 및 고객 지원 센터
        </div>
        <div className='mt-2 text-xs text-[#767676]'>
          상담시간 -
          <span className=' text-xs text-[#111111] ml-[3px] leading-5'>
            평일 10:00~18:00
            <br /> (점심시간 : 13:00~14:00 / 주말 및 공휴일 휴무)
          </span>
        </div>
        <div className='my-4 grid grid-cols-2 gap-4'>
          <Button
            onClick={() => handleClickEvents.call('16681571')}
            variant='outline'
          >
            1668-1571
          </Button>
          <Button
            onClick={() => handleClickEvents.email('info@carsayo.net')}
            variant='outline'
          >
            1:1 문의
          </Button>
        </div>
      </div>
      <div className='mt-3 bg-white p-4 mb-8'>
        <div className='mt-4 flex'>
          <div className='text-[#555555]'>
            앱 버전 <span className='text-[#111111]'>2.0.00</span>
          </div>
          <div className='ml-auto text-[#111111]'>최신 버전입니다.</div>
        </div>
        <Button
          onClick={() => {
            setIsLogoutOpen(true);
          }}
          variant='gray'
          className='mt-6 font-normal'
        >
          로그아웃
        </Button>
      </div>
      <EditDrawer isOpen={isOpen} setIsOpen={setIsOpen}></EditDrawer>
      <Confirm
        isOpen={isLogoutOpen}
        setIsOpen={setIsLogoutOpen}
        isCloseButton={true}
        onConfirm={handleClickEvents.logout}
        title='카사요 로그아웃'
        desc={`정말 로그아웃 하시겠습니까?`}
      ></Confirm>
    </div>
  );
}
