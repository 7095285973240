/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useHistoryStore from 'store/useHistoryStore';

const InitRestore = () => {
  const { history, addHistory, moveHistory } = useHistoryStore();

  const location = useLocation();

  const targetIndex = history.findIndex((el) => {
    return el.key === location.key;
  });

  useLayoutEffect(() => {
    if (history.length === 0 || targetIndex === -1) {
      addHistory(location);
    } else {
      moveHistory(targetIndex);
    }
  }, [location.pathname]);

  return <></>;
};

export default InitRestore;
