import usePurchaseStore from '../store';
import { useEffect, useLayoutEffect, useState } from 'react';
import dayjs from 'dayjs';
import CustomizedSelects, {
  CustomizedMenuItems,
} from '../../../../components/ui/select_v2';
import { Button } from '../../../../components/ui/button';
import { Calendar, ChevronDown } from 'lucide-react';
import { cn } from '../../../../utils';
import { SelectSigungu } from '../../../../components/view/buy/selectSigungu';
import CarsayoBottomSheet from '../../../../components/common/CarsayoBottomSheet';
import DatePicker from 'react-mobile-datepicker-ts';
import { Controller, useForm } from 'react-hook-form';
import { getRegion } from '../../../../utils/getSidogungu';
import { CarsayoToast } from '../../../../components/common/v2/CarsayoToast';
import { useNavigate } from 'react-router-dom';
import { PriceDetail } from './priceDetail';
import { HalbooFormValues } from './HalbooForm';
import {
  BuyerType,
  DutyFreeCondition,
  SidoData,
  SigunguData,
} from '@carsayo/types';

export type IlsibullFormValues = {
  purchaseDateType: 'input' | 'null';
  purchaseDate: string | null | undefined;
  buyerType: BuyerType | '';
  deliveryRegion: string;
  residenceRegion: string;
  dutyFreeCondition: DutyFreeCondition | '';
};

export function IlsibullForm({ purchasePrice }: { purchasePrice: number }) {
  const { purchaseOrderDTO, setPurchaseOrderDTO } = usePurchaseStore();
  const [isOpen, setIsOpen] = useState(false);
  const [target, setTarget] = useState<'거주' | '인수'>('거주');

  const [isDatePickerOpen, setDatePickerOpen] = useState(false);

  const navigate = useNavigate();
  const { control, handleSubmit, getValues, setValue, formState } =
    useForm<IlsibullFormValues>({
      mode: 'onSubmit',
      criteriaMode: 'firstError',
      shouldFocusError: true,
      defaultValues: {
        purchaseDateType:
          purchaseOrderDTO?.orderData &&
          (purchaseOrderDTO.orderData.additionalInfo.일시불.purchaseDate ===
            null ||
            purchaseOrderDTO.orderData.additionalInfo.일시불.purchaseDate ===
              undefined)
            ? 'null'
            : 'input',
        purchaseDate:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.일시불.purchaseDate !==
            null &&
          purchaseOrderDTO.orderData.additionalInfo.일시불.purchaseDate !==
            'null' &&
          purchaseOrderDTO.orderData.additionalInfo.일시불.purchaseDate !==
            'input'
            ? purchaseOrderDTO.orderData.additionalInfo.일시불.purchaseDate
            : null,
        buyerType:
          purchaseOrderDTO?.orderData?.additionalInfo.일시불.buyerType !==
          undefined
            ? (String(
                purchaseOrderDTO.orderData.additionalInfo.일시불.buyerType,
              ) as BuyerType)
            : 'individual',
        dutyFreeCondition:
          purchaseOrderDTO?.orderData?.additionalInfo.일시불
            .dutyFreeCondition !== undefined
            ? (String(
                purchaseOrderDTO.orderData.additionalInfo.일시불
                  .dutyFreeCondition,
              ) as DutyFreeCondition)
            : '',
        deliveryRegion: getRegion(
          purchaseOrderDTO?.orderData?.additionalInfo.일시불
            .residenceRegion as number,
        ),
        residenceRegion: getRegion(
          purchaseOrderDTO?.orderData?.additionalInfo.일시불
            .deliveryRegion as number,
        ),
      },
    });

  const confirm = (data: IlsibullFormValues) => {
    if (purchaseOrderDTO && purchaseOrderDTO.orderData) {
      const currentAdditionalInfo = purchaseOrderDTO.orderData.additionalInfo;
      if (currentAdditionalInfo.method === '일시불') {
        if (
          currentAdditionalInfo.일시불.deliveryRegion === undefined ||
          currentAdditionalInfo.일시불.residenceRegion === undefined ||
          currentAdditionalInfo.일시불.dutyFreeCondition === undefined ||
          currentAdditionalInfo.일시불.buyerType === undefined ||
          currentAdditionalInfo.일시불.purchaseDate === undefined ||
          currentAdditionalInfo.일시불.purchaseDate === ''
        ) {
          CarsayoToast.error('아직 모든 내용이 입력되지 않았습니다.');
          return;
        }
      }
    }

    navigate('/purchase/estimate', { replace: true });
    return;
  };

  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const setDefaultValue = function async() {
    if (!purchaseOrderDTO || !purchaseOrderDTO.orderData) return;

    let isUpdate = false;
    const updateData = {
      ...purchaseOrderDTO.orderData.additionalInfo.일시불,
    };

    if (
      purchaseOrderDTO?.orderData?.additionalInfo.일시불.purchaseDate ===
      undefined
    ) {
      isUpdate = true;
      updateData.purchaseDate = null;
    }

    if (
      purchaseOrderDTO?.orderData?.additionalInfo.일시불.buyerType === undefined
    ) {
      isUpdate = true;
      updateData.buyerType = 'individual';
    }

    if (isUpdate) {
      setPurchaseOrderDTO({
        id: purchaseOrderDTO.id,
        type: purchaseOrderDTO.type,
        update: {
          additionalInfo: {
            ...purchaseOrderDTO.orderData.additionalInfo,
            일시불: {
              ...purchaseOrderDTO.orderData.additionalInfo.일시불,
              ...updateData,
            },
          },
        },
      });
    }
  };

  const goBack = () => {
    navigate('/purchase/option', { replace: true });
  };

  const handleInputChange = (date: Date) => {
    const formatDate = dayjs(date).format('YYYYMMDD');
    if (!purchaseOrderDTO?.orderData) return;

    setPurchaseOrderDTO({
      id: purchaseOrderDTO.id,
      type: purchaseOrderDTO.type,
      update: {
        additionalInfo: {
          ...purchaseOrderDTO.orderData.additionalInfo,
          일시불: {
            ...purchaseOrderDTO.orderData.additionalInfo.일시불,
            purchaseDate: formatDate,
          },
        },
      },
    });
  };

  useEffect(() => {
    const errorObj = Object.keys(
      formState.errors,
    ) as (keyof HalbooFormValues)[];
    const firstErrorKey = errorObj[0];
    const errorElement = document.querySelector(`[name=${firstErrorKey}]`);
    if (errorElement) {
      if (errorElement.getAttribute('type') !== 'hidden') {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        if (errorElement.nextElementSibling) {
          errorElement.nextElementSibling.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }
    }
  }, [formState.errors]);

  useLayoutEffect(() => {
    setDefaultValue();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(confirm)}>
        <div>
          <div className='mt-4 space-y-4 px-4 pb-6'>
            {/* 구입 시기 선택 */}
            <div>
              <div>구입 시기</div>
              <div className='flex mt-2'>
                <Controller
                  name='purchaseDateType'
                  control={control}
                  render={({ field, fieldState, formState }) => (
                    <CustomizedSelects
                      value={field.value}
                      name={'purchaseDateType'}
                      onValueChange={async (value) => {
                        // useForm setValue
                        setValue(
                          'purchaseDateType',
                          value.toString() as 'input' | 'null',
                          {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true,
                          },
                        );
                        if (value === 'null') {
                          setValue('purchaseDate', null, {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true,
                          });
                        }
                        if (!purchaseOrderDTO?.orderData) return;
                        setPurchaseOrderDTO({
                          id: purchaseOrderDTO.id,
                          type: purchaseOrderDTO.type,
                          update: {
                            additionalInfo: {
                              ...purchaseOrderDTO.orderData.additionalInfo,
                              일시불: {
                                ...purchaseOrderDTO.orderData.additionalInfo
                                  .일시불,
                                purchaseDate: value === 'null' ? null : '',
                              },
                            },
                          },
                        });
                      }}
                      placeholder='선택해주세요'
                    >
                      <CustomizedMenuItems value='null'>
                        1개월 이내
                      </CustomizedMenuItems>
                      <CustomizedMenuItems value='input'>
                        직접 선택
                      </CustomizedMenuItems>
                    </CustomizedSelects>
                  )}
                />
                <Controller
                  control={control}
                  name='purchaseDate'
                  rules={{
                    required: getValues('purchaseDateType') !== 'null',
                  }}
                  render={({ field, fieldState, formState }) => (
                    <Button
                      disabled={getValues('purchaseDateType') === 'null'}
                      onClick={() => {
                        setDatePickerOpen(true);
                      }}
                      name='purchaseDate'
                      className={cn(
                        'flex px-4 rounded-none text-black font-normal ml-4 h-[52px] w-full border border-input bg-background py-2 outline-none ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:border-[#111111] disabled:cursor-not-allowed disabled:opacity-50 text-sm placeholder:text-sm',
                        getValues('purchaseDateType') !== 'null' &&
                          formState.errors.purchaseDate
                          ? 'border-[#DC0000] focus:border-[#DC0000]'
                          : '',
                      )}
                    >
                      {purchaseOrderDTO?.orderData?.additionalInfo.일시불
                        .purchaseDate
                        ? dayjs(
                            purchaseOrderDTO.orderData.additionalInfo.일시불
                              .purchaseDate,
                          ).format('YYYY년 MM월 DD일')
                        : '미선택'}
                      <div className='flex-auto'></div>
                      <Calendar className='flex-none w-5 h-5 text-gray-500' />
                    </Button>
                  )}
                />
              </div>
            </div>

            <div>
              <div>구입 주체</div>
              <div className='mt-2'>
                <Controller
                  control={control}
                  name='buyerType'
                  rules={{ required: true }}
                  render={({ field, fieldState, formState }) => (
                    <CustomizedSelects
                      value={field.value}
                      name={'buyerType'}
                      classes={
                        formState.errors.buyerType
                          ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                          : ''
                      }
                      onValueChange={async (value) => {
                        // useForm setValue
                        setValue('buyerType', value as BuyerType, {
                          shouldValidate: true,
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                        if (!purchaseOrderDTO?.orderData) return;
                        setPurchaseOrderDTO({
                          id: purchaseOrderDTO.id,
                          type: purchaseOrderDTO.type,
                          update: {
                            additionalInfo: {
                              ...purchaseOrderDTO.orderData.additionalInfo,
                              일시불: {
                                ...purchaseOrderDTO.orderData.additionalInfo
                                  .일시불,
                                buyerType: value as BuyerType,
                              },
                            },
                          },
                        });
                      }}
                      placeholder=''
                    >
                      <CustomizedMenuItems value='individual'>
                        개인
                      </CustomizedMenuItems>
                      <CustomizedMenuItems value='sole_proprietor'>
                        개인사업자
                      </CustomizedMenuItems>
                      <CustomizedMenuItems value='corporation'>
                        법인사업자
                      </CustomizedMenuItems>
                    </CustomizedSelects>
                  )}
                />
              </div>
            </div>

            <div>
              <div>거주(등록) 지역</div>
              <div className='mt-2'>
                <Controller
                  control={control}
                  name='residenceRegion'
                  rules={{ required: true }}
                  render={({ field, fieldState, formState }) => (
                    <div
                      className={cn(
                        'flex h-[52px] items-center border border-input px-3 py-2 text-sm text-[#555555]',
                        formState.errors.residenceRegion
                          ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                          : '',
                      )}
                      onClick={() => {
                        setTarget('거주');
                        setIsOpen(true);
                      }}
                    >
                      <input
                        type='hidden'
                        className='w-1 h-1'
                        value={field.value}
                        name='residenceRegion'
                      />
                      {purchaseOrderDTO?.orderData?.additionalInfo.일시불
                        .residenceRegion ? (
                        <div>
                          {purchaseOrderDTO?.orderData?.additionalInfo.일시불
                            .residenceRegion !== undefined &&
                            SidoData.find((el1) => {
                              return (
                                purchaseOrderDTO.orderData &&
                                el1.id ===
                                  (purchaseOrderDTO?.orderData?.additionalInfo
                                    .일시불.residenceRegion !== undefined &&
                                    SigunguData.find((el) => {
                                      return (
                                        purchaseOrderDTO.orderData &&
                                        el.id ===
                                          purchaseOrderDTO.orderData
                                            .additionalInfo.일시불
                                            .residenceRegion
                                      );
                                    })?.sidoId)
                              );
                            })?.name}{' '}
                          {purchaseOrderDTO?.orderData?.additionalInfo.일시불
                            .residenceRegion !== undefined &&
                            SigunguData.find((el) => {
                              return (
                                purchaseOrderDTO.orderData &&
                                el.id ===
                                  purchaseOrderDTO.orderData.additionalInfo
                                    .일시불.residenceRegion
                              );
                            })?.name}
                        </div>
                      ) : (
                        <div>선택해 주세요</div>
                      )}
                      <ChevronDown className='ml-auto h-5 w-5 text-[#111111]' />
                    </div>
                  )}
                />
              </div>
            </div>
            <div>
              <div>인수(탁송) 지역</div>
              <div className='mt-2'>
                <Controller
                  control={control}
                  name='deliveryRegion'
                  rules={{ required: true }}
                  render={({ field, fieldState, formState }) => (
                    <div
                      className={cn(
                        'flex h-[52px] items-center border border-input px-3 py-2 text-sm text-[#555555]',
                        formState.errors.deliveryRegion
                          ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                          : '',
                      )}
                      onClick={() => {
                        setTarget('인수');
                        setIsOpen(true);
                      }}
                    >
                      {/* useForm 시용 위한 비노출 input */}
                      <input
                        type='hidden'
                        value={field.value}
                        className='w-1 h-1'
                        name='deliveryRegion'
                      />
                      {purchaseOrderDTO?.orderData?.additionalInfo.일시불
                        .deliveryRegion ? (
                        <div>
                          {purchaseOrderDTO?.orderData?.additionalInfo.일시불
                            .deliveryRegion !== undefined &&
                            SidoData.find((el1) => {
                              return (
                                purchaseOrderDTO.orderData &&
                                el1.id ===
                                  (purchaseOrderDTO?.orderData?.additionalInfo
                                    .일시불.deliveryRegion !== undefined &&
                                    SigunguData.find((el) => {
                                      return (
                                        purchaseOrderDTO.orderData &&
                                        el.id ===
                                          purchaseOrderDTO.orderData
                                            .additionalInfo.일시불
                                            .deliveryRegion
                                      );
                                    })?.sidoId)
                              );
                            })?.name}{' '}
                          {purchaseOrderDTO?.orderData?.additionalInfo.일시불
                            .deliveryRegion !== undefined &&
                            SigunguData.find((el) => {
                              return (
                                purchaseOrderDTO.orderData &&
                                el.id ===
                                  purchaseOrderDTO.orderData.additionalInfo
                                    .일시불.deliveryRegion
                              );
                            })?.name}
                        </div>
                      ) : (
                        <div>선택해 주세요</div>
                      )}
                      <ChevronDown className='ml-auto h-5 w-5 text-[#111111]' />
                    </div>
                  )}
                />
              </div>
            </div>

            <div>
              <div>면세 조건</div>
              <div className='mt-2'>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name='dutyFreeCondition'
                  render={({ field, fieldState, formState }) => (
                    <CustomizedSelects
                      value={field.value}
                      name={'dutyFreeCondition'}
                      classes={
                        formState.errors.dutyFreeCondition
                          ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                          : ''
                      }
                      onValueChange={async (value) => {
                        if (!purchaseOrderDTO?.orderData) return;
                        // useForm setValue
                        setValue(
                          'dutyFreeCondition',
                          value as DutyFreeCondition,
                          {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true,
                          },
                        );
                        setPurchaseOrderDTO({
                          id: purchaseOrderDTO.id,
                          type: purchaseOrderDTO.type,
                          update: {
                            additionalInfo: {
                              ...purchaseOrderDTO.orderData.additionalInfo,
                              일시불: {
                                ...purchaseOrderDTO.orderData.additionalInfo
                                  .일시불,
                                dutyFreeCondition: value as DutyFreeCondition,
                              },
                            },
                          },
                        });
                      }}
                      placeholder='선택해주세요'
                    >
                      <CustomizedMenuItems value='normal'>
                        일반인 (해당 없음)
                      </CustomizedMenuItems>
                      <CustomizedMenuItems value='manyChild'>
                        다자녀 (취득세 감면)
                      </CustomizedMenuItems>
                      <CustomizedMenuItems value='isSevereDisability'>
                        중증 장애 (1~3급)
                      </CustomizedMenuItems>
                      <CustomizedMenuItems value='isMildDisability'>
                        경증 장애 (시각 4급)
                      </CustomizedMenuItems>
                      <CustomizedMenuItems value='isVisualDisability'>
                        시각 장애 (일반 4급)
                      </CustomizedMenuItems>
                      <CustomizedMenuItems value='isNationalMerit'>
                        국가유공자
                      </CustomizedMenuItems>
                      <CustomizedMenuItems value='isAgentOrangeDisabled'>
                        장애인 (고엽제)
                      </CustomizedMenuItems>
                      <CustomizedMenuItems value='isMay18Disabled'>
                        장애인 (5.18 민주화)
                      </CustomizedMenuItems>
                    </CustomizedSelects>
                  )}
                />
              </div>
            </div>

            <SelectSigungu
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              setRegion={(value) => {
                if (!purchaseOrderDTO?.orderData) return;
                // useForm setValue
                setValue(
                  target === '거주' ? 'residenceRegion' : 'deliveryRegion',
                  getRegion(value),
                  {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  },
                );

                if (target === '거주') {
                  setPurchaseOrderDTO({
                    id: purchaseOrderDTO.id,
                    type: purchaseOrderDTO.type,
                    update: {
                      additionalInfo: {
                        ...purchaseOrderDTO.orderData.additionalInfo,
                        일시불: {
                          ...purchaseOrderDTO.orderData.additionalInfo.일시불,
                          residenceRegion: value,
                        },
                      },
                    },
                  });
                } else {
                  setPurchaseOrderDTO({
                    id: purchaseOrderDTO.id,
                    type: purchaseOrderDTO.type,
                    update: {
                      additionalInfo: {
                        ...purchaseOrderDTO.orderData.additionalInfo,
                        일시불: {
                          ...purchaseOrderDTO.orderData.additionalInfo.일시불,
                          deliveryRegion: value,
                        },
                      },
                    },
                  });
                }
              }}
            />

            {/* 구입 시기 DatePicker */}
            <CarsayoBottomSheet
              isOpen={isDatePickerOpen}
              onClose={() => {
                setDatePickerOpen(false);
                setValue(
                  'purchaseDate',
                  dayjs(
                    purchaseOrderDTO?.orderData?.additionalInfo.일시불
                      .purchaseDate,
                  ).format('YYYYMMDD'),
                  {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  },
                );
              }}
            >
              <DatePicker
                isOpen={isDatePickerOpen}
                min={dayjs().subtract(1, 'year').startOf('day').toDate()}
                max={dayjs().add(20, 'year').endOf('day').toDate()}
                onChange={handleInputChange}
                showHeader={false}
                showFooter={false}
                value={
                  purchaseOrderDTO?.orderData?.additionalInfo?.일시불
                    ?.purchaseDate
                    ? dayjs(
                        purchaseOrderDTO?.orderData?.additionalInfo?.일시불
                          ?.purchaseDate,
                      ).toDate()
                    : new Date()
                }
              />
            </CarsayoBottomSheet>
          </div>
          <div className='sticky bottom-0 w-full h-full flex-none bg-[#F7F7FB] px-4 py-6'>
            {purchasePrice > 0 && (
              <div className='flex items-center justify-between'>
                <div className='text-[#555555]'>총 예상 금액</div>
                <div
                  onClick={() => setIsDetailOpen(true)}
                  className='flex items-center gap-2 font-semibold'
                >
                  {(
                    Number(purchaseOrderDTO?.orderData?.carGrade?.price) +
                    Number(
                      purchaseOrderDTO?.orderData?.carColor?.price
                        ? purchaseOrderDTO?.orderData?.carColor?.price
                        : 0,
                    ) +
                    Number(
                      purchaseOrderDTO?.orderData?.carOptionList &&
                        purchaseOrderDTO?.orderData?.carOptionList.length > 0
                        ? purchaseOrderDTO.orderData.carOptionList
                            .map((el) => {
                              return el.price;
                            })
                            .reduce((prev, curr) => {
                              return prev + curr;
                            })
                        : 0,
                    ) +
                    0
                  ).toLocaleString()}
                  원 <img src='/assets/images/v2/coin.svg' alt='' />
                </div>
              </div>
            )}
            <div className='mt-4 grid grid-cols-2'>
              <Button
                variant='outline'
                className='rounded-r-none bg-white'
                onClick={goBack}
              >
                이전
              </Button>

              <Button className='rounded-l-none' type='submit'>
                다음
              </Button>
              {/*<input*/}
              {/*  type='submit'*/}
              {/*  className='rounded-l-none'*/}
              {/*  // onClick={confirm}*/}
              {/*/>다음*/}
            </div>
          </div>
          {purchaseOrderDTO && purchaseOrderDTO.orderData && (
            <PriceDetail
              isOpen={isDetailOpen}
              setIsOpen={setIsDetailOpen}
              purchaseCarInfo={purchaseOrderDTO.orderData}
            />
          )}
        </div>
      </form>
    </div>
  );
}
