import usePurchaseStore from '../store';
import { useEffect, useLayoutEffect, useState } from 'react';
import CustomizedSelects, {
  CustomizedMenuItems,
} from '../../../../components/ui/select_v2';
import getPercentagePrice from '../../../../utils/getPercentagePrice';
import { cn } from '../../../../utils';
import { ChevronDown } from 'lucide-react';
import { SelectSigungu } from '../../../../components/view/buy/selectSigungu';
import { Controller, useForm } from 'react-hook-form';
import { getRegion } from '../../../../utils/getSidogungu';
import { CarsayoToast } from '../../../../components/common/v2/CarsayoToast';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/ui/button';
import { PriceDetail } from './priceDetail';
import { HalbooFormValues } from './HalbooForm';
import { BuyerType, SidoData, SigunguData } from '@carsayo/types';

export type RentFormValues = {
  contractTerm: string; // 계약기간
  price_deposit: string; // 보증금
  price_advancedPayment: string; // 선납금
  price_residual: string; // 잔존가치
  agreeDistace: string; // 약정거리
  maintenancePlan: '일반형' | '고급형' | '최고급형' | ''; // 정비
  driverMiniumAge: string; // 운전자 연령
  indemnityAmount: string; // 면책금
  personalInjuryCoverage: string; // 보험담보(자손)
  propertyDamageCoverage: string; // 보험담보(대물)
  buyerType: BuyerType | ''; // 구입주체
  deliveryRegion: string; // 인수지역
};

export function RentForm({ purchasePrice }: { purchasePrice: number }) {
  const { purchaseOrderDTO, setPurchaseOrderDTO } = usePurchaseStore();
  const [isOpen, setIsOpen] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const deliveryRegionData =
    purchaseOrderDTO && purchaseOrderDTO.orderData
      ? purchaseOrderDTO?.orderData?.additionalInfo.렌트.deliveryRegion
      : 0;

  const { control, handleSubmit, setValue, formState } =
    useForm<RentFormValues>({
      mode: 'onSubmit',
      criteriaMode: 'firstError',
      shouldFocusError: true,
      defaultValues: {
        contractTerm:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.렌트.contractTerm
            ? String(
                purchaseOrderDTO.orderData.additionalInfo.렌트.contractTerm,
              )
            : '24',
        deliveryRegion: getRegion(deliveryRegionData as number),
        price_deposit:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.렌트.price_deposit !==
            undefined
            ? String(
                purchaseOrderDTO.orderData.additionalInfo.렌트.price_deposit,
              )
            : '',
        price_advancedPayment:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.렌트
            .price_advancedPayment !== undefined
            ? String(
                purchaseOrderDTO.orderData.additionalInfo.렌트
                  .price_advancedPayment,
              )
            : '',
        price_residual:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.렌트.price_residual !==
            undefined
            ? String(
                purchaseOrderDTO.orderData.additionalInfo.렌트.price_residual,
              )
            : '',
        agreeDistace:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.렌트.agreeDistace !==
            undefined
            ? String(
                purchaseOrderDTO.orderData.additionalInfo.렌트.agreeDistace,
              )
            : '',
        buyerType:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.렌트.buyerType !== undefined
            ? (String(
                purchaseOrderDTO.orderData.additionalInfo.렌트.buyerType,
              ) as BuyerType)
            : '',
        maintenancePlan:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.렌트.maintenancePlan !==
            undefined
            ? (String(
                purchaseOrderDTO.orderData.additionalInfo.렌트.maintenancePlan,
              ) as '일반형' | '고급형' | '최고급형')
            : '일반형',
        driverMiniumAge:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.렌트.driverMiniumAge !==
            undefined
            ? String(
                purchaseOrderDTO.orderData.additionalInfo.렌트.driverMiniumAge,
              )
            : '',
        indemnityAmount:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.렌트.indemnityAmount !==
            undefined
            ? String(
                purchaseOrderDTO.orderData.additionalInfo.렌트.indemnityAmount,
              )
            : '',
        personalInjuryCoverage:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.렌트
            .personalInjuryCoverage !== undefined
            ? String(
                purchaseOrderDTO.orderData.additionalInfo.렌트
                  .personalInjuryCoverage,
              )
            : '',
        propertyDamageCoverage:
          purchaseOrderDTO?.orderData &&
          purchaseOrderDTO.orderData.additionalInfo.렌트
            .propertyDamageCoverage !== undefined
            ? String(
                purchaseOrderDTO.orderData.additionalInfo.렌트
                  .propertyDamageCoverage,
              )
            : '',
      },
    });

  const navigate = useNavigate();
  const goBack = () => {
    navigate('/purchase/option', { replace: true });
  };
  const goNext = () => {
    navigate('/purchase/estimate', { replace: true });
  };

  const confirm = (data: RentFormValues) => {
    if (!purchaseOrderDTO?.orderData) {
      return;
    }

    const currentAdditionalInfo = purchaseOrderDTO.orderData.additionalInfo;

    if (currentAdditionalInfo.method === '렌트') {
      if (
        currentAdditionalInfo.렌트.agreeDistace === undefined ||
        currentAdditionalInfo.렌트.contractTerm === undefined ||
        currentAdditionalInfo.렌트.price_advancedPayment === undefined ||
        currentAdditionalInfo.렌트.price_deposit === undefined ||
        currentAdditionalInfo.렌트.price_residual === undefined ||
        currentAdditionalInfo.렌트.driverMiniumAge === undefined ||
        currentAdditionalInfo.렌트.indemnityAmount === undefined ||
        currentAdditionalInfo.렌트.personalInjuryCoverage === undefined ||
        currentAdditionalInfo.렌트.propertyDamageCoverage === undefined ||
        currentAdditionalInfo.렌트.maintenancePlan === undefined ||
        currentAdditionalInfo.렌트.deliveryRegion === undefined ||
        currentAdditionalInfo.렌트.buyerType === undefined
      ) {
        CarsayoToast.error('아직 모든 내용이 입력되지 않았습니다.');
        return;
      }
    }

    goNext();
    return;
  };

  const setDefaultValue = function async() {
    if (!purchaseOrderDTO || !purchaseOrderDTO.orderData) return;

    let isUpdate = false;
    const updateData = {
      ...purchaseOrderDTO.orderData.additionalInfo.렌트,
    };

    if (!purchaseOrderDTO?.orderData?.additionalInfo.렌트.contractTerm) {
      isUpdate = true;
      updateData.contractTerm = 24;
    }

    if (!purchaseOrderDTO?.orderData?.additionalInfo.렌트.maintenancePlan) {
      isUpdate = true;
      updateData.maintenancePlan = '일반형';
    }

    if (isUpdate) {
      setPurchaseOrderDTO({
        id: purchaseOrderDTO.id,
        type: purchaseOrderDTO.type,
        update: {
          additionalInfo: {
            ...purchaseOrderDTO.orderData.additionalInfo,
            렌트: {
              ...purchaseOrderDTO.orderData.additionalInfo.렌트,
              ...updateData,
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    const errorObj = Object.keys(
      formState.errors,
    ) as (keyof HalbooFormValues)[];
    const firstErrorKey = errorObj[0];
    const errorElement = document.querySelector(`[name=${firstErrorKey}]`);
    if (errorElement) {
      if (errorElement.getAttribute('type') !== 'hidden') {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        if (errorElement.nextElementSibling) {
          errorElement.nextElementSibling.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }
    }
  }, [formState.errors]);

  useLayoutEffect(() => {
    setDefaultValue();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(confirm)}>
        <div>
          <div className='mt-4 space-y-4 px-4 pb-6'>
            {purchaseOrderDTO?.orderData && (
              <>
                <div>
                  <div>계약 기간</div>
                  <div className='mt-2'>
                    <Controller
                      name='contractTerm'
                      control={control}
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          name={'contractTerm'}
                          classes={
                            formState.errors.contractTerm
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : ''
                          }
                          onValueChange={async (value) => {
                            setValue('contractTerm', value, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  렌트: {
                                    ...purchaseOrderDTO.orderData.additionalInfo
                                      .렌트,
                                    contractTerm: Number(value),
                                  },
                                },
                              },
                            });
                          }}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='24'>
                            24개월
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='36'>
                            36개월
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='48'>
                            48개월
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='60'>
                            60개월
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />
                  </div>
                </div>
                <div>
                  <div>보증금</div>
                  <div className='mt-2'>
                    <Controller
                      name='price_deposit'
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          name={'price_deposit'}
                          classes={
                            formState.errors.price_deposit
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : ''
                          }
                          onValueChange={async (value) => {
                            setValue('price_deposit', value, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  렌트: {
                                    ...purchaseOrderDTO.orderData.additionalInfo
                                      .렌트,
                                    price_deposit: Number(value),
                                  },
                                },
                              },
                            });
                          }}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='0'>
                            0%
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='10'>
                            10%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  10,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='15'>
                            15%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  15,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='20'>
                            20%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  30,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='30'>
                            30%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  30,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='40'>
                            40%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  40,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='50'>
                            50%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  50,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='60'>
                            60%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  60,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>선납금</div>
                  <div className='mt-2'>
                    <Controller
                      control={control}
                      name='price_advancedPayment'
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          name={'price_advancedPayment'}
                          classes={
                            formState.errors.price_advancedPayment
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : ''
                          }
                          onValueChange={async (value) => {
                            setValue('price_advancedPayment', value, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  렌트: {
                                    ...purchaseOrderDTO.orderData.additionalInfo
                                      .렌트,
                                    price_advancedPayment: Number(value),
                                  },
                                },
                              },
                            });
                          }}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='0'>
                            0%
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='10'>
                            10%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  10,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='20'>
                            20%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  30,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='30'>
                            30%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  30,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='40'>
                            40%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  40,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='50'>
                            50%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  50,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='60'>
                            60%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  60,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>잔존가치</div>
                  <div className='mt-2'>
                    <Controller
                      control={control}
                      name='price_residual'
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          classes={
                            formState.errors.price_residual
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : ''
                          }
                          onValueChange={async (value) => {
                            setValue('price_residual', value, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  렌트: {
                                    ...purchaseOrderDTO.orderData.additionalInfo
                                      .렌트,
                                    price_residual: Number(value),
                                  },
                                },
                              },
                            });
                          }}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='100'>
                            최대
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='10'>
                            10%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  10,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='15'>
                            15%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  15,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='20'>
                            20%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  30,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='30'>
                            30%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  30,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='40'>
                            40%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  40,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='50'>
                            50%{' '}
                            {purchasePrice > 0 && (
                              <>
                                (
                                {getPercentagePrice(
                                  purchasePrice,
                                  50,
                                ).toLocaleString()}
                                원)
                              </>
                            )}
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>약정거리</div>
                  <div className='mt-2'>
                    <Controller
                      control={control}
                      name='agreeDistace'
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          name={'agreeDistace'}
                          classes={
                            formState.errors.agreeDistace
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : ''
                          }
                          onValueChange={async (value) => {
                            setValue('agreeDistace', value, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  렌트: {
                                    ...purchaseOrderDTO.orderData.additionalInfo
                                      .렌트,
                                    agreeDistace: Number(value),
                                  },
                                },
                              },
                            });
                          }}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='10000'>
                            10,000km
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='20000'>
                            20,000km
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='30000'>
                            30,000km
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='50000'>
                            50,000km
                          </CustomizedMenuItems>
                          <CustomizedMenuItems
                            disabled={
                              purchaseOrderDTO.orderData &&
                              purchaseOrderDTO.orderData.carMaker?.type ===
                                'imported'
                            }
                            value='0'
                          >
                            무제한
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>정비</div>
                  <div className='mt-2'>
                    <Controller
                      name='maintenancePlan'
                      control={control}
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          name={'maintenancePlan'}
                          classes={
                            formState.errors.maintenancePlan
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : ''
                          }
                          onValueChange={async (value: string | undefined) => {
                            setValue(
                              'maintenancePlan',
                              value as '일반형' | '고급형' | '최고급형',
                              {
                                shouldValidate: true,
                                shouldDirty: true,
                                shouldTouch: true,
                              },
                            );
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  렌트: {
                                    ...purchaseOrderDTO.orderData.additionalInfo
                                      .렌트,
                                    maintenancePlan: value as
                                      | '일반형'
                                      | '고급형'
                                      | '최고급형',
                                  },
                                },
                              },
                            });
                          }}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='일반형'>
                            일반형 (셀프 정비)
                          </CustomizedMenuItems>
                          {purchaseOrderDTO.orderData &&
                            purchaseOrderDTO.orderData.carMaker?.type ===
                              'domestic' && (
                              <CustomizedMenuItems value='고급형'>
                                고급형 (입고 정비)
                              </CustomizedMenuItems>
                            )}
                          {purchaseOrderDTO.orderData &&
                            purchaseOrderDTO.orderData.carMaker?.type ===
                              'domestic' && (
                              <CustomizedMenuItems value='최고급형'>
                                최고급형 (순회 정비)
                              </CustomizedMenuItems>
                            )}
                        </CustomizedSelects>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>운전자 연령</div>
                  <div className='mt-2'>
                    <Controller
                      control={control}
                      name='driverMiniumAge'
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          name={'driverMiniumAge'}
                          value={field.value}
                          classes={
                            formState.errors.driverMiniumAge
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : ''
                          }
                          onValueChange={async (value) => {
                            setValue('driverMiniumAge', value, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  렌트: {
                                    ...purchaseOrderDTO.orderData.additionalInfo
                                      .렌트,
                                    driverMiniumAge: Number(value),
                                  },
                                },
                              },
                            });
                          }}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='21'>
                            만 21세 이상
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='26'>
                            만 26세 이상
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>면책금</div>
                  <div className='mt-2'>
                    <Controller
                      control={control}
                      name='indemnityAmount'
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          name={'indemnityAmount'}
                          classes={
                            formState.errors.indemnityAmount
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : ''
                          }
                          onValueChange={async (value) => {
                            setValue('indemnityAmount', value, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  렌트: {
                                    ...purchaseOrderDTO.orderData.additionalInfo
                                      .렌트,
                                    indemnityAmount: Number(value),
                                  },
                                },
                              },
                            });
                          }}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='100000'>
                            100,000원
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='200000'>
                            200,000원
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='300000'>
                            300,000원
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='500000'>
                            500,000원
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>보험 담보 (자손)</div>
                  <div className='mt-2'>
                    <Controller
                      control={control}
                      name='personalInjuryCoverage'
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          name={'personalInjuryCoverage'}
                          classes={
                            formState.errors.personalInjuryCoverage
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : ''
                          }
                          onValueChange={async (value) => {
                            setValue('personalInjuryCoverage', value, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  렌트: {
                                    ...purchaseOrderDTO.orderData.additionalInfo
                                      .렌트,
                                    personalInjuryCoverage: Number(value),
                                  },
                                },
                              },
                            });
                          }}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='30000000'>
                            3,000만원
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='50000000'>
                            5,000만원
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='100000000'>
                            1억 원
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>보험 담보 (대물)</div>
                  <div className='mt-2'>
                    <Controller
                      control={control}
                      name='propertyDamageCoverage'
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          name={'propertyDamageCoverage'}
                          classes={
                            formState.errors.propertyDamageCoverage
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : ''
                          }
                          onValueChange={async (value) => {
                            setValue('propertyDamageCoverage', value, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  렌트: {
                                    ...purchaseOrderDTO.orderData.additionalInfo
                                      .렌트,
                                    propertyDamageCoverage: Number(value),
                                  },
                                },
                              },
                            });
                          }}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='100000000'>
                            1억 원
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='200000000'>
                            2억 원
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='300000000'>
                            3억 원
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>구입 주체</div>
                  <div className='mt-2'>
                    <Controller
                      control={control}
                      name='buyerType'
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => (
                        <CustomizedSelects
                          value={field.value}
                          name={'buyerType'}
                          classes={
                            formState.errors.buyerType
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : ''
                          }
                          onValueChange={async (value) => {
                            setValue('buyerType', value as BuyerType, {
                              shouldValidate: true,
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  렌트: {
                                    ...purchaseOrderDTO.orderData.additionalInfo
                                      .렌트,
                                    buyerType: value as BuyerType,
                                  },
                                },
                              },
                            });
                          }}
                          placeholder='선택해주세요'
                        >
                          <CustomizedMenuItems value='individual'>
                            개인
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='sole_proprietor'>
                            개인사업자
                          </CustomizedMenuItems>
                          <CustomizedMenuItems value='corporation'>
                            법인사업자
                          </CustomizedMenuItems>
                        </CustomizedSelects>
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>인수(탁송) 지역</div>
                  <div className='mt-2'>
                    <Controller
                      control={control}
                      name='deliveryRegion'
                      rules={{ required: true }}
                      render={({ field, fieldState, formState }) => (
                        <div
                          className={cn(
                            'flex h-[52px] items-center border border-input px-3 py-2 text-sm text-[#555555]',
                            formState.errors.deliveryRegion
                              ? 'border-[#DC0000] focus:border-[#DC0000] border-solid border'
                              : '',
                          )}
                          onClick={() => {
                            setIsOpen(true);
                          }}
                        >
                          {/* useForm 시용 위한 비노출 input */}
                          <input
                            type='hidden'
                            value={field.value}
                            name='deliveryRegion'
                          />
                          {purchaseOrderDTO?.orderData?.additionalInfo.렌트
                            .deliveryRegion ? (
                            <div>
                              {purchaseOrderDTO?.orderData?.additionalInfo.렌트
                                .deliveryRegion !== undefined &&
                                SidoData.find((el1) => {
                                  return (
                                    purchaseOrderDTO.orderData &&
                                    el1.id ===
                                      (purchaseOrderDTO?.orderData
                                        ?.additionalInfo.렌트.deliveryRegion !==
                                        undefined &&
                                        SigunguData.find((el) => {
                                          return (
                                            purchaseOrderDTO.orderData &&
                                            el.id ===
                                              purchaseOrderDTO.orderData
                                                .additionalInfo.렌트
                                                .deliveryRegion
                                          );
                                        })?.sidoId)
                                  );
                                })?.name}{' '}
                              {purchaseOrderDTO?.orderData?.additionalInfo.렌트
                                .deliveryRegion !== undefined &&
                                SigunguData.find((el) => {
                                  return (
                                    purchaseOrderDTO.orderData &&
                                    el.id ===
                                      purchaseOrderDTO.orderData.additionalInfo
                                        .렌트.deliveryRegion
                                  );
                                })?.name}
                            </div>
                          ) : (
                            <div>선택해 주세요</div>
                          )}
                          <ChevronDown className='ml-auto h-5 w-5 text-[#111111]' />
                        </div>
                      )}
                    />
                  </div>
                </div>

                <SelectSigungu
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  setRegion={(value) => {
                    setValue('deliveryRegion', getRegion(value), {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    if (!purchaseOrderDTO?.orderData) return;
                    setPurchaseOrderDTO({
                      id: purchaseOrderDTO.id,
                      type: purchaseOrderDTO.type,
                      update: {
                        additionalInfo: {
                          ...purchaseOrderDTO.orderData.additionalInfo,
                          렌트: {
                            ...purchaseOrderDTO.orderData.additionalInfo.렌트,
                            deliveryRegion: value,
                          },
                        },
                      },
                    });
                  }}
                />
              </>
            )}
          </div>

          <div className='sticky bottom-0 w-full h-full flex-none bg-[#F7F7FB] px-4 py-6'>
            {purchasePrice > 0 && (
              <div className='flex items-center justify-between'>
                <div className='text-[#555555]'>총 예상 금액</div>
                <div
                  onClick={() => setIsDetailOpen(true)}
                  className='flex items-center gap-2 font-semibold'
                >
                  {(
                    Number(purchaseOrderDTO?.orderData?.carGrade?.price) +
                    Number(
                      purchaseOrderDTO?.orderData?.carColor?.price
                        ? purchaseOrderDTO?.orderData?.carColor?.price
                        : 0,
                    ) +
                    Number(
                      purchaseOrderDTO?.orderData?.carOptionList &&
                        purchaseOrderDTO?.orderData?.carOptionList.length > 0
                        ? purchaseOrderDTO.orderData.carOptionList
                            .map((el) => {
                              return el.price;
                            })
                            .reduce((prev, curr) => {
                              return prev + curr;
                            })
                        : 0,
                    ) +
                    0
                  ).toLocaleString()}
                  원 <img src='/assets/images/v2/coin.svg' alt='' />
                </div>
              </div>
            )}
            <div className='mt-4 grid grid-cols-2'>
              <Button
                variant='outline'
                className='rounded-r-none bg-white'
                onClick={goBack}
              >
                이전
              </Button>

              <Button className='rounded-l-none' type='submit'>
                다음
              </Button>
              {/*<input*/}
              {/*  type='submit'*/}
              {/*  className='rounded-l-none'*/}
              {/*  // onClick={confirm}*/}
              {/*/>다음*/}
            </div>
          </div>
          {purchaseOrderDTO && purchaseOrderDTO.orderData && (
            <PriceDetail
              isOpen={isDetailOpen}
              setIsOpen={setIsDetailOpen}
              purchaseCarInfo={purchaseOrderDTO.orderData}
            />
          )}
        </div>
      </form>
    </div>
  );
}
