import React from 'react';

interface MyComponentProps {
  fill?: string;
  width?: string;
  height?: string;
}

const MyMenuIcon: React.FC<MyComponentProps> = ({ fill, width, height }) => {
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_2126_8034'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='16'
        height='16'
      >
        <path d='M16 0H0V16H16V0Z' fill='white' />
      </mask>
      <g mask='url(#mask0_2126_8034)'>
        <path
          d='M7.82812 10.2427H14.3939C15.2809 10.2427 16 9.5219 16 8.63274V1.61C16 0.720815 15.2809 0 14.3939 0H7.82812C6.9411 0 6.22202 0.720815 6.22202 1.61V8.63274C6.22202 9.5219 6.9411 10.2427 7.82812 10.2427Z'
          fill='#E1E6EF'
        />
        <path
          d='M10.7416 5.58658H14.5384C14.7159 5.58658 14.8597 5.44243 14.8597 5.26459V4.75583C14.8597 4.578 14.7159 4.43384 14.5384 4.43384H10.7416C10.5642 4.43384 10.4204 4.578 10.4204 4.75583V5.26459C10.4204 5.44243 10.5642 5.58658 10.7416 5.58658Z'
          fill='#C4CEDC'
        />
        <path
          d='M10.7416 3.47086H14.5384C14.7159 3.47086 14.8597 3.3267 14.8597 3.14887V2.64011C14.8597 2.46227 14.7159 2.31812 14.5384 2.31812H10.7416C10.5642 2.31812 10.4204 2.46227 10.4204 2.64011V3.14887C10.4204 3.3267 10.5642 3.47086 10.7416 3.47086Z'
          fill='#C4CEDC'
        />
        <path
          d='M7.81846 5.58658H9.06801C9.24542 5.58658 9.38923 5.44243 9.38923 5.26459V4.75583C9.38923 4.578 9.24542 4.43384 9.06801 4.43384H7.81846C7.64105 4.43384 7.49724 4.578 7.49724 4.75583V5.26459C7.49724 5.44243 7.64105 5.58658 7.81846 5.58658Z'
          fill='#A9B8CC'
        />
        <path
          d='M7.81846 3.47086H9.06801C9.24542 3.47086 9.38923 3.3267 9.38923 3.14887V2.64011C9.38923 2.46227 9.24542 2.31812 9.06801 2.31812H7.81846C7.64105 2.31812 7.49724 2.46227 7.49724 2.64011V3.14887C7.49724 3.3267 7.64105 3.47086 7.81846 3.47086Z'
          fill='#A9B8CC'
        />
        <path
          d='M10.7416 7.71183H14.5384C14.7159 7.71183 14.8597 7.56767 14.8597 7.38983V6.88108C14.8597 6.70324 14.7159 6.55908 14.5384 6.55908H10.7416C10.5642 6.55908 10.4204 6.70324 10.4204 6.88108V7.38983C10.4204 7.56767 10.5642 7.71183 10.7416 7.71183Z'
          fill='#C4CEDC'
        />
        <path
          d='M7.81846 7.71183H9.06801C9.24542 7.71183 9.38923 7.56767 9.38923 7.38983V6.88108C9.38923 6.70324 9.24542 6.55908 9.06801 6.55908H7.81846C7.64105 6.55908 7.49724 6.70324 7.49724 6.88108V7.38983C7.49724 7.56767 7.64105 7.71183 7.81846 7.71183Z'
          fill='#A9B8CC'
        />
        <path
          d='M13.8928 9.40862L12.049 8.47805C11.9526 8.42975 11.8338 8.43297 11.7406 8.48771C11.6475 8.54245 11.5864 8.64549 11.5832 8.75497L11.5029 11.2182C11.4997 11.3438 11.5671 11.4597 11.6796 11.5177C11.7245 11.5402 11.7759 11.5531 11.8241 11.5531C11.8948 11.5531 11.9655 11.5306 12.0233 11.4823L13.9474 9.94958C14.0341 9.88196 14.0791 9.77248 14.0662 9.663C14.0566 9.55352 13.9891 9.45692 13.8928 9.40862Z'
          fill='#E1E6EF'
        />
        <path
          d='M1.65105 7.30931C1.65105 9.25739 2.93914 10.4005 4.85362 10.4005C4.86004 10.4005 4.86325 10.4005 4.86968 10.4005C4.8761 10.4005 4.87931 10.4005 4.88574 10.4005C6.80021 10.4005 8.08831 9.30247 8.08831 7.30931C8.08831 3.30368 1.65105 3.0654 1.65105 7.30931Z'
          fill='#61361B'
        />
        <path
          d='M2.45411 7.97583C2.45411 7.97583 2.45411 7.97583 2.4509 7.97583C2.4509 7.97583 2.4509 7.97583 2.44769 7.97583C1.89519 7.97583 1.69603 8.14649 1.69603 8.79048C1.69603 9.43447 2.22926 9.58581 2.44769 9.60513C2.44769 9.60513 2.44769 9.60513 2.4509 9.60513C2.4509 9.60513 2.4509 9.60513 2.45411 9.60513C2.90382 9.60513 3.20577 9.45379 3.20577 8.79048C3.20577 8.12717 3.00661 7.97583 2.45411 7.97583Z'
          fill='#FFB191'
        />
        <path
          d='M7.28527 7.97583C7.28527 7.97583 7.28527 7.97583 7.28848 7.97583C7.28848 7.97583 7.28848 7.97583 7.2917 7.97583C7.8442 7.97583 8.04335 8.14649 8.04335 8.79048C8.04335 9.43447 7.51013 9.58581 7.2917 9.60513C7.2917 9.60513 7.2917 9.60513 7.28848 9.60513C7.28848 9.60513 7.28848 9.60513 7.28527 9.60513C6.83556 9.60513 6.53362 9.45379 6.53362 8.79048C6.53362 8.12717 6.73277 7.97583 7.28527 7.97583Z'
          fill='#FFB191'
        />
        <path
          d='M9.62056 13.5914C9.42461 13.2405 9.0006 12.9764 8.3678 12.8026C6.80024 12.3775 6.18671 12.2423 4.90183 12.2358H4.86971H4.83758C3.5527 12.2391 2.93917 12.3775 1.37163 12.8026C0.73561 12.9732 0.314811 13.2405 0.118866 13.5914C-0.0513805 13.8973 0.0128636 14.1485 0.0192881 14.1678C0.0257125 14.1904 0.144564 14.6991 0.542878 15.2272C1.08574 15.9485 2.48304 15.9968 2.54728 15.9968H7.19856C7.25638 15.9968 8.6569 15.9485 9.19976 15.2272C9.59807 14.6991 9.71692 14.1871 9.72014 14.1743C9.72656 14.1453 9.79402 13.8941 9.62377 13.5882L9.62056 13.5914Z'
          fill='#7273F9'
        />
        <path
          d='M6.36339 11.5564H3.37282C3.2893 12.1263 3.22185 12.619 3.22185 12.619C3.39852 13.015 4.86007 13.2308 4.86007 13.2308C4.86007 13.2308 6.40193 13.0022 6.51436 12.619C6.51436 12.619 6.44369 12.1263 6.36339 11.5564Z'
          fill='#FFCBB4'
        />
        <path
          d='M6.36335 11.5564C6.2959 11.0862 6.2188 10.5614 6.16741 10.2136C6.05177 9.44084 5.69521 9.2251 5.11702 9.2251H4.61913C4.04093 9.2251 3.68759 9.44406 3.56873 10.2136C3.53019 10.4744 3.47879 10.8319 3.42418 11.1925C3.42418 11.2118 3.41776 11.2279 3.41776 11.2472C3.40812 11.3116 3.39849 11.3728 3.38885 11.4372C3.38564 11.4598 3.38243 11.4823 3.37921 11.5048C3.37921 11.5242 3.37279 11.5403 3.37279 11.5596C3.36315 11.6175 3.35673 11.6755 3.34709 11.7334C3.76789 11.9395 4.27542 12.0136 4.85362 12.0136C4.85683 12.0136 4.86325 12.0136 4.86647 12.0136C4.86968 12.0136 4.8761 12.0136 4.87931 12.0136C5.45751 12.0136 5.96504 11.9363 6.38584 11.7334C6.3762 11.6755 6.36978 11.6175 6.36014 11.5596L6.36335 11.5564Z'
          fill='#FFB191'
        />
        <path
          d='M4.88259 5.17114C4.88259 5.17114 4.87295 5.17114 4.86974 5.17114C4.86653 5.17114 4.8601 5.17114 4.85689 5.17114C2.9649 5.17114 2.27428 6.62013 2.27428 8.30417C2.27428 10.278 3.31182 11.4372 4.85689 11.4372C4.8601 11.4372 4.86653 11.4372 4.86974 11.4372C4.87295 11.4372 4.87938 11.4372 4.88259 11.4372C6.42766 11.4372 7.4652 10.3231 7.4652 8.30417C7.4652 6.54285 6.77779 5.17114 4.88259 5.17114Z'
          fill='#FFCBB4'
        />
        <path
          d='M3.98633 9.00933C4.11405 9.00933 4.21761 8.90555 4.21761 8.77749C4.21761 8.64947 4.11405 8.54565 3.98633 8.54565C3.85858 8.54565 3.75505 8.64947 3.75505 8.77749C3.75505 8.90555 3.85858 9.00933 3.98633 9.00933Z'
          fill='#61361B'
        />
        <path
          d='M5.73059 9.00933C5.85834 9.00933 5.96187 8.90555 5.96187 8.77749C5.96187 8.64947 5.85834 8.54565 5.73059 8.54565C5.60287 8.54565 5.49931 8.64947 5.49931 8.77749C5.49931 8.90555 5.60287 9.00933 5.73059 9.00933Z'
          fill='#61361B'
        />
        <path
          d='M4.90183 8.68408H4.83438C4.75632 8.68408 4.69304 8.74751 4.69304 8.82576V9.36671C4.69304 9.44496 4.75632 9.50839 4.83438 9.50839H4.90183C4.97989 9.50839 5.04317 9.44496 5.04317 9.36671V8.82576C5.04317 8.74751 4.97989 8.68408 4.90183 8.68408Z'
          fill='#FF9C82'
        />
        <path
          d='M4.90182 10.3809C4.6352 10.3809 4.43926 10.3101 4.42641 10.3036C4.36538 10.2811 4.33326 10.2102 4.35574 10.1491C4.37823 10.0879 4.4489 10.0557 4.50993 10.0782C4.51314 10.0782 4.89861 10.2199 5.28728 10.0718C5.34831 10.0492 5.41898 10.0782 5.44147 10.1426C5.46395 10.2038 5.43504 10.2746 5.3708 10.2972C5.20377 10.3616 5.04315 10.3809 4.89861 10.3809H4.90182Z'
          fill='#EE8A78'
        />
        <path
          d='M7.79926 7.94985C7.79926 7.94985 7.73822 7.65361 7.54549 7.27043C7.56155 7.04826 7.5648 6.81964 7.53268 6.65864C7.4427 6.2336 6.7328 4.95528 5.14276 4.83614C3.55272 4.717 2.56657 5.66045 2.28069 6.61034C2.18753 6.91302 2.15862 7.15773 2.15541 7.35737C1.9948 7.69547 1.94341 7.94985 1.94341 7.94985C1.94341 7.94985 2.03977 8.61316 2.32245 8.97701C2.32245 8.97701 2.27426 7.42177 3.5206 6.33342C3.48526 6.44612 3.44672 6.69728 3.7583 6.69728C3.95425 6.69728 4.14055 6.55882 4.29153 6.39782C4.33971 6.52018 4.44893 6.59424 4.62881 6.53306C4.87936 6.4429 5.0207 6.16599 5.07852 6.02431C5.97472 6.298 6.66214 6.96454 7.05724 7.44753C7.43628 8.25252 7.417 8.97701 7.417 8.97701C7.69968 8.61316 7.79608 7.94985 7.79608 7.94985H7.79926Z'
          fill='#61361B'
        />
        <path
          d='M3.35994 7.64064C3.33424 7.64064 3.30533 7.62776 3.29248 7.602C3.27 7.56336 3.28285 7.51506 3.32139 7.49252C3.73577 7.24458 4.12766 7.4571 4.14372 7.46676C4.18226 7.4893 4.19511 7.5376 4.17584 7.57624C4.15335 7.61488 4.10517 7.62776 4.06662 7.60844C4.05378 7.602 3.73898 7.43134 3.40491 7.63098C3.39206 7.63742 3.37921 7.64386 3.36315 7.64386L3.35994 7.64064Z'
          fill='#61361B'
        />
        <path
          d='M5.5732 7.64064C5.5475 7.64064 5.51859 7.62776 5.50574 7.602C5.48325 7.56336 5.4961 7.51506 5.53465 7.49252C5.94902 7.24458 6.34091 7.4571 6.35697 7.46676C6.39552 7.4893 6.40837 7.5376 6.3891 7.57624C6.36661 7.61488 6.31843 7.62776 6.27988 7.60844C6.26703 7.602 5.95224 7.43134 5.61817 7.63098C5.60532 7.63742 5.59247 7.64386 5.57641 7.64386L5.5732 7.64064Z'
          fill='#61361B'
        />
        <path
          d='M4.77975 13.4431C3.10619 13.4431 2.91988 12.3129 2.91667 12.3032C2.90382 12.2163 2.96485 12.1326 3.05158 12.1197C3.13831 12.1068 3.21862 12.1648 3.23468 12.2549C3.2411 12.2904 3.39529 13.1211 4.77975 13.1211C6.42761 13.1211 6.51434 12.2743 6.51756 12.2388C6.52398 12.1519 6.6075 12.0811 6.6878 12.0907C6.77453 12.0972 6.84199 12.1712 6.83556 12.2614C6.83556 12.3097 6.74562 13.4463 4.77654 13.4463L4.77975 13.4431Z'
          fill='white'
        />
      </g>
    </svg>
  );
};
export default MyMenuIcon;
