/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useLayoutEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { AnimatePresence, motion } from 'framer-motion';
import CarsayoBanner from 'components/common/v2/CarsayoBanner';
import useMemberStore from 'store/useMemberStore';
import { Link, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { isLogin, setFcmToken } from 'apis/authentication';
import {
  PopularCar,
  PopularCarCategory,
  UpdateInterestCarDTO,
  InterestCarInfo,
  PopularCarCategoryId,
} from '@carsayo/types';
import SampleCar from 'assets/images/v2/sampleCarImg_BMW5.svg';
import HeartIcon from 'components/common/global/svg/HeartIcon';
import ArrowRight from 'components/common/global/svg/ArrowRight';
import {
  getInterestCarList,
  getPopularCarList,
  selectInterestCar,
} from 'apis/car';
import { classNames } from 'primereact/utils';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import dayjs from 'dayjs';
import { cn } from 'utils';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import SwiperCore from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import NoQuoteIcon from 'assets/images/v2/noQuoteIcon.svg';
import GoraniImg from 'assets/images/v2/goraniImg.jpg';
import StarBadge from 'assets/images/v2/starBadge.svg';
import managerUniform from 'assets/images/v2/carmanagerLeaseRent.svg';
import { Button } from 'components/ui/button';
import CarsayoCI from 'components/svg/CarsayoCI';
import {
  InsuranceCompany,
  InsuranceCompanyData,
} from 'pages/static/insurance/data/company';
import VideoDrawer from './components/videoDrawer';
import NoticeAccordion from './components/noticeAccordion';
import { addHistoryInsuranceCooconClick } from 'apis/history';
import usePayment from 'store/usePayment';
import UnderlineSketch from 'components/svg/UnderlineSketch';

interface InterestTemplateProps {
  data: InterestCarInfo;
  setInterestCarList: () => void;
}

function InterestTemplate({ data, setInterestCarList }: InterestTemplateProps) {
  const [isInterestCar, setIsInterestCar] = useState(data.isInterest);
  const handleClickEvents = {
    selecteInterestCar: async () => {
      try {
        const result: UpdateInterestCarDTO = await selectInterestCar(
          data.id ?? 0,
          !isInterestCar,
        );
        if (result) {
          setInterestCarList();
          if (result.isInterest) {
            CarsayoToast.text('관심차량을 등록했습니다.');
          } else {
            CarsayoToast.text('관심차량을 해제했습니다.');
          }
          setIsInterestCar(result.isInterest);
          return;
        }
      } catch (error: any) {
        console.error(error);
        return;
      }
    },
  };
  return (
    <>
      <div className='relative mt-6 flex min-h-40 px-[14.13vw] py-[1.84vh] items-center justify-center rounded-[20px] bg-[rgba(0,0,0,.03)]'>
        <img
          src={data.image_url}
          alt={data.name}
          className='object-contain lg:px-[20px] lg:py-[10px]  md:px-[20px] md:py-[10px] px-[14vw] py-[1.84vh]'
        />
        <button
          className='w-[30px] h-[30px] text-transparent indent-[-9999px] absolute top-[20px] right-[20px]'
          onClick={() => handleClickEvents.selecteInterestCar()}
        >
          <HeartIcon
            fill={isInterestCar ? '#DC0000' : undefined}
            stroke={isInterestCar ? '#DC0000' : undefined}
            width={'30px'}
            height={'30px'}
          />
          관심차량버튼
        </button>
      </div>
      <div className='mt-4 px-1'>
        <div className='font-semibold'>
          <span className='mr-[3px]'>
            {data.carMaker.name ?? '제조사명 없음'}
          </span>
          {/* <span className='mr-[3px]'>{data.name_brand ?? ''}</span>
          <span>{data.name_generation ?? '차량브랜드 없음'}</span> */}
        </div>
        <div className='text-[15px] font-semibold leading-[22px] text-[#555555]'>
          {data.name ?? '차량이름 없음'}
        </div>
        <div className='mt-2 text-base font-semibold text-[#DC0000]'>
          {data.price
            ? `${Math.floor((data.price.min + data.price.max) / 20000).toLocaleString()} 만원`
            : '금액없음'}
        </div>
        <div className='mt-1.5 flex justify-between items-center'>
          <div className='flex flex-row'>
            <div className='h-7 bg-[#F7F7FB] px-2 leading-7 text-[#555555]'>
              {data.car_category?.name ?? '타입없음'}
            </div>
          </div>
        </div>
        <Link
          to={`/purchase/brand?carModelId=${data.id}`}
          className='mt-6 flex h-[52px] items-center justify-center rounded-md border border-[#E5E5EC] text-base'
        >
          견적 확인하기
        </Link>
      </div>
    </>
  );
}

function Star({
  fill,
  width,
  height,
}: {
  fill: string;
  width: number;
  height: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.247 3.52291L11.7012 8.21333H16.4042C16.6576 8.21333 16.7644 8.55739 16.5576 8.71187L12.7552 11.6118L14.2095 16.3022C14.2895 16.555 14.0093 16.7726 13.8025 16.6111L10.0002 13.7112L6.19779 16.6111C5.99099 16.7656 5.71082 16.555 5.79087 16.3022L7.24511 11.6118L3.44274 8.71187C3.23594 8.55739 3.34267 8.21333 3.59617 8.21333H8.2991L9.75334 3.52291C9.83339 3.27014 10.1669 3.27014 10.247 3.52291Z'
        fill={fill}
        stroke={fill}
        strokeWidth='1.66667'
        strokeLinejoin='round'
      />
    </svg>
  );
}

const InsuranceBannerOrder: InsuranceCompany[] = [
  '삼성화재',
  'AXA 손해보험',
  '메리츠화재',
  '한화손해보험',
  '하나손해보험',
  '캐롯손해보험',
  '현대해상',
  'DB손해보험',
  'KB손해보험',
];

// 이용후기
function ReviewContainer() {
  // 이용 후기용 스테이트
  const [reviewSwiper, setReviewSwiper] = useState<SwiperCore | null>(null);
  const [reviewPage, setReviewPage] = useState(1);
  return (
    <div className='mt-3 bg-[#7273F9] px-4 py-6 pb-8 text-white'>
      <div className='text-xl font-medium'>이용후기</div>
      <div className='flex justify-between'>
        <div className='mt-2 text-xs text-[#D9D9D9]'>
          많은 분들이 좋은 조건으로 빠른 거래했어요!
        </div>
        <div className='flex items-center gap-1'>
          <div
            className={cn(
              'flex h-4 w-4 items-center justify-center text-[#CACAD7]',
              reviewPage > 1 && 'text-white cursor-pointer',
            )}
            onClick={() => reviewSwiper?.slidePrev()}
          >
            <ChevronLeft className='h-4 w-4' />
          </div>
          <div>
            <span>{reviewPage}</span>/4
          </div>
          <div
            className={cn(
              'flex h-4 w-4 items-center justify-center text-[#CACAD7]',
              reviewPage < 4 && 'text-white cursor-pointer',
            )}
            onClick={() => reviewSwiper?.slideNext()}
          >
            <ChevronRight className='h-4 w-4' />
          </div>
        </div>
      </div>
      <div className='-mx-4 mt-10'>
        <Swiper
          slidesPerView={1}
          onRealIndexChange={(swiper: SwiperClass) =>
            setReviewPage(swiper.realIndex + 1)
          }
          onSwiper={(swiper: SwiperClass) => setReviewSwiper(swiper)}
        >
          <SwiperSlide className='px-4'>
            <div className='h-[337px] rounded-xl bg-white bg-review-icon bg-right-bottom bg-no-repeat px-4 py-6'>
              <div className='flex justify-between border-b border-[#F1F1F5] pb-6'>
                <div>
                  <div className='inline-flex rounded-2xl bg-[#111111] px-[15px]'>
                    내 차 팔기
                  </div>
                  <div className='mt-2 flex gap-1.5'>
                    <div className='flex'>
                      {Array.from({ length: 5 }).map((_, i) => (
                        <Star key={i} fill='#FFAA00' width={20} height={20} />
                      ))}
                    </div>
                    <div className='text-[#555555]'>정민서</div>
                  </div>
                </div>
                <div>
                  <img src='/assets/images/v2/sell-car.svg' alt='' />
                </div>
              </div>
              <div className='mt-6 leading-8 text-[#111111]'>
                무엇보다도 가격을 최고가로 받을 수 있는 게 좋은 앱이네요.
                훌륭합니다 모든 과정이 빠르고 간단합니다 입찰제 중고차 판매가
                마음에 듭니다.
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className='px-4'>
            <div className='h-[337px] rounded-xl bg-white bg-review-icon bg-right-bottom bg-no-repeat px-4 py-6'>
              <div className='flex justify-between border-b border-[#F1F1F5] pb-6'>
                <div>
                  <div className='inline-flex rounded-2xl bg-[#111111] px-[15px]'>
                    신차구매
                  </div>
                  <div className='mt-2 flex gap-1.5'>
                    <div className='flex'>
                      {Array.from({ length: 5 }).map((_, i) => (
                        <Star key={i} fill='#FFAA00' width={20} height={20} />
                      ))}
                    </div>
                    <div className='text-[#555555]'>림아</div>
                  </div>
                </div>
                <div>
                  <img src='/assets/images/v2/sell-car.svg' alt='' />
                </div>
              </div>
              <div className='mt-6 leading-8 text-[#111111]'>
                앱 뛰어 납니다. 정말 좋네요. 신차 구입을 했어요. 많은 혜택으로
                만족스러운 구매를 했네요
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className='px-4'>
            <div className='h-[337px] rounded-xl bg-white bg-review-icon bg-right-bottom bg-no-repeat px-4 py-6'>
              <div className='flex justify-between border-b border-[#F1F1F5] pb-6'>
                <div>
                  <div className='inline-flex rounded-2xl bg-[#111111] px-[15px]'>
                    내차팔기
                  </div>
                  <div className='mt-2 flex gap-1.5'>
                    <div className='flex'>
                      {Array.from({ length: 5 }).map((_, i) => (
                        <Star key={i} fill='#FFAA00' width={20} height={20} />
                      ))}
                    </div>
                    <div className='text-[#555555]'>하호랑</div>
                  </div>
                </div>
                <div>
                  <img src='/assets/images/v2/sell-car.svg' alt='' />
                </div>
              </div>
              <div className='mt-6 leading-8 text-[#111111]'>
                중고차를 판매할때는 카사요를 쓰는게 이득이었어요. 여러군데
                알아봤는데 편리함이나 가격적으로 여기가 최고였음 만족합니다~
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className='px-4'>
            <div className='h-[337px] rounded-xl bg-white bg-review-icon bg-right-bottom bg-no-repeat px-4 py-6'>
              <div className='flex justify-between border-b border-[#F1F1F5] pb-6'>
                <div>
                  <div className='inline-flex rounded-2xl bg-[#111111] px-[15px]'>
                    신차구매
                  </div>
                  <div className='mt-2 flex gap-1.5'>
                    <div className='flex'>
                      {Array.from({ length: 5 }).map((_, i) => (
                        <Star key={i} fill='#FFAA00' width={20} height={20} />
                      ))}
                    </div>
                    <div className='text-[#555555]'>태익김</div>
                  </div>
                </div>
                <div>
                  <img src='/assets/images/v2/sell-car.svg' alt='' />
                </div>
              </div>
              <div className='mt-6 leading-8 text-[#111111]'>
                새차 구입할 때 많은 혜택도 받았어요 추천합니다. 편리하게 차를
                사고 파는게 가능해서 넘 좋았어요.
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

// 메인 스와이퍼
function MainSwiperContainer() {
  const { loginMember } = useMemberStore();
  const navigate = useNavigate();
  // 메인 스와이퍼용 스테이트
  const [page, setPage] = useState(1);
  // 메인 배너용 스와이퍼 상태
  const [bannerSwiper, setBannerSwiper] = useState<SwiperCore | null>(null);
  const [isVideoDrawerOpen, setIsVideoDrawerOpen] = useState<boolean>(false);
  const audioFiles = [
    '/assets/audio/carsayoA.wav',
    '/assets/audio/carsayoB.wav',
    '/assets/audio/carsayoC.wav',
    '/assets/audio/carsayoD.wav',
  ];
  const audioRef = useRef<HTMLAudioElement>(null);
  const [audioPlay, setAudioPlay] = useState<boolean | null>(null);
  const { initPayment } = usePayment();
  const testPayment = () => {
    initPayment({
      amount: '500',
      name: '테스트결제시도',
      redirectPath: 'main',
      failedPath: 'error',
    });
  };

  const playRandomAudio = () => {
    if (audioPlay) {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setAudioPlay(null);
    } else {
      const randomIndex = Math.floor(Math.random() * audioFiles.length);
      if (audioRef.current) {
        audioRef.current.src = audioFiles[randomIndex];
        audioRef.current.addEventListener(
          'ended',
          () => {
            setAudioPlay(null);
          },
          { once: true },
        );
        audioRef.current.play();
        setAudioPlay(true);
      }
    }
  };
  const handleClickEvents = {
    moveToInsurance: () => {
      navigate('/static/insurance');
    },
    moveToCharacter: () => {
      navigate('/static/character');
    },
    moveToPromotion: () => {
      navigate('/static/promotion');
    },
    openCooconInsurance: () => {
      addHistoryInsuranceCooconClick({
        from: `app${location.pathname}`,
        target: '쿠콘',
      });
      if (process.env.REACT_APP_NODE_ENV === 'development') {
        window.open(
          'https://insu-dev.coocon.co.kr/bridge.act?org_cd=RUQBBRKU&browser_type=2',
        );
        return;
      }
      window.open(
        'https://insu.coocon.net/bridge.act?org_cd=RUQBBRKU&browser_type=2',
      );
    },
  };
  return (
    <>
      <div className='main-swiper__swiper'>
        <Swiper
          modules={[Autoplay]}
          centeredSlides={true}
          slidesPerView={'auto'}
          slidesPerGroup={1}
          loop={true}
          autoplay={{ delay: 3000 }}
          onRealIndexChange={(swiper: SwiperClass) => {
            setPage(swiper.realIndex + 1);
          }}
          onSwiper={setBannerSwiper}
          navigation={{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
          }}
        >
          <SwiperSlide>
            <div
              className='swiper__type01'
              onClick={handleClickEvents.moveToInsurance}
              style={{
                background:
                  'linear-gradient(0deg, #3DCEB6, #3DCEB6),linear-gradient(52.16deg, #3DCAB3 60.12%, #2DA590 100%)',
              }}
            >
              <div>
                <span>무이자로 가입하는</span>
                <h3>
                  <span>9개 보험사 다이렉트</span>
                  <span>자동차 보험</span>
                </h3>
                <p>삼성, 캐롯, 하나, 현대, AXA, DB, KB, 메리츠, 한화</p>
              </div>
              <img
                src='/assets/images/svg/m-main-swiper01.svg'
                alt='무이자가입아이콘'
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className='swiper__type02'
              style={{
                background: 'url(/assets/images/svg/premium-bg.svg)',
              }}
            >
              <div onClick={handleClickEvents.moveToCharacter}>
                <div>
                  <h3>
                    <span style={{ fontFamily: 'Times New Roman' }}>Car</span>
                    <span style={{ fontFamily: 'Times New Roman' }}>
                      Heroes Rise
                    </span>
                  </h3>
                  <span>
                    <UnderlineSketch fill='#FFE492' width='80' height='10' />
                  </span>
                  <span className='star-effect'>
                    <img
                      src='/assets/images/svg/star-effect.svg'
                      alt='별모양'
                    />
                  </span>
                </div>
                <h4>
                  <img
                    src='/assets/images/png/m-menu-logo.png'
                    alt='작은심볼로고'
                  />
                </h4>
                <img
                  src='/assets/images/svg/m-menu-character02.svg'
                  alt='카사요히어로즈메뉴이미지02'
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='swiper__type03'>
              <div onClick={handleClickEvents.moveToPromotion}>
                <div>
                  <h3>
                    <strong style={{ fontFamily: 'Times New Roman' }}>
                      Premium Service
                    </strong>
                    <span style={{ fontFamily: 'Times New Roman' }}>
                      With Car Heroes
                    </span>
                  </h3>
                </div>
                <img
                  src='/assets/images/svg/m-menu-character.svg'
                  alt='카사요히어로즈메뉴이미지'
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className='swiper__type04'
              style={{ backgroundColor: '#FFD600' }}
            >
              <h3>
                <CarsayoCI fill='#111' />
              </h3>
              <p>브랜드 오디오 클립</p>
              <button onClick={playRandomAudio}>
                {audioPlay ? (
                  <div>
                    <span>
                      <img
                        src='/assets/images/svg/stop.svg'
                        alt='정지 아이콘'
                      />
                      <span className='animate-ping bg-yellow-500 absolute inline-flex w-10 h-10 rounded-full opacity-25'></span>
                    </span>
                    <ul
                      className={`audio-visualizer ${audioPlay ? 'playing' : ''}`}
                    >
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                ) : (
                  <img
                    src='/assets/images/svg/m-main-swiper-playBtn.svg'
                    alt='오디오 재생 아이콘'
                  />
                )}

                <audio ref={audioRef}>
                  <track
                    src='captions_en.vtt'
                    kind='captions'
                    label='english_captions'
                  />
                </audio>
              </button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='swiper__type05'>
              <h3>
                <CarsayoCI />
              </h3>
              <p>자동차 쇼핑 통합 플랫폼</p>
              <button
                onClick={() => {
                  setIsVideoDrawerOpen(true);
                }}
              >
                브랜드 필름 보기
              </button>
              <img
                src='/assets/images/svg/m-main-swiper-05.svg'
                alt='카공이 캐릭터 이미지'
              />
            </div>
          </SwiperSlide>
        </Swiper>
        <div className='pagination'>
          <div
            className='swiper-button-prev'
            onClick={() => bannerSwiper?.slidePrev()}
          ></div>
          <div className='swiper-pagination'>
            <span>{page}</span>/5
          </div>
          <div
            className='swiper-button-next'
            onClick={() => bannerSwiper?.slideNext()}
          ></div>
        </div>
      </div>
      <div className={`main-swiper__menu bgColor-${page}`}>
        <div>
          <h2>자동차 쇼핑 통합 플랫폼</h2>
          {(process.env.NODE_ENV !== 'production' ||
            (process.env.NODE_ENV === 'production' &&
              loginMember?.id === 'test-carsayo-customer')) && (
            <p className='text-blue-600' onClick={testPayment}>
              테스트결제시도버 튼
            </p>
          )}
          <ul>
            <li
              onClick={() => {
                navigate('/purchase/brand?purchaseType=leaseRent');
              }}
            >
              <div>
                <img src='/assets/images/svg/rentCar.svg' alt='' />
              </div>
              <p>장기렌트</p>
            </li>
            <li
              onClick={() => {
                navigate('/purchase/brand?purchaseType=leaseRent');
              }}
            >
              <div>
                <img src='/assets/images/svg/leaseCar.svg' alt='' />
              </div>
              <p>리스</p>
            </li>
            <li
              onClick={() => {
                navigate('/purchase/brand?purchaseType=newCar');
              }}
            >
              <div>
                <img src='/assets/images/svg/newCar.svg' alt='' />
              </div>
              <p>신차 구매</p>
            </li>
            <li
              onClick={() => {
                navigate('/selling/landing');
              }}
            >
              <div>
                <img src='/assets/images/svg/salesCar.svg' alt='' />
              </div>
              <p>내 차 팔기</p>
            </li>
          </ul>
        </div>
      </div>
      <VideoDrawer
        isOpen={isVideoDrawerOpen}
        setIsOpen={setIsVideoDrawerOpen}
      ></VideoDrawer>
    </>
  );
}

// AD형 보험 이동 banner
function ADInsuranceBanner() {
  const navigate = useNavigate();
  const handleClickEvents = {
    moveToInsurance: () => {
      navigate('/static/insurance');
    },
  };

  return (
    <div className='ad-banner'>
      <div onClick={handleClickEvents.moveToInsurance}>
        <h4>비교하고 최저가 보험 결정하셨다면</h4>
        <button>혜택을 더해서 자동차 보험 가입하기</button>
        <div>
          <img
            src='/assets/images/svg/ad-insurance-img.svg'
            alt='혜택 담은 자동차 이미지'
          />
          <p>각 카드사별 최대 3만원 혜택을 확인하세요</p>
        </div>
      </div>
    </div>
  );
}

function CustomerMainPage() {
  const [expanded, setExpanded] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isCopyright, setIsCopyright] = useState(false);
  const [interestCarList, setInterestCarList] = useState<InterestCarInfo[]>();
  const [currentBestCar, setCurrentBestCar] =
    useState<PopularCarCategoryId>('domestic');
  const [popularCarDomesticList, setPopularCarDomesticList] =
    useState<PopularCar[]>();
  const [popularCarImportedList, setPopularCarImportedList] =
    useState<PopularCar[]>();
  const [popularCarSuvList, setPopularCarSuvList] = useState<PopularCar[]>();

  const { loginMember } = useMemberStore();
  const { setIsBottomTabbar } = useSystemStore();

  // 견적 통계용 스테이트
  const [quoteSwiper, setQuoteSwiper] = useState<SwiperCore | null>(null);
  const [quotePage, setQuotePage] = useState(1);

  const handleVoidEvents = {
    checkLogin: async () => {
      if (!loginMember) {
        const {
          data: { isLogined },
        } = await isLogin();
        if (!isLogined) {
          navigate('/auth/signup', { replace: true });
          return;
        }
      }
    },

    getPopularCarList: async () => {
      const carListArray: PopularCarCategory[] = await getPopularCarList();
      if (carListArray) {
        setPopularCarDomesticList(
          carListArray
            .filter((item) => item.id === 'domestic')
            .map((item) => item.carList)
            .flat(),
        );
        setPopularCarImportedList(
          carListArray
            .filter((item) => item.id === 'imported')
            .map((item) => item.carList)
            .flat(),
        );
        setPopularCarSuvList(
          carListArray
            .filter((item) => item.id === 'suv')
            .map((item) => item.carList)
            .flat(),
        );
      }
      return;
    },
    getInterestCarList: async () => {
      if (!loginMember) {
        CarsayoToast.error('로그인을 진행해주세요!');
        navigate('/auth/login');
      } else {
        const result: InterestCarInfo[] = await getInterestCarList();
        if (result) {
          setInterestCarList(result);
        }
      }
    },
  };

  const handleClickEvents = {
    call: (callNumber: string) => {
      const telUrl = 'tel:' + callNumber;
      window.location.href = telUrl;
    },
    selecteInterestCar: async (carId: number, isInterest: boolean) => {
      try {
        const result: UpdateInterestCarDTO = await selectInterestCar(
          carId,
          !isInterest,
        );
        if (result) {
          handleVoidEvents.getPopularCarList();
          if (result.isInterest) {
            CarsayoToast.text('관심차량으로 등록했습니다.');
          } else {
            CarsayoToast.text('관심차량을 해제했습니다.');
          }
          handleVoidEvents.getInterestCarList();
          return;
        }
      } catch (error: any) {
        console.error(error);
        CarsayoToast.error(error.response.data.message);
        return;
      }
    },
    moveToInsurance: () => {
      navigate('/static/insurance');
    },
    openCooconInsurance: () => {
      addHistoryInsuranceCooconClick({
        from: `app${location.pathname}`,
        target: '쿠콘',
      });
      if (process.env.REACT_APP_NODE_ENV === 'development') {
        window.open(
          'https://insu-dev.coocon.co.kr/bridge.act?org_cd=RUQBBRKU&browser_type=2',
        );
        return;
      }
      window.open(
        'https://insu.coocon.net/bridge.act?org_cd=RUQBBRKU&browser_type=2',
      );
    },
  };

  useLayoutEffect(() => {
    setIsBottomTabbar(true);
    if (loginMember) setFcmToken();
  }, []);

  useLayoutEffect(() => {
    handleVoidEvents.getPopularCarList();
  }, []);

  useLayoutEffect(() => {
    if (loginMember) handleVoidEvents.getInterestCarList();
  }, [loginMember]);

  // 내 관심차량 리스트
  function MyInterestCar() {
    const handleSetInterestCarList = async () => {
      const carListArray: PopularCarCategory[] = await getPopularCarList();
      if (carListArray) {
        setPopularCarDomesticList(
          carListArray
            .filter((item) => item.id === 'domestic')
            .map((item) => item.carList)
            .flat(),
        );
        setPopularCarImportedList(
          carListArray
            .filter((item) => item.id === 'imported')
            .map((item) => item.carList)
            .flat(),
        );
        setPopularCarSuvList(
          carListArray
            .filter((item) => item.id === 'suv')
            .map((item) => item.carList)
            .flat(),
        );
      }
      const result: InterestCarInfo[] = await getInterestCarList();
      if (result) {
        setInterestCarList(result);
      }
    };
    if (interestCarList && interestCarList.length > 0) {
      return (
        <Swiper
          modules={[Pagination]}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true }}
          className='white-banner -mx-4 h-full px-4'
        >
          {interestCarList.map((item, index) => (
            <SwiperSlide key={`interest-${index}`}>
              <InterestTemplate
                data={item}
                setInterestCarList={handleSetInterestCarList}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      );
    } else {
      return (
        <div
          className={classNames(
            'mt-4 w-full h-[46.3vh] rounded-lg flex flex-col justify-end items-center gap-[1.97vh] py-[2.9vh]',
            'bg-interest-banner bg-no-repeat bg-cover bg-center',
          )}
        >
          <div className='flex flex-col items-center text-[#fff]'>
            <p className='mb-[2px]'>카사요</p>
            <p className='text-[24px] leading-[34px] font-[600]'>
              고객님을 위한
              <br /> 관심 차량 설정
            </p>
          </div>
          <p className='text-[#fff] text-[13px]'>
            관련 소식을 빠르게 알려드립니다!
          </p>
        </div>
      );
    }
  }
  // 차량 조회 순위
  function BestCarSortingList() {
    return (
      <>
        {/* 국산차 리스트 */}
        <div
          className={classNames(
            'transition-all',
            currentBestCar === 'domestic'
              ? 'w-full block h-auto opacity-100 translate-y-0'
              : 'w-0 h-0 hidden opacity-0 translate-y-[-400px]',
          )}
        >
          {popularCarDomesticList && popularCarDomesticList.length > 0 && (
            <ol className='w-full flex flex-col gap-6'>
              {popularCarDomesticList.map((item, index) => {
                return (
                  <li
                    key={`bestCar_${item.id}`}
                    className='flex flex-row gap-[3.2vw] justify-start items-stretch cursor-pointer'
                    onClick={() =>
                      navigate(`/purchase/brand?carModelId=${item.model.id}`)
                    }
                  >
                    <div className='w-[32vw] relative rounded-[12px] px-[12px] pt-[20px] pb-[16px] bg-[#00000008] flex justify-center items-center'>
                      <span className='absolute top-0 left-0 h-[20px] w-auto px-2 bg-[#111111] text-[#fff] text-[11px]'>
                        {index + 1}
                      </span>
                      {item.model.image_url ? (
                        <img
                          src={item.model.image_url}
                          alt='인기조회차량사진'
                          className='object-contain w-full h-full'
                        />
                      ) : (
                        <img
                          src={SampleCar}
                          alt='인기조회차량사진'
                          className='object-contain'
                        />
                      )}
                    </div>
                    <div className='grow'>
                      <div className='flex flex-row justify-between items-center w-full'>
                        <p className='text-[13px] text-[#767676]'>
                          {item.maker.name ? item.maker.name : '제조사명 없음'}
                        </p>
                        <button
                          className='w-[20px] h-[20px] text-transparent indent-[-9999px]'
                          onClick={(event) => {
                            event.stopPropagation();
                            handleClickEvents.selecteInterestCar(
                              item.model.id,
                              item.isInterest,
                            );
                          }}
                        >
                          <HeartIcon
                            fill={item.isInterest ? '#DC0000' : undefined}
                            stroke={item.isInterest ? '#DC0000' : undefined}
                          />
                          관심차량버튼
                        </button>
                      </div>
                      <p className='text-[15px] text-[#555555]'>
                        {item.name ? item.name : '모델명 없음'}
                      </p>
                      <p className='font-[600] text-[#111111] mb-[6px]'>
                        <span className='mr-[3px]'>
                          {item.model.price.min
                            ? Math.floor(
                                item.model.price.min / 10000,
                              ).toLocaleString()
                            : 0}
                        </span>
                        ~
                        <span className='mx-[3px]'>
                          {item.model.price.max
                            ? Math.floor(
                                item.model.price.max / 10000,
                              ).toLocaleString()
                            : 100}
                        </span>
                        만원
                      </p>
                      <div className='flex flex-row justify-between items-center'>
                        <span className='text-[11px] text-[#111111] inline-flex justify-center items-center bg-[#F7F7FB] py-[2px] px-[4px]'>
                          {item.model?.car_category
                            ? item.model.car_category.name
                            : '카테고리 없음'}
                        </span>
                        <Link
                          to={`/purchase/brand?carModelId=${item.model.id}`}
                          className='text-[12px] cursor-pointer text-[#7273F9] inline-flex justify-center items-center gap-[2px]'
                        >
                          견적확인
                          <ArrowRight />
                        </Link>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ol>
          )}
        </div>
        {/* 수입차 리스트 */}
        <div
          className={classNames(
            'transition-all',
            currentBestCar === 'imported'
              ? 'w-full block h-auto opacity-100 translate-y-0'
              : 'w-0 h-0 hidden opacity-0 translate-y-[-400px]',
          )}
        >
          {popularCarImportedList && popularCarImportedList.length > 0 && (
            <ol className='w-full flex flex-col gap-6'>
              {popularCarImportedList.map((item, index) => {
                return (
                  <li
                    key={`bestCar_${item.id}`}
                    className='flex flex-row gap-[3.2vw] justify-start items-stretch cursor-pointer'
                    onClick={() =>
                      navigate(`/purchase/brand?carModelId=${item.model.id}`)
                    }
                  >
                    <div className='w-[32vw] relative rounded-[12px] px-[12px] pt-[20px] pb-[16px] bg-[#00000008] flex justify-center items-center'>
                      <span className='absolute top-0 left-0 h-[20px] w-auto px-2 bg-[#111111] text-[#fff] text-[11px]'>
                        {index + 1}
                      </span>
                      {item.model.image_url ? (
                        <img
                          src={item.model.image_url}
                          alt='인기조회차량사진'
                          className='object-contain w-full h-full'
                        />
                      ) : (
                        <img
                          src={SampleCar}
                          alt='인기조회차량사진'
                          className='object-contain'
                        />
                      )}
                    </div>
                    <div className='grow'>
                      <div className='flex flex-row justify-between items-center w-full'>
                        <p className='text-[13px] text-[#767676]'>
                          {item.maker.name ? item.maker.name : '제조사명 없음'}
                        </p>
                        <button
                          className='w-[20px] h-[20px] text-transparent indent-[-9999px]'
                          onClick={(event) => {
                            event.stopPropagation();
                            handleClickEvents.selecteInterestCar(
                              item.model.id,
                              item.isInterest,
                            );
                          }}
                        >
                          <HeartIcon
                            fill={item.isInterest ? '#DC0000' : undefined}
                            stroke={item.isInterest ? '#DC0000' : undefined}
                          />
                          관심차량버튼
                        </button>
                      </div>
                      <p className='text-[15px] text-[#555555]'>
                        {item.name ? item.name : '모델명 없음'}
                      </p>
                      <p className='font-[600] text-[#111111] mb-[6px]'>
                        <span className='mr-[3px]'>
                          {item.model.price.min
                            ? Math.floor(
                                item.model.price.min / 10000,
                              ).toLocaleString()
                            : 0}
                        </span>
                        ~
                        <span className='mx-[3px]'>
                          {item.model.price.max
                            ? Math.floor(
                                item.model.price.max / 10000,
                              ).toLocaleString()
                            : 100}
                        </span>
                        만원
                      </p>
                      <div className='flex flex-row justify-between items-center'>
                        <span className='text-[11px] text-[#111111] inline-flex justify-center items-center bg-[#F7F7FB] py-[2px] px-[4px]'>
                          {item.model?.car_category
                            ? item.model.car_category.name
                            : '카테고리 없음'}
                        </span>
                        <Link
                          to={`/purchase/brand?carModelId=${item.model.id}`}
                          className='text-[12px] cursor-pointer text-[#7273F9] inline-flex justify-center items-center gap-[2px]'
                        >
                          견적확인
                          <ArrowRight />
                        </Link>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ol>
          )}
        </div>
        {/* SUV 리스트 */}
        <div
          className={classNames(
            'transition-all',
            currentBestCar === 'suv'
              ? 'w-full block h-auto opacity-100 translate-y-0'
              : 'w-0 h-0 hidden opacity-0 translate-y-[-400px]',
          )}
        >
          {popularCarSuvList && popularCarSuvList.length > 0 && (
            <ol className='w-full flex flex-col gap-6'>
              {popularCarSuvList.map((item, index) => {
                return (
                  <li
                    key={`bestCar_${item.id}`}
                    className='flex flex-row gap-[3.2vw] justify-start items-stretch cursor-pointer'
                    onClick={() =>
                      navigate(`/purchase/brand?carModelId=${item.model.id}`)
                    }
                  >
                    <div className='w-[32vw] relative rounded-[12px] px-[12px] pt-[20px] pb-[16px] bg-[#00000008] flex justify-center items-center'>
                      <span className='absolute top-0 left-0 h-[20px] w-auto px-2 bg-[#111111] text-[#fff] text-[11px]'>
                        {index + 1}
                      </span>
                      {item.model.image_url ? (
                        <img
                          src={item.model.image_url}
                          alt='인기조회차량사진'
                          className='object-contain w-full h-full'
                        />
                      ) : (
                        <img
                          src={SampleCar}
                          alt='인기조회차량사진'
                          className='object-contain'
                        />
                      )}
                    </div>
                    <div className='grow'>
                      <div className='flex flex-row justify-between items-center w-full'>
                        <p className='text-[13px] text-[#767676]'>
                          {item.maker.name ? item.maker.name : '제조사명 없음'}
                        </p>
                        <button
                          className='w-[20px] h-[20px] text-transparent indent-[-9999px]'
                          onClick={(event) => {
                            event.stopPropagation();
                            handleClickEvents.selecteInterestCar(
                              item.model.id,
                              item.isInterest,
                            );
                          }}
                        >
                          <HeartIcon
                            fill={item.isInterest ? '#DC0000' : undefined}
                            stroke={item.isInterest ? '#DC0000' : undefined}
                          />
                          관심차량버튼
                        </button>
                      </div>
                      <p className='text-[15px] text-[#555555]'>
                        {item.name ? item.name : '모델명 없음'}
                      </p>
                      <p className='font-[600] text-[#111111] mb-[6px]'>
                        <span className='mr-[3px]'>
                          {item.model.price.min
                            ? Math.floor(
                                item.model.price.min / 10000,
                              ).toLocaleString()
                            : 0}
                        </span>
                        ~
                        <span className='mx-[3px]'>
                          {item.model.price.max
                            ? Math.floor(
                                item.model.price.max / 10000,
                              ).toLocaleString()
                            : 100}
                        </span>
                        만원
                      </p>
                      <div className='flex flex-row justify-between items-center'>
                        <span className='text-[11px] text-[#111111] inline-flex justify-center items-center bg-[#F7F7FB] py-[2px] px-[4px]'>
                          {item.model?.car_category
                            ? item.model.car_category.name
                            : '카테고리 없음'}
                        </span>
                        <Link
                          to={`/purchase/brand?carModelId=${item.model.id}`}
                          className='text-[12px] cursor-pointer text-[#7273F9] inline-flex justify-center items-center gap-[2px]'
                        >
                          견적확인
                          <ArrowRight />
                        </Link>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ol>
          )}
        </div>
      </>
    );
  }

  return (
    <main className='bg-[#F1F1F5] text-sm'>
      <section className='main-swiper bg-white'>
        {/* 메인 스와이퍼 영역 */}
        <MainSwiperContainer />
      </section>

      {/* 다이렉트 자동차보험 배너 */}
      <section
        onClick={handleClickEvents.openCooconInsurance}
        className='relative flex flex-col justify-between px-6 pt-6 pb-3 w-full h-32 bg-[#2F2DA0]'
      >
        <div className='absolute top-6 right-6 tiny:right-2'>
          <div>
            {InsuranceBannerOrder.map((el) => {
              const targetCompanyName = el as InsuranceCompany;
              const targetCompanyData = InsuranceCompanyData[targetCompanyName];
              return (
                <img
                  key={targetCompanyName}
                  className={cn(
                    'md:ml-2 tiny:ml-[-20px] ml-[-18px] float-left w-8 h-8',
                  )}
                  src={targetCompanyData.design.logo.svg}
                  alt='보험사 로고'
                />
              );
            })}
          </div>
        </div>
        <div className='flex flex-col gap-2 text-white'>
          <div className='font-medium text-[20px]'>다이렉트 자동차보험</div>
          <div className='text-[14px] tracking-tighter'>
            9개 보험사 한번에 확인하기
          </div>
        </div>
        <div className='flex gap-2 justify-between text-white'>
          <div className='text-[12px] text-gray-400'>
            손해보험협회 심의필 제129622호(2024.08.01~2025.07.31)
          </div>
          <div className='w-6 flex flex-col justify-end'>
            <div className='text-[12px] text-gray-400'>쿠콘</div>
          </div>
        </div>
      </section>
      {/* AD형 자동차보험 배너 */}
      <section className='bg-white pb-2'>
        <ADInsuranceBanner />
      </section>

      {/* bg-gradient-to-t from-white to-[#F1F1F5] */}
      <div className=' bg-white p-4 py-10'>
        {loginMember && loginMember.type === 'dealer' ? (
          <div className='flex items-center justify-between lg:max-w-[600px] lg:mx-auto md:max-w-[600px] md:mx-auto'>
            <div className='flex text-[13px] font-semibold leading-6 tracking-[-0.325px] text-[#7273F9]'>
              {loginMember.dealerInfo?.signupState === 'accepted' ? (
                <>
                  <img
                    className='w-[22px] h-[22px] '
                    src='/assets/images/v2/starCollar.svg'
                    alt=''
                  />
                  카사요 인증
                </>
              ) : (
                <>
                  <img
                    className='w-[22px] h-[22px] mr-2'
                    src='/assets/images/v2/purchaseProcess.svg'
                    alt=''
                  />
                  <span className='text-red-600'>카사요 미인증</span>
                </>
              )}
            </div>
            <div className='flex font-medium text-[#555] text-[14px] leading-5 tracking-[-0.35px]'>
              카 매니저{' '}
              <span className='ml-1 text-[#111]'>
                {loginMember.name_real
                  ? loginMember.name_real
                  : loginMember.name_nick}
                님
              </span>
              {loginMember.dealerInfo?.signupState === 'accepted' ? (
                <div className='ml-2 h-5 items-center border border-green-700 px-1 text-[11px] text-green-700'>
                  승인{' '}
                  {loginMember.dealerInfo?.accepted_at
                    ? dayjs(loginMember.dealerInfo?.accepted_at).format('MM.DD')
                    : ''}
                </div>
              ) : loginMember.dealerInfo?.signupState === 'rejected' ? (
                <div className='ml-2 h-5 items-center border border-red-700 px-1 text-[11px] text-red-700'>
                  거부{' '}
                  {loginMember.dealerInfo?.rejected_at
                    ? dayjs(loginMember.dealerInfo?.rejected_at).format('MM.DD')
                    : ''}
                </div>
              ) : (
                <div className='ml-2 h-5 items-center border border-gray-600 px-1 text-[11px] text-gray-600'>
                  승인대기{' '}
                  {loginMember.dealerInfo?.submitted_at
                    ? dayjs(loginMember.dealerInfo?.submitted_at).format(
                        'MM.DD',
                      )
                    : ''}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className='flex items-center justify-between lg:max-w-[600px] lg:mx-auto md:max-w-[600px] md:mx-auto'>
            <div className='h-6 bg-[#612694] text-[12px] px-1.5 leading-6 text-white'>
              카사요 약속
            </div>
            <div className=''>투명하고 체계적인 판매 / 매입 토탈 시스템</div>
          </div>
        )}

        {loginMember && (
          <Link
            to={'/quote'}
            className='mt-4 flex justify-between max-w-[600px] mx-auto'
          >
            <div className='flex h-[34px] items-center gap-1.5 border border-[#E5E5EC] px-2 lg:w-[20%] lg:flex lg:justify-center lg:items-center md:w-[20%] md:flex md:justify-center md:items-center'>
              {loginMember.customerInfo && (
                <>
                  <div className='text-[13px] text-[#555555]'>견적요청</div>
                  <div className='text-[13px] text-[#7273F9]'>
                    {loginMember.customerInfo.count?.order?.submitted ?? 0}
                  </div>
                </>
              )}
              {loginMember.dealerInfo && (
                <>
                  <div className='text-[13px] text-[#555555]'>입찰 중</div>
                  <div className='text-[13px] text-[#7273F9]'>
                    {loginMember.dealerInfo.count?.order?.bidding ?? 0}
                  </div>
                </>
              )}
            </div>
            <div className='flex h-[34px] items-center gap-1.5 border border-[#E5E5EC] px-2 lg:w-[20%] lg:flex lg:justify-center lg:items-center md:w-[20%] md:flex md:justify-center md:items-center'>
              <div className='text-[13px] text-[#555555]'>계약진행</div>
              <div className='text-[13px] text-[#7273F9]'>
                {loginMember.customerInfo
                  ? loginMember.customerInfo?.count?.order?.progressing ?? 0
                  : loginMember.dealerInfo?.count?.order?.progressing ?? 0 ?? 0}
              </div>
            </div>
            <div className='flex h-[34px] items-center gap-1.5 border border-[#E5E5EC] px-2 lg:w-[20%] lg:flex lg:justify-center lg:items-center md:w-[20%] md:flex md:justify-center md:items-center'>
              <div className='text-[13px] text-[#555555]'>계약완료</div>
              <div className='text-[13px] text-[#7273F9]'>
                {loginMember.customerInfo
                  ? loginMember.customerInfo?.count?.order?.finished ?? 0
                  : loginMember.dealerInfo?.count?.order?.finished ?? 0}
              </div>
            </div>
            <div className='flex h-[34px] items-center gap-1.5 border border-[#E5E5EC] px-2 lg:w-[20%] lg:flex lg:justify-center lg:items-center md:w-[20%] md:flex md:justify-center md:items-center'>
              <div className='text-[13px] text-[#555555]'>취소/보류</div>
              <div className='text-[13px] text-[#7273F9]'>
                {loginMember.customerInfo
                  ? (loginMember.customerInfo?.count?.order?.canceled ?? 0) +
                    (loginMember.customerInfo?.count?.order?.holded ?? 0)
                  : (loginMember.dealerInfo?.count?.order?.canceled ?? 0) +
                    (loginMember.dealerInfo?.count?.order?.holded ?? 0)}
              </div>
            </div>
          </Link>
        )}
        {/* 견적카운트, 내역 */}
        {loginMember ? (
          process.env.REACT_APP_NODE_ENV === 'development' &&
          (loginMember && loginMember.type === 'dealer' ? (
            // 딜러용 견적 카운트
            <div>
              {loginMember.dealerInfo &&
              (loginMember.dealerInfo.count.order?.bidding ||
                loginMember.dealerInfo.count.order?.progressing) ? (
                <div className='w-full py-4 flex flex-col justify-center items-center'>
                  <div className='w-full border min-h-[110px] rounded-[4px] border-[#E5E5EC] py-4 mb-4'>
                    <Swiper
                      slidesPerView={2}
                      slidesPerGroup={2}
                      onRealIndexChange={(swiper: SwiperClass) =>
                        setQuotePage(Math.ceil((swiper.activeIndex + 1) / 2))
                      }
                      onSwiper={(swiper: SwiperClass) => setQuoteSwiper(swiper)}
                    >
                      <SwiperSlide className='px-[2vw]'>
                        <div className='flex flex-row justify-start items-center gap-[4.2vw]'>
                          <div className='w-[21.3vw] h-[21.3vw] border border-[#11111108] rounded-full p-[3px]'>
                            <div className='w-full h-full bg-[#11111108] rounded-full'>
                              <img
                                src={GoraniImg}
                                alt='샘플이미지'
                                className='rounded-full object-contain'
                              />
                            </div>
                          </div>
                          <div className='flex flex-col justify-start items-start gap-[4px] text-[11px]'>
                            <p className='inline-flex justify-center items-center bg-[#111111] px-[4px] text-white'>
                              리스
                            </p>
                            <p>
                              <span>48:00</span>남음
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className='px-[2vw]'>
                        <div className='flex flex-row justify-start items-center gap-[4.2vw]'>
                          <div className='w-[21.3vw] h-[21.3vw] border border-[#11111108] rounded-full p-[3px]'>
                            <div className='w-full h-full bg-[#11111108] rounded-full'>
                              <img
                                src={GoraniImg}
                                alt='샘플이미지'
                                className='rounded-full object-contain'
                              />
                            </div>
                          </div>
                          <div className='flex flex-col justify-start items-start gap-[4px] text-[11px]'>
                            <p className='inline-flex justify-center items-center bg-[#111111] px-[4px] text-white'>
                              장기렌트
                            </p>
                            <p>
                              <span>48:00</span>남음
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className='px-[2vw]'>
                        <div className='flex flex-row justify-start items-center gap-[4.2vw]'>
                          <div className='w-[21.3vw] h-[21.3vw] border border-[#11111108] rounded-full p-[3px]'>
                            <div className='w-full h-full bg-[#11111108] rounded-full'>
                              <img
                                src={GoraniImg}
                                alt='샘플이미지'
                                className='rounded-full object-contain'
                              />
                            </div>
                          </div>
                          <div className='flex flex-col justify-start items-start gap-[4px] text-[11px]'>
                            <p className='inline-flex justify-center items-center bg-[#111111] px-[4px] text-white'>
                              신차
                            </p>
                            <p>
                              <span>48:00</span>남음
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className='px-[2vw]'>
                        <div className='flex flex-row justify-start items-center gap-[4.2vw]'>
                          <div className='w-[21.3vw] h-[21.3vw] border border-[#11111108] rounded-full p-[3px]'>
                            <div className='w-full h-full bg-[#11111108] rounded-full'>
                              <img
                                src={GoraniImg}
                                alt='샘플이미지'
                                className='rounded-full object-contain'
                              />
                            </div>
                          </div>
                          <div className='flex flex-col justify-start items-start gap-[4px] text-[11px]'>
                            <p className='inline-flex justify-center items-center bg-[#111111] px-[4px] text-white'>
                              내차팔기
                            </p>
                            <p>
                              <span>48:00</span>남음
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                  <div className='inline-flex items-center gap-1 py-[5px] px-[12px] rounded-2xl bg-[#00000099]'>
                    <div
                      className={cn(
                        'flex h-4 w-4 items-center justify-center text-[#CACAD7]',
                        !quoteSwiper?.isBeginning && 'text-white',
                      )}
                      onClick={() => quoteSwiper?.slidePrev()}
                    >
                      <ChevronLeft className='h-4 w-4' />
                    </div>
                    <div className='text-[#fff]'>
                      <span className='text-[#fff]'>{quotePage}</span>/
                      <span
                        className={classNames(
                          quoteSwiper?.isEnd ? 'text-white' : 'text-[#CACAD7]',
                        )}
                      >
                        2
                      </span>
                    </div>
                    <div
                      className={cn(
                        'flex h-4 w-4 items-center justify-center text-[#CACAD7]',
                        !quoteSwiper?.isEnd && 'text-white',
                      )}
                      onClick={() => quoteSwiper?.slideNext()}
                    >
                      <ChevronRight className='h-4 w-4' />
                    </div>
                  </div>
                </div>
              ) : loginMember.dealerInfo?.signupState === 'submitted' ? (
                <div className='mt-4 flex h-[52px] rounded-[4px] items-center gap-[6px] justify-center bg-[#F7F7FB] font-normal'>
                  <span className='w-[14px] h-[18px] flex justify-center items-center'>
                    <img
                      src={NoQuoteIcon}
                      alt='견적없음아이콘'
                      className='object-contain'
                    />
                  </span>
                  <div>카사요 담당자가 가입 신청을 검토중입니다.</div>
                </div>
              ) : loginMember.dealerInfo?.signupState === 'rejected' ? (
                <div className='mt-4 flex flex-col rounded-[4px] items-center gap-[6px] justify-center bg-[#F7F7FB] font-normal'>
                  <div className='justify-center items-center pt-4 py-2'>
                    카사요 가입신청이{' '}
                    <span className='font-medium text-red-500'>거부</span>
                    되었습니다.
                  </div>
                  <Button
                    className='bg-red-600 text-white'
                    variant='outline'
                    onClick={() => {
                      navigate('/mypage/dealerSignupForm');
                    }}
                  >
                    가입신청 다시하기
                  </Button>
                </div>
              ) : (
                <div className='mt-4 flex h-[52px] rounded-[4px] items-center gap-[6px] justify-center bg-[#F7F7FB] font-normal'>
                  <span className='w-[14px] h-[18px] flex justify-center items-center'>
                    <img
                      src={NoQuoteIcon}
                      alt='견적없음아이콘'
                      className='object-contain'
                    />
                  </span>
                  <div>진행중인 계약이 없습니다.</div>
                </div>
              )}
            </div>
          ) : (
            // 일반용 견적 카운트
            <div>
              {loginMember.customerInfo &&
              loginMember.customerInfo.count.order?.progressing ? (
                <div className='w-full py-4 flex flex-col justify-center items-center'>
                  <div className='w-full border min-h-[110px] rounded-[4px] border-[#E5E5EC] py-4 mb-4'>
                    <Swiper
                      slidesPerView={1}
                      slidesPerGroup={1}
                      onRealIndexChange={(swiper: SwiperClass) =>
                        setQuotePage(swiper.activeIndex + 1)
                      }
                      onSwiper={(swiper: SwiperClass) => setQuoteSwiper(swiper)}
                    >
                      <SwiperSlide className='px-[2vw]'>
                        <div className='flex flex-row justify-start items-center gap-[2.6vw]'>
                          <div className='shrink w-[21.3vw] h-[21.3vw] border border-[#11111108] rounded-full p-[3px]'>
                            <div className='w-full h-full bg-[#11111108] rounded-full flex justify-center items-center'>
                              <img
                                src={GoraniImg}
                                alt='샘플이미지'
                                className='rounded-full object-fill'
                              />
                            </div>
                          </div>
                          <div className='flex flex-col justify-start items-start gap-2 text-[13px] py-2'>
                            <div className='w-full flex justify-between items-center'>
                              <div className='flex justify-start items-center gap-[6px]'>
                                <span className='text-[#7273F9] font-[600] flex justify-start items-center'>
                                  <img
                                    src={StarBadge}
                                    alt='인증마크 아이콘'
                                    className='object-contain'
                                  />
                                  카사요 인증
                                </span>
                                <span className='flex justify-center items-center text-[11px] leading-[16px] font-[600] px-1 bg-[#612694] rounded-[100px] text-white'>
                                  12
                                </span>
                              </div>
                              <div className='text-[#2F66BA]'>45:10 남음</div>
                            </div>
                            <p className='leading-[18px]'>
                              제네시스 G90 24년식 3.5 터보 프리미엄 패키지
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className='px-[2vw]'>
                        <div className='flex flex-row justify-start items-center gap-[2.6vw]'>
                          <div className='shrink w-[21.3vw] h-[21.3vw] border border-[#11111108] rounded-full p-[3px]'>
                            <div className='w-full h-full bg-[#11111108] rounded-full flex justify-center items-center'>
                              <img
                                src={GoraniImg}
                                alt='샘플이미지'
                                className='rounded-full object-fill'
                              />
                            </div>
                          </div>
                          <div className='flex flex-col justify-start items-start gap-2 text-[13px] py-2'>
                            <div className='w-full flex justify-between items-center'>
                              <div className='flex justify-start items-center gap-[6px]'>
                                <span className='text-[#7273F9] font-[600] flex justify-start items-center'>
                                  <img
                                    src={StarBadge}
                                    alt='인증마크 아이콘'
                                    className='object-contain'
                                  />
                                  카사요 인증
                                </span>
                                <span className='flex justify-center items-center text-[11px] leading-[16px] font-[600] px-1 bg-[#612694] rounded-[100px] text-white'>
                                  12
                                </span>
                              </div>
                              <div className='text-[#2F66BA]'>45:10 남음</div>
                            </div>
                            <p className='leading-[18px]'>
                              제네시스 G90 24년식 3.5 터보 프리미엄 패키지
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className='px-[2vw]'>
                        <div className='flex flex-row justify-start items-center gap-[2.6vw]'>
                          <div className='shrink w-[21.3vw] h-[21.3vw] border border-[#11111108] rounded-full p-[3px]'>
                            <div className='w-full h-full bg-[#11111108] rounded-full flex justify-center items-center'>
                              <img
                                src={GoraniImg}
                                alt='샘플이미지'
                                className='rounded-full object-fill'
                              />
                            </div>
                          </div>
                          <div className='flex flex-col justify-start items-start gap-2 text-[13px] py-2'>
                            <div className='w-full flex justify-between items-center'>
                              <div className='flex justify-start items-center gap-[6px]'>
                                <span className='text-[#7273F9] font-[600] flex justify-start items-center'>
                                  <img
                                    src={StarBadge}
                                    alt='인증마크 아이콘'
                                    className='object-contain'
                                  />
                                  카사요 인증
                                </span>
                                <span className='flex justify-center items-center text-[11px] leading-[16px] font-[600] px-1 bg-[#612694] rounded-[100px] text-white'>
                                  12
                                </span>
                              </div>
                              <div className='text-[#2F66BA]'>45:10 남음</div>
                            </div>
                            <p className='leading-[18px]'>
                              제네시스 G90 24년식 3.5 터보 프리미엄 패키지
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className='px-[2vw]'>
                        <div className='flex flex-row justify-start items-center gap-[2.6vw]'>
                          <div className='shrink w-[21.3vw] h-[21.3vw] border border-[#11111108] rounded-full p-[3px]'>
                            <div className='w-full h-full bg-[#11111108] rounded-full flex justify-center items-center'>
                              <img
                                src={GoraniImg}
                                alt='샘플이미지'
                                className='rounded-full object-fill'
                              />
                            </div>
                          </div>
                          <div className='flex flex-col justify-start items-start gap-2 text-[13px] py-2'>
                            <div className='w-full flex justify-between items-center'>
                              <div className='flex justify-start items-center gap-[6px]'>
                                <span className='text-[#7273F9] font-[600] flex justify-start items-center'>
                                  <img
                                    src={StarBadge}
                                    alt='인증마크 아이콘'
                                    className='object-contain'
                                  />
                                  카사요 인증
                                </span>
                                <span className='flex justify-center items-center text-[11px] leading-[16px] font-[600] px-1 bg-[#612694] rounded-[100px] text-white'>
                                  12
                                </span>
                              </div>
                              <div className='text-[#2F66BA]'>45:10 남음</div>
                            </div>
                            <p className='leading-[18px]'>
                              제네시스 G90 24년식 3.5 터보 프리미엄 패키지
                            </p>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                  <div className='inline-flex items-center gap-1 py-[5px] px-[12px] rounded-2xl bg-[#00000099]'>
                    <div
                      className={cn(
                        'flex h-4 w-4 items-center justify-center text-[#CACAD7]',
                        !quoteSwiper?.isBeginning && 'text-white',
                      )}
                      onClick={() => quoteSwiper?.slidePrev()}
                    >
                      <ChevronLeft className='h-4 w-4' />
                    </div>
                    <div className='text-[#fff]'>
                      <span className='text-[#fff]'>{quotePage}</span>/
                      <span
                        className={classNames(
                          quoteSwiper?.isEnd ? 'text-white' : 'text-[#CACAD7]',
                        )}
                      >
                        4
                      </span>
                    </div>
                    <div
                      className={cn(
                        'flex h-4 w-4 items-center justify-center text-[#CACAD7]',
                        !quoteSwiper?.isEnd && 'text-white',
                      )}
                      onClick={() => quoteSwiper?.slideNext()}
                    >
                      <ChevronRight className='h-4 w-4' />
                    </div>
                  </div>
                </div>
              ) : (
                <div className='relative mt-8 text-[12px] flex flex-col justify-start items-start px-[20px] py-[6px] rounded-[4px] bg-[#F7F7FB] font-normal'>
                  <div className='text-[#7273F9] font-[600] flex justify-start items-start'>
                    <img
                      src={StarBadge}
                      alt='인증마크 아이콘'
                      className='object-contain'
                    />
                    카사요 인증
                  </div>
                  <div className='w-full leading-[18px] text-center mb-4'>
                    카 매니저가 함께합니다.
                  </div>
                  <img
                    src={managerUniform}
                    alt='유니폼아이콘'
                    className='w-[32px] h-[32px] object-contain absolute top-[-16px] right-[16px]'
                  />
                </div>
              )}
            </div>
          ))
        ) : (
          <div className='mt-4 flex h-[52px] items-center justify-center bg-[#F7F7FB] px-4'>
            <Link to='/auth/login' className='flex items-center'>
              로그인 하기
              <div className='flex h-3 w-3 items-center justify-center'>
                <img src='/assets/images/v2/chevronRight.svg' alt='' />
              </div>
            </Link>
            <div className='ml-auto flex gap-4'>
              <Link to='/auth/signup' className='underline underline-offset-4'>
                회원가입
              </Link>
            </div>
          </div>
        )}
      </div>
      <CarsayoBanner bannerCategoryId={20001000} pagination='bullet-tr' />
      {/* 차량 조회 순위 */}
      <div className='bg-white px-4 py-6'>
        <div className='w-full bg-white lg:max-w-[600px] lg:mx-auto md:max-w-[600px] md:mx-auto'>
          <div className='flex items-center justify-between'>
            <div className='text-xl font-semibold'>차량 조회 순위</div>
            <div className='text-xs text-[#767676]'>
              현재 카사요에서 많이 찾고 있어요
            </div>
          </div>
          <div className='my-4'>
            <ul
              className={cn(
                'w-full flex justify-evenly items-center relative',
                'after:content-[""] after:absolute after:w-[calc(100%/3)] after:h-[1px] after:bg-[#7273F9] after:transition-all',
                `after:bottom-0 after:left-0 ${currentBestCar === 'domestic' ? 'after:translate-x-0' : currentBestCar === 'imported' ? 'after:translate-x-[100%]' : 'after:translate-x-[200%]'}`,
              )}
            >
              <li
                className={`w-full cursor-pointer transition-all py-[0.875rem] px-4 text-center border-b-[1px] box-border border-[#E5E5EC]`}
                onClick={() => setCurrentBestCar('domestic')}
              >
                <span>국산차</span>
              </li>
              <li
                className={`w-full cursor-pointer transition-all py-[0.875rem] px-4 text-center border-b-[1px] box-border border-[#E5E5EC]`}
                onClick={() => setCurrentBestCar('imported')}
              >
                <span>수입차</span>
              </li>
              <li
                className={`w-full cursor-pointer transition-all py-[0.875rem] px-4 text-center border-b-[1px] box-border border-[#E5E5EC]`}
                onClick={() => setCurrentBestCar('suv')}
              >
                <span>SUV</span>
              </li>
            </ul>
          </div>
          <BestCarSortingList></BestCarSortingList>
        </div>
      </div>
      {/* 내 관심 차량 */}
      <div className='mt-3 bg-white px-4 py-6'>
        <div className='w-full bg-white lg:max-w-[600px] lg:mx-auto md:max-w-[600px] md:mx-auto'>
          <div className='flex items-center justify-between'>
            <div className='text-xl font-semibold'>내 관심차량</div>
            <div className='text-xs text-[#767676]'>
              전국 카 매니저님들의 견적을 확인하세요
            </div>
          </div>
          <MyInterestCar></MyInterestCar>
        </div>
      </div>

      {/* 이용후기 영역 */}
      <ReviewContainer />
      {/* <CarsayoBanner
        bannerCategoryId={20002000}
        pagination='bullet-tr'
        className='mt-3'
      /> */}
      <div className='bg-white px-4 py-6'>
        <div className='w-full bg-white lg:max-w-[600px] lg:mx-auto md:max-w-[600px] md:mx-auto'>
          <div className='flex items-center justify-between'>
            <div className='flex gap-2'>
              <div className='text-xl font-semibold'>믿으니까</div>
              <img
                src='/assets/images/v2/carsayo.png'
                alt='CARSAYO'
                className='h-7'
              />
            </div>
            <div className='text-xs text-[#767676]'>고객만족 프로세스</div>
          </div>
          <div className='mt-6 space-y-4'>
            <div className='flex flex-wrap'>
              <div className='flex flex-1 items-center gap-4'>
                <div className='h-[22px] w-[22px] bg-[#DF732C] text-center text-[13px] leading-[22px] text-white'>
                  01
                </div>
                <div className='text-[15px] font-semibold leading-[22px]'>
                  견적 요청
                </div>
              </div>
              <div className='w-10 shrink-0'>
                <img
                  src='/assets/images/v2/requestForm.svg'
                  alt='request form icon'
                />
              </div>
              <div className='flex flex-1 items-center justify-end text-[#555555]'>
                원하시는 차량 선택
              </div>
              <div className='mt-2 flex w-full justify-center'>
                <div className='h-5 w-0.5 bg-[#BDBDBD]'></div>
              </div>
            </div>
            <div className='flex flex-wrap'>
              <div className='flex flex-1 items-center gap-4'>
                <div className='h-[22px] w-[22px] bg-[#DF732C] text-center text-[13px] leading-[22px] text-white'>
                  02
                </div>
                <div className='text-[15px] font-semibold leading-[22px]'>
                  견적 비교{' '}
                </div>
              </div>
              <div className='w-10 shrink-0'>
                <img
                  src='/assets/images/v2/quoteComparison.svg'
                  alt='request form icon'
                />
              </div>
              <div className='flex flex-1 items-center justify-end text-[#555555]'>
                카 매니저 조건 확인
              </div>
              <div className='mt-2 flex w-full justify-center'>
                <div className='h-5 w-0.5 bg-[#BDBDBD]'></div>
              </div>
            </div>
            <div className='flex flex-wrap'>
              <div className='flex flex-1 items-center gap-4'>
                <div className='h-[22px] w-[22px] bg-[#DF732C] text-center text-[13px] leading-[22px] text-white'>
                  03
                </div>
                <div className='text-[15px] font-semibold leading-[22px]'>
                  카 매니저 선택
                </div>
              </div>
              <div className='w-10 shrink-0'>
                <img
                  src='/assets/images/v2/selectDealer.svg'
                  alt='request form icon'
                />
              </div>
              <div className='flex flex-1 items-center justify-end text-[#555555]'>
                48시간 여유있게 검토
              </div>
              <div className='mt-2 flex w-full justify-center'>
                <div className='h-5 w-0.5 bg-[#BDBDBD]'></div>
              </div>
            </div>
            <div className='flex flex-wrap'>
              <div className='flex flex-1 items-center gap-4'>
                <div className='h-[22px] w-[22px] bg-[#DF732C] text-center text-[13px] leading-[22px] text-white'>
                  04
                </div>
                <div className='text-[15px] font-semibold leading-[22px]'>
                  카 매니저 상담
                </div>
              </div>
              <div className='w-10 shrink-0'>
                <img
                  src='/assets/images/v2/finalDecision.svg'
                  alt='request form icon'
                />
              </div>
              <div className='flex flex-1 items-center justify-end text-[#555555]'>
                통화 및 궁금증 확인
              </div>
              <div className='mt-2 flex w-full justify-center'>
                <div className='h-5 w-0.5 bg-[#BDBDBD]'></div>
              </div>
            </div>
            <div className='flex flex-wrap'>
              <div className='flex flex-1 items-center gap-4'>
                <div className='h-[22px] w-[22px] bg-[#DF732C] text-center text-[13px] leading-[22px] text-white'>
                  05
                </div>
                <div className='text-[15px] font-semibold leading-[22px]'>
                  최종 결정
                </div>
              </div>
              <div className='w-10 shrink-0'>
                <img
                  src='/assets/images/v2/requestForm.svg'
                  alt='request form icon'
                />
              </div>
              <div className='flex flex-1 items-center justify-end text-[#555555]'>
                협의된 내용으로 출고
              </div>
              <div className='mt-2 flex w-full justify-center'>
                <div className='h-5 w-0.5 bg-[#BDBDBD]'></div>
              </div>
            </div>
          </div>
          <div className='mt-6 flex gap-2 overflow-x-auto lg:justify-between md:justify-between'>
            <div className='flex h-[100px] w-[188px] shrink-0 items-center gap-4 bg-[#F7F7FB] px-4 py-5'>
              <div className='shrink-0'>
                <img
                  src='/assets/images/v2/contractProcess.svg'
                  alt='contract process icon'
                />
              </div>
              <div>
                <div className='text-[13px] font-medium'>차량 계약 과정</div>
                <div className='mt-2 text-xs leading-[18px] text-[#767676]'>
                  계약 시 불편함이 있다면 카사요가 도와드립니다.
                </div>
              </div>
            </div>
            <div className='flex h-[100px] w-[188px] shrink-0 items-center gap-4 bg-[#F7F7FB] px-4 py-5'>
              <div className='shrink-0'>
                <img
                  src='/assets/images/v2/purchaseProcess.svg'
                  alt='purchase process icon'
                />
              </div>
              <div>
                <div className='text-[13px] font-semibold'>차량 구매 과정</div>
                <div className='mt-2 text-xs leading-[18px] text-[#767676]'>
                  딜러의 약속 불이행은 확인 및 조치해드립니다.
                </div>
              </div>
            </div>
            <div className='flex h-[100px] w-[188px] shrink-0 items-center gap-4 bg-[#F7F7FB] px-4 py-5'>
              <div className='shrink-0'>
                <img
                  src='/assets/images/v2/problemSolving.svg'
                  alt='purchase process icon'
                />
              </div>
              <div>
                <div className='text-[13px] font-semibold'>문제 해결 과정</div>
                <div className='mt-2 text-xs leading-[18px] text-[#767676]'>
                  문제 발생 시 카사요가 중재해 드립니다.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 공지사항 */}
      <div className='mt-3 bg-white px-4 py-2'>
        <div className='w-full bg-white lg:max-w-[600px] lg:mx-auto md:max-w-[600px] md:mx-auto'>
          <NoticeAccordion expanded={expanded} setExpanded={setExpanded} />
        </div>
      </div>
      {/* bg-gradient-to-b from-white to-[#F1F1F5] */}
      {/* footer */}
      <div className='mt-3 bg-[#F7F7FB] px-4 pb-8 pt-6'>
        <Link
          to={'/mypage/termList'}
          className='-mx-4 flex items-center justify-center text-[#505050]'
        >
          <div className='px-[2vw]'>이용약관</div>
          <div className='h-3 w-[1px] bg-[#E5E5EC]'></div>
          <div className='px-[2vw]'>개인정보 처리방침</div>
          <div className='h-3 w-[1px] bg-[#E5E5EC]'></div>
          <div className='px-[2vw]'>금융 소비자 보호법 등록증</div>
        </Link>
        <div
          className='mt-6 flex justify-center gap-1'
          onClick={() => setIsCopyright(!isCopyright)}
        >
          <img
            src='/assets/images/v2/footerCarsayo.png'
            alt='logo'
            className='h-4'
          />
          {!isCopyright ? (
            <img
              src='/assets/images/v2/chevronBottom.svg'
              alt='chevron bottom'
            />
          ) : (
            <img src='/assets/images/v2/chevronTop.svg' alt='chevron top' />
          )}
        </div>

        <AnimatePresence initial={false}>
          {isCopyright && (
            <motion.div
              initial='collapsed'
              animate='open'
              exit='collapsed'
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              className='pt-8 text-center text-[13px] text-[#767676]'
            >
              <div className='space-y-1.5'>
                <div>카사요(주) 대표 : 최용민</div>
                <div>
                  <span className='text-[13px] text-[#111111]'>벤처기업</span>{' '}
                  20240514030044 (혁신성장형)
                </div>
                <div>(04790) 서울특별시 성동구 아차산로 49, 1403호</div>
                <div className='flex justify-center gap-2'>
                  <div>사업자등록번호 : 142-87-01847</div>
                </div>
                <div>통신판매업신고 : 제 2021-서울성동-00533</div>
                <div>이메일 : info@carsayo.net</div>
              </div>
              <div
                onClick={() => handleClickEvents.call('16681571')}
                className='mt-3 text-[#111111] underline underline-offset-4'
              >
                고객센터 : 1668-1571
              </div>
              {/* <div className='mt-[26px] flex items-center justify-center gap-4'>
                <a href='/main'>
                  <img src='/assets/images/v2/naver.svg' alt='' />
                </a>
                <a href='/main'>
                  <img src='/assets/images/v2/blog.svg' alt='' />
                </a>
                <a href='/main'>
                  <img src='/assets/images/v2/kakao.svg' alt='' />
                </a>
                <a href='/main'>
                  <img src='/assets/images/v2/instargram.svg' alt='' />
                </a>
              </div> */}
              <div className='mt-8'>
                Copyright ⓒ 카사요(주) ALL RIGHTS RESERVED.
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </main>
  );
}

export default CustomerMainPage;
