/* eslint-disable @typescript-eslint/no-unused-vars */
import { Textarea } from 'components/ui/textarea';
import { Check } from 'lucide-react';
import useSellingStore from '../store';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from 'components/ui/dialog';
import { cn } from 'utils';

export default function StepComponent1() {
  const { sellingOrderDTO, setSellingOrderDTO } = useSellingStore();

  const [textCount, setTextCount] = useState<number>(0);
  const textRef = useRef<HTMLTextAreaElement>(null);

  const ref = useRef<HTMLDivElement>(null);

  const handleClickEvents = {
    직접입력: {
      submit: async () => {
        if (!sellingOrderDTO) return;
        setSellingOrderDTO({
          ...sellingOrderDTO,
          update: {
            additionalInfo: {
              ...sellingOrderDTO.orderData.additionalInfo,
              optionSelect: {
                ...sellingOrderDTO.orderData.additionalInfo.optionSelect,
                옵션없음: false,
                직접입력: {
                  selected: true,
                  content: textRef.current?.value,
                },
              },
            },
          },
        });
      },
    },
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (
      !sellingOrderDTO ||
      !sellingOrderDTO.orderData.additionalInfo.optionSelect.직접입력?.content
    )
      return;
    setTextCount(
      /** 한글은 3byte, 영문은 1byte */
      sellingOrderDTO.orderData.additionalInfo.optionSelect.직접입력?.content.replace(
        /[\0-\x7f]|([0-\u07ff]|(.))/g,
        '$&$1$2',
      ).length,
    );
  }, []);

  return (
    <div ref={ref} className='rounded border border-[#0075FF] bg-[#0075FF]/10'>
      <div className='flex h-[70px] items-center border-b border-[#0075FF] px-4 text-title font-semibold text-white'>
        차량에 장착된 추가 옵션을 선택해주세요
      </div>
      <div className='space-y-4 px-4 py-6'>
        {sellingOrderDTO?.orderData.usedCarInfo.seloptlist &&
          sellingOrderDTO?.orderData.usedCarInfo.seloptlist.length > 0 && (
            <>
              <div className='grid grid-cols-3 gap-x-[17.5px] gap-y-4 '>
                {sellingOrderDTO?.orderData.usedCarInfo.seloptlist.map(
                  (el, index) => {
                    return (
                      <label
                        key={index}
                        className='group h-auto border py-4 text-[#9EA3B2] transition has-[:checked]:text-white'
                      >
                        <div className='relative mx-auto h-6 w-6'>
                          <input
                            type='checkbox'
                            className='flex h-6 w-6 appearance-none items-center justify-center rounded-full bg-[#0075FF]/10'
                            checked={
                              sellingOrderDTO?.orderData.additionalInfo
                                .optionSelect.optionList &&
                              sellingOrderDTO?.orderData.additionalInfo.optionSelect.optionList.find(
                                (el2) => {
                                  return (
                                    String(el2.optname).replaceAll(' ', '') ===
                                    String(el.optname).replaceAll(' ', '')
                                  );
                                },
                              ) !== undefined
                            }
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              if (!sellingOrderDTO) return;

                              let updatedOptionList = sellingOrderDTO?.orderData
                                .additionalInfo.optionSelect.optionList
                                ? sellingOrderDTO?.orderData.additionalInfo
                                    .optionSelect.optionList
                                : [];

                              if (e.target.checked === true) {
                                updatedOptionList = [
                                  ...updatedOptionList,
                                  {
                                    optname: el.optname,
                                    optprice: Number(el.optprice),
                                  },
                                ];
                              } else {
                                updatedOptionList = updatedOptionList.filter(
                                  (el2) => {
                                    return (
                                      String(el2.optname).replaceAll(
                                        ' ',
                                        '',
                                      ) !==
                                      String(el.optname).replaceAll(' ', '')
                                    );
                                  },
                                );
                              }

                              setSellingOrderDTO({
                                ...sellingOrderDTO,
                                update: {
                                  additionalInfo: {
                                    ...sellingOrderDTO.orderData.additionalInfo,
                                    optionSelect: {
                                      optionList: updatedOptionList,
                                      옵션없음: false,
                                      직접입력: {
                                        selected: false,
                                        content: '',
                                      },
                                    },
                                  },
                                },
                              });
                            }}
                          />
                          <Check className='absolute left-1.5 top-1.5 h-3 w-3 text-white opacity-0 transition group-has-[:checked]:opacity-100' />
                        </div>
                        <div className='mt-4 mx-2 text-center whitespace-normal'>
                          {el.optname}
                        </div>
                      </label>
                    );
                  },
                )}
              </div>
              <div className='my-4 h-[1px] bg-[#E5E5EC]'></div>
            </>
          )}

        <label className='group flex h-[72px] items-center gap-2 border px-4 text-[#9EA3B2] transition has-[:checked]:text-white'>
          <div className='relative h-6 w-6'>
            <input
              type='checkbox'
              className='flex h-6 w-6 appearance-none items-center justify-center rounded-full bg-[#0075FF]/10'
              checked={
                sellingOrderDTO?.orderData.additionalInfo.optionSelect.옵션없음
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (sellingOrderDTO && e.target.checked) {
                  setSellingOrderDTO({
                    ...sellingOrderDTO,
                    update: {
                      additionalInfo: {
                        ...sellingOrderDTO.orderData.additionalInfo,
                        optionSelect: {
                          optionList: [],
                          옵션없음: true,
                          직접입력: {
                            selected: false,
                            content: '',
                          },
                        },
                      },
                    },
                  });
                }
              }}
            />
            <Check className='absolute left-1.5 top-1.5 h-3 w-3 text-white opacity-0 transition group-has-[:checked]:opacity-100' />
          </div>
          <div>옵션 없음</div>
        </label>

        <Dialog>
          <DialogTrigger asChild>
            <div className='flex h-[72px] items-center gap-2 border border-white px-4'>
              <div className='relative h-6 w-6'>
                <input
                  type='radio'
                  name='distance'
                  checked={
                    sellingOrderDTO?.orderData?.additionalInfo?.optionSelect
                      ?.직접입력?.selected
                  }
                  className={cn(
                    'flex h-6 w-6 appearance-none items-center justify-center rounded-full bg-[#0075FF]/10',
                    sellingOrderDTO?.orderData?.additionalInfo?.optionSelect
                      ?.직접입력?.selected && 'text-white',
                  )}
                />
                {sellingOrderDTO?.orderData?.additionalInfo?.optionSelect
                  ?.직접입력?.selected && (
                  <Check className='absolute left-1.5 top-1.5 h-3 w-3 text-white' />
                )}
              </div>

              <div className='flex w-full flex-col pr-8'>
                <div
                  className={cn(
                    'text-base font-medium text-[#9EA3B2]',
                    sellingOrderDTO?.orderData?.additionalInfo?.optionSelect
                      ?.직접입력?.selected && 'text-white',
                  )}
                >
                  직접입력
                </div>
                {sellingOrderDTO?.orderData?.additionalInfo?.optionSelect
                  ?.직접입력?.content && (
                  <div className='leading-3 text-[12px] font-medium text-[#9EA3B2] w-full truncate overflow-hidden whitespace-nowrap'>
                    {
                      sellingOrderDTO?.orderData?.additionalInfo?.optionSelect
                        ?.직접입력?.content
                    }
                  </div>
                )}
              </div>
            </div>
          </DialogTrigger>
          <DialogContent className='w-[311px] gap-0 border-0 p-0'>
            <div className='space-y-4 px-4 py-6 pb-8'>
              <div className='text-center font-semibold'>
                추가적인 옵션 내역이 있다면 알려주세요
              </div>
              <div className='relative border bg-white'>
                <Textarea
                  ref={textRef}
                  onChange={(e) => {
                    setTextCount(
                      /** 한글은 3byte, 영문은 1byte */
                      e.target.value.replace(
                        /[\0-\x7f]|([0-\u07ff]|(.))/g,
                        '$&$1$2',
                      ).length,
                    );
                  }}
                  defaultValue={
                    sellingOrderDTO?.orderData?.additionalInfo?.optionSelect
                      ?.직접입력?.content
                      ? sellingOrderDTO.orderData.additionalInfo.optionSelect
                          .직접입력?.content
                      : ''
                  }
                  className='h-[158px] border-0 focus:border-0'
                  placeholder='예) 외관 튜닝'
                />
                <div className='absolute bottom-4 right-2.5 text-[#767676]'>
                  {textCount}
                  /1000
                </div>
              </div>
            </div>
            <div className='grid grid-cols-2'>
              <DialogClose className='h-12 rounded-none border-0 bg-[#F7F7FB] text-sm font-semibold'>
                취소
              </DialogClose>
              <DialogClose
                onClick={handleClickEvents.직접입력.submit}
                className='rounded-none border-0 bg-[#0075FF] text-sm font-semibold text-white'
              >
                수정하기
              </DialogClose>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
