import 'react-mobile-datepicker-ts/dist/main.css';

import React from 'react';
import DatePicker from 'react-mobile-datepicker-ts';

interface CarsayoDatePickerInterface {
  isOpen: boolean;
  dateId: string;
  maxDate?: Date;
  minDate?: Date;
  onClose: () => void;
  setData: (id: string, value: Date) => void;
}

const CarsayoDatePicker = ({
  isOpen,
  dateId,
  maxDate,
  minDate,
  onClose,
  setData,
}: CarsayoDatePickerInterface) => {
  const [time, setTime] = React.useState(new Date());

  const handleSelect = (nextTime: typeof time) => {
    setTime(nextTime);
    setData(dateId, nextTime);
  };

  return (
    <>
      <DatePicker
        value={time}
        isOpen={isOpen}
        onChange={handleSelect}
        showHeader={false}
        showFooter={false}
        min={minDate ?? undefined}
        max={maxDate ?? undefined}
      />
    </>
  );
};

export default CarsayoDatePicker;
