/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useHistoryStore from 'store/useHistoryStore';

type setData<T> = (data: T) => void;
type setDataParam<T> = { data: T; setData: setData<T> };

/** 페이지 내 상태값을 복구하고자 하면 사용합니다.
 * @notice "뒤로가기"로 해당 페이지에 다시 들어왔을 경우 동작합니다.
 * @notice Avaliable for global store only
 */
const useRestore = () => {
  const { history, setHistory } = useHistoryStore();
  const location = useLocation();

  const historyRef = useRef(history);

  useLayoutEffect(() => {
    // Update the ref with the latest state whenever state changes
    historyRef.current = history;
  }, [history]);

  /**
   * @todo 캐싱하고자 하는 상태값과 상태값 정의 메소드를 추가하세용
   * @notice 뒤로가기로 돌아왔을 경우 "getRestore"를 통해 캐싱된 값이 복구됩니다.
   */
  const setRestore = <T, K, J>(
    param: [setDataParam<T>, setDataParam<K>?, setDataParam<J>?],
  ): (() => void) => {
    const dataRef = useRef(param);

    useLayoutEffect(() => {
      // Update the ref with the latest state whenever state changes
      dataRef.current = param;
    }, [param]);

    useEffect(() => {
      return () => {
        const restoreMethod = () => {
          for (let i = 0; i < dataRef.current.length; i++) {
            const target: setDataParam<any> | undefined = dataRef.current[i];
            if (!target) continue;
            target.setData(target.data);
          }
          return;
        };

        const targetHistoryIndex = historyRef.current.findIndex((el) => {
          return el.key === location.key;
        });

        const updateHistory = [...historyRef.current];
        updateHistory[targetHistoryIndex] = {
          ...updateHistory[targetHistoryIndex],
          restore: restoreMethod,
        };

        setHistory(updateHistory);
      };
    }, []);

    return () => {};
  };

  /** @description 뒤로가기로 돌아왔을 경우 "setRestore"로 정의한 값이 복구됩니다. */
  const getRestore = () => {
    const currentKey = location.key;

    const restoreFunction = history.find((el) => {
      return el.key === currentKey;
    })?.restore;

    if (restoreFunction) restoreFunction();

    return;
  };

  return { setRestore, getRestore };
};

export default useRestore;
