import { OrderState } from '@carsayo/types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

/*
 * 딜러 메인 사이드 메뉴에서 주문리스트 탭으로 기억하고 들어갈 스테이트를 정의합니다.
 */
interface TabInterface {
  page: number;
  type: OrderState | 'whole' | 'bidding' | 'marking' | null;
  isTabSlide: boolean;
}
interface DealerMainState {
  orderTab: TabInterface;
  biddingSwiperPage: number;
  isBiddingSidePageOpen: boolean;
}
interface DealerMainAction {
  setOrderTab: (tab: TabInterface) => void;
  setBiddingSwiperPage: (page: number) => void;
  setBiddingSidePageOpen: (open: boolean) => void;
  resetOrder: () => void;
}

const useDealerMainStateStore = create<DealerMainState & DealerMainAction>()(
  devtools(
    immer((set) => ({
      orderTab: {
        page: 0,
        type: null,
        isTabSlide: false,
      },
      biddingSwiperPage: 0,
      isBiddingSidePageOpen: false,
      setOrderTab: (tab: TabInterface) => {
        set((state) => {
          state.orderTab = tab;
        });
      },
      setBiddingSwiperPage: (page: number) => {
        set((state) => {
          state.biddingSwiperPage = page;
        });
      },
      setBiddingSidePageOpen: (open: boolean) => {
        set((state) => {
          state.isBiddingSidePageOpen = open;
        });
      },
      resetOrder: () => {
        set((state) => {
          state.orderTab = {
            page: 0,
            type: null,
            isTabSlide: false,
          };
          (state.biddingSwiperPage = 0), (state.isBiddingSidePageOpen = false);
        });
      },
    })),
    {
      name: 'dealerMainPageState',
    },
  ),
);

export default useDealerMainStateStore;
