import { FileInfo } from '@carsayo/types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type AcceptFile = 'image' | 'pdf' | 'zip';

interface FileUploaderState {
  fileId?: string;
  uploadCallback?: (data: FileInfo, file?: File) => void | Promise<void>;
  uploadInfo: {
    fileType: string;
    acceptFile: AcceptFile | AcceptFile[];
    fileName?: string;
    isPublic: boolean;
  };
}
interface FileUploaderAction {
  fileUpload: (
    uploadInfo: {
      /** 파일 유형을 선택합니다. */
      acceptFile: AcceptFile | AcceptFile[];
      /** 파일의 유형을 자유롭게 입력합니다.
       * @example "estimateFile", "profileImage"
       */
      fileType: string;
      /** 파일의 이름을 자유롭게 입력합니다.
       * @example "견적서", "프로필 사진"
       */
      fileName?: string;
      /** 파일이 공개 파일인지, 비공개 파일인지
       * @notice 비공개 파일은 본인과 관리자만 확인할 수 있습니다.
       */
      isPublic: boolean;
    },
    callback: (data: FileInfo, file?: File) => void | Promise<void>,
  ) => void;
  resetFileUploader: () => void;
}

const useFileUploader = create<FileUploaderState & FileUploaderAction>()(
  devtools(
    immer((set) => ({
      fileId: undefined,
      uploadCallback: undefined,

      uploadInfo: {
        acceptFile: ['image', 'pdf', 'zip'],
        fileType: 'blank',
        fileName: undefined,
        isPublic: true,
      },
      fileUpload: async (
        uploadInfo: {
          acceptFile: AcceptFile | AcceptFile[];
          fileType: string;
          fileName?: string;
          isPublic: boolean;
        },
        callback: (data: FileInfo) => void | Promise<void>,
      ) => {
        set((state) => {
          state.uploadInfo = uploadInfo;
          state.uploadCallback = callback;
        });

        return;
      },
      resetFileUploader: () => {
        set((state) => {
          state.uploadInfo = {
            acceptFile: ['image', 'pdf', 'zip'],
            fileType: 'blank',
            fileName: undefined,
            isPublic: true,
          };
          state.uploadCallback = undefined;
        });
      },
    })),
  ),
);

export default useFileUploader;
