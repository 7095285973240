import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.locale('ko');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Seoul');

export const VirtualAccountWaitingItem = ({
  bankName,
  amount,
  accountNumber,
  expireDate,
  expireTime,
}: {
  bankName: string;
  amount: number;
  accountNumber: string;
  expireDate: string;
  expireTime: string;
}) => {
  return (
    <div className='border-t'>
      <div className='flex h-[52px] items-center border-b'>
        <div className='w-[100px] shrink-0 bg-[#F7F7FB] p-4'>결제방법</div>
        <div className='p-4 text-[#767676]'>가상계좌</div>
      </div>
      <div className='flex h-[52px] items-center border-b'>
        <div className='w-[100px] shrink-0 bg-[#F7F7FB] p-4'>입금은행</div>
        <div className='p-4 text-[#767676]'>{bankName}</div>
      </div>
      <div className='flex h-[52px] items-center border-b'>
        <div className='w-[100px] shrink-0 bg-[#F7F7FB] p-4'>계좌번호</div>
        <div className='p-4 text-[#767676]'>{accountNumber}</div>
      </div>
      <div className='flex h-[52px] items-center border-b'>
        <div className='w-[100px] shrink-0 bg-[#F7F7FB] p-4'>입금금액</div>
        <div className='p-4 text-[#767676]'>{amount.toLocaleString()}원</div>
      </div>
      <div className='flex h-[52px] items-center border-b'>
        <div className='w-[100px] shrink-0 bg-[#F7F7FB] p-4'>입금기한</div>
        <div className='p-4 text-[#767676]'>
          {`${dayjs(expireDate + expireTime)
            .add(9, 'hour')
            .format('YYYY년 MM월 DD일 HH:mm')}`}
        </div>
      </div>
    </div>
  );
};
