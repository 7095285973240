import { ReactNode } from 'react';
import { toast } from 'sonner';
import Carsayo from 'assets/images/v2/Carsayo.svg';

class AlertToastSingleton {
  private static instance: AlertToastSingleton;
  private closeTime = 2000;
  public static getInstance() {
    if (!AlertToastSingleton.instance) {
      AlertToastSingleton.instance = new AlertToastSingleton();
    }
    return AlertToastSingleton.instance;
  }

  public text(message: string, inIcon?: ReactNode) {
    toast(message, {
      position: 'bottom-center',
      duration: this.closeTime,
      icon: inIcon ?? (
        <img
          src={Carsayo}
          alt='카사요심볼'
          className='object-contain w-[25px] h-[25px]'
        />
      ),
      className:
        'px-[25px] py-[10px] flex bg-[#333] border-0  justify-start items-center gap-[10px]  text-[13px] leading-[18px] shadow-md ',
    });
  }

  public success(message: string, inIcon?: ReactNode) {
    toast.success(message, {
      position: 'bottom-center',
      duration: this.closeTime,
      icon: inIcon ?? (
        <img src={Carsayo} alt='카사요심볼' className='object-contain' />
      ),
      className:
        'px-[25px] py-[10px] flex bg-[#333] border-0  justify-start items-center gap-[10px]  text-[13px] leading-[18px] shadow-md ',
    });
  }

  public info(message: string, inIcon?: ReactNode) {
    toast.info(message, {
      position: 'bottom-center',
      duration: this.closeTime,
      icon: inIcon ?? (
        <img src={Carsayo} alt='카사요심볼' className='object-contain' />
      ),
      className:
        'px-[25px] py-[10px] flex bg-[#333] border-0  justify-start items-center gap-[10px]  text-[13px] leading-[18px] shadow-md ',
    });
  }

  public warn(message: string, inIcon?: ReactNode) {
    toast.warning(message, {
      position: 'bottom-center',
      duration: this.closeTime,
      icon: inIcon ?? (
        <img src={Carsayo} alt='카사요심볼' className='object-contain' />
      ),
      className:
        'px-[25px] py-[10px] flex bg-[#333] border-0  justify-start items-center gap-[10px]  text-[13px] leading-[18px] shadow-md ',
    });
  }

  public error(message: string, inIcon?: ReactNode) {
    toast.error(message, {
      position: 'bottom-center',
      duration: this.closeTime,
      icon: inIcon ?? (
        <img src={Carsayo} alt='카사요심볼' className='object-contain' />
      ),
      className:
        'px-[25px] py-[10px] flex bg-[#333] border-0  justify-start items-center gap-[10px]  text-[13px] leading-[18px] shadow-md ',
    });
  }
}

export const CarsayoToast = AlertToastSingleton.getInstance();

export default AlertToastSingleton;
