import { ChevronRight } from 'lucide-react';
import { useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';

export default function CommunityMain() {
  const { setTopTabbarTitle, setTopTabbarState } = useSystemStore();
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      rightElement: 'home',
      leftElement: 'back',
    });
    setTopTabbarTitle('커뮤니티');
  }, []);

  return (
    <div className='bg-[#F1F1F5]'>
      <div className='bg-white px-5 py-8'>
        <div className='flex items-center justify-between'>
          <div className='text-xl font-semibold'>카사요 라이프</div>
          <div className='text-xs text-[#767676]'>슬기로운 자동차 문화</div>
        </div>
        <div className='mt-8 grid grid-cols-4 justify-between gap-x-10 gap-y-4 text-center'>
          <Link to='review' className='flex flex-1 flex-col items-center'>
            <div className='mx-auto h-[30px] w-[30px]'>
              <img src='/assets/images/v2/com-1.svg' alt='' />
            </div>
            <div className='mt-3 text-[11px] leading-[16px]'>카사요 후기</div>
          </Link>
          <div className='flex flex-1 flex-col items-center'>
            <div className='mx-auto h-[30px] w-[30px]'>
              <img src='/assets/images/v2/com-2.svg' alt='' />
            </div>
            <div className='mt-3 text-[11px] leading-[16px]'>신차 소식</div>
          </div>
          <div className='flex flex-1 flex-col items-center'>
            <div className='mx-auto h-[30px] w-[30px]'>
              <img src='/assets/images/v2/com-3.svg' alt='' />
            </div>
            <div className='mt-3 text-[11px] leading-[16px]'>전기차</div>
          </div>
          <div className='flex flex-1 flex-col items-center'>
            <div className='mx-auto h-[30px] w-[30px]'>
              <img src='/assets/images/v2/com-4.svg' alt='' />
            </div>
            <div className='mt-3 text-[11px] leading-[16px]'>자동차 꿀팁</div>
          </div>
          <div className='flex flex-1 flex-col items-center'>
            <div className='mx-auto h-[30px] w-[30px]'>
              <img src='/assets/images/v2/com-5.svg' alt='' />
            </div>
            <div className='mt-3 text-[11px] leading-[16px]'>연비배틀</div>
          </div>
          <div className='flex flex-1 flex-col items-center'>
            <div className='mx-auto h-[30px] w-[30px]'>
              <img src='/assets/images/v2/com-6.svg' alt='' />
            </div>
            <div className='mt-3 text-[11px] leading-[16px]'>내차사진</div>
          </div>
          <div className='flex flex-1 flex-col items-center'>
            <div className='mx-auto h-[30px] w-[30px]'>
              <img src='/assets/images/v2/com-7.svg' alt='' />
            </div>
            <div className='mt-3 text-[11px] leading-[16px]'>AS 센터</div>
          </div>
          <div className='flex flex-1 flex-col items-center'>
            <div className='mx-auto h-[30px] w-[30px]'>
              <img src='/assets/images/v2/com-8.svg' alt='' />
            </div>
            <div className='mt-3 text-[11px] leading-[16px]'>티 타임</div>
          </div>
        </div>
      </div>
      <div className='mt-3 bg-white px-5 py-6'>
        <div className='text-xl font-semibold'>전체 인기 TOP 10</div>
        <div className='-mx-5 mt-6 flex snap-x snap-mandatory scroll-px-5 gap-2 overflow-x-auto scroll-smooth whitespace-nowrap px-5 [&::-webkit-scrollbar]:hidden'>
          {Array.from({ length: 10 }).map((_, i) => (
            <div key={i}>
              <div className='h-[92px] w-[164px] snap-start overflow-hidden rounded-xl'>
                <img
                  src='/assets/images/v2/car2.png'
                  alt=''
                  className='h-full w-full object-cover'
                />
              </div>
              <div className='mt-3 px-1 text-xs'>카사요 후기</div>
              <div className='mt-1 px-1'>최저견적으로 생애 첫차</div>
            </div>
          ))}
        </div>
      </div>
      <div className='mt-3 bg-white px-5 py-6'>
        <div className='text-xl font-semibold'>전체 인기 TOP 10</div>
        <div className='mt-4 space-y-6'>
          {Array.from({ length: 2 }).map((_, i) => (
            <div key={i} className='rounded border p-4'>
              <div className='flex items-center gap-2'>
                <div className='flex h-5 items-center border bg-[#B71C1C] px-1 text-[11px] text-white'>
                  HOT
                </div>
                <div className='flex items-center justify-center text-[#555555] '>
                  <Link
                    to='review'
                    className='flex flex-1 flex-col items-center'
                  >
                    카사요 후기
                  </Link>
                  <ChevronRight className='h-4 w-4' />
                </div>
              </div>
              <div className='mt-4 flex gap-4'>
                <div className='h-20 w-20 shrink-0 overflow-hidden rounded-[10px]'>
                  <img
                    src='/assets/images/v2/car2.png'
                    alt=''
                    className='h-full w-full object-cover'
                  />
                </div>
                <div>
                  카사요에서 만원의 기적으로 구매했어요. 이번에 아주 특템을 한거
                  같아요. 게시글에 이미지가 있다면 옆의 형식이 됩니다.
                </div>
              </div>
              <div className='-mx-4 mt-4 flex justify-between border-t px-4 pt-4'>
                <div className='flex items-center gap-4'>
                  <div className='text-desc text-[#555555]'>
                    새벽 라디오 들으며
                  </div>
                  <div className='text-desc text-[#9EA3B2]'>· 1일 전</div>
                </div>
                <div className='flex items-center gap-2'>
                  <div>
                    <img src='/assets/images/v2/comment.svg' alt='' />
                  </div>
                  <div>12</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
