import React, { InputHTMLAttributes } from 'react';
import ResetInputValue from '../../assets/images/resetInputValue.svg';

interface CarsayoTextInputInterface
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'className'> {
  carsayoInputRef?: (ref: HTMLInputElement | null) => HTMLInputElement;
  className?: string;
  isRounded?: boolean;
  isHideBorder?: boolean;
  postFix?: string | React.ReactNode;
  onResetAll?: () => void;
  onEnterKeyDown?: () => void;
}

const CarsayoInput = ({
  carsayoInputRef,
  className,
  isRounded = true,
  isHideBorder = false,
  postFix,
  onResetAll,
  onEnterKeyDown,
  ...rest
}: CarsayoTextInputInterface) => {
  const borderStyle = isHideBorder
    ? 'border-[0px]'
    : 'focus:border-[#333] focus:ring-[#333] border-gray-200';

  const handleClickEvents = {
    resetInputValue: () => {
      if (onResetAll) {
        onResetAll();
      }
    },
  };

  return (
    <div className='relative w-auto'>
      <input
        type={rest.type ?? 'text'}
        onKeyDown={(event) => {
          if (onEnterKeyDown && (event.key === 'Enter' || event.keyCode === 13))
            onEnterKeyDown();
        }}
        ref={(refObject) => carsayoInputRef && carsayoInputRef(refObject)}
        className={`text-[16px] text-[#333] font-[350] readonly:ring-0  placeholder:text-[#ccc] placeholder:font-[300] placeholder:text-[14px] disabled:bg-[#f1f1f1] w-[100%] min-h-[50px] rounded-[0.625rem] px-[20px] outline-0 border-solid border-[1px] box-border transition-all ${borderStyle} ${isRounded && `rounded-lg`} ${className && className}`}
        style={{
          paddingRight: postFix ? '40px' : '',
        }}
        {...rest}
      />
      {!postFix && rest.value && onResetAll && (
        <button
          className='absolute right-[15px] position-y-center text-[14px]'
          onClick={handleClickEvents.resetInputValue}
        >
          <img src={ResetInputValue} />
        </button>
      )}
      {postFix && (
        <p className='absolute right-[15px] position-y-center text-[14px] !z-0'>
          {postFix}
        </p>
      )}
    </div>
  );
};

export default CarsayoInput;
