import {
  GetNotificationListDTO,
  CarsayoNotification,
  NotificationIdDTO,
} from '@carsayo/types';
import { api } from 'apis';

export const getNotificationList = async (
  reqData: GetNotificationListDTO,
): Promise<CarsayoNotification[]> => {
  try {
    const response = await api.post('notification/list', reqData);
    const data: CarsayoNotification[] = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const readNotification = async (
  reqData: NotificationIdDTO,
): Promise<void> => {
  try {
    await api.post('notification/read', reqData);
    return;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const readWholeNotification = async (): Promise<void> => {
  try {
    await api.post('notification/readWhole');
    return;
  } catch (error: any) {
    throw new Error(error);
  }
};
