/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useLayoutEffect, useState } from 'react';
import useSystemStore from 'store/useSystemStore';
import ArrowTop from 'assets/images/v2/arrowTop.svg';
import CarsayoDatePicker from 'components/common/CarsayoDatePicker';
import CarsayoInput from 'components/common/CarsayoInput';
import classNames from 'classnames';
import CarsayoRadio from 'components/common/v2/CarsayoRadio';
import TelIcon from 'components/common/global/svg/TelIcon';
import { useNavigate } from 'react-router-dom';
import CarsayoPagination from 'components/common/CarsayoPagination';
import { getManagerQuoteListApi } from 'apis/quote';
import {
  dateToStringMMDD,
  dateToStringHHMM,
  remainingTime,
  dateToString,
  convertDealerType,
  slicePrice,
} from 'utils/convert';
import {
  parseDealerOrderListDataState,
  parseSigunguId,
} from 'utils/typeConvert';
import useMemberStore from 'store/useMemberStore';
import PeopleIcon from 'assets/images/v2/People-3.svg';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import CarsayoBottomSheet from 'components/common/CarsayoBottomSheet';
import dayjs from 'dayjs';
import useOnResumeStore from 'store/useOnResumeStore';
import useQuoteStore from 'store/useQuoteStore';
import { DealerOrderListData, GetOrderListDTO } from '@carsayo/types';

const QuoteBoxMain = () => {
  const { setTopTabbarTitle, setTopTabbarState } = useSystemStore();
  const { loginMember } = useMemberStore();
  const { setOnResume } = useOnResumeStore();
  const {
    quoteScrollPosition,
    quoteListPage,
    searchData,
    setSearchData,
    setQuotePage,
    setSkipData,
    setQuoteScrollPosition,
  } = useQuoteStore();
  const navigate = useNavigate();
  // 검색 관련
  const [dateId, setDateId] = useState('');
  const [startDateValue, setStartDateValue] = useState<Date>(
    searchData.interval?.start
      ? searchData.interval.start
      : dayjs().subtract(1, 'month').toDate(),
  );
  const [page, setPage] = useState(quoteListPage ? quoteListPage : 1);
  const [endDateValue, setEndDateValue] = useState<Date>(
    searchData.interval?.end ? searchData.interval.end : new Date(),
  );
  const [isBottomOpen, setBottomOpen] = useState(false);
  const [progressRadio, setProgressRadio] = useState('all');
  const [readingRadio, setReadingRadio] = useState('all');
  const [bidRadio, setBidRadio] = useState('all');
  const [isSearchBoxOpen, setSearchBoxOpen] = useState(false);
  const [isQuoteBoxOpen, setQuoteBoxOpen] = useState<boolean[]>([]);
  const [dateRange, setDateRange] = useState('range');
  const [totalLength, setTotalLength] = useState<number>();
  // 견적함리스트
  const [quoteList, setQuoteList] = useState<DealerOrderListData[]>();
  const [quoteSearch, setQuoteSearch] = useState<GetOrderListDTO>(
    searchData
      ? searchData
      : {
          take: 5,
          skip: 0,
          interval: {
            target: 'created_at',
            start: startDateValue,
            end: endDateValue,
          },
          option: undefined,
        },
  );

  const handleSetDateValue = (id: string, value: Date) => {
    if (id === 'start') {
      setStartDateValue(value);
    } else if (id === 'end') {
      setEndDateValue(value);
    }
  };
  // 자식 요소로 전달하는 함수
  const setData = (id: string, value: Date) => {
    handleSetDateValue(id, value);
  };

  const handleVoidEvents = {
    getQuoteList: async () => {
      try {
        const result = await getManagerQuoteListApi(quoteSearch);
        if (result) {
          setQuoteList(result.data);
          console.info(result.data);
          setQuoteBoxOpen(new Array(result.data.length).fill(false));
          setTotalLength(result.totalCount);
        } else {
          CarsayoToast.error('견적함 데이터가 없습니다.');
          navigate('/');
        }
      } catch (error: any) {
        console.error(error);
      }
    },
  };
  const handleClickEvents = {
    popupOpen: (id: string) => () => {
      setDateRange('range');
      setDateId(id);
      setBottomOpen(!isBottomOpen);
    },
    searchBoxOpen: () => {
      setSearchBoxOpen(!isSearchBoxOpen);
    },
    quoteBoxOpen: (index: number) => () => {
      const newOpenStates = [...isQuoteBoxOpen];
      newOpenStates[index] = !newOpenStates[index];
      setQuoteBoxOpen(newOpenStates);
    },
    moveDetail: (id: string) => () => {
      navigate(`/quote/manager/detail/${id}`);
    },
    search: () => {
      let start = new Date();
      let end = dayjs().subtract(1, 'month').toDate();
      if (dateRange) {
        if (dateRange === '90d') {
          start = dayjs().subtract(90, 'day').toDate();
          end = new Date();
        } else if (dateRange === '1y') {
          start = dayjs().startOf('year').toDate();
          end = dayjs().endOf('year').toDate();
        } else if (dateRange === '2y') {
          start = dayjs().subtract(1, 'year').startOf('year').toDate();
          end = dayjs().endOf('year').toDate();
        } else if (dateRange === '3y') {
          start = dayjs().subtract(2, 'year').startOf('year').toDate();
          end = dayjs().endOf('year').toDate();
        } else {
          start = startDateValue ?? new Date();
          end = endDateValue ?? dayjs().subtract(1, 'month').toDate();
        }
        setSearchData({
          take: 5,
          skip: page ? (page - 1) * 5 : 0,
          interval: {
            target: 'created_at',
            start: start,
            end: end,
          },
          option: {
            orderStateList:
              progressRadio === 'all'
                ? undefined
                : progressRadio === 'progress'
                  ? ['submitted', 'progressing']
                  : progressRadio === 'finished'
                    ? ['canceled', 'deleted', 'failBid', 'finished', 'holded']
                    : undefined,
            isReaded:
              readingRadio === 'read'
                ? true
                : readingRadio === 'unread'
                  ? false
                  : undefined,
            isAccepted:
              bidRadio === 'success'
                ? true
                : bidRadio === 'failed'
                  ? false
                  : undefined,
          },
        });
        setQuoteSearch({
          take: 5,
          skip: page ? (page - 1) * 5 : 0,
          interval: {
            target: 'created_at',
            start: start,
            end: end,
          },
          option: {
            orderStateList:
              progressRadio === 'all'
                ? undefined
                : progressRadio === 'progress'
                  ? ['submitted', 'progressing']
                  : progressRadio === 'finished'
                    ? ['canceled', 'deleted', 'failBid', 'finished', 'holded']
                    : undefined,
            isReaded:
              readingRadio === 'read'
                ? true
                : readingRadio === 'unread'
                  ? false
                  : undefined,
            isAccepted:
              bidRadio === 'success'
                ? true
                : bidRadio === 'failed'
                  ? false
                  : undefined,
          },
        });
        setPage(1);
        setQuotePage(1);
      }
    },
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      rightElement: 'home',
      leftElement: 'back',
    });
    setTopTabbarTitle('견적함');
    handleVoidEvents.getQuoteList();
  }, []);

  useEffect(() => {
    window.scrollTo(0, quoteScrollPosition);
    console.info('스크롤 위치', quoteScrollPosition);
  }, [quoteScrollPosition]);

  useEffect(() => {
    setOnResume(async () => {
      try {
        const result = await getManagerQuoteListApi(quoteSearch);
        if (result) {
          setQuoteList(result.data);
          console.info(result.data);
          setQuoteBoxOpen(new Array(result.data.length).fill(false));
          setTotalLength(result.totalCount);
        } else {
          CarsayoToast.error('견적함 데이터가 없습니다.');
          navigate('/');
        }
      } catch (error: any) {
        console.error(error);
      }
    });
  }, []);

  useEffect(() => {
    handleVoidEvents.getQuoteList();
  }, [quoteSearch]);

  useEffect(() => {
    setQuoteSearch((prevState) => ({
      ...prevState,
      skip: page ? (page - 1) * 5 : 0,
    }));
    setSkipData(page ? (page - 1) * 5 : 0);
    setQuotePage(page);
  }, [page]);

  useEffect(() => {
    if (dateRange === '90d') {
      setStartDateValue(dayjs().subtract(90, 'day').toDate());
      setEndDateValue(new Date());
    } else if (dateRange === '1y') {
      setStartDateValue(dayjs().startOf('year').toDate());
      setEndDateValue(dayjs().endOf('year').toDate());
    } else if (dateRange === '2y') {
      setStartDateValue(dayjs().subtract(1, 'year').startOf('year').toDate());
      setEndDateValue(dayjs().endOf('year').toDate());
    } else if (dateRange === '3y') {
      setStartDateValue(dayjs().subtract(2, 'year').startOf('year').toDate());
      setEndDateValue(dayjs().endOf('year').toDate());
    } else {
      setStartDateValue(startDateValue ?? new Date());
      setEndDateValue(endDateValue ?? dayjs().subtract(1, 'month').toDate());
    }
  }, [dateRange]);

  useEffect(() => {
    return () => {
      setQuoteScrollPosition(window.scrollY);
    };
  }, [setQuoteScrollPosition]);

  return (
    <>
      <div className='bg-[rgb(241,241,245)]'>
        {/* 검색박스 */}
        <div className='w-full bg-[#fff] pt-8'>
          {/* 검색박스 여닫는 버튼 */}
          <div
            className='z-[1] cursor-pointer w-full bg-[#F7F7FB] gap-[5.3vw] py-2 px-4 flex flex-row justify-between items-center'
            onClick={handleClickEvents.searchBoxOpen}
          >
            <p className='py-2'>조회 설정</p>
            <p className='py-2 grow text-left'>
              <span>
                {startDateValue
                  ? dateToString(startDateValue).slice(0, 10)
                  : dateToString(new Date()).slice(0, 10)}
              </span>
              <span className='mx-[3px]'>~</span>
              <span>
                {endDateValue
                  ? dateToString(endDateValue).slice(0, 10)
                  : dateToString(new Date()).slice(0, 10)}
              </span>
            </p>
            <img
              src={ArrowTop}
              alt='위쪽화살표'
              className={classNames(
                'transition-all cursor-pointer',
                isSearchBoxOpen ? 'rotate-[0deg]' : 'rotate-[180deg]',
              )}
            />
          </div>
          {/* 검색박스 컨텐츠 */}
          <div
            className={classNames(
              'transition-all pb-6 mb-[12px]',
              isSearchBoxOpen
                ? 'h-auto opacity-100 translate-y-0 z-[0] pointer-events-auto'
                : 'h-0 opacity-0 translate-y-[-50px] z-[-100] pointer-events-none fixed',
            )}
          >
            <div className='w-full p-4'>
              <div className='w-full flex flex-col gap-4 border-b-[1px] border-[#eee]'>
                <div className='flex flex-row justify-between gap-x-[1vw] gap-y-[5px] text-[13px]'>
                  <button
                    className={classNames(
                      'py-[9px] px-[3vw] border w-full',
                      dateRange === '90d'
                        ? 'border-[#7273F9] text-[#7273F9]'
                        : 'border-[#E5E5EC] text-[#767676]',
                    )}
                    onClick={() => setDateRange('90d')}
                  >
                    최근 90일
                  </button>
                  <button
                    className={classNames(
                      'py-[9px] px-[12px] border w-full',
                      dateRange === '1y'
                        ? 'border-[#7273F9] text-[#7273F9]'
                        : 'border-[#E5E5EC] text-[#767676]',
                    )}
                    onClick={() => setDateRange('1y')}
                  >
                    {dayjs().year().toString()}년
                  </button>
                  <button
                    className={classNames(
                      'py-[9px] px-[12px] border w-full',
                      dateRange === '2y'
                        ? 'border-[#7273F9] text-[#7273F9]'
                        : 'border-[#E5E5EC] text-[#767676]',
                    )}
                    onClick={() => setDateRange('2y')}
                  >
                    {dayjs().subtract(1, 'year').year().toString()}년
                  </button>
                  <button
                    className={classNames(
                      'py-[9px] px-[12px] border w-full',
                      dateRange === '3y'
                        ? 'border-[#7273F9] text-[#7273F9]'
                        : 'border-[#E5E5EC] text-[#767676]',
                    )}
                    onClick={() => setDateRange('3y')}
                  >
                    {dayjs().subtract(2, 'year').year().toString()}년
                  </button>
                </div>
                <div className='w-full pb-4'>
                  <div className='flex flex-row items-center gap-[5.5vw] w-full'>
                    <div className='relative w-[calc(50%-11px)]'>
                      <CarsayoInput
                        type='text'
                        placeholder='2022-01-01'
                        className={classNames(
                          'pr-[42px] rounded-none !text-[14px] !py-[10px] !px-[16px] !min-h-[20px]',
                          startDateValue && '!border-[#E5E5EC]',
                          dateRange === 'range' &&
                            '!text-[#7273F9] !border-[#7273F9]',
                        )}
                        onClick={handleClickEvents.popupOpen('start')}
                        value={
                          startDateValue
                            ? startDateValue.toISOString().split('T')[0]
                            : ''
                        }
                        readOnly
                      />
                      <span
                        className={classNames(
                          'text-transparent -indent-view-out absolute',
                          'w-[18px] h-[18px] right-[15px] top-0 bottom-[1px] my-auto',
                          'bg-calendar-icon bg-no-repeat bg-content-fit bg-center',
                        )}
                      >
                        달력아이콘
                      </span>
                    </div>
                    <span className='font-[600]'>~</span>
                    <div className='relative w-[calc(50%-11px)]'>
                      <CarsayoInput
                        type='text'
                        placeholder='2022-01-01'
                        className={classNames(
                          'pr-[42px] rounded-none !text-[14px] !py-[10px] !px-[16px] !min-h-[20px]',
                          endDateValue && '!border-[#E5E5EC]',
                          dateRange === 'range' &&
                            '!text-[#7273F9] !border-[#7273F9]',
                        )}
                        onClick={handleClickEvents.popupOpen('end')}
                        value={
                          endDateValue
                            ? endDateValue.toISOString().split('T')[0]
                            : ''
                        }
                        readOnly
                      />
                      <span
                        className={classNames(
                          'text-transparent -indent-view-out absolute',
                          'w-[18px] h-[18px] right-[15px] top-0 bottom-[1px] my-auto',
                          'bg-calendar-icon bg-no-repeat bg-content-fit bg-center',
                        )}
                      >
                        달력아이콘
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full px-4'>
              <ul className='flex flex-wrap gap-x-[10px] gap-y-[12px] mb-[16px]'>
                <li className='w-full max-w-[380px] flex flex-row items-center gap-[6vw] p-2 pl-0'>
                  <span className='text-[#767676]'>진행여부</span>
                  <div className='flex flex-row gap-4'>
                    <CarsayoRadio
                      name={'progressRadio'}
                      label={'전체'}
                      className='ml-4'
                      onChange={(e) => setProgressRadio(e.target.value)}
                      value='all'
                      checked={progressRadio === 'all'}
                    />
                    <CarsayoRadio
                      name={'progressRadio'}
                      label={'진행'}
                      className='ml-4'
                      onChange={(e) => setProgressRadio(e.target.value)}
                      value='progress'
                      checked={progressRadio === 'progress'}
                    />
                    <CarsayoRadio
                      name={'progressRadio'}
                      label={'마감'}
                      className='ml-4'
                      onChange={(e) => setProgressRadio(e.target.value)}
                      value='finished'
                      checked={progressRadio === 'finished'}
                    />
                  </div>
                </li>
                <li className='w-full max-w-[380px] flex flex-row items-center gap-[6vw] p-2 pl-0'>
                  <span className='text-[#767676]'>열람여부</span>
                  <div className='flex flex-row gap-4'>
                    <CarsayoRadio
                      name={'readingRadio'}
                      label={'전체'}
                      className='ml-4'
                      onChange={(e) => setReadingRadio(e.target.value)}
                      value='all'
                      checked={readingRadio === 'all'}
                    />
                    <CarsayoRadio
                      name={'readingRadio'}
                      label={'열람'}
                      className='ml-4'
                      onChange={(e) => setReadingRadio(e.target.value)}
                      value='read'
                      checked={readingRadio === 'read'}
                    />
                    <CarsayoRadio
                      name={'readingRadio'}
                      label={'미열람'}
                      className='ml-4'
                      onChange={(e) => setReadingRadio(e.target.value)}
                      value='unread'
                      checked={readingRadio === 'unread'}
                    />
                  </div>
                </li>
                <li className='w-full max-w-[380px] flex flex-row items-center gap-[6vw] p-2 pl-0'>
                  <span className='text-[#767676]'>낙찰여부</span>
                  <div className='flex flex-row gap-4'>
                    <CarsayoRadio
                      name={'bidRadio'}
                      label={'전체'}
                      className='ml-4'
                      onChange={(e) => setBidRadio(e.target.value)}
                      value='all'
                      checked={bidRadio === 'all'}
                    />
                    <CarsayoRadio
                      name={'bidRadio'}
                      label={'낙찰'}
                      className='ml-4'
                      onChange={(e) => setBidRadio(e.target.value)}
                      value='success'
                      checked={bidRadio === 'success'}
                    />
                    <CarsayoRadio
                      name={'bidRadio'}
                      label={'유찰'}
                      className='ml-4'
                      onChange={(e) => setBidRadio(e.target.value)}
                      value='failed'
                      checked={bidRadio === 'failed'}
                    />
                  </div>
                </li>
              </ul>
              <button
                className='py-[14px] px-[16px] w-full flex justify-center items-center text-[#7273F9] border border-[#7273F9] bg-[#F4EEFF]'
                onClick={handleClickEvents.search}
              >
                검색
              </button>
            </div>
          </div>
        </div>
        {/* 견적 상태 카운트박스  */}
        <div className='w-full bg-[#fff] p-4  mb-[12px]'>
          <ul className='w-full flex flex-row justify-center items-center'>
            <li
              className={classNames(
                'w-[25%] relative',
                'after:content-[""] after:absolute after:right-0 after:top-0 after:bottom-0 after:my-auto after:h-[29px] after:w-[1px] after:border-r-[1px] after:border-[#E5E5EC]',
              )}
            >
              <div className='flex flex-col justify-start items-center gap-[10px]'>
                <p className='text-[#767676] font-[600] leading-[20px]'>입찰</p>
                <p className='text-[20px] font-[600] leading-[28px]'>
                  {loginMember?.dealerInfo
                    ? loginMember.dealerInfo?.count?.order?.bidding ?? 0
                    : 0}
                </p>
              </div>
            </li>
            <li
              className={classNames(
                'w-[25%] relative',
                'after:content-[""] after:absolute after:right-0 after:top-0 after:bottom-0 after:my-auto after:h-[29px] after:w-[1px] after:border-r-[1px] after:border-[#E5E5EC]',
              )}
            >
              <div className='flex flex-col justify-start items-center gap-[10px]'>
                <p className='text-[#767676] font-[600] leading-[20px]'>
                  계약진행
                </p>
                <p className='text-[20px] font-[600] leading-[28px]'>
                  {loginMember?.dealerInfo
                    ? loginMember.dealerInfo?.count?.order?.progressing ?? 0
                    : 0}
                </p>
              </div>
            </li>
            <li
              className={classNames(
                'w-[25%] relative',
                'after:content-[""] after:absolute after:right-0 after:top-0 after:bottom-0 after:my-auto after:h-[29px] after:w-[1px] after:border-r-[1px] after:border-[#E5E5EC]',
              )}
            >
              <div className='flex flex-col justify-start items-center gap-[10px]'>
                <p className='text-[#767676] font-[600] leading-[20px]'>
                  취소/보류
                </p>
                <p className='text-[20px] font-[600] leading-[28px]'>
                  {loginMember?.dealerInfo
                    ? (loginMember.customerInfo?.count?.order?.canceled ?? 0) +
                      (loginMember.customerInfo?.count?.order?.holded ?? 0)
                    : 0}
                </p>
              </div>
            </li>
            <li className={classNames('w-[25%] relative')}>
              <div className='flex flex-col justify-start items-center gap-[10px]'>
                <p className='text-[#767676] font-[600] leading-[20px]'>완료</p>
                <p className='text-[20px] font-[600] leading-[28px]'>
                  {loginMember?.dealerInfo
                    ? loginMember.dealerInfo?.count?.order?.finished ?? 0
                    : 0}
                </p>
              </div>
            </li>
          </ul>
        </div>

        {/* 견적 리스트 박스  */}
        <div className='w-full bg-[#fff]  pb-4 mb-[12px]'>
          <ul className='w-full flex flex-col justify-center items-center gap-[12px] bg-[#F1F1F5]'>
            {quoteList && quoteList.length > 0 ? (
              quoteList.map((item, index) => {
                return (
                  <li
                    key={`quoteContent_${index}`}
                    id={item.id}
                    className={classNames('w-full pt-10 bg-[#fff]')}
                  >
                    <div className='w-full pt-4 border-t-[1px] border-[#35383F] relative'>
                      <span className='inline-flex justify-center items-center gap-[5px] py-[6px] px-[16px] bg-[#1F222A] text-white rounded-[24px] top-[-16px] absolute w-[28.79vw] left-0 right-0 mx-auto'>
                        <strong className='font-[600]'>
                          {dateToStringMMDD(item.created_at && item.created_at)}
                        </strong>
                        {dateToStringHHMM(item.created_at && item.created_at)}
                      </span>
                    </div>

                    {/* 카드 컨텐츠 */}
                    <div className='w-full flex flex-col justify-start items-center p-4 pb-8'>
                      <div
                        className='w-full flex flex-col items-start gap-3 pb-8 cursor-pointer'
                        onClick={handleClickEvents.moveDetail(item.id)}
                      >
                        <div className='w-full flex flex-row justify-between items-center gap-3'>
                          <div className='flex flex-row justify-start items-center gap-3'>
                            <p
                              className={classNames(
                                'min-w-[70px] rounded-[6px] px-3 py-2 flex justify-center items-center font-[500]',
                                `${parseDealerOrderListDataState(item).textColor}`,
                                `${parseDealerOrderListDataState(item).bgColor}`,
                                // item.state === 'submitted'
                                //   ? 'bg-[#04b012]'
                                //   : item.state === 'progressing'
                                //     ? 'bg-[#0075FF]'
                                //     : item.state === 'holded'
                                //       ? 'bg-[#FFAA00]'
                                //       : item.state === 'canceled'
                                //         ? 'bg-[#B71C1C]'
                                //         : item.state === 'deleted'
                                //           ? 'bg-[#B71C1C]'
                                //           : item.state === 'finished'
                                //             ? 'bg-[#2F66BA]'
                                //             : 'bg-[#111111]',
                              )}
                            >
                              {parseDealerOrderListDataState(item).text}
                            </p>
                            <p className='flex text-[13px] font-[600]'>
                              {/* {convertDealerType[item.type] ?? '타입 없음'} */}
                              {item.type === 'newCar'
                                ? item.newCar &&
                                  item.newCar.installmentTerm === 0
                                  ? '일시불'
                                  : `할부 (${item.newCar?.installmentTerm}개월)`
                                : convertDealerType[item.type] ?? '타입없읍'}
                            </p>
                          </div>
                          <div className='flex flex-row justify-start items-center gap-3'>
                            <ul className='flex flex-row justify-start items-center gap-[5px] text-[11px] min-w-[23%]'>
                              <li
                                className={classNames(
                                  'max-w-[70px] py-[2px] leading-[16px] text-center px-[4px] border text-[#767676] border-[#767676]',
                                  item.isBidReaded && item.state === 'submitted'
                                    ? '!border-[#107700] !text-[#107700]'
                                    : item.isReaded && item.isBidded
                                      ? item.state === 'submitted'
                                        ? '!border-[#107700] !text-[#107700]'
                                        : item.state === 'progressing'
                                          ? '!border-[#2F66BA] !text-[#2F66BA]'
                                          : item.state === 'failBid'
                                            ? '!border-[#111111] !text-[#111111]'
                                            : item.state === 'holded'
                                              ? '!border-[#FFAA00] !text-[#FFAA00]'
                                              : item.state === 'canceled'
                                                ? '!border-[#B71C1C] !text-[#B71C1C]'
                                                : item.state === 'deleted'
                                                  ? '!border-[#B71C1C] !text-[#B71C1C]'
                                                  : '!border-[#2F66BA] !text-[#2F66BA]'
                                      : item.state === 'failBid'
                                        ? '!border-[#111111] !text-[#111111]'
                                        : item.isReaded &&
                                            item.isBidded === false
                                          ? '열람'
                                          : '미열람',
                                )}
                              >
                                {item.isBidReaded && item.state === 'submitted'
                                  ? '입찰확인'
                                  : item.isReaded && item.isBidded
                                    ? item.state === 'submitted'
                                      ? '입찰중'
                                      : item.state === 'progressing'
                                        ? '진행중'
                                        : item.state === 'failBid'
                                          ? '종료'
                                          : item.state === 'holded'
                                            ? '보류'
                                            : item.state === 'canceled'
                                              ? '취소'
                                              : item.state === 'deleted'
                                                ? '삭제'
                                                : '계약완료'
                                    : item.state === 'failBid'
                                      ? '입찰종료'
                                      : item.isReaded &&
                                          item.isBidded === false &&
                                          item.state === 'submitted'
                                        ? '열람'
                                        : '미열람'}
                              </li>
                              {item.state === 'submitted' && (
                                <li
                                  className={classNames(
                                    `max-w-[70px] py-[2px] text-center leading-[16px] px-[4px] text-white`,
                                    item.created_at &&
                                      remainingTime(item.created_at) === '종료'
                                      ? 'bg-[#111111]'
                                      : 'bg-[#2F66BA]',
                                  )}
                                >
                                  {remainingTime(
                                    item.created_at && item.created_at,
                                  )}
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                        <p className='text-[#111111] underline'>
                          {item.brand ? `${item.brand} ` : ''}
                          {item.title ?? '차량이름 없음'}
                        </p>
                      </div>
                      <div className='w-full border-t-[1px] border-[#E5E5EC] relative'>
                        <button
                          className='shadow-[0px_4px_10px_#0000000f] absolute left-0 right-0 mx-auto top-[-20px] p-[10px] rounded-full border border-[#E5E5EC] w-[40px] inline-flex justify-center items-center bg-white'
                          onClick={handleClickEvents.quoteBoxOpen(index)}
                        >
                          <img
                            src={ArrowTop}
                            alt='닫기버튼'
                            className={classNames(
                              'transition-all',
                              isQuoteBoxOpen[index]
                                ? 'rotate-[0deg]'
                                : 'rotate-[180deg]',
                            )}
                          />
                        </button>
                      </div>
                      <div className='w-full pt-4'>
                        <ul
                          className={classNames(
                            'transition-all w-full p-4 pb-0',
                            isQuoteBoxOpen[index]
                              ? 'h-auto opacity-100 translate-y-0 z-[0] pointer-events-auto'
                              : 'h-0 opacity-0 translate-y-[-50px] z-[-100] pointer-events-none fixed',
                          )}
                        >
                          <li className='flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                            <p className='text-[#767676] min-w-[12vw]'>
                              견적요청
                            </p>
                            <p className='text-[#111111]'>
                              {dateToString(item.created_at) ?? (
                                <span className='text-[#999999]'>없음</span>
                              )}
                            </p>
                          </li>
                          {item.leaseRent && (
                            <li className='flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                              <p className='text-[#767676] min-w-[12vw]'>
                                계약기간
                              </p>
                              <p className='text-[#111111]'>
                                {item.leaseRent.contractTerm ? (
                                  `${item.leaseRent.contractTerm}개월`
                                ) : (
                                  <span className='text-[#999999]'>없음</span>
                                )}
                              </p>
                            </li>
                          )}

                          <li className='flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                            <p className='text-[#767676] min-w-[12vw]'>
                              예상안내
                            </p>
                            <p className='text-[#111111]'>
                              {item.price ? (
                                slicePrice(item.price)
                              ) : (
                                <span className='text-[#999999]'>없음</span>
                              )}
                            </p>
                          </li>
                          <li className='flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                            <p className='text-[#767676] min-w-[12vw]'>
                              닉네임
                            </p>
                            <p className='text-[#111111]'>
                              {item.member.name_nick ?? (
                                <span className='text-[#999999]'>없음</span>
                              )}
                            </p>
                          </li>
                          {/* <li className='flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                            <p className='text-[#767676] min-w-[12vw]'>이름</p>
                            <p className='text-[#111111]'>
                              {item.member.name_real ?? (
                                <span className='text-[#999999]'>없음</span>
                              )}
                            </p>
                          </li> */}
                          <li className='flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                            <p className='text-[#767676] min-w-[12vw]'>
                              연락처
                            </p>
                            <p className='text-[#111111] flex justify-start items-center gap-[10px]'>
                              {item.member.phoneNumber &&
                                item.member.phoneNumber !== '비공개' && (
                                  <a href={`tel:${item.member.phoneNumber}`}>
                                    <TelIcon />
                                  </a>
                                )}
                              <span>
                                {item.member.phoneNumber ? (
                                  item.member.phoneNumber === '비공개' ? (
                                    '비공개'
                                  ) : (
                                    '공개'
                                  )
                                ) : (
                                  <span className='text-[#999999]'>없음</span>
                                )}
                              </span>
                            </p>
                          </li>
                          {item.newCar && (
                            <>
                              <li className='flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                                <p className='text-[#767676] min-w-[12vw]'>
                                  거주지역
                                </p>
                                <p className='text-[#111111]'>
                                  {item?.newCar?.residenceRegion ? (
                                    `${
                                      parseSigunguId(
                                        item.newCar.residenceRegion,
                                      )?.sido.name ?? ''
                                    } ${
                                      parseSigunguId(
                                        item.newCar.residenceRegion,
                                      )?.name ?? ''
                                    }`
                                  ) : (
                                    <span className='text-[#999999]'>없음</span>
                                  )}
                                </p>
                              </li>
                              <li className='flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                                <p className='text-[#767676] min-w-[12vw]'>
                                  인수지역
                                </p>
                                <p className='text-[#111111]'>
                                  {item?.newCar?.deliveryRegion ? (
                                    `${
                                      parseSigunguId(item.newCar.deliveryRegion)
                                        ?.sido.name ?? ''
                                    } ${
                                      parseSigunguId(item.newCar.deliveryRegion)
                                        ?.name ?? ''
                                    }`
                                  ) : (
                                    <span className='text-[#999999]'>없음</span>
                                  )}
                                </p>
                              </li>
                            </>
                          )}
                          {item.sellCar && (
                            <li className='flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                              <p className='text-[#767676] min-w-[12vw]'>
                                판매지역
                              </p>
                              <p className='text-[#111111]'>
                                {item?.sellCar?.sellRegion ? (
                                  `${
                                    parseSigunguId(item.sellCar.sellRegion)
                                      ?.sido.name ?? ''
                                  } ${
                                    parseSigunguId(item.sellCar.sellRegion)
                                      ?.name ?? ''
                                  }`
                                ) : (
                                  <span className='text-[#999999]'>없음</span>
                                )}
                              </p>
                            </li>
                          )}
                        </ul>
                        <button
                          className='w-full mt-4 py-[14px] px-[16px] text-center text-[#111111] text-[16px] font-[500] border border-[#E5E5EC]'
                          onClick={handleClickEvents.moveDetail(item.id)}
                        >
                          상세
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <li className='w-full bg-white'>
                {/* 입찰중인 견적 없을때 */}
                <div className='w-full py-[40px] px-[16px]'>
                  <p className='text-[28px] font-[600] leading-[38px] text-[#111111] mb-4 text-center'>
                    검색 결과가
                    <br />
                    없습니다.
                  </p>
                  <p className='text-[#9EA3B2] mb-2 w-full text-center'>
                    조금만 기다리시면 고객님들이 입찰한
                    <br />
                    견적을 확인할 수 있습니다.
                  </p>
                  <p className='w-[53.3vw] h-[150px] flex justify-center items-center mx-auto'>
                    <img
                      src={PeopleIcon}
                      alt='사람아이콘'
                      className='object-contain'
                    />
                  </p>
                </div>
              </li>
            )}
          </ul>
        </div>
        {/* 안내사항 */}
        <div className='w-full bg-[#fff] p-4 pb-8'>
          <div className='w-full bg-[#F7F7FB] p-4'>
            <p className='text-[#111111] font-[600] mb-4'>안내사항</p>
            <ul className='text-[#767676] text-[13px] flex flex-col gap-[4px]'>
              <li className='relative pl-[10px] after:content-["•"] after:w-[2px] after:h-full after:text-[#767676] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
                계약이 완료된 후 카 매니저에게 메시지 및 알림을 통해 수수료 정산
                요청이 자동 발송됩니다.
              </li>
              <li className='relative pl-[10px] after:content-["•"] after:w-[2px] after:h-full after:text-[#767676] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
                수수료 정산 관련 사항은 마이 페이지 &gt; 결제관리에서 확인할 수
                있습니다.
              </li>
            </ul>
          </div>
          {/* 페이지네이션 */}
          {quoteList &&
            quoteList.length > 0 &&
            totalLength &&
            totalLength > 0 && (
              <div className='w-full h-auto mt-[32px]'>
                <CarsayoPagination
                  totalCount={totalLength}
                  page={page}
                  setPage={setPage}
                />
              </div>
            )}
        </div>
      </div>

      <CarsayoBottomSheet
        isOpen={isBottomOpen}
        onClose={handleClickEvents.popupOpen('')}
      >
        {dateId === 'start' ? (
          <CarsayoDatePicker
            isOpen={isBottomOpen}
            onClose={handleClickEvents.popupOpen('start')}
            dateId={dateId}
            maxDate={endDateValue !== null ? endDateValue : undefined}
            setData={setData}
          />
        ) : (
          <CarsayoDatePicker
            isOpen={isBottomOpen}
            onClose={handleClickEvents.popupOpen('end')}
            dateId={dateId}
            minDate={startDateValue !== null ? startDateValue : undefined}
            setData={setData}
          />
        )}
      </CarsayoBottomSheet>
    </>
  );
};
export default QuoteBoxMain;
