import { Link, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import IconHome from '../../../assets/images/v2/home.svg';
import IconLeftArrow from '../../../assets/images/v2/leftArrow.svg';
import IconNotification from '../../../assets/images/v2/notification.svg';
import useMemberStore from 'store/useMemberStore';
import { cn } from 'utils';
import { AlignJustify } from 'lucide-react';

const TopTabbar = () => {
  const navigate = useNavigate();

  const { loginMember } = useMemberStore();
  const { topTabberState, isSideMenu, setIsSideMenu } = useSystemStore();

  const handleClickEvents = {
    moveToAlarmPage: () => {
      if (loginMember) {
        navigate('/my-page/alarm-list');
      } else {
        navigate('/auth/signup', { replace: true });
      }
    },

    toggleSideMenu: () => {
      navigate({ search: 'subpage' });
      setIsSideMenu(!isSideMenu);
    },
  };

  const Icons = {
    alert: () => {
      return (
        <div
          className='relative cursor-pointer'
          onClick={handleClickEvents.moveToAlarmPage}
        >
          {loginMember && loginMember.notification.unreadCount > 0 && (
            <span className='absolute top-1 right-1 flex w-2 h-2'>
              <span className='animate-ping bg-[#ff2f2f] absolute inline-flex w-full h-full rounded-full opacity-75'></span>
              <span className='bg-[#ff2f2f] relative inline-flex w-2 h-2 rounded-full'></span>
            </span>
          )}
          <img src={IconNotification} />
        </div>
      );
    },
  };

  function LeftIcon() {
    return (
      <div className='min-w-5'>
        {topTabberState.leftElement === 'back' ? (
          <a onClick={() => navigate(-1)}>
            <img src={IconLeftArrow} alt='arrow left' />
          </a>
        ) : (
          topTabberState.leftElement
        )}
      </div>
    );
  }

  function RightIcon() {
    return (
      <div className='min-w-5'>
        {topTabberState.rightElement === 'home' ? (
          <Link to='/main'>
            <img src={IconHome} alt='carsayoIcon' />
          </Link>
        ) : (
          topTabberState.rightElement
        )}
      </div>
    );
  }

  function Main() {
    return (
      <div
        className={cn(
          'flex h-14 items-center justify-between px-5',
          isSideMenu && 'hidden',
        )}
      >
        <Link to='/main'>
          <img src={'/assets/images/svg/casayoLogo.svg'} className='h-[22px]' />
        </Link>
        <Icons.alert />
      </div>
    );
  }

  function MainType_2() {
    return (
      <div className='flex h-14 items-center justify-between px-5'>
        <div className='flex items-center gap-3'>
          <AlignJustify
            className='w-6 h-6 cursor-pointer'
            onClick={handleClickEvents.toggleSideMenu}
          />
          <img
            src={'/assets/images/svg/casayoLogo_black.svg'}
            className='h-[18px]'
          />
        </div>
        <Icons.alert />
      </div>
    );
  }

  function SubPage() {
    return (
      <div className='flex h-14 items-center justify-between px-5'>
        <LeftIcon />
        {topTabberState.title && (
          <div className='text-xl font-semibold'>{topTabberState.title}</div>
        )}
        <RightIcon />
      </div>
    );
  }

  return topTabberState.state !== 'hidden' ? (
    <div
      className={cn(
        'bg-transparent flex-none w-full',
        topTabberState.className,
      )}
      style={{ paddingTop: 'env(safe-area-inset-top, 0)' }}
    >
      {topTabberState.mode === 'main' && <Main />}
      {topTabberState.mode === 'mainType_2' && <MainType_2 />}
      {topTabberState.mode === 'subpage' && <SubPage />}
    </div>
  ) : (
    <></>
  );
};

export default TopTabbar;
