import SubPage from 'components/view/SubPage';
import { X } from 'lucide-react';
import SingleImgPopup from './CarsayoSingleImgPopup';
import { useState } from 'react';

interface CarsayoImgListInterface {
  isSideMenu: boolean;
  setIsSideMenu: (isOpen: boolean) => void;
  imgArray: Array<string>;
}

const CarsayoImgList = ({
  isSideMenu,
  setIsSideMenu,
  imgArray,
}: CarsayoImgListInterface) => {
  const [isSinglePopupOpen, setSinglePopupOpen] = useState(false);
  const [startIndex, setStartIndex] = useState(0);

  const handleClickEvents = {
    openSinglePopup: (index: number) => () => {
      setStartIndex(index);
      setSinglePopupOpen(!isSinglePopupOpen);
    },
  };

  return (
    <>
      <SubPage isOpen={isSideMenu} setIsOpen={setIsSideMenu}>
        <div className='flex flex-none h-14 items-center justify-between bg-white px-4'>
          <div className='pl-4 grow text-center text-xl font-semibold'>
            전체 사진 보기
          </div>
          <div className='cursor-pointer' onClick={() => setIsSideMenu(false)}>
            <X></X>
          </div>
        </div>
        <div className='w-full overflow-y-auto'>
          <ul className='w-full flex flex-col justify-center items-center gap-[12px]'>
            {imgArray.map((item, index) => {
              return (
                <li className='w-full h-auto' key={`img_${item}`}>
                  <button
                    onClick={handleClickEvents.openSinglePopup(index)}
                    className='w-full h-auto'
                  >
                    <img
                      src={item}
                      alt={`이미지리스트${index}`}
                      className='w-full object-contain'
                      // h-[35.8vh] max-h-[291px]
                    />
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </SubPage>
      <SingleImgPopup
        imgs={imgArray}
        isOpen={isSinglePopupOpen}
        setOpen={setSinglePopupOpen}
        startIndex={startIndex}
      />
    </>
  );
};
export default CarsayoImgList;
