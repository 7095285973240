import { PurchaseOrderInfo, PurchaseCarInfo } from '@carsayo/types';

export default (purchaseOrderDTO: PurchaseOrderInfo | PurchaseCarInfo) => {
  return (
    Number(purchaseOrderDTO.carGrade?.price) +
    Number(
      purchaseOrderDTO.carColor?.price ? purchaseOrderDTO.carColor?.price : 0,
    ) +
    Number(
      purchaseOrderDTO.carOptionList &&
        purchaseOrderDTO.carOptionList.length > 0
        ? purchaseOrderDTO.carOptionList
            .map((el) => {
              return el.price;
            })
            .reduce((prev, curr) => {
              return prev + curr;
            })
        : 0,
    ) +
    0
  );
};
