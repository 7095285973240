import { HistoryInsuranceClickDTO } from '@carsayo/types';
import { api } from 'apis';
import SentryErrorReturns from 'utils/sentryErrorReturns';

/** 보험 링크 클릭 로그 생성 - 쿠콘 */
export const addHistoryInsuranceCooconClick = async (
  reqData: HistoryInsuranceClickDTO,
) => {
  try {
    await api.post('history/insurance/coocon/click', reqData);
    return;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

/** 보험 링크 클릭 로그 생성 - ADLink */
export const addHistoryInsuranceAdLinkClick = async (
  reqData: HistoryInsuranceClickDTO,
) => {
  try {
    await api.post('history/insurance/adLink/click', reqData);
    return;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};
