import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/swiper-bundle.css';
import { X } from 'lucide-react';
import { AnimatePresence, motion } from 'framer-motion';
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch';
import { useRef, useState } from 'react';

interface SingleImgPopupInterface {
  imgs: Array<string>;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  startIndex?: number;
}

const SingleImgPopup = ({
  imgs,
  isOpen,
  setOpen,
  startIndex,
}: SingleImgPopupInterface) => {
  SwiperCore.use([Pagination]);

  const transformRef = useRef<ReactZoomPanPinchRef>(null);
  const [isPanningEnabled, setIsPanningEnabled] = useState(false);

  const handleZoom = (
    ref: ReactZoomPanPinchRef,
    event: MouseEvent | TouchEvent,
  ) => {
    if (ref) {
      const { scale } = ref.state;
      setIsPanningEnabled(scale > 1);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.1, duration: 0.3 },
          }}
          className='fixed w-full h-full bg-gray-800 top-0 left-0 z-[6000] flex flex-col'
        >
          <div className='p-2 flex justify-end'>
            <div className='cursor-pointer' onClick={() => setOpen(false)}>
              <X className='text-[#fff]'></X>
            </div>
          </div>
          <Swiper
            className='singleImgSwiper !relative w-full h-full overflow-hidden'
            centeredSlides={true}
            spaceBetween={20}
            initialSlide={startIndex ? startIndex : 0}
            modules={[Pagination]}
            pagination={{ clickable: true }}
            // noSwiping={true}
            // allowTouchMove={false}
          >
            {imgs &&
              imgs.map((item, index) => {
                return (
                  <SwiperSlide
                    key={`${item}_${index}`}
                    className='w-full h-[100%]'
                  >
                    <TransformWrapper
                      ref={transformRef}
                      initialScale={1}
                      minScale={1}
                      maxScale={5}
                      pinch={{ step: 0.05 }}
                      centerOnInit={true}
                      doubleClick={{
                        mode: 'zoomIn',
                      }}
                      onZoom={handleZoom}
                      panning={{ disabled: !isPanningEnabled }}
                    >
                      <TransformComponent>
                        <img
                          src={item}
                          alt={`slide_${index}`}
                          className='w-full max-w-none'
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </SwiperSlide>
                );
              })}

            <div className='swiper-pagination'></div>
          </Swiper>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default SingleImgPopup;
