import { SearchPaymentDTO, PaymentSearchList } from '@carsayo/types';
import { api } from 'apis';

export const getPaymentList = async (
  reqData: SearchPaymentDTO,
): Promise<PaymentSearchList> => {
  try {
    const response = await api.post('nicepayment/list', {
      ...reqData,
    });
    const data: PaymentSearchList = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};
