/* eslint-disable @typescript-eslint/no-unused-vars */
import { getNotificationList, readNotification } from 'apis/notification';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import EmailIcon from 'assets/images/v2/email.svg';
import GiftIcon from 'assets/images/v2/gift.svg';
import RightArrow from 'assets/images/v2/arrowRightGray.svg';
import BottomArrow from 'assets/images/v2/arrowBottom.svg';
import MegaphoneIcon from 'assets/images/v2/megaphone.svg';
import { checkDate } from 'utils/convert';
import useMemberStore from 'store/useMemberStore';
import { CarsayoNotification, GetNotificationListDTO } from '@carsayo/types';

const AlarmListPage = () => {
  const navigate = useNavigate();
  const { setTopTabbarState } = useSystemStore();
  const { loginMember, setLoginMember } = useMemberStore();
  const [carsayoNotificationList, setCarsayoNotificationList] = useState<
    CarsayoNotification[]
  >([]);
  const [takeNotification, setTakeNotification] =
    useState<GetNotificationListDTO>({
      take: 4,
      skip: 0,
      isReaded: false,
    });

  const handleVoidEvents = {
    getNotificationList: async () => {
      setCarsayoNotificationList(await getNotificationList(takeNotification));
    },
  };

  const handleClickEvents = {
    moveToAlarmPage: (el: CarsayoNotification) => async () => {
      await handleClickEvents.readNotification(el);
      if (el.path.app) {
        navigate(el.path.app, { replace: true });
      } else {
        return;
      }
    },
    readNotification: async (notification: CarsayoNotification) => {
      if (!loginMember) return;
      await readNotification({ notificationId: notification.id });
      const modifiedData = carsayoNotificationList.filter((el) => {
        if (el.id !== notification.id) return;
      });
      setCarsayoNotificationList(modifiedData);
      setLoginMember({
        ...loginMember,
        notification: {
          unreadCount:
            loginMember?.notification.unreadCount &&
            loginMember?.notification.unreadCount > 0
              ? loginMember?.notification.unreadCount - 1
              : 0,
        },
      });
    },
    // readWholeNotification: async () => {
    //   await readWholeNotification();
    //   const modifiedData = carsayoNotificationList.map((el) => {
    //     return {
    //       ...el,
    //       isReaded: true,
    //     };
    //   });
    //   setCarsayoNotificationList(modifiedData);
    //   setNotification({
    //     totalCount: memberInfo.notification.totalCount,
    //     unreadCount: 0,
    //   });
    // },
    moreNotification: async () => {
      setTakeNotification({
        take: 4,
        skip: carsayoNotificationList.length,
        isReaded: false,
      });
    },
  };

  useLayoutEffect(() => {
    // handleVoidEvents.getNotificationList();
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      rightElement: 'home',
      leftElement: 'back',
      title: '알림',
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (takeNotification.skip === 0) {
          const result = await getNotificationList(takeNotification);
          if (result) {
            setCarsayoNotificationList(result);
          }
        } else {
          console.info(takeNotification);
          const result = await getNotificationList(takeNotification);
          if (result) {
            let newNotifications: CarsayoNotification[];
            if (Array.isArray(result)) {
              newNotifications = [...carsayoNotificationList, ...result];
            } else {
              newNotifications = [...carsayoNotificationList, result];
            }
            setCarsayoNotificationList(newNotifications);
          }
        }
      } catch (error: any) {
        console.info(error.message);
      }
    };
    fetchData();
  }, [takeNotification]);

  return (
    <div className='p-4 pb-10 w-full h-full'>
      {carsayoNotificationList && carsayoNotificationList.length > 0 ? (
        <div className='w-full flex flex-col justify-between gap-[1.97vh] h-full pt-[16px] pb-[12px]'>
          <ul className='w-full h-auto max-h-[75vh] overflow-y-auto flex flex-col gap-[24px]'>
            {carsayoNotificationList.map((el, index) => {
              return (
                <li key={`nofi_${el.id}`} className='w-full h-full'>
                  <button
                    className={`w-full min-h-[11vh] pb-[24px] ${index < carsayoNotificationList.length - 1 && 'border-b-[1px] border-[#F1F1F5]'} relative`}
                    onClick={handleClickEvents.moveToAlarmPage(el)}
                  >
                    <div className='flex flex-row gap-[16px] items-stretch h-full'>
                      <div className='min-w-[6.4vw]'>
                        <img
                          src={el.type === 'event' ? GiftIcon : EmailIcon}
                          alt='이메일 아이콘'
                          className='w-[6.4vw] h-auto object-contain'
                        />
                      </div>
                      <div className='text-[#11111] grow h-full flex flex-col gap-[8px] justify-between items-stretch'>
                        {el.title && (
                          <p className='max-w-[80%]  text-left text-[14px] font-[600] leading-[24px]'>
                            {el.title}
                          </p>
                        )}
                        {el.content && (
                          <p className='max-w-[80%]  text-left text-[13px] leading-[24px]'>
                            {el.content}
                          </p>
                        )}
                        {el.created_at && (
                          <p className='text-[#767676] text-left text-[13px] tracking-[-0.33px]'>
                            {checkDate(el.created_at)}
                          </p>
                        )}
                      </div>
                    </div>
                    <span className='absolute inline-flex justify-center items-center top-0 bottom-[40%] my-auto right-0'>
                      <img
                        src={RightArrow}
                        alt='오른쪽 화살표'
                        className='w-[16px] h-[16px] object-contain'
                      />
                    </span>
                  </button>
                </li>
              );
            })}
          </ul>
          {loginMember?.notification?.unreadCount &&
            carsayoNotificationList.length <
              loginMember.notification.unreadCount && (
              <button
                className='w-full py-[14px] px-[16px] flex justify-center items-center min-h-[52px] border border-[#E5E5EC]'
                onClick={handleClickEvents.moreNotification}
              >
                더보기
                <img src={BottomArrow} alt='아래 화살표' className='ml-[5px]' />
              </button>
            )}
        </div>
      ) : (
        <div className='pt-[1.97vh] min-h-[26.6vh] w-full flex flex-col justify-center items-center gap-[0.98vh]'>
          <img
            src={MegaphoneIcon}
            alt='확성기아이콘'
            className='w-[26.6vw] h-auto object-contain mx-auto'
          />
          <p className='text-[#767676]'>모든 알림을 읽으셨습니다!</p>
        </div>
      )}
    </div>
    // <div className='h-dvh flex flex-col bg-[#333]'>
    //   <HeaderMain
    //     title={`알림`}
    //     rightSubButtons={
    //       <div className='flex flex-row gap-[15px]'>
    //         <button onClick={handleClickEvents.moveToAlarmPage} className=''>
    //           <img
    //             className='w-[24px] h-[24px] '
    //             src={SettingIcon}
    //             alt='SettingIcon'
    //           />
    //         </button>
    //       </div>
    //     }
    //   />
    //   <div className='relative w-full flex-1 bg-white rounded-t-[40px] shadow-bottom-sheet px-[24px] pb-[100px] overflow-y-auto pt-[35px]'>
    //     {carsayoNotificationList.length > 0 ? (
    //       <>
    //         <div className='flex pb-5'>
    //           <div>최근 일주일 알림을 확인하실 수 있습니다.</div>
    //           <div className='flex-auto'></div>
    //           <button
    //             onClick={handleClickEvents.readWholeNotification}
    //             className='text-[12px] bg-[#F4F4F8] px-[10px] py-[5px] min-w-[45px] min-h-[17px] rounded-[7px] text-nowrap'
    //           >
    //             모두읽음
    //           </button>
    //         </div>
    //         {carsayoNotificationList.map((el) => {
    //           return (
    //             <li
    //               key={el.id}
    //               className='border-b-[1px] border-[#EEE] px-[10px] py-[15px] hover:cursor-pointer list-none'
    //             >
    //               <div
    //                 className='flex flex-col'
    //                 onClick={async () => {
    //                   if (!el.isReaded) {
    //                     await handleClickEvents.readNotification(el.id);
    //                   }
    //                   handleClickEvents.redirect(el?.path?.app);
    //                 }}
    //               >
    //                 <div className='flex flex-row items-center justify-between'>
    //                   <div className='flex flex-row w-full items-center gap-[10px]'>
    //                     <div
    //                       className={`text-[10px] ${!el.isReaded ? 'bg-[#FF7E6D]' : 'bg-[#09B2C9]'} text-white px-[7px] py-[2px] rounded-[5px]`}
    //                     >
    //                       {!el.isReaded ? '미열람' : '열람'}
    //                     </div>
    //                     <div className='flex-auto'></div>
    //                     <div className='text-[12px]'>
    //                       {elapsedTime(el.created_at)}
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <p className='mt-[10px] text-[14px]'>{el.content}</p>
    //               </div>
    //             </li>
    //           );
    //         })}
    //       </>
    //     ) : (
    //       <div>최근 알림이 없습니다</div>
    //     )}
    //   </div>
    // </div>
  );
};

export default AlarmListPage;
