/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from 'components/ui/dialog';
import { cn } from 'utils';
import { Check } from 'lucide-react';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import useSellingStore from '../store';

export default function StepComponent0() {
  const [dialogDistance, setDialogDistance] = useState<number>();
  const { sellingOrderDTO, setSellingOrderDTO } = useSellingStore();

  return (
    <div className='rounded border border-[#0075FF] bg-[#0075FF]/10'>
      <div className='flex h-[70px] items-center border-b border-[#0075FF] px-4 text-title font-semibold text-white'>
        주행 거리를 입력해주세요
      </div>
      <div className='space-y-4 px-4 py-6'>
        <Dialog>
          <DialogTrigger asChild>
            <div className='flex h-[72px] items-center gap-2 border border-white px-4'>
              <div className='relative h-6 w-6'>
                <input
                  type='radio'
                  name='distance'
                  className={cn(
                    'flex h-6 w-6 appearance-none items-center justify-center rounded-full bg-[#0075FF]/10',
                    Number(sellingOrderDTO?.orderData.drivenDistance) &&
                      'text-white',
                  )}
                />
                {Number(sellingOrderDTO?.orderData.drivenDistance) > 0 && (
                  <Check className='absolute left-1.5 top-1.5 h-3 w-3 text-white' />
                )}
              </div>
              <div
                className={cn(
                  'text-base font-medium text-[#9EA3B2]',
                  Number(sellingOrderDTO?.orderData.drivenDistance) &&
                    'text-white',
                )}
              >
                {Number(sellingOrderDTO?.orderData.drivenDistance)
                  ? Number(
                      sellingOrderDTO?.orderData.drivenDistance,
                    ).toLocaleString() + 'km'
                  : '입력하기'}
              </div>
            </div>
          </DialogTrigger>
          <DialogContent className='w-[311px] gap-0 border-0 p-0'>
            <div className='space-y-4 px-4 py-6 pb-8'>
              <div className='text-center font-semibold'>주행거리</div>
              {Number(
                sellingOrderDTO?.orderData.accidentHistory?.LAST_MILEAGE,
              ) && (
                <div className='text-[#555555]'>
                  최근 기록 :{' '}
                  {Number(
                    sellingOrderDTO?.orderData.accidentHistory?.LAST_MILEAGE,
                  ).toLocaleString()}
                  km
                </div>
              )}
              <div className='relative'>
                <NumericFormat
                  defaultValue={Number(
                    sellingOrderDTO?.orderData.accidentHistory?.LAST_MILEAGE,
                  )}
                  value={dialogDistance}
                  onValueChange={({ floatValue }) =>
                    setDialogDistance(floatValue)
                  }
                  allowLeadingZeros
                  thousandSeparator=','
                  className='h-[44px] w-full border border-input px-3 text-sm outline-none focus:border-[#111111]'
                ></NumericFormat>
                <div className='absolute right-0 top-0 flex h-[44px] items-center justify-center px-4'>
                  km
                </div>
              </div>
            </div>
            <div className='grid grid-cols-2'>
              <DialogClose className='h-12 rounded-none border-0 bg-[#F7F7FB] text-sm font-semibold'>
                취소
              </DialogClose>
              <DialogClose
                className='rounded-none border-0 bg-[#0075FF] text-sm font-semibold text-white'
                onClick={() => {
                  if (sellingOrderDTO !== undefined)
                    setSellingOrderDTO({
                      ...sellingOrderDTO,
                      update: {
                        drivenDistance: dialogDistance
                          ? dialogDistance
                          : Number(
                                sellingOrderDTO?.orderData.accidentHistory
                                  ?.LAST_MILEAGE,
                              )
                            ? Number(
                                sellingOrderDTO?.orderData.accidentHistory
                                  ?.LAST_MILEAGE,
                              )
                            : undefined,
                      },
                    });
                }}
              >
                수정하기
              </DialogClose>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
