import {
  GetPostListDTO,
  PostList,
  GetPostDTO,
  PostDetailInfo,
} from '@carsayo/types';
import { api } from 'apis';

export const getPostList = async (
  reqData: GetPostListDTO,
): Promise<PostList> => {
  try {
    const response = await api.post('board/post/getList', reqData);
    const data: PostList = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getPost = async (reqData: GetPostDTO): Promise<PostDetailInfo> => {
  try {
    const response = await api.get('board/post', {
      params: reqData,
    });
    const data: PostDetailInfo = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};
