import React from 'react';

interface MyComponentProps {
  fill?: string;
}

const SigninLogo: React.FC<MyComponentProps> = ({ fill }) => {
  return (
    <svg
      width='169'
      height='28'
      viewBox='0 0 169 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1170_5867)'>
        <path
          d='M141.345 2.71538C141.345 1.23829 140.189 0 138.657 0C137.931 0 137.082 0.344951 136.583 0.972938L129.7 9.23457C129.547 9.41836 129.265 9.41823 129.112 9.23432L123.456 1.05254C122.878 0.353796 122.073 0.00884489 121.233 0.00884489C119.657 0.00884489 118.388 1.24713 118.388 2.88344C118.388 3.5468 118.546 4.16594 119.045 4.78509L125.981 14.5315C126.265 14.8749 126.421 15.3071 126.421 15.7533V25.031C126.421 26.6231 127.725 27.941 129.301 27.941C130.876 27.941 132.181 26.6231 132.181 25.031V15.479C132.181 15.0328 132.336 14.6007 132.621 14.2573L140.741 4.46667C141.161 3.96251 141.353 3.42297 141.353 2.72423L141.345 2.71538Z'
          fill={fill ?? 'white'}
        />
        <path
          d='M154.176 0C146.356 0 140.205 6.16684 140.205 13.9705C140.205 21.7742 146.356 27.941 154.176 27.941C161.995 27.941 168.146 21.7742 168.146 13.9705C168.146 6.16684 161.995 0 154.176 0ZM154.176 22.3139C149.728 22.3139 146.206 18.6244 146.206 13.9705C146.206 9.31662 149.728 5.62713 154.176 5.62713C158.624 5.62713 162.145 9.31662 162.145 13.9705C162.145 18.6244 158.624 22.3139 154.176 22.3139Z'
          fill={fill ?? 'white'}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.5 13.9705C0.5 6.18701 6.67045 0 14.4705 0C17.5573 0 20.411 0.972815 22.7253 2.6526C24.0083 3.58389 24.2935 5.37897 23.3622 6.66204C22.4309 7.9451 20.6358 8.23028 19.3528 7.29899C18.009 6.32361 16.3335 5.7413 14.4705 5.7413C9.84547 5.7413 6.2413 9.35366 6.2413 13.9705C6.2413 18.5874 9.84547 22.1997 14.4705 22.1997C16.3335 22.1997 18.009 21.6174 19.3528 20.642C20.6358 19.7107 22.4309 19.9959 23.3622 21.279C24.2935 22.562 24.0083 24.3571 22.7253 25.2884C20.411 26.9682 17.5573 27.941 14.4705 27.941C6.67045 27.941 0.5 21.754 0.5 13.9705Z'
          fill={fill ?? 'white'}
        />
        <path
          d='M86.2188 27.941C81.1766 27.941 78.132 25.7327 77.1654 22.9442C76.956 22.3828 76.8594 21.8026 76.8594 21.2412C76.8594 19.5194 77.9226 18.3591 79.4207 18.3591C80.5645 18.3591 81.3377 18.827 82.0949 20.1744C82.8842 21.8587 84.4468 22.5699 86.3155 22.5699C88.6191 22.5699 90.1172 21.3722 90.1172 19.7253C90.1172 18.2281 89.1346 17.4608 86.3799 16.8432L83.9796 16.3192C79.3563 15.3086 76.9399 12.6698 76.9399 8.55261C76.9399 3.34993 80.8222 0 86.1705 0C90.6166 0 93.8706 1.98376 94.9983 5.40854C95.1594 5.87641 95.2399 6.32556 95.2399 6.887C95.2399 8.47775 94.2412 9.54449 92.7591 9.5632C91.4704 9.5632 90.6649 9.02048 89.9722 7.71045C89.2151 6.02613 87.9264 5.37111 86.2188 5.37111C84.0763 5.37111 82.8198 6.49399 82.8198 8.08474C82.8198 9.50706 83.8669 10.2931 86.396 10.8545L88.7802 11.3785C93.7418 12.4827 95.9971 14.822 95.9971 19.0703C95.9971 24.4975 92.3403 27.941 86.2188 27.941Z'
          fill={fill ?? 'white'}
        />
        <path
          d='M36.7946 1.65426C37.4646 0.220752 39.1726 -0.399445 40.6095 0.269011C42.0464 0.937468 42.668 2.64145 41.998 4.07496L31.3478 26.2868C30.6778 27.7203 28.9698 28.3405 27.5329 27.672C26.096 27.0036 25.4744 25.2996 26.1444 23.8661L36.7946 1.65426Z'
          fill={fill ?? 'white'}
        />
        <path
          d='M41.9994 1.65426C41.3293 0.220752 39.6213 -0.399445 38.1845 0.269011C36.7476 0.937468 36.1259 2.64145 36.796 4.07496L47.4462 26.2868C48.1162 27.7203 49.8242 28.3405 51.261 27.672C52.6979 27.0036 53.3196 25.2996 52.6496 23.8661L41.9994 1.65426Z'
          fill={fill ?? 'white'}
        />
        <path
          d='M41.9534 22.1997H50.2581V27.941H41.9534C40.4152 27.941 39.1582 26.6592 39.1582 25.0704C39.1582 23.4906 40.4064 22.1997 41.9534 22.1997Z'
          fill={fill ?? 'white'}
        />
        <path
          d='M107.874 1.65426C108.544 0.220752 110.252 -0.399445 111.689 0.269011C113.125 0.937468 113.747 2.64145 113.077 4.07496L102.427 26.2868C101.757 27.7203 100.049 28.3405 98.6122 27.672C97.1753 27.0036 96.5537 25.2996 97.2237 23.8661L107.874 1.65426Z'
          fill={fill ?? 'white'}
        />
        <path
          d='M113.078 1.65426C112.408 0.220752 110.7 -0.399445 109.264 0.269011C107.827 0.937468 107.205 2.64145 107.875 4.07496L118.525 26.2868C119.195 27.7203 120.903 28.3405 122.34 27.672C123.777 27.0036 124.399 25.2996 123.729 23.8661L113.078 1.65426Z'
          fill={fill ?? 'white'}
        />
        <path
          d='M113.032 22.1997H121.337V27.941H113.032C111.494 27.941 110.237 26.6592 110.237 25.0704C110.237 23.4906 111.485 22.1997 113.032 22.1997Z'
          fill={fill ?? 'white'}
        />
        <path
          d='M58.1069 27.9331C59.6741 27.9331 60.9713 26.597 60.9713 24.9828V6.88761C60.9713 6.04229 61.6568 5.35703 62.5023 5.35703H65.8633C67.5436 5.35703 69.013 6.6875 69.013 8.41819C69.013 9.94312 68.1572 11.1 66.6262 11.2881C66.8176 11.5217 70.9268 15.5015 70.9268 15.5015C73.025 14.6407 74.8307 12.3928 74.8307 8.65343C74.8307 3.8918 71.2437 0 66.0461 0H58.0982C56.5311 0 55.2339 1.33613 55.2339 2.95024V24.9739C55.2339 26.588 56.5311 27.9241 58.0982 27.9241L58.1069 27.9331Z'
          fill={fill ?? 'white'}
        />
        <path
          d='M69.3381 12.6154L70.9267 15.5016L72.2663 18.3723L74.8306 23.7308C75.6101 25.0805 75.1289 26.817 73.7559 27.6095C72.3829 28.402 70.638 27.9503 69.8585 26.6007L64.366 15.4853C63.6097 14.1758 64.1019 12.465 65.4778 11.6709C66.8459 10.8812 68.5775 11.2985 69.3381 12.6154Z'
          fill={fill ?? 'white'}
        />
      </g>
      <defs>
        <clipPath id='clip0_1170_5867'>
          <rect
            width='168'
            height='28'
            fill={fill ?? 'white'}
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SigninLogo;
