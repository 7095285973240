import { PurchaseOrderDTO, PurchaseCarInfo } from '@carsayo/types';

export default (
  purchaseOrderDTO: PurchaseOrderDTO | PurchaseCarInfo,
  type?: 'detailInfo',
) => {
  if (type && type === 'detailInfo') {
    const info = purchaseOrderDTO as PurchaseCarInfo;
    return (
      Number(info.carGrade?.price) +
      Number(info.carColor?.price ? info.carColor?.price : 0) +
      Number(
        info.carOptionList && info.carOptionList.length > 0
          ? info.carOptionList
              .map((el) => {
                return el.price;
              })
              .reduce((prev, curr) => {
                return prev + curr;
              })
          : 0,
      ) +
      0
    );
  } else {
    const info = purchaseOrderDTO as PurchaseOrderDTO;
    return (
      Number(info.orderData?.carGrade?.price) +
      Number(
        info.orderData?.carColor?.price ? info.orderData?.carColor?.price : 0,
      ) +
      Number(
        info.orderData?.carOptionList &&
          info.orderData?.carOptionList.length > 0
          ? info.orderData.carOptionList
              .map((el) => {
                return el.price;
              })
              .reduce((prev, curr) => {
                return prev + curr;
              })
          : 0,
      ) +
      0
    );
  }
};
