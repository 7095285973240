import { PurchaseCarInfo } from '@carsayo/types';

export default (purchaseCarInfo: PurchaseCarInfo) => {
  return (
    Number(purchaseCarInfo?.carGrade?.price) +
    Number(
      purchaseCarInfo?.carColor?.price ? purchaseCarInfo?.carColor?.price : 0,
    ) +
    Number(
      purchaseCarInfo?.carOptionList &&
        purchaseCarInfo?.carOptionList.length > 0
        ? purchaseCarInfo.carOptionList
            .map((el) => {
              return el.price;
            })
            .reduce((prev, curr) => {
              return prev + curr;
            })
        : 0,
    ) +
    0
  );
};
