/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { Textarea } from 'components/ui/textarea';
import { Checkbox } from 'components/ui/checkbox';
import { Button } from 'components/ui/button';
import { useLayoutEffect, useState } from 'react';
import Confirm from 'components/ui/confirm';
import useSystemStore from 'store/useSystemStore';
import { customerSignOut, dealerSignOut } from 'apis/setting';
import { useNavigate } from 'react-router-dom';
import useMemberStore from 'store/useMemberStore';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';

const formSchema = z.object({
  reason: z.string(),
  detailReason: z.string(),
});

export default function Signout() {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      reason: '',
      detailReason: '',
    },
  });

  const { setTopTabbarState, setLoadingDim } = useSystemStore();
  const { loginMember, setLoginMember, resetLoginMember } = useMemberStore();

  const [directInputActivate, setDirectInputActivate] =
    useState<boolean>(false);

  const [isCheckReason, setIsCheckedReason] = useState<boolean>(false);
  const [isAgree, setIsAgree] = useState<boolean>(false);

  const signout = async () => {
    const formData = form.getValues();

    if (formData.reason === 'directInput' && !formData.detailReason) {
      form.setError('detailReason', { message: '탈퇴 사유를 입력해 주세요.' });
      return;
    }
    setLoadingDim(true);

    try {
      if (loginMember?.type === 'customer') {
        await customerSignOut({
          reason: formData.detailReason
            ? formData.detailReason
            : formData.reason,
        });
        resetLoginMember();
      } else {
        const updatedMember = await dealerSignOut({
          reason: formData.detailReason
            ? formData.detailReason
            : formData.reason,
        });
        setLoginMember(updatedMember);
      }
      setTimeout(() => {
        navigate('/main');
      }, 2000);
      return;
    } catch (e: any) {
      console.error(e);
      CarsayoToast.error(e);
    }

    setLoadingDim(false);
    return;
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '회원 탈퇴',
      rightElement: 'home',
      leftElement: 'back',
    });

    return () => {
      setLoadingDim(false);
    };
  }, []);

  return (
    <div className='p-4 pb-10 text-[15px] leading-[22px]'>
      {loginMember && (
        <>
          <div className='border-b pb-6'>
            카사요 서비스를 이용하는데 불편함이 있었나요? 부족한 점, 아쉬움에
            대한 내용을 남겨주시면 더 나은 서비스를 제공하도록 노력하겠습니다.
          </div>
          <Form {...form}>
            <form id='signupForm' className='mt-6 space-y-6'>
              <FormField
                control={form.control}
                name='reason'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>탈퇴 사유</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={(value) => {
                          if (!isCheckReason) setIsCheckedReason(true);
                          if (value === 'directInput') {
                            setDirectInputActivate(true);
                          } else {
                            setDirectInputActivate(false);
                            form.setValue('detailReason', '');
                          }
                          field.onChange(value);
                        }}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder='사유 선택' />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value='서비스 미이용'>
                            서비스 미이용
                          </SelectItem>
                          <SelectItem value='directInput'>직접 입력</SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='detailReason'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>탈퇴 사유</FormLabel>
                    <FormControl>
                      <Textarea
                        disabled={!directInputActivate}
                        placeholder='내용을 입력해주세요'
                        className='min-h-[158px]'
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
          <div className='mt-6'>탈퇴 주의 사항</div>
          {loginMember?.type === 'customer' ? (
            <ul className='mt-2 list-outside list-decimal pl-4 text-[#767676]'>
              <li>
                정상회원 또는 거래중인 상품이 없는 경우 즉시 탈퇴 가능합니다.
              </li>
              <li>
                회원탈퇴 시, 즉시 탈퇴 처리되며 재가입은 탈퇴일로 부터 30일 이후
                가능합니다.
              </li>
              <li>
                거래중인 판매 또는 미확정 건이 있는 경우 거래 종료 후 탈퇴가
                가능합니다.
              </li>
              <li>이용정지 회원의 경우 해제 후 탈퇴가 가능합니다.</li>
              <li>
                전자 상거래 등에서의 소비자 보호에 관한 법률 제6조(거래기록의
                보전 등)에 의거 거래 정보는 회원 탈퇴 후 5년 간 보존됩니다.
              </li>
            </ul>
          ) : (
            <ul className='mt-2 list-outside list-decimal pl-4 text-[#767676]'>
              <li>
                카매니저는 회원탈퇴 신청 후 승인이 되어야 탈퇴 처리됩니다.
              </li>
              <li>
                회원탈퇴 승인시, 즉시 탈퇴 처리되며 재가입은 탈퇴일로 부터 30일
                이후 가능합니다.
              </li>
              <li>
                거래중 인 판매 또는 미확정 건이 있는 경우 거래 종료 후 탈퇴
                신청이 가능합니다.
              </li>
              <li>이용정지 회원의 경우 해제 후 탈퇴가 가능합니다.</li>
              <li>
                전자 상거래 등에서의 소비자 보호에 관한 법률 제6조(거래기록의
                보전 등)에 의거 거래 정보는 회원 탈퇴 후 5년 간 보존됩니다.
              </li>
            </ul>
          )}

          <div className='mt-6 flex gap-2'>
            <Checkbox
              checked={isAgree}
              id='check'
              onClick={() => {
                setIsAgree(!isAgree);
              }}
            />
            <label htmlFor='check'>상기 사항을 모두 확인하였습니다.</label>
          </div>
          <Button
            disabled={!isAgree || !isCheckReason}
            className='mt-8'
            onClick={() => setIsOpen(true)}
          >
            확인
          </Button>
          <Confirm
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isCloseButton={true}
            onConfirm={signout}
            title={
              loginMember?.type === 'customer'
                ? '카사요 탈퇴하기'
                : '카매니저 탈퇴신청'
            }
            desc={`카사요 탈퇴를 진행 하시겠습니까?`}
          ></Confirm>
        </>
      )}
    </div>
  );
}
