import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Integrations } from '@sentry/tracing';

/** Polyfill */
import 'core-js/stable';
import 'core-js/features/global-this';
import 'regenerator-runtime/runtime';
import 'abortcontroller-polyfill';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { PrimeReactProvider } from 'primereact/api';
import Tailwind from 'primereact/passthrough/tailwind';
import { AxiosError } from 'axios';
import { ErrorResponse } from 'utils/sentryErrorReturns';
// import SentryLogError from 'utils/sentryLogError';

const isLocalhost = window.location.hostname === 'localhost';

if (!isLocalhost) {
  Sentry.init({
    dsn: 'https://088fa1caecfa8a057b953c5ec250f56a@o4506821662015488.ingest.us.sentry.io/4506821691899905',
    environment: process.env.REACT_APP_NODE_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: false,
      }),
    ],
    ignoreErrors: [
      '403',
      /유효한 로그인 인증 정보가 아닙니다/i,
      /유효한 로그인 정보가 존재하지 않습니다/i,
    ],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 1 / 300,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      if (event) {
        if (event.transaction === '/auth/signup/dealer/phoneCheck') {
          return null;
        }
      }

      // 요청 본문을 이벤트에 추가
      if (hint && hint.originalException) {
        const error = hint.originalException as AxiosError;
        const errorData = error.response as ErrorResponse;
        if (errorData && errorData.data) {
          if (
            errorData.data.error === 'E-LG42PedAERF' ||
            errorData.data.error === 'E-LG43aedAERF'
          ) {
            return null;
          }
        }

        if (error.config && error.config.data) {
          event.request = {
            ...event.request,
            data: error.config.data,
          };
        }
      }
      return event;
      // if (
      //   hint &&
      //   hint.originalException &&
      //   (hint.originalException as AxiosError).config
      // ) {
      //   const error = hint.originalException as AxiosError;
      //   if (error.config && error.config.data) {
      //     event.request = {
      //       ...event.request,
      //       data: error.config.data,
      //     };
      //     SentryLogError(event, error);
      //   }
      // }
      // return null;
    },
  });
}

const value = {
  unstyled: true,
  pt: Tailwind,
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <PrimeReactProvider value={value}>
    <App />
  </PrimeReactProvider>,
);

reportWebVitals();
