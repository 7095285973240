import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { X } from 'lucide-react';
import dayjs from 'dayjs';
import { CooconAccidentHistoryInfo } from '@carsayo/types';

export default function SellingAccidentHistoryDetail({
  isOpen,
  accidentHistory,
  setIsOpen,
}: {
  isOpen: boolean;
  accidentHistory: CooconAccidentHistoryInfo;
  setIsOpen: (value: boolean) => void;
}) {
  return (
    <Drawer
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
      }}
    >
      <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
        <DrawerHeader className='relative'>
          <DrawerTitle>차량 이력 조회</DrawerTitle>
          <DrawerClose asChild>
            <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
              <X className='h-7 w-7' />
            </Button>
          </DrawerClose>
        </DrawerHeader>
        <div className='grow overflow-y-auto'>
          <div className='flex h-[54px] items-center gap-2 bg-[#F7F7FB] px-4'>
            <div className='text-[#555555]'>조회 일자</div>
            <div>
              {dayjs(accidentHistory.SEARCH_DATE).format('YYYY년 MM월 DD일')}
            </div>
          </div>

          <div className='border-b p-4'>
            <div className='flex items-center justify-between'>
              <div className='text-lg font-semibold'>내차피해 보험 처리</div>
              {Number(accidentHistory.MY_COUNT) > 0 ? (
                <div className='font-semibold text-[#FFAA00]'>
                  {Number(accidentHistory.MY_COUNT)}회
                </div>
              ) : (
                <div className='font-semibold'>없음</div>
              )}
            </div>

            {Number(accidentHistory.MY_COUNT) > 0 && (
              <div className='mt-3 space-y-2'>
                {accidentHistory.RESP_MY_DATA.map((el) => {
                  return (
                    <>
                      <div
                        className='flex h-8 items-center justify-between'
                        key={el.MY_ACCIDENT_DATE}
                      >
                        <div>
                          {dayjs(el.MY_ACCIDENT_DATE).format('YYYY-MM-DD')}
                        </div>
                        <div className='text-desc'>
                          수리 {Number(el.MY_REPAIR_COST).toLocaleString()}원
                        </div>
                      </div>
                      <div className='border-t' key={el.MY_COMPONENT}>
                        {Number(el.MY_COMPONENT) !== 0 && (
                          <div className='flex h-[52px] items-center border-b'>
                            <div className='w-[100px] bg-[#F7F7FB] p-4'>
                              부품
                            </div>
                            <div className='flex-1 p-4 text-right text-[#767676]'>
                              {Number(el.MY_COMPONENT).toLocaleString()} 원
                            </div>
                          </div>
                        )}
                        {Number(el.MY_PAY) !== 0 && (
                          <div className='flex h-[52px] items-center border-b'>
                            <div className='w-[100px] bg-[#F7F7FB] p-4'>
                              공임
                            </div>
                            <div className='flex-1 p-4 text-right text-[#767676]'>
                              {Number(el.MY_PAY).toLocaleString()} 원
                            </div>
                          </div>
                        )}
                        {Number(el.MY_PAINTING) !== 0 && (
                          <div className='flex h-[52px] items-center border-b'>
                            <div className='w-[100px] bg-[#F7F7FB] p-4'>
                              도장
                            </div>
                            <div className='flex-1 p-4 text-right text-[#767676]'>
                              {Number(el.MY_PAINTING).toLocaleString()} 원
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            )}
          </div>

          <div className='border-b p-4'>
            <div className='flex items-center justify-between'>
              <div className='text-lg font-semibold'>상대차 가해 보험 처리</div>
              {Number(accidentHistory.OTHER_COUNT) > 0 ? (
                <div className='font-semibold text-[#FFAA00]'>
                  {Number(accidentHistory.OTHER_COUNT)}회
                </div>
              ) : (
                <div className='font-semibold'>없음</div>
              )}
            </div>
            {Number(accidentHistory.OTHER_COUNT) > 0 && (
              <div className='mt-3 space-y-2'>
                {accidentHistory.RESP_OTHER_DATA.map((el) => {
                  return (
                    <>
                      <div
                        className='flex h-8 items-center justify-between'
                        key={el.OTHER_REPAIR_COST}
                      >
                        <div>
                          {dayjs(el.OTHER_ACCIDENT_DATE).format('YYYY-MM-DD')}
                        </div>
                        <div className='text-desc'>
                          수리 {Number(el.OTHER_REPAIR_COST).toLocaleString()}원
                        </div>
                      </div>
                      <div className='border-t' key={el.OTHER_COMPONENT}>
                        {Number(el.OTHER_COMPONENT) !== 0 && (
                          <div className='flex h-[52px] items-center border-b'>
                            <div className='w-[100px] bg-[#F7F7FB] p-4'>
                              부품
                            </div>
                            <div className='flex-1 p-4 text-right text-[#767676]'>
                              {Number(el.OTHER_COMPONENT).toLocaleString()} 원
                            </div>
                          </div>
                        )}
                        {Number(el.OTHER_PAY) !== 0 && (
                          <div className='flex h-[52px] items-center border-b'>
                            <div className='w-[100px] bg-[#F7F7FB] p-4'>
                              공임
                            </div>
                            <div className='flex-1 p-4 text-right text-[#767676]'>
                              {Number(el.OTHER_PAY).toLocaleString()} 원
                            </div>
                          </div>
                        )}
                        {Number(el.OTHER_PAINTING) !== 0 && (
                          <div className='flex h-[52px] items-center border-b'>
                            <div className='w-[100px] bg-[#F7F7FB] p-4'>
                              도장
                            </div>
                            <div className='flex-1 p-4 text-right text-[#767676]'>
                              {Number(el.OTHER_PAINTING).toLocaleString()} 원
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            )}
          </div>

          <div className='space-y-3 border-b p-4'>
            <div className='flex items-center justify-between'>
              <div className='text-lg font-semibold'>특수용도 이력</div>
            </div>
            <div className='flex items-center justify-between'>
              <div>대여이력 (렌터카)</div>
              <div className='text-[#555555]'>
                {accidentHistory.RENT_CAR_HISTORY}
              </div>
            </div>
            <div className='flex items-center justify-between'>
              <div>영업이력 (택시, 화물 등)</div>
              <div className='text-[#555555]'>
                {' '}
                {accidentHistory.BUSINESS_HISTORY}
              </div>
            </div>
            <div className='flex items-center justify-between'>
              <div>관용이력</div>
              <div className='text-[#555555]'>
                {accidentHistory.COMMON_USE_HISTORY}
              </div>
            </div>
          </div>
          <div className='space-y-3 border-b p-4'>
            <div className='flex items-center justify-between'>
              <div className='text-lg font-semibold'>소유자 변경 이력</div>
            </div>
            {accidentHistory.RESP_MY_CHANGE_DATA.length > 0 &&
              accidentHistory.RESP_MY_CHANGE_DATA.map((el) => {
                return (
                  <div
                    className='flex items-center justify-between'
                    key={el.MY_CHANGE_CHANGE_NAME}
                  >
                    <div>{el.MY_CHANGE_CHANGE_NAME}</div>
                    <div className='text-[#555555]'>
                      {dayjs(el.MY_CHANGE_CHANGE_DATE).format('YYYY-MM-DD')}
                    </div>
                  </div>
                );
              })}
          </div>
          {Number(accidentHistory.CAR_NUM_CHANGE_COUNT) > 1 && (
            <div className='space-y-3 border-b p-4'>
              <div className='flex items-center justify-between'>
                <div className='text-lg font-semibold'>차량 번호 변경</div>
                {
                  <div className='font-semibold'>
                    {Number(accidentHistory.CAR_NUM_CHANGE_COUNT)}회
                  </div>
                }
              </div>
            </div>
          )}
          <div className='space-y-3 p-4'>
            <div className='flex items-center justify-between'>
              <div className='text-lg font-semibold'>특수 사고 이력</div>
            </div>
            <div className='flex items-center justify-between'>
              <div>전손 사고</div>
              <div>
                {Number(accidentHistory.TOTAL_LOSS_HISTORY) > 0
                  ? `${Number(accidentHistory.TOTAL_LOSS_HISTORY)}회`
                  : '없음'}
              </div>
            </div>
            <div className='flex items-center justify-between'>
              <div>침수 사고 (전손)</div>
              <div>
                {Number(accidentHistory.FLOODING_TOTAL_LOSS_HISTORY) > 0
                  ? `${Number(accidentHistory.FLOODING_TOTAL_LOSS_HISTORY)}회`
                  : '없음'}
              </div>
            </div>
            <div className='flex items-center justify-between'>
              <div>침수 사고 (분손)</div>
              <div>
                {Number(accidentHistory.FLOODING_PARTIAL_LOSS_HISTORY) > 0
                  ? `${Number(accidentHistory.FLOODING_PARTIAL_LOSS_HISTORY)}회`
                  : '없음'}
              </div>
            </div>
            <div className='flex items-center justify-between'>
              <div>도난 사고</div>
              <div>
                {Number(accidentHistory.ROBBERY_HISTORY) > 0
                  ? `${Number(accidentHistory.ROBBERY_HISTORY)}회`
                  : '없음'}
              </div>
            </div>
          </div>
          <div className='px-4 pb-8 mb-[var(--safeAreaBottomPadding)]'>
            <Button
              onClick={() => {
                setIsOpen(false);
              }}
              className='rounded-none'
              variant='black'
              size='lg'
            >
              확인
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
