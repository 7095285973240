import Expression from 'components/svg/Expression';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import { useNavigate } from 'react-router-dom';

interface SwiperDataArrayInterface {
  text: string;
  textColor: string;
  clickEvent: () => void;
  fill: string;
  width?: string;
  height?: string;
}

const PromotionMain = () => {
  const navigate = useNavigate();
  const swiperDataArray: SwiperDataArrayInterface[] = [
    {
      text: '#1. 자동차 다이렉트 보험',
      textColor: '#303056',
      clickEvent: () => {
        navigate('/static/insurance');
      },
      fill: '#A7DE93',
      width: '200',
      height: '83',
    },
  ];

  return (
    <div className='premium-unavailable'>
      <div className='lamp'>
        <img
          src='/assets/images/svg/premium-unavailable-lampImg.svg'
          alt='램프이미지'
        />
        <img
          src='/assets/images/svg/premium-unavailable-lightImg.svg'
          alt='불빛'
        />
      </div>
      {/* <div className='premium-unavailable__top'>
        <button onClick={() => navigate(-1)}>
          <BackIcon />
        </button>
        <h1>프리미엄 혜택</h1>
      </div> */}
      <div className='premium-unavailable__box'>
        <div className='premium-unavailable__box__content'>
          <h2>
            <span>Premium</span>
            <span>Service</span>
          </h2>
          <div>
            <img
              src='/assets/images/svg/character_section05_img01.svg'
              alt='카사요 히어로즈 이미지'
            />
            <img
              src='/assets/images/svg/light-shadow.svg'
              alt='조명 그림자 이미지'
            />
          </div>
          <div>
            <h3>With Car Heroes</h3>
          </div>
        </div>
        {/* 하단 버튼 스와이퍼 */}
        <div className='m-promotion__swiper'>
          <Swiper
            modules={[Pagination]}
            slidesPerView={1}
            centeredSlides={true}
            loop={swiperDataArray.length > 2 ? true : false}
            pagination={{ clickable: true }}
            spaceBetween={16}
          >
            {swiperDataArray &&
              swiperDataArray.length > 0 &&
              swiperDataArray.map((item, index) => {
                return (
                  <SwiperSlide>
                    <Expression
                      className='mb'
                      key={`promotion_swiper_${item.text}_${index}`}
                      text={item.text}
                      textColor={item.textColor}
                      clickEvent={item.clickEvent}
                      fill={item.fill}
                      width={item.width}
                      height={item.height}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
export default PromotionMain;
