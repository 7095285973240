import { useEffect, useLayoutEffect, useState } from 'react';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import Confirm from 'components/ui/confirm';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import CarsayoNiceAuth, {
  CarsayoNiceAuthCallback,
} from 'components/common/v2/CarsayoNiceAuth';
import {
  checkExistPhoneNumber,
  niceTokenPhoneCheck,
} from 'apis/authentication';
import signupStore from '../store';
import { isValidPhoneNumberFormat } from 'utils/telNumber';
import { ConsentClause } from '../component/ConsentClause';
import { TermCategoryId } from '@carsayo/types';

const formSchema = z.object({
  phone: z.string().min(1, {
    message: '휴대폰 번호를 입력해주세요',
  }),
});

export default function DealerAuthAndTermPage() {
  const navigate = useNavigate();

  const { setTopTabbarTitle, setLoadingDim } = useSystemStore();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState<{
    title: string;
    desc: string;
  }>({ title: '', desc: '' });

  const [isTermOpen, setIsTermOpen] = useState(false);
  const [isNiceAuthOpen, setIsNiceAuthOpen] = useState<boolean>(false);

  const { signupInfo, setSignupStore } = signupStore();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      phone: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    const inputPhoneNumber = form.getValues('phone');

    if (!isValidPhoneNumberFormat(inputPhoneNumber)) {
      form.setError('phone', {
        message: '휴대폰 번호를 확인해주세요.',
      });
      return;
    }

    const checkPhoneNumberData = await checkExistPhoneNumber({
      phoneNumber: inputPhoneNumber,
    });
    if (checkPhoneNumberData.isMember) {
      form.setError('phone', {
        message: '이미 사용중인 휴대전화 번호입니다. 다시 한번 확인해 주세요.',
      });

      return;
    }

    setSignupStore({
      ...signupInfo,
      phoneNumber: inputPhoneNumber,
    });

    // 약관 동의창 실행
    setIsTermOpen(true);
  };

  const termAgreeCallback = (data: TermCategoryId[]) => {
    setLoadingDim(true);

    // 나이스 본인인증 모듈을 실행하고, 이후 성공 혹은 실패시 콜백을 동작시킵니다.
    setIsNiceAuthOpen(true);

    setSignupStore({
      ...signupInfo,
      agreeTermList: data,
    });
    return;
  };

  const niceApiCallback = async (params: CarsayoNiceAuthCallback) => {
    if (
      params.status === 'success' &&
      params.token &&
      signupInfo &&
      signupInfo.phoneNumber
    ) {
      const phoneNumberCheckResponse = await niceTokenPhoneCheck({
        identityVerifyToken: params.token,
        phoneNumber: signupInfo.phoneNumber,
      });
      if (!phoneNumberCheckResponse.isMatched) {
        setConfirmMessage({
          title: '본인 인증 실패',
          desc: '입력한 휴대전화번호와 다른 번호가 입력되었습니다.',
        });
        setIsConfirmOpen(true);
        setLoadingDim(false);
        return;
      }

      if (signupInfo.agreeTermList) {
        setSignupStore({
          ...signupInfo,
          niceAuthToken: params.token,
        });

        navigate('/auth/signup/dealer/additionalInfo');
      } else {
        console.error('Failed!!!!');
      }
    } else {
      setConfirmMessage({
        title: '본인 인증 실패',
        desc: '본인 인증이 정상적으로 완료되지 않았습니다.',
      });
      setIsConfirmOpen(true);
    }

    setLoadingDim(false);
  };

  useEffect(() => {
    return () => {
      setLoadingDim(false);
    };
  }, []);

  useLayoutEffect(() => {
    setTopTabbarTitle('카매니저 가입');
  }, []);

  return (
    <div className='flex min-h-full grow flex-col p-4 pb-10'>
      <CarsayoNiceAuth
        isOpen={isNiceAuthOpen}
        setOpen={setIsNiceAuthOpen}
        callback={niceApiCallback}
      />

      <div className='text-lg font-semibold'>
        카 매니저님의
        <br />
        <span className='text-primary'>본인 확인</span>을 진행합니다
      </div>
      <div className='mt-6'>
        <Form {...form}>
          <form
            id='signupForm'
            onSubmit={form.handleSubmit(onSubmit)}
            className='space-y-8'
          >
            <FormField
              control={form.control}
              name='phone'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>휴대전화</FormLabel>
                  <FormControl>
                    <Input
                      type='number'
                      autoComplete='off'
                      placeholder='숫자만 입력하세요'
                      maxLength={13}
                      {...field}
                    ></Input>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
      </div>

      <Button className='mt-auto' type='submit' form='signupForm'>
        시작하기
      </Button>

      {signupInfo?.dealer?.type && (
        <ConsentClause
          termData={{
            category1: {
              title: '카사요 약관 및 동의사항',
              term: [20100, 20200],
              isEssential: true,
            },
            category2: {
              title: '카매니저 추가 동의사항',
              term:
                signupInfo.dealer.type === 'newcar'
                  ? [24090, 24100]
                  : signupInfo.dealer.type === 'usedcar'
                    ? [25090, 25100]
                    : signupInfo.dealer.type === 'leaseRent'
                      ? [26090, 26100]
                      : [],
              isEssential: true,
            },
            category3: {
              title: '광고 및 마케팅 수신 동의',
              term: [95100, 95200, 95300],
              isEssential: false,
            },
          }}
          isOpen={isTermOpen}
          setIsOpen={setIsTermOpen}
          onConfirm={termAgreeCallback}
        />
      )}

      <Confirm
        title={confirmMessage.title}
        desc={confirmMessage.desc}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
      />
    </div>
  );
}
