/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import { Textarea } from 'components/ui/textarea';
import { FullScreen } from 'components/view/FullScreen';
import SubPage from 'components/view/SubPage';
import dayjs from 'dayjs';
import { AnimatePresence, motion, useDragControls } from 'framer-motion';
import { Pencil, Trash2, X } from 'lucide-react';
import { ReactNode, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDealerOrderMemo, useUpdateDealerOrderMemo } from './query';
import { createDealerOrderMemo, deleteDealerOrderMemo } from 'apis/quote';
import { OrderMemo } from '@carsayo/types';

function DraggableDrawer({
  isOpen,
  setIsOpen,
  children,
}: {
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
  children: ReactNode;
}) {
  const controls = useDragControls();

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <FullScreen className='bg-black bg-opacity-25 flex flex-col justify-end'>
          <div onClick={closeDrawer} className='flex-auto'></div>
          <motion.div
            className='flex flex-col bg-white rounded-t-3xl'
            initial={{ y: 400 }}
            animate={{ y: 0 }}
            exit={{ y: 400 }}
            transition={{ ease: false, duration: 0.3 }}
            drag={'y'}
            dragSnapToOrigin={true}
            dragControls={controls}
            dragListener={false}
            dragConstraints={{
              top: 0,
              bottom: 300,
            }}
            dragElastic={{ top: 0, bottom: 0.3 }}
            onDragEnd={(event, info) => {
              if (info.offset.y > 100) closeDrawer();
              return;
            }}
          >
            <motion.div
              className='flex justify-center items-center'
              onPointerDown={(e) => {
                controls.start(e);
              }}
              style={{ touchAction: 'none' }}
            >
              <div className='bg-gray-600 w-20 h-1 rounded-full mt-4 mb-6'></div>
            </motion.div>
            {children}
          </motion.div>
        </FullScreen>
      )}
    </AnimatePresence>
  );
}

// memo: OrderMemo
function MemoCard({ memo }: { memo: OrderMemo }) {
  const { refetch } = useDealerOrderMemo({
    orderId: memo.order_id,
  });

  const deleteMemo = async () => {
    await deleteDealerOrderMemo({
      orderId: memo.order_id,
      memoId: memo.id,
    });
    refetch();
  };

  return (
    <div className='border border-gray-200 rounded-lg p-2'>
      <div className='flex justify-between items-center font-semibold text-[12px] pb-1 mb-1 border-b border-gray-100'>
        {dayjs(memo.created_at).format('YYYY-MM-DD HH:mm')}
        <Trash2 className='w-4 h-4 cursor-pointer' onClick={deleteMemo} />
      </div>
      <div className='whitespace-pre-line'>
        {ReactHtmlParser(memo.content_html)}
      </div>
    </div>
  );
}

function Tab_List({
  orderMemoData,
  setIsWriteOpen,
}: {
  orderMemoData: OrderMemo[];
  setIsWriteOpen: (data: boolean) => void;
}) {
  return (
    <section className='relative flex flex-col h-full overflow-y-scroll p-4 gap-2 pb-24'>
      {orderMemoData.map((el) => {
        return <MemoCard key={el.id} memo={el} />;
      })}
      <div
        className='fixed flex justify-center items-center right-6 bottom-6 rounded-full shadow-lg shadow-red-200 bg-red-500 w-12 h-12 text-white active:bg-red-400 cursor-pointer'
        onClick={() => {
          setIsWriteOpen(true);
        }}
      >
        <Pencil className='w-5 h-5' />
      </div>
    </section>
  );
}

export default function DealerMemoSubpage({
  isOpen,
  orderId,
  setIsOpen,
}: {
  isOpen: boolean;
  orderId: string;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const { data: orderMemoData, isSuccess } = useDealerOrderMemo({
    orderId: orderId,
  });
  const updateDealerOrderMemo = useUpdateDealerOrderMemo({
    orderId: orderId,
  });

  const [isWriteOpen, setIsWriteOpen] = useState<boolean>(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const submit = async () => {
    if (!orderMemoData) return;

    if (textAreaRef.current) {
      const value = textAreaRef.current.value;

      const updatedData = await createDealerOrderMemo({
        orderId: orderId,
        content: value,
        content_html: value,
      });

      updateDealerOrderMemo({
        orderId: orderId,
        data: [updatedData, ...orderMemoData],
      });
    }

    setIsWriteOpen(false);
    return;
  };

  return (
    <SubPage isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className='flex flex-none h-14 items-center justify-between bg-white px-4'>
        <div className='pl-4 grow text-center text-xl font-semibold'>
          주문 메모
        </div>
        <div
          className='cursor-pointer'
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <X></X>
        </div>
      </div>

      {isSuccess && (
        <>
          <Tab_List
            setIsWriteOpen={setIsWriteOpen}
            orderMemoData={orderMemoData}
          />
          <DraggableDrawer isOpen={isWriteOpen} setIsOpen={setIsWriteOpen}>
            <div className='h-56 flex flex-col px-4 pb-4 justify-between gap-4'>
              <Textarea
                ref={textAreaRef}
                className='flex-auto'
                defaultValue={''}
                placeholder='메모를 입력해 주세요'
              ></Textarea>
              <Button onClick={submit}>작성하기</Button>
            </div>
          </DraggableDrawer>
        </>
      )}
    </SubPage>
  );
}
