/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { X } from 'lucide-react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { SellingOrderInfo, SellingOrderDetailInfo } from '@carsayo/types';

export default function SellingCarEstimatePrice({
  mode,
  isOpen,
  sellingOrderInfo,
  setIsOpen,
}: {
  /**
   * @case sellingCar: 내차팔기 진행 과정에서 사용할 때입니다.
   * @case info: 주문 상세보기에서 사용할 때 씁니다.
   */
  mode: 'sellingCar' | 'info';
  isOpen: boolean;
  sellingOrderInfo: SellingOrderInfo | SellingOrderDetailInfo;
  setIsOpen: (value: boolean) => void;
}) {
  const navigate = useNavigate();
  return (
    <Drawer
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
      }}
    >
      <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
        <DrawerHeader className='relative'>
          <DrawerTitle>시세 조회</DrawerTitle>
          <DrawerClose asChild>
            <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
              <X className='h-7 w-7' />
            </Button>
          </DrawerClose>
        </DrawerHeader>
        <div className='grow overflow-y-auto'>
          <div className='flex h-[54px] items-center gap-2 bg-[#F7F7FB] px-4'>
            <div className='text-[#555555]'>조회 일자</div>
            <div>
              {dayjs(sellingOrderInfo.accidentHistory?.SEARCH_DATE).format(
                'YYYY년 MM월 DD일',
              )}
            </div>
          </div>

          <div className='border-b p-4'>
            <div className='flex items-center justify-between'>
              <div className='text-lg font-semibold'>주행거리별 시세</div>
            </div>
            <div className='text-gray-500'>
              ※ 상기 가격은 실제 거래 가격과{' '}
              <span className='font-semibold'>다를</span> 수 있습니다.
            </div>

            {sellingOrderInfo.usedCarInfo.mileageprice &&
              sellingOrderInfo.usedCarInfo.mileageprice.map((el) => {
                return (
                  <div className='mt-3 space-y-2' key={`mileage_${el.price}`}>
                    <div className='flex h-8 items-center justify-between'>
                      <div>{el.mileage.toLocaleString()}km</div>
                      <div className='text-desc'>
                        {el.price.toLocaleString()}원
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          {sellingOrderInfo.usedCarInfo.usedprice?.[0] && (
            <div className='border-b p-4'>
              <div className='flex items-center justify-between'>
                <div className='text-lg font-semibold'>매매 기준 시세</div>
              </div>
              <div className='text-gray-500'>
                ※ 동일 차량 기준 한달 평균 매매금액입니다.
              </div>
              <div className='mt-3 space-y-2'>
                <div className='flex h-8 items-center justify-between'>
                  <div>
                    {dayjs(
                      sellingOrderInfo.usedCarInfo.usedprice[0].stdym,
                    ).format('YYYY년 MM월')}
                  </div>
                  <div className='text-desc'>
                    {sellingOrderInfo.usedCarInfo.usedprice[0].price.toLocaleString()}
                    원
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className='px-4 py-4'>
            <div>
              ※ 차량의 사고유무, 상태, 옵션, 색상 등이 고려되지 아니한 시세로
              참고용 자료로만 사용하시기 바랍니다.
            </div>
            <div>
              ※ 개인 및 자동차매매업자간의 거래 금액은 시세에 반영되지 않습니다.
            </div>
            <div>
              ※ 상기 가격은 실제 거래 가격과{' '}
              <span className='font-semibold'>다를</span> 수 있습니다.
            </div>
          </div>

          <div className='px-4 pb-6 mb-[var(--safeAreaBottomPadding)]'>
            {mode === 'sellingCar' ? (
              <Button
                onClick={() => {
                  navigate('/selling/photo');
                }}
                className='rounded-none'
                variant='black'
                size='lg'
              >
                내차팔기 진행하기
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setIsOpen(false);
                }}
                className='rounded-none'
                variant='black'
                size='lg'
              >
                확인
              </Button>
            )}
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
