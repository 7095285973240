import {
  SetProfileImageDTO,
  Member,
  MemberDescriptionDTO,
  MemberNickNameDTO,
  MemberSettingUpdateDTO,
  IsAgreeTerm,
  GetTermDetailDTO,
  TermCategoryDetailInfo,
  MemberChangeAgreeTermDTO,
  TermUpdateResult,
  SigunguIdDTO,
  AccountVerifyIndividualDTO,
  AccountVerifyCorporationDTO,
  MemberSignoutDTO,
  UpdateSalesAreaDTO,
  UpdateSalesMakerDTO,
  UpdateLeaseRentTypeDTO,
  TermDetail,
} from '@carsayo/types';
import { api } from 'apis';

export const updateProfileImage = async (
  reqData: SetProfileImageDTO,
): Promise<Member> => {
  try {
    const response = await api.post('/member/setting/profileImage', {
      ...reqData,
    });
    const data: Member = response.data;
    return data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};

export const updateDescription = async (
  reqData: MemberDescriptionDTO,
): Promise<Member> => {
  try {
    const response = await api.post('/member/setting/description', reqData);
    const data: Member = response.data;
    return data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};

export const updateNickname = async (
  reqData: MemberNickNameDTO,
): Promise<Member> => {
  try {
    const response = await api.post('/member/setting/nickname', {
      ...reqData,
    });
    const data: Member = response.data;
    return data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};

export const updateMessageReceiveSetting = async (
  reqData: MemberSettingUpdateDTO,
): Promise<Member> => {
  try {
    const response = await api.post('/member/setting/messageReceive', {
      ...reqData,
    });
    const data: Member = response.data;
    return data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};

/** 특정 약관에 동의하였는지 확인 */
export const checkIsTermAgree = async (
  termId: number,
): Promise<IsAgreeTerm> => {
  try {
    const response = await api.get(`/term/isAgree?termCategoryId=${termId}`);
    const data: IsAgreeTerm = response.data;
    return data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};

/** 약관 내용 가져오기 */
export const getTerm = async (
  reqData: GetTermDetailDTO,
): Promise<TermDetail> => {
  try {
    const response = await api.get(`/term/detail`, { params: reqData });
    const data: TermDetail = response.data;
    return data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};

/** 약관 카테고리 상세정보 가져오기 */
export const getTermCategoryInfo = async (
  reqData: GetTermDetailDTO,
): Promise<TermCategoryDetailInfo> => {
  try {
    const response = await api.get(`/term/category/detail`, {
      params: reqData,
    });
    const data: TermCategoryDetailInfo = response.data;
    return data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};

/** 약관 동의내역 업데이트 */
export const updateTerm = async (
  reqData: MemberChangeAgreeTermDTO,
): Promise<TermUpdateResult> => {
  try {
    const response = await api.post(`/term/update`, reqData);
    const data: TermUpdateResult = response.data;
    return data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};

/** 회원 지역 업데이트 */
export const updateRegion = async (reqData: SigunguIdDTO): Promise<Member> => {
  try {
    const response = await api.post(`/member/setting/region`, reqData);
    const data: Member = response.data;
    return data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};

/** 회원 개인 계좌 등록 */
export const updateIndividualAccount = async (
  reqData: AccountVerifyIndividualDTO,
): Promise<void> => {
  try {
    await api.post(`/member/setting/account/verify/individual`, reqData);
    return;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};

/** 회원 법인 계좌 등록 */
export const updateCorporationAccount = async (
  reqData: AccountVerifyCorporationDTO,
): Promise<void> => {
  try {
    await api.post(`/member/setting/account/verify/corporation`, reqData);
    return;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};

/** 일반회원 탈퇴 */
export const customerSignOut = async (
  reqData: MemberSignoutDTO,
): Promise<void> => {
  try {
    await api.post(`/member/signout`, reqData);
    return;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
/** 카매니저 회원탈퇴 신청 */
export const dealerSignOut = async (
  reqData: MemberSignoutDTO,
): Promise<Member> => {
  try {
    const response = await api.post(`/member/dealer/signout`, reqData);
    return response.data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};

/** 카매니저 영업지역 설정 */
export const updateDealerSalesArea = async (
  reqData: UpdateSalesAreaDTO,
): Promise<Member> => {
  try {
    const response = await api.post(`/member/dealer/salesArea`, reqData);
    const data: Member = response.data;
    return data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};

/** 카매니저 취급차량 설정 */
export const updateDealerSalesMaker = async (
  reqData: UpdateSalesMakerDTO,
): Promise<Member> => {
  try {
    const response = await api.post(`/member/dealer/salesMaker`, reqData);
    const data: Member = response.data;
    return data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};

/** 리스렌트 카매니저 유형 설정 */
export const updateLeaseRentType = async (
  reqData: UpdateLeaseRentTypeDTO,
): Promise<Member> => {
  try {
    const response = await api.post(`/member/dealer/leaseRentType`, reqData);
    const data: Member = response.data;
    return data;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};
