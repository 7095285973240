import { getPaymentList } from 'apis/payment';
import { useLayoutEffect, useState } from 'react';
import { parsePaymentState } from 'utils/textConvert';
import useSystemStore from 'store/useSystemStore';
import { Button } from 'components/ui/button';
import { cn } from '../../../utils';
import { PaidItem } from './components/payment/PaidItem';
import { VirtualAccountWaitingItem } from './components/payment/VirtualAccountingWaitingItem';
import {
  PaymentSearchList,
  SearchPaymentDTO,
  NicePaymentInfo,
} from '@carsayo/types';

const takeCount: number = 10;

export default function MypagePayment() {
  const { setTopTabbarState } = useSystemStore();
  const [paymentSearchList, setPaymentSearchList] =
    useState<PaymentSearchList>();
  const [searchPaymentDTO, setSearchPaymentDTO] = useState<SearchPaymentDTO>({
    take: takeCount,
    interval: null,
    option: null,
  });
  /** 추후 Infinite Scrolling 추가할 때 쓰세요 */
  const [isDataLoadDone, setIsDataLoadDone] = useState<boolean>(false);

  const handleVoidEvents = {
    setPaymentData: async () => {
      const response = await getPaymentList(searchPaymentDTO);

      if (response.data.length < takeCount) {
        setIsDataLoadDone(true);
      } else {
        setSearchPaymentDTO({
          ...searchPaymentDTO,
          skip:
            (searchPaymentDTO.skip ? searchPaymentDTO.skip : 0) +
            response.data.length,
        });
      }

      const updatedData: NicePaymentInfo[] = paymentSearchList?.data
        ? paymentSearchList.data
        : [];
      if (paymentSearchList?.data) {
        response.data = updatedData.concat(response.data);
      }
      setPaymentSearchList(response);

      return;
    },
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '결제 내역',
      rightElement: 'home',
      leftElement: 'back',
    });
    handleVoidEvents.setPaymentData();
  }, []);

  return (
    <div className='px-4 pb-10'>
      {paymentSearchList && (
        <>
          {paymentSearchList.data.length > 0
            ? paymentSearchList.data.map((item, index) => (
                <div className='border-b pb-8' key={`payment_${index}`}>
                  <div className='flex h-14 items-center justify-between'>
                    <div className='flex items-center gap-2'>
                      <div className='flex items-center gap-1'>
                        <div
                          className={cn(
                            'h-5 px-1 bg-[#2F66BA] text-[11px] leading-5 text-white',
                            {
                              'bg-[#04B014]': item.status === 'canceled',
                              'bg-[#DC0000]': item.status === 'cancelFailed',
                              'bg-[#FFAC00]':
                                item.status === 'virtualAccountWaiting',
                            },
                          )}
                        >
                          {parsePaymentState(item.status)}
                        </div>
                      </div>
                      <div className='text-base font-semibold'>{item.name}</div>
                    </div>
                  </div>
                  {item.status === 'virtualAccountWaiting' ? (
                    <VirtualAccountWaitingItem
                      bankName={item.virtualBank?.bankName as string}
                      amount={item.amount}
                      accountNumber={item.virtualBank?.accountNumber as string}
                      expireDate={item.virtualBank?.expireDate as string}
                      expireTime={item.virtualBank?.expireTime as string}
                    />
                  ) : (
                    <PaidItem
                      method={item.method}
                      amount={item.amount}
                      paid_at={item.paid_at}
                      status={item.status}
                      canceled_at={item.canceled_at}
                    />
                  )}
                </div>
              ))
            : paymentSearchList.data.length === 0 && (
                <div className='font-base py-[60px] text-center font-semibold'>
                  결제 내역이 존재하지 않습니다
                </div>
              )}

          {!isDataLoadDone && (
            <Button
              onClick={handleVoidEvents.setPaymentData}
              variant='outline'
              className='mt-8'
            >
              더보기
            </Button>
          )}
        </>
      )}
    </div>
  );
}
