import { useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useMemberStore from 'store/useMemberStore';
import useSystemStore from 'store/useSystemStore';

const SignoutDealerPage = () => {
  const navigate = useNavigate();
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const { loginMember } = useMemberStore();

  useLayoutEffect(() => {
    setTopTabbarState({
      mode: 'subpage',
      state: 'hidden',
    });
    setIsBottomTabbar(false);
  }, []);

  useEffect(() => {
    if (
      loginMember?.dealerInfo?.signout === null ||
      // 여기해야함!!
      loginMember?.dealerInfo?.signout.state === 'accepted'
    ) {
      navigate('/');
    }
  }, [loginMember]);

  return (
    <div className='flex min-h-full grow flex-col p-4'>
      <div className='mt-10 text-xl text-center font-semibold'>
        탈퇴 신청 접수 완료
      </div>
      <div className='mt-20 text-center'>
        <div>
          <img src='/assets/images/v2/headset.svg' alt='' className='mx-auto' />
        </div>
        <div className='mt-8 text-base font-semibold'>
          정상적으로 탈퇴 신청이 접수 되었습니다.
        </div>
        <div
          onClick={() => {
            const telUrl = 'mailto:info@carsayo.net';
            window.location.href = telUrl;
          }}
          className='mt-8 w-full text-[#111111] bg-[#F7F7FB] py-4 px-4 text-center justify-start'
        >
          문의 이메일:{' '}
          <span className='font-[500] underline'>info@carsayo.net</span>
        </div>
      </div>
    </div>
  );
};

export default SignoutDealerPage;
