import dayjs from 'dayjs';
import { GetOrderListDTO } from '@carsayo/types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface QuoteState {
  searchData: GetOrderListDTO;
  quoteListPage: number;
  quoteScrollPosition: number;
}
interface QuoteAction {
  setSkipData: (skip: number) => void;
  setTakeData: (take: number) => void;
  setSearchData: (searchData: GetOrderListDTO) => void;
  resetQuoteStore: () => void;
  setQuotePage: (page: number) => void;
  setQuoteScrollPosition: (position: number) => void;
}

const useQuoteStore = create<QuoteState & QuoteAction>()(
  devtools(
    immer((set) => ({
      searchData: {
        skip: 0,
        take: 5,
        interval: {
          target: 'created_at',
          start: dayjs().subtract(1, 'month').toDate(),
          end: dayjs().toDate(),
        },
        option: undefined,
        search: undefined,
        additionalInfo: undefined,
      },
      quoteListPage: 1,
      quoteScrollPosition: 0,
      setSkipData: (skip: number) => {
        set((state) => {
          state.searchData.skip = skip;
        });
      },
      setTakeData: (take: number) => {
        set((state) => {
          state.searchData.take = take;
        });
      },
      setSearchData: (searchData: GetOrderListDTO) => {
        set((state) => {
          state.searchData.take = searchData.take;
          state.searchData.skip = searchData.skip;
          state.searchData.interval = searchData.interval;
          state.searchData.option = searchData.option;
        });
      },
      setQuoteScrollPosition: (position: number) =>
        set((state) => {
          state.quoteScrollPosition = position;
        }),
      resetQuoteStore: () => {
        set((state) => {
          state.searchData = {
            take: 5,
            skip: 0,
            interval: {
              target: 'created_at',
              start: dayjs().subtract(1, 'month').toDate(),
              end: dayjs().toDate(),
            },
            option: undefined,
          };
          state.quoteListPage = 1;
          state.quoteScrollPosition = 0;
        });
      },
      setQuotePage: (page: number) => {
        set((state) => {
          state.quoteListPage = page;
        });
      },
    })),
  ),
);

export default useQuoteStore;
