import { Button } from 'components/ui/button';
import { Link } from 'react-router-dom';

export default function CommunityReview() {
  return (
    <div className='bg-[#F1F1F5]'>
      <div className='relative flex h-[82px] items-end justify-end bg-[#7273F9] px-5 py-3'>
        <div className='absolute -bottom-4 left-5'>
          <img src='/assets/images/v2/casayo-logo.svg' alt='' />
        </div>
        <div className='flex gap-2 text-desc'>
          <div className='text-white'>
            · 전체<span className='ml-4 text-[#111111]'>342</span>
          </div>
          <div className='text-white'>
            · 오늘<span className='ml-4 text-[#111111]'>36</span>
          </div>
        </div>
      </div>
      <div className='bg-white px-5 py-6'>
        <div className='mt-4 flex items-center justify-between'>
          <div className='text-xl font-semibold'>카사요 후기</div>
          <div className='text-xs text-[#767676]'>
            많은 분들의 후기를 공유해보세요
          </div>
        </div>
      </div>
      <div className='mt-3 bg-white px-5 py-4'>
        <div>
          <Link
            to='detail/1'
            className='flex justify-between gap-5 border-b py-6 last:border-b-0'
          >
            <div className='flex-1'>
              <div className='flex justify-between'>
                <div className='flex gap-1'>
                  <div className='flex h-5 items-center border border-[#2F66BA] px-1 text-[11px] text-[#2F66BA]'>
                    NEW
                  </div>
                  <div className='flex h-5 items-center border px-1 text-[11px]'>
                    리스
                  </div>
                </div>
                <div className='flex h-5 w-5 items-center justify-center rounded-full bg-[#F7F7FB] text-[11px]'>
                  12
                </div>
              </div>
              <div className='mt-2'>
                카사요에서 최저가 견적으로 구매 했어요.{' '}
              </div>
              <div className='mt-[14px] flex items-center gap-4'>
                <div className='text-xs text-[#555555]'>새벽 라디오 들으며</div>
                <div className='text-desc text-[#9EA3B2]'>· 2024.05.26</div>
              </div>
            </div>
            <div className='h-20 w-20 shrink-0 overflow-hidden rounded-[10px]'>
              <img
                src='/assets/images/v2/car2.png'
                alt=''
                className='h-full w-full object-cover'
              />
            </div>
          </Link>
          <Link
            to='detail/1'
            className='flex justify-between gap-5 border-b py-6 last:border-b-0'
          >
            <div className='flex-1'>
              <div className='flex justify-between'>
                <div className='flex gap-1'>
                  <div className='flex h-5 items-center bg-[#B71C1C] px-1 text-[11px] text-white'>
                    HOT
                  </div>
                  <div className='flex h-5 items-center border px-1 text-[11px]'>
                    장기렌트
                  </div>
                </div>
                <div className='flex h-5 w-5 items-center justify-center rounded-full bg-[#F7F7FB] text-[11px]'>
                  32
                </div>
              </div>
              <div className='mt-2'>
                카사요에서 최저가 견적으로 구매 했어요.{' '}
              </div>
              <div className='mt-[14px] flex items-center gap-4'>
                <div className='text-xs text-[#555555]'>새벽 라디오 들으며</div>
                <div className='text-desc text-[#9EA3B2]'>· 2024.05.26</div>
              </div>
            </div>
            <div className='h-20 w-20 shrink-0 overflow-hidden rounded-[10px]'>
              <img
                src='/assets/images/v2/car2.png'
                alt=''
                className='h-full w-full object-cover'
              />
            </div>
          </Link>
        </div>

        <Button variant='outline' className='mt-8 rounded-none'>
          더보기
        </Button>
      </div>
    </div>
  );
}
