import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import { Button } from 'components/ui/button';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { Input } from 'components/ui/input';
import { Dispatch, SetStateAction, useLayoutEffect, useState } from 'react';
import { cn } from 'utils';
import useSystemStore from 'store/useSystemStore';
import {
  getMember,
  getMyDealerSignupForm,
  reSubmitDealerSignupForm,
} from 'apis/authentication';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import useFileUploader from 'store/useFileUploader';
import { encodeFileToBase64 } from 'utils/convertImage';
import useMemberStore from 'store/useMemberStore';
import download from 'utils/download';
import { dateTranslator } from 'utils/date';
import { useNavigate } from 'react-router-dom';
import { Textarea } from 'components/ui/textarea';
import { parseSidoId } from 'utils/typeConvert';
import CarsayoDatePicker2 from 'components/common/CarsayoDatePicker2';
import { SelectSido } from 'components/view/buy/selectSido';
import { FileInfo, MemberDealerSignupForm, SidoId } from '@carsayo/types';

type UploadedAuthFileInfo = {
  file_loanSalesCertificate: FileInfo | null;
  file_idCard: FileInfo | null;
  file_nameCard: FileInfo | null;
  file_employeeCard: FileInfo | null;
  file_employCertificate: FileInfo | null;
  file_businessCertificate: FileInfo | null;
};
type AuthFileInfo = {
  title: string;
  key: keyof UploadedAuthFileInfo;
  isEssential: boolean;
};
const authFileData: {
  leaseRent: AuthFileInfo[];
  newCar: AuthFileInfo[];
  usedCar: AuthFileInfo[];
} = {
  leaseRent: [
    { title: '신분증', key: 'file_idCard', isEssential: true },
    {
      title: '대출성 금융상품 대리 중개업자 등록증',
      key: 'file_loanSalesCertificate',
      isEssential: true,
    },
  ],
  newCar: [
    { title: '신분증', key: 'file_idCard', isEssential: true },
    { title: '명함', key: 'file_nameCard', isEssential: true },
    { title: '사원증', key: 'file_employeeCard', isEssential: false },
    {
      title: '재직증명서',
      key: 'file_employCertificate',
      isEssential: false,
    },
  ],
  usedCar: [
    { title: '신분증', key: 'file_idCard', isEssential: true },
    { title: '명함', key: 'file_nameCard', isEssential: true },
    { title: '매매종사원증', key: 'file_employeeCard', isEssential: true },
    {
      title: '소속 매매상사 사업자 등록증',
      key: 'file_businessCertificate',
      isEssential: true,
    },
  ],
};
/** authFileUpload (회원가입)에서 가져옴 */
function FileInput({
  index,
  item,
  disabled,
  uploadedAuthFileInfo,
  setUploadedAuthFileInfo,
}: {
  index: number;
  item: AuthFileInfo;
  disabled?: boolean;
  uploadedAuthFileInfo: UploadedAuthFileInfo;
  setUploadedAuthFileInfo: Dispatch<SetStateAction<UploadedAuthFileInfo>>;
}) {
  const { fileUpload } = useFileUploader();
  const [imageSrc, setImageSrc] = useState<string>();

  return (
    <div key={`authFileInput_${index}`}>
      <div className='mb-2 inline-flex text-[#767676] leading-[20px] peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
        {item.title}
      </div>
      <div className='flex items-center gap-4 border px-4'>
        <div
          onClick={() => {
            if (uploadedAuthFileInfo[item.key])
              download(uploadedAuthFileInfo[item.key] as FileInfo);
          }}
          className='h-9 w-9 overflow-hidden'
        >
          <img
            className='w-full h-full'
            src={
              imageSrc
                ? imageSrc
                : uploadedAuthFileInfo[item.key] &&
                    uploadedAuthFileInfo[item.key]?.extension !== 'pdf'
                  ? uploadedAuthFileInfo[item.key]?.url
                  : uploadedAuthFileInfo[item.key]?.extension === 'pdf'
                    ? '/assets/images/v2/document.svg'
                    : '/assets/images/v2/file.svg'
            }
            alt=''
          />
        </div>
        <div className='flex h-[68px] items-center'>
          <div className='inline-flex h-[18px] w-2.5 items-center justify-center'>
            <span
              className={cn(
                'h-0.5 w-0.5 rounded-full bg-[#767676]',
                item.isEssential &&
                  !uploadedAuthFileInfo[item.key] &&
                  'bg-destructive',
              )}
            ></span>
          </div>
          <div
            className={cn(
              item.isEssential &&
                !uploadedAuthFileInfo[item.key] &&
                'text-destructive',
            )}
          >
            {item.isEssential ? '필수사항입니다' : '선택사항입니다'}
          </div>
        </div>
        <input id={`file-${item}`} type='file' className='w-0 hidden' />

        {!disabled &&
          (!uploadedAuthFileInfo[item.key] ? (
            <Button
              onClick={() => {
                fileUpload(
                  {
                    acceptFile: ['image', 'pdf'],
                    fileType: 'dealerAuthFile',
                    isPublic: false,
                  },
                  async (fileInfo, file) => {
                    if (fileInfo.extension !== 'pdf' && file) {
                      const base64String = await encodeFileToBase64(file);
                      setImageSrc(base64String);
                    }

                    setUploadedAuthFileInfo({
                      ...uploadedAuthFileInfo,
                      [item.key]: fileInfo,
                    });
                  },
                );
              }}
              size='sm'
              variant='outline'
              className='ml-auto w-auto border-[#111111] px-3'
            >
              <label htmlFor='file'>업로드</label>
            </Button>
          ) : (
            <Button
              onClick={() => {
                setUploadedAuthFileInfo({
                  ...uploadedAuthFileInfo,
                  [item.key]: null,
                });
                setImageSrc('');
              }}
              size='sm'
              variant='outline'
              className='ml-auto w-auto border-[#111111] px-3'
            >
              <label htmlFor='file'>제거</label>
            </Button>
          ))}
      </div>
    </div>
  );
}

const formSchema = z.object({
  email: z
    .string({ message: '이메일 주소를 입력해주세요' })
    .min(1, {
      message: '이메일 주소를 입력해주세요',
    })
    .email('유효한 이메일 주소가 아닙니다'),
  experenceYear: z.string(),
  isCarsayoEmployee: z.boolean().default(false),
  organization: z
    .string({ message: '외부일 경우 소속명을 입력해주세요' })
    .min(1, {
      message: '외부일 경우 소속명을 입력해주세요',
    }),

  tradingCertificate_term_start: z.date().optional(),
  tradingCertificate_term_finish: z.date().optional(),
  region: z.number({ message: '근무지역을 선택해 주세요' }).min(1, {
    message: '근무지역을 선택해 주세요',
  }),
  joinReason: z.string({ message: '자기 소개를 입력해 주세요' }).min(1, {
    message: '자기 소개를 입력해 주세요',
  }),
});

function SignupForm({
  memberDealerSignupForm,
  setMemberDealerSignupForm,
}: {
  memberDealerSignupForm: MemberDealerSignupForm;
  setMemberDealerSignupForm: Dispatch<
    SetStateAction<MemberDealerSignupForm | undefined>
  >;
}) {
  const { loginMember, setLoginMember } = useMemberStore();
  const { setLoadingDim } = useSystemStore();
  const [tab, setTab] = useState('basic');

  const navigate = useNavigate();
  const [isRegionOpen, setRegionOpen] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isDatePickerOpen2, setIsDatePickerOpen2] = useState(false);

  const [uploadedAuthFileInfo, setUploadedAuthFileInfo] =
    useState<UploadedAuthFileInfo>({
      file_loanSalesCertificate:
        memberDealerSignupForm.file_loanSalesCertificate,
      file_idCard: memberDealerSignupForm.file_idCard,
      file_nameCard: memberDealerSignupForm.file_nameCard,
      file_employeeCard: memberDealerSignupForm.file_employeeCard,
      file_employCertificate: memberDealerSignupForm.file_employCertificate,
      file_businessCertificate: memberDealerSignupForm.file_businessCertificate,
    });

  const handleDateChange = (date: Date) => {
    if (date) {
      form.setValue('tradingCertificate_term_start', date);
    }
  };
  const handleDateChange2 = (date: Date) => {
    if (date) {
      form.setValue('tradingCertificate_term_finish', date);
    }
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: memberDealerSignupForm.email,
      experenceYear: String(memberDealerSignupForm.experienceYears),
      isCarsayoEmployee: false,
      organization: memberDealerSignupForm.organization
        ? memberDealerSignupForm.organization
        : '',
      region: loginMember?.dealerInfo?.workingSido ?? undefined,
      tradingCertificate_term_start:
        memberDealerSignupForm.tradingCertificate_term_start
          ? dayjs(memberDealerSignupForm.tradingCertificate_term_start).toDate()
          : undefined,
      tradingCertificate_term_finish:
        memberDealerSignupForm.tradingCertificate_term_finish
          ? dayjs(
              memberDealerSignupForm.tradingCertificate_term_finish,
            ).toDate()
          : undefined,
      joinReason: memberDealerSignupForm.joinReason
        ? memberDealerSignupForm.joinReason
        : '',
    },
  });
  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setLoadingDim(true);

    try {
      await reSubmitDealerSignupForm({
        email: data.email,
        experienceYears: Number(data.experenceYear),
        isCarsayoEmployee: false,
        organization: data.organization,
        workingSido: data.region as SidoId,
        file_loanSalesCertificateId:
          uploadedAuthFileInfo.file_loanSalesCertificate?.id,
        file_idCardId: uploadedAuthFileInfo.file_idCard?.id,
        file_nameCardId: uploadedAuthFileInfo.file_nameCard?.id,
        file_employeeCardId: uploadedAuthFileInfo.file_employeeCard?.id,
        file_employCertificateId:
          uploadedAuthFileInfo.file_employCertificate?.id,
        file_businessCertificateId:
          uploadedAuthFileInfo.file_businessCertificate?.id,

        tradingCertificate_term_start: data.tradingCertificate_term_start
          ? dateTranslator(data.tradingCertificate_term_start)
          : undefined,
        tradingCertificate_term_finish: data.tradingCertificate_term_finish
          ? dateTranslator(data.tradingCertificate_term_finish)
          : undefined,
        joinReason: data.joinReason,
      });
      setLoginMember(await getMember());
      setTimeout(() => {
        navigate(-1);
        setLoadingDim(false);
      }, 2000);
    } catch (error) {
      setLoadingDim(false);
    }
  };

  return (
    <>
      <div className='grid grid-cols-2 gap-2 border-t px-4 pt-8'>
        <Button
          variant={tab === 'basic' ? 'default' : 'outline'}
          className='rounded-none'
          onClick={() => setTab('basic')}
        >
          기본 정보
        </Button>
        <Button
          variant={tab === 'proof' ? 'default' : 'outline'}
          className='rounded-none'
          onClick={() => setTab('proof')}
        >
          소속 증명 파일
        </Button>
      </div>

      <Form {...form}>
        <form
          id='signupForm'
          onSubmit={form.handleSubmit(onSubmit)}
          className='p-4 pb-10'
        >
          <div className={`space-y-6 ${tab === 'basic' ? '' : 'hidden'}`}>
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>이메일 주소</FormLabel>
                  <FormControl>
                    <Input
                      disabled={memberDealerSignupForm.state !== 'rejected'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='region'
              render={({ field }) => (
                <FormItem className='w-full '>
                  <FormLabel>근무 지역</FormLabel>
                  <FormControl className='w-full'>
                    <div className='w-full !z-0'>
                      <Input
                        disabled={memberDealerSignupForm.state !== 'rejected'}
                        onClick={
                          memberDealerSignupForm.state !== 'rejected'
                            ? () => {}
                            : () => setRegionOpen(!isRegionOpen)
                        }
                        placeholder='선택하세요'
                        value={
                          form.getValues('region')
                            ? `${parseSidoId(form.getValues('region') as SidoId)?.name ?? ''}`
                            : '선택하세요'
                        }
                        className='!z-0'
                      ></Input>
                      <SelectSido
                        isOpen={isRegionOpen}
                        setIsOpen={setRegionOpen}
                        selectSido={(value) => {
                          value
                            ? field.onChange(value)
                            : field.onChange(undefined);
                        }}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='experenceYear'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>경력 연수</FormLabel>
                  <FormControl>
                    <Select
                      disabled={memberDealerSignupForm.state !== 'rejected'}
                      onValueChange={(value) => field.onChange(value)}
                    >
                      <SelectTrigger>
                        <SelectValue
                          placeholder={`${memberDealerSignupForm.experienceYears}년`}
                        />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value={'1'}>1년</SelectItem>
                        <SelectItem value={'2'}>2년</SelectItem>
                        <SelectItem value={'3'}>3년</SelectItem>
                        <SelectItem value={'4'}>4년</SelectItem>
                        <SelectItem value={'5'}>5년</SelectItem>
                        <SelectItem value={'6'}>6년</SelectItem>
                        <SelectItem value={'7'}>7년</SelectItem>
                        <SelectItem value={'8'}>8년</SelectItem>
                        <SelectItem value={'9'}>9년</SelectItem>
                        <SelectItem value={'10'}>10년</SelectItem>
                        <SelectItem value={'11'}>11년</SelectItem>
                        <SelectItem value={'12'}>12년</SelectItem>
                        <SelectItem value={'13'}>13년</SelectItem>
                        <SelectItem value={'14'}>14년</SelectItem>
                        <SelectItem value={'15'}>15년</SelectItem>
                        <SelectItem value={'16'}>16년</SelectItem>
                        <SelectItem value={'17'}>17년</SelectItem>
                        <SelectItem value={'18'}>18년</SelectItem>
                        <SelectItem value={'19'}>19년</SelectItem>
                        <SelectItem value={'20'}>20년</SelectItem>
                        <SelectItem value={'21'}>21년</SelectItem>
                        <SelectItem value={'22'}>22년</SelectItem>
                        <SelectItem value={'23'}>23년</SelectItem>
                        <SelectItem value={'24'}>24년</SelectItem>
                        <SelectItem value={'25'}>25년</SelectItem>
                        <SelectItem value={'26'}>26년</SelectItem>
                        <SelectItem value={'27'}>27년</SelectItem>
                        <SelectItem value={'28'}>28년</SelectItem>
                        <SelectItem value={'29'}>29년</SelectItem>
                        <SelectItem value={'30'}>30년</SelectItem>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='isCarsayoEmployee'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>소속</FormLabel>
                  <FormControl>
                    <>
                      <div className='flex gap-4'>
                        {/* <Select
                          disabled={memberDealerSignupForm.state !== 'rejected'}
                          onValueChange={(value) => {
                            if (value === '내부') {
                              form.setValue('organization', '카사요');
                              form.clearErrors('organization');
                            } else {
                              form.setValue('organization', '');
                            }
                            field.onChange(value);
                          }}
                        >
                          <SelectTrigger className='w-[120px]'>
                            <SelectValue
                              placeholder={form.getValues('isCarsayoEmployee')}
                            />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value='외부'>외부</SelectItem>
                            <SelectItem value='내부'>내부</SelectItem>
                          </SelectContent>
                        </Select> */}

                        <FormField
                          control={form.control}
                          name='organization'
                          render={({ field }) => (
                            <FormItem className='flex-auto'>
                              <FormControl>
                                <Input
                                  // disabled={
                                  //   memberDealerSignupForm.state !==
                                  //     'rejected' ||
                                  //   form.getValues('isCarsayoEmployee') ===
                                  //     '내부'
                                  // }
                                  disabled={
                                    memberDealerSignupForm.state !== 'rejected'
                                  }
                                  placeholder='소속 딜러사 혹은 대리점'
                                  {...field}
                                ></Input>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>

                      {/* <div className='space-y-2.5 bg-[#F7F7FB] p-4 text-desc text-[#767676]'>
                        <div className='flex'>
                          <div className='inline-flex h-[18px] w-2.5 items-center justify-center'>
                            <span className='h-0.5 w-0.5 rounded-full bg-[#767676]'></span>
                          </div>
                          <div>외부 : 카사요 임직원이 아닌 경우</div>
                        </div>
                        <div className='flex'>
                          <div className='inline-flex h-[18px] w-2.5 items-center justify-center'>
                            <span className='h-0.5 w-0.5 rounded-full bg-[#767676]'></span>
                          </div>
                          <div>
                            내부 : 카사요 임직원일 경우 (소속명 수정 불가)
                          </div>
                        </div>
                      </div> */}
                    </>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {loginMember?.dealerInfo?.type === 'usedcar' && (
              <div className='flex items-center gap-2'>
                <FormField
                  control={form.control}
                  name='tradingCertificate_term_start'
                  render={({ field }) => (
                    <FormItem className='w-full'>
                      <FormLabel>매매업종 사업증 유효기간</FormLabel>
                      <div className='flex'>
                        <FormControl className='w-5 flex-auto'>
                          {/* <DatePicker
                            disabled={
                              memberDealerSignupForm.state !== 'rejected'
                            }
                            defaultValue={
                              memberDealerSignupForm.tradingCertificate_term_start
                                ? dayjs(
                                    memberDealerSignupForm.tradingCertificate_term_start,
                                  ).toDate()
                                : undefined
                            }
                            onChange={(date) => {
                              if (date)
                                form.setValue(
                                  'tradingCertificate_term_start',
                                  date,
                                );
                            }}
                            placeholder='시작일'
                          ></DatePicker> */}
                          <CarsayoDatePicker2
                            disabled={
                              memberDealerSignupForm.state !== 'rejected'
                            }
                            isOpen={isDatePickerOpen}
                            onOpen={
                              memberDealerSignupForm.state === 'rejected'
                                ? () => setIsDatePickerOpen(true)
                                : () => {}
                            }
                            onCancel={() => setIsDatePickerOpen(false)}
                            onChange={handleDateChange}
                            className='w-[42.2vw] pr-[15px !z-0'
                            value={
                              form.getValues('tradingCertificate_term_start') ??
                              undefined
                            }
                            placeholder='시작일'
                          />
                        </FormControl>
                        <div className='px-2 pt-4 flex-none'>-</div>
                        <FormField
                          control={form.control}
                          name='tradingCertificate_term_finish'
                          render={({ field }) => (
                            <FormItem className='w-5 flex-auto'>
                              <FormControl>
                                {/* <DatePicker
                                  disabled={
                                    memberDealerSignupForm.state !== 'rejected'
                                  }
                                  defaultValue={
                                    memberDealerSignupForm.tradingCertificate_term_finish
                                      ? dayjs(
                                          memberDealerSignupForm.tradingCertificate_term_finish,
                                        ).toDate()
                                      : undefined
                                  }
                                  onChange={(date) => {
                                    if (date)
                                      form.setValue(
                                        'tradingCertificate_term_finish',
                                        date,
                                      );
                                  }}
                                  placeholder='종료일'
                                ></DatePicker> */}
                                <CarsayoDatePicker2
                                  disabled={
                                    memberDealerSignupForm.state !== 'rejected'
                                  }
                                  isOpen={isDatePickerOpen2}
                                  onOpen={
                                    memberDealerSignupForm.state === 'rejected'
                                      ? () => setIsDatePickerOpen2(true)
                                      : () => {}
                                  }
                                  onCancel={() => setIsDatePickerOpen2(false)}
                                  onChange={handleDateChange2}
                                  className='pr-[15px] !z-0'
                                  value={
                                    form.getValues(
                                      'tradingCertificate_term_finish',
                                    ) ?? undefined
                                  }
                                  placeholder='종료일'
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            )}
            <FormField
              control={form.control}
              name='joinReason'
              disabled={memberDealerSignupForm.state !== 'rejected'}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>자기 소개</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder='자기 소개를 입력해 주세요'
                      {...field}
                    ></Textarea>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className={`space-y-6  ${tab === 'basic' ? 'hidden' : ''}`}>
            {loginMember?.dealerInfo?.type === 'leaseRent'
              ? authFileData.leaseRent.map((item, index) => {
                  return FileInput({
                    index: index,
                    item: item,
                    disabled: memberDealerSignupForm.state !== 'rejected',
                    uploadedAuthFileInfo,
                    setUploadedAuthFileInfo,
                  });
                })
              : loginMember?.dealerInfo?.type === 'newcar'
                ? authFileData.newCar.map((item, index) => {
                    return FileInput({
                      index: index,
                      item: item,
                      disabled: memberDealerSignupForm.state !== 'rejected',
                      uploadedAuthFileInfo,
                      setUploadedAuthFileInfo,
                    });
                  })
                : authFileData.usedCar.map((item, index) => {
                    return FileInput({
                      index: index,
                      item: item,
                      disabled: memberDealerSignupForm.state !== 'rejected',
                      uploadedAuthFileInfo,
                      setUploadedAuthFileInfo,
                    });
                  })}
          </div>
          {memberDealerSignupForm.state === 'rejected' && (
            <Button type='submit' className='mt-6'>
              재신청
            </Button>
          )}
        </form>
      </Form>
    </>
  );
}

export default function MypageDealerSignupForm() {
  const { setTopTabbarState } = useSystemStore();
  const { refreshLoginMember } = useMemberStore();

  const [memberDealerSignupForm, setMemberDealerSignupForm] =
    useState<MemberDealerSignupForm>();

  const handleVoidEvents = {
    getMemberDealerSignupForm: async () => {
      setMemberDealerSignupForm(await getMyDealerSignupForm());
    },
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '카 매니저 승인 상태',
      rightElement: 'home',
      leftElement: 'back',
    });
    handleVoidEvents.getMemberDealerSignupForm();
    return () => {
      refreshLoginMember();
    };
  }, []);

  return (
    <div>
      {memberDealerSignupForm && (
        <>
          <Accordion
            type='single'
            collapsible
            className='w-full'
            defaultValue='item-1'
          >
            <AccordionItem value='item-1'>
              <AccordionTrigger className='flex min-h-14 items-center gap-4 px-4 py-0 text-left'>
                <div className='text-lg font-semibold'>승인 결과</div>
              </AccordionTrigger>
              <AccordionContent className='whitespace-pre-line px-4 pb-8'>
                {memberDealerSignupForm?.state === 'rejected' && (
                  <>
                    {memberDealerSignupForm?.rejectMessage && (
                      <>
                        <div className='text-[#505050]'>
                          승인 거절 사유는 아래와 같습니다.
                        </div>
                        <ul className='list-outside list-disc p-4 text-[#505050] marker:text-[#767676]'>
                          {memberDealerSignupForm.rejectMessage}
                        </ul>
                      </>
                    )}
                    <div>아래 페이지에서 파일 확인 후 다시 신청해 주세요.</div>
                  </>
                )}
                <div className='mt-4 border-t'>
                  <div className='flex h-[52px] items-center border-b'>
                    <div className='w-[100px] shrink-0 bg-[#F7F7FB] p-4'>
                      승인 결과
                    </div>
                    {memberDealerSignupForm?.state === 'accepted' && (
                      <div className='p-4 text-[#107700]'>가입 승인</div>
                    )}
                    {memberDealerSignupForm?.state === 'submitted' && (
                      <div className='p-4 text-[#000000]'>승인 대기</div>
                    )}
                    {memberDealerSignupForm?.state === 'rejected' && (
                      <div className='p-4 text-destructive'>승인 거절</div>
                    )}
                  </div>
                  <div className='flex h-[52px] items-center border-b'>
                    {memberDealerSignupForm?.state === 'accepted' && (
                      <>
                        <div className='w-[100px] shrink-0 bg-[#F7F7FB] p-4'>
                          승인일
                        </div>
                        <div className='p-4 text-[#767676]'>
                          {dayjs(memberDealerSignupForm?.accepted_at).format(
                            'YYYY.MM.DD (ddd)',
                          )}
                        </div>
                      </>
                    )}
                    {memberDealerSignupForm?.state === 'submitted' && (
                      <>
                        <div className='w-[100px] shrink-0 bg-[#F7F7FB] p-4'>
                          신청일
                        </div>
                        <div className='p-4 text-[#767676]'>
                          {dayjs(memberDealerSignupForm?.submitted_at).format(
                            'YYYY.MM.DD (ddd)',
                          )}
                        </div>
                      </>
                    )}
                    {memberDealerSignupForm?.state === 'rejected' && (
                      <>
                        <div className='w-[100px] shrink-0 bg-[#F7F7FB] p-4'>
                          승인 거부일
                        </div>
                        <div className='p-4 text-[#767676]'>
                          {dayjs(memberDealerSignupForm?.rejected_at).format(
                            'YYYY.MM.DD (ddd)',
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>

          <SignupForm
            memberDealerSignupForm={memberDealerSignupForm}
            setMemberDealerSignupForm={setMemberDealerSignupForm}
          />
        </>
      )}
    </div>
  );
}
