export const isValidPhoneNumberFormat = (phoneNumber: string): boolean => {
  const pattern = /^01[016789]-?\d{3,4}-?\d{4}$/;
  const match = pattern.test(phoneNumber);
  return match;
};
export const returnPhoneNumberWithDashes = (value: string) => {
  const phoneNumberValue = value.replaceAll('-', '');
  if (phoneNumberValue.length > 11) {
    return { phoneNumberWithDashes: value.slice(0, -1), isValid: false };
  }
  const phoneNumberWithDashes = phoneNumberDashes(phoneNumberValue);
  const phoneNumberRegExp = new RegExp(/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/gi);
  const plainPhoneNumberRegExp = new RegExp(/^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/gi);
  const isPhoneNumber = !phoneNumberRegExp.test(phoneNumberValue);
  const isValid = plainPhoneNumberRegExp.test(phoneNumberValue);

  console.info('phoneNumberValue', phoneNumberValue);
  console.info('isPhoneNumber', !isPhoneNumber);
  console.info('isValid', isValid);
  return { phoneNumberWithDashes, isValid };
};

export const phoneNumberDashes = (phoneNumber: string) => {
  const replaceDashesToBlank = phoneNumber.replaceAll('-', '');

  switch (replaceDashesToBlank.length) {
    case 1:
    case 2: {
      return replaceDashesToBlank;
      break;
    }
    case 3: {
      const resultPhoneNumber = `${replaceDashesToBlank.slice(
        0,
        2,
      )}-${replaceDashesToBlank.slice(2, replaceDashesToBlank.length)}`;
      return resultPhoneNumber;
      break;
    }
    case 4:
    case 5:
    case 6:
    case 7:
    case 8: {
      const resultPhoneNumber = `${replaceDashesToBlank.slice(
        0,
        3,
      )}-${replaceDashesToBlank.slice(3, replaceDashesToBlank.length)}`;
      return resultPhoneNumber;
      break;
    }
    case 9: {
      const resultPhoneNumber = `${replaceDashesToBlank.slice(
        0,
        2,
      )}-${replaceDashesToBlank.slice(2, 5)}-${replaceDashesToBlank.slice(
        5,
        9,
      )}`;
      return resultPhoneNumber;
      break;
    }
    case 10: {
      const resultPhoneNumber = `${replaceDashesToBlank.slice(
        0,
        3,
      )}-${replaceDashesToBlank.slice(3, 6)}-${replaceDashesToBlank.slice(
        6,
        replaceDashesToBlank.length,
      )}`;
      return resultPhoneNumber;
      break;
    }
    case 11: {
      const resultPhoneNumber = `${replaceDashesToBlank.slice(
        0,
        3,
      )}-${replaceDashesToBlank.slice(3, 7)}-${replaceDashesToBlank.slice(
        7,
        replaceDashesToBlank.length,
      )}`;
      return resultPhoneNumber;
      break;
    }

    default: {
      return replaceDashesToBlank;
    }
  }
};
