import { ReactNode } from 'react';
import { cn } from 'utils';

/** 전체 화면 영역
 * @description inline style - padding for app safeArea
 */
export function FullScreen({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) {
  return (
    <div
      className={cn(
        'top-0 fixed right-0 flex flex-col w-full h-full z-[5000] truncate',
        className,
      )}
      style={{
        paddingTop: 'env(safe-area-inset-top, 0)',
        paddingBottom: 'env(safe-area-inset-bottom, 0)',
      }}
    >
      {children}
    </div>
  );
}
