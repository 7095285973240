import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import { Button } from 'components/ui/button';
import { useLayoutEffect, useState } from 'react';
import useSystemStore from 'store/useSystemStore';
import { getPostList, getPost } from 'apis/board';
import ReactHtmlParser from 'react-html-parser';
import dayjs from 'dayjs';
import { PostList, PostDetailInfo, GetPostListDTO } from '@carsayo/types';

const takeCount: number = 10;

export default function MypageNotice() {
  const { setTopTabbarState } = useSystemStore();
  const [postList, setPostList] = useState<PostList>();
  const [postDetailInfo, setPostDetailInfo] = useState<{
    [key: number]: PostDetailInfo;
  }>({});

  const [searchDTO, setSearchDTO] = useState<GetPostListDTO>({
    boardKey: 'notice',
    take: takeCount,
    option: {},
    search: null,
  });

  /** 추후 Infinite Scrolling 추가할 때 쓰세요 */
  const [isDataLoadDone, setIsDataLoadDone] = useState<boolean>(false);

  const handleVoidEvents = {
    getPostList: async () => {
      const searchedPostList: PostList = await getPostList(searchDTO);

      // 마이페이지 메인에서 미확인된 공지사항 등록시 깜빡이는 기능 끄기 위함
      if (!searchDTO.skip && searchedPostList.searchedPost[0]) {
        localStorage.setItem(
          'checkedNotice',
          String(searchedPostList.searchedPost[0].id),
        );
      }

      if (searchedPostList.searchedPost.length < takeCount) {
        setIsDataLoadDone(true);
      } else {
        setSearchDTO({
          ...searchDTO,
          skip:
            (searchDTO.skip ? searchDTO.skip : 0) +
            searchedPostList.searchedPost.length,
        });
      }

      if (postList) {
        searchedPostList.searchedPost = postList.searchedPost.concat(
          searchedPostList.searchedPost,
        );
      }
      setPostList(searchedPostList);

      return;
    },
    getPost: async (postId: number) => {
      if (postDetailInfo[postId]) return;
      const post: PostDetailInfo = await getPost({
        boardKey: 'notice',
        postId: postId,
      });
      setPostDetailInfo({ ...postDetailInfo, [postId]: post });
    },
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '공지사항',
      rightElement: 'home',
      leftElement: 'back',
    });
    handleVoidEvents.getPostList();
  }, []);

  return (
    <div className='px-4 pb-10'>
      {postList &&
        postList.importantPost.map((el, index) => {
          return (
            <Accordion
              key={`notice-fixed-${index}`}
              type='single'
              collapsible
              className='w-full'
            >
              <AccordionItem value={`inportantPost-${index}`}>
                <AccordionTrigger
                  onClick={() => handleVoidEvents.getPost(el.id)}
                  className='flex items-center gap-4 border-b border-[#F1F1F5] py-4 text-left'
                >
                  <div>
                    <div className='flex items-center gap-1'>
                      {dayjs(el.created_at).isAfter(dayjs().add(-7, 'day')) && (
                        <div className='h-5 border border-[rgba(183,28,28,.2)] px-1 text-center text-desc leading-5 text-destructive'>
                          N
                        </div>
                      )}
                      <div className='h-[22px] bg-destructive px-1 text-desc leading-[22px] text-destructive-foreground'>
                        중요
                      </div>
                    </div>
                    <div className='mt-2 font-semibold'>{el.title}</div>
                    <div className='mt-2 text-[#767676]'>
                      {dayjs(el.created_at).format('YYYY-MM-DD')}
                    </div>
                  </div>
                </AccordionTrigger>
                <AccordionContent className='whitespace-pre-line py-5'>
                  {ReactHtmlParser(postDetailInfo?.[el.id]?.content_html)}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          );
        })}

      {postList &&
        postList.searchedPost.map((el, index) => {
          return (
            <Accordion
              key={`notice-search-${index}`}
              type='single'
              collapsible
              className='w-full'
            >
              <AccordionItem value={`normalPost-${index}`}>
                <AccordionTrigger
                  onClick={() => handleVoidEvents.getPost(el.id)}
                  className='flex items-center gap-4 border-b border-[#F1F1F5] py-4 text-left'
                >
                  <div>
                    <div className='flex items-center gap-1'>
                      {dayjs(el.created_at).isAfter(dayjs().add(-7, 'day')) && (
                        <div className='h-5 border border-[rgba(183,28,28,.2)] px-1 text-center text-desc leading-5 text-destructive'>
                          N
                        </div>
                      )}
                    </div>
                    <div className='mt-2 font-semibold'>{el.title}</div>
                    <div className='mt-2 text-[#767676]'>
                      {dayjs(el.created_at).format('YYYY-MM-DD')}
                    </div>
                  </div>
                </AccordionTrigger>
                <AccordionContent className='whitespace-pre-line py-5'>
                  {ReactHtmlParser(postDetailInfo?.[el.id]?.content_html)}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          );
        })}

      {postList && !isDataLoadDone && (
        <Button
          onClick={handleVoidEvents.getPostList}
          variant='outline'
          className='mt-8'
        >
          더보기
        </Button>
      )}
    </div>
  );
}
