import {
  CarModel,
  CarTrimIncludeGrade,
  CarColor,
  CarOption,
  CarMakerId,
  PurchaseOrderDTO,
  CarMakerData,
} from '@carsayo/types';
import {
  getCarColor,
  getCarModel,
  getCarOption,
  getCarTrimIncludeGrade,
} from 'apis/car';
import { updatePurchase } from 'apis/purchase';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface PurchaseCatalogue {
  model?: {
    makerId: number;
    data: CarModel[];
  };
  trimIncludeGrade?: {
    modelId: number;
    data: CarTrimIncludeGrade[];
  };
  color?: {
    gradeId: number;
    data: CarColor[];
  };
  option?: { gradeId: number; data: CarOption[] };
}
interface SetPurchaseCatalogue {
  /** 해당 브랜드 내 구매 가능 모델 리스트를 카탈로그에 넣습니다 */
  makerId?: CarMakerId | null;
  /** 해당 모델 내 트림-등급 리스트를 카탈로그에 넣습니다 */
  modelId?: number | null;
  /** 해당 모델 내 색상, 옵션 리스트를 카탈로그에 넣습니다 */
  gradeId?: number | null;
}

interface PurchaseState {
  purchaseOrderDTO?: PurchaseOrderDTO;
  /** 차량 카탈로그 데이터
   * @description 이 데이터를 통해 회원이 차량을 선택할 수 있게 합니다
   */
  catalogue?: PurchaseCatalogue;
}
interface PurchaseAction {
  setCatalogue: (params: SetPurchaseCatalogue) => void;
  setPurchaseOrderDTO: (
    purchaseOrderDTO: PurchaseOrderDTO,
    option?: { initial?: boolean },
  ) => void;
  resetPurchaseStore: () => void;
}

const usePurchaseStore = create<PurchaseState & PurchaseAction>()(
  devtools(
    immer((set, get) => ({
      purchaseOrderDTO: undefined,
      catalogue: undefined,
      purchaseType: undefined,

      setCatalogue: async (params: SetPurchaseCatalogue) => {
        const currentCatalogue = get().catalogue;

        const targetMaker = params.makerId
          ? CarMakerData.find((el) => {
              return el.id === params.makerId;
            })
          : undefined;

        const updatedCatalogue: PurchaseCatalogue = {
          model:
            params.makerId && targetMaker
              ? {
                  makerId: params.makerId,
                  data: await getCarModel({
                    carMakerId: targetMaker.id,
                    withoutPriceModel: targetMaker.isSuperCar
                      ? true
                      : undefined,
                  }),
                }
              : params.makerId === null
                ? undefined
                : currentCatalogue?.model,
          trimIncludeGrade: params.modelId
            ? {
                modelId: params.modelId,
                data: await getCarTrimIncludeGrade(String(params.modelId)),
              }
            : params.modelId === null
              ? undefined
              : currentCatalogue?.trimIncludeGrade,
          color: params.gradeId
            ? {
                gradeId: params.gradeId,
                data: await getCarColor(String(params.gradeId)),
              }
            : params.gradeId === null
              ? undefined
              : currentCatalogue?.color,
          option: params.gradeId
            ? {
                gradeId: params.gradeId,
                data: await getCarOption(String(params.gradeId)),
              }
            : params.gradeId === null
              ? undefined
              : currentCatalogue?.option,
        };
        set((state) => {
          state.catalogue = updatedCatalogue;
        });
      },

      setPurchaseOrderDTO: async (
        purchaseOrderDTO: PurchaseOrderDTO,
        option?: { initial?: boolean },
      ) => {
        const updatedPurchase = await updatePurchase(purchaseOrderDTO);

        set((state) => {
          state.purchaseOrderDTO = updatedPurchase;
        });

        // 차량 제조사, 모델 등 업데이트시 카탈로그 갱신
        if (
          purchaseOrderDTO.update?.carMakerId ||
          purchaseOrderDTO.update?.carModelId ||
          purchaseOrderDTO.update?.carGradeId
        ) {
          if (purchaseOrderDTO.update.carMakerId !== undefined) {
            if (
              get().catalogue?.model?.makerId !==
              purchaseOrderDTO.update.carMakerId
            ) {
              await get().setCatalogue({
                makerId: purchaseOrderDTO.update.carMakerId,
              });
            }
          }

          if (purchaseOrderDTO.update.carModelId !== undefined) {
            if (
              get().catalogue?.trimIncludeGrade?.modelId !==
              purchaseOrderDTO.update.carModelId
            ) {
              await get().setCatalogue({
                modelId: purchaseOrderDTO.update.carModelId,
              });
            }
          }

          if (purchaseOrderDTO.update.carGradeId !== undefined) {
            if (
              get().catalogue?.color?.gradeId !==
              purchaseOrderDTO.update.carGradeId
            ) {
              await get().setCatalogue({
                gradeId: purchaseOrderDTO.update.carGradeId,
              });
            }
          }
        }

        // 시작 데이터를 가지고 카탈로그 갱신
        if (option?.initial) {
          if (updatedPurchase.orderData?.carMaker !== undefined) {
            if (
              get().catalogue?.model?.makerId !==
              updatedPurchase.orderData.carMaker.id
            ) {
              await get().setCatalogue({
                makerId: updatedPurchase.orderData.carMaker.id,
              });
            }
          }

          if (updatedPurchase.orderData?.carModel !== undefined) {
            if (
              get().catalogue?.trimIncludeGrade?.modelId !==
              updatedPurchase.orderData.carModel.id
            ) {
              await get().setCatalogue({
                modelId: updatedPurchase.orderData.carModel.id,
              });
            }
          }

          if (updatedPurchase.orderData?.carGrade !== undefined) {
            if (
              get().catalogue?.color?.gradeId !==
              updatedPurchase.orderData.carGrade.id
            ) {
              await get().setCatalogue({
                gradeId: updatedPurchase.orderData.carGrade.id,
              });
            }
          }
        }
      },
      resetPurchaseStore: () => {
        set((state) => {
          state.purchaseOrderDTO = undefined;
          state.catalogue = undefined;
        });
      },
    })),
  ),
);

export default usePurchaseStore;
