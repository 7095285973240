/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLayoutEffect } from 'react';
import dayjs from 'dayjs';
import useSystemStore from 'store/useSystemStore';
import { getManagerCommissionList } from 'apis/commission';
import { cn } from 'utils';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { OrderCommissionListData, BankCodeData } from '@carsayo/types';

export default function MypageCommission() {
  const navigate = useNavigate();
  const { setTopTabbarState } = useSystemStore();

  const useCommissionList = () => {
    return useQuery({
      queryKey: ['dealerCommissionList'],
      queryFn: async () => {
        const data: OrderCommissionListData[] =
          await getManagerCommissionList();
        return data;
      },
      gcTime: 5 * 60 * 1000, // 5 min
      staleTime: 30 * 1000, // 30 sec
    });
  };
  const { data: commissionList } = useCommissionList();

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '수수료 정산',
      rightElement: 'home',
      leftElement: 'back',
    });
  }, []);

  return (
    <div className='px-4 pb-10'>
      {commissionList && (
        <>
          {commissionList.length > 0
            ? commissionList
                .filter((el) => {
                  return el.price;
                })
                .map((item) => (
                  <div className='border-b pb-8' key={item.id}>
                    <div className='flex gap-2 pt-6 pb-3 items-stretch'>
                      <div
                        className={cn(
                          'flex-none mt-0.5 h-5 px-1 text-[11px] leading-5 text-white',
                          item.state === 'paid' && 'bg-[#2F66BA]',
                          item.state === 'sended' && 'bg-red-500',
                          item.state === 'canceled' && 'bg-gray-700',
                        )}
                      >
                        {item.state === 'paid'
                          ? '납부완료'
                          : item.state === 'sended'
                            ? '미납부'
                            : item.state === 'canceled'
                              ? '취소'
                              : '미청구'}
                      </div>
                      <div
                        onClick={() => {
                          navigate(`/quote/manager/detail/${item.order.id}`);
                        }}
                        className='flex flex-auto items-center gap-2 text-base font-semibold underline'
                      >
                        {item.order.title}
                      </div>
                    </div>
                    <div className='border'>
                      <div className='flex items-center border-b'>
                        <div className='w-[90px] shrink-0 bg-[#F7F7FB] p-4'>
                          고지일
                        </div>
                        <div className='p-4 text-[#767676]'>
                          {dayjs(item.asked_at).format('YYYY-MM-DD')}
                        </div>
                      </div>
                      {item.state === 'sended' &&
                        item.bankCode &&
                        item.accountNumber && (
                          <>
                            <div className='flex items-center border-b'>
                              <div className='w-[90px] shrink-0 bg-[#F7F7FB] p-4'>
                                입금계좌
                              </div>
                              <div className='p-4 text-[#767676]'>
                                {
                                  BankCodeData.find((el) => {
                                    return el.id === item.bankCode;
                                  })?.name
                                }{' '}
                                {item.accountNumber}
                              </div>
                            </div>
                            <div className='flex items-center border-b'>
                              <div className='w-[90px] shrink-0 bg-[#F7F7FB] p-4'>
                                예금주명
                              </div>
                              <div className='p-4 text-[#767676]'>
                                {item.ownerName}
                              </div>
                            </div>
                          </>
                        )}
                      {item.paid_at && (
                        <div className='flex items-center border-b'>
                          <div className='w-[90px] shrink-0 bg-[#F7F7FB] p-4'>
                            납부확인
                          </div>
                          <div className='p-4 text-[#767676]'>
                            {dayjs(item.paid_at).format('YYYY-MM-DD')}
                          </div>
                        </div>
                      )}
                      {item.canceled_at && (
                        <div className='flex items-center border-b'>
                          <div className='w-[90px] shrink-0 bg-[#F7F7FB] p-4'>
                            취소일
                          </div>
                          <div className='p-4 text-[#767676]'>
                            {dayjs(item.canceled_at).format('YYYY-MM-DD')}
                          </div>
                        </div>
                      )}
                      <div className='flex items-center'>
                        <div className='w-[90px] shrink-0 bg-[#F7F7FB] p-4'>
                          금액
                        </div>
                        <div className='p-4 text-[#767676]'>
                          {item.price ? item.price.toLocaleString() + '원' : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            : commissionList.length === 0 && (
                <div className='font-base py-[60px] text-center font-semibold'>
                  수수료 정산 내역이 존재하지 않습니다
                </div>
              )}
        </>
      )}
    </div>
  );
}
