import * as Sentry from '@sentry/react';

export interface ErrorResponse {
  data: any;
  status: number;
  statusText: string;
  headers: Record<string, string>;
  config: {
    url?: string;
    method?: string;
    headers?: Record<string, string>;
    data?: any;
    [key: string]: any;
  };
  request?: any;
}

const SentryErrorReturns = (errorData: ErrorResponse) => {
  const { data, status, config, request } = errorData;

  /** 이 경우 로그인 토큰이 잘못되어(만료되었거나 등) 발생하는 권한 에러입니다.
   * 이 경우 Sentry에 에러 로그를 보내지 않습니다.
   */
  if (data.error === 'E-LG3BDAa5S') {
    console.error(`Catch Error: { 
      requestBody: ${config.data}
      data: ${data.error}
      message: ${data.message} 
      path: ${data.path} 
      status: ${data.statusCode} 
      time: ${data.timestamp}
    }`);
    return;
  }

  Sentry.setContext('Sentry Catch API Response Detail', {
    data,
    status,
    config,
    request,
  });

  // Sentry에 오류 캡처
  Sentry.captureException(
    new Error(`Sentry Catch Error: { 
    data: ${data.error}
    message: ${data.message} 
    path: ${data.path} 
    status: ${data.statusCode} 
    time: ${data.timestamp}
  }`),
  );
  console.error(`Sentry Catch Error: { 
    requestBody: ${config.data}
    data: ${data.error}
    message: ${data.message} 
    path: ${data.path} 
    status: ${data.statusCode} 
    time: ${data.timestamp}
  }`);
};
export default SentryErrorReturns;
