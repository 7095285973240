/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from 'components/ui/button';
import Confirm from 'components/ui/confirm';
import { useNavigate } from 'react-router-dom';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { Input } from 'components/ui/input';
import signupStore from '../store';
import CarsayoDatePicker2 from 'components/common/CarsayoDatePicker2';
import SpeakerIcon from 'assets/images/v2/sound.svg';
import { parseSidoId } from 'utils/typeConvert';
import { Textarea } from 'components/ui/textarea';
import { SelectSido } from 'components/view/buy/selectSido';
import { SidoId } from '@carsayo/types';

const formSchema = z.object({
  email: z
    .string({ message: '이메일 주소를 입력해주세요' })
    .min(1, {
      message: '이메일 주소를 입력해주세요',
    })
    .email('유효한 이메일 주소가 아닙니다'),

  experenceYear: z.string(),
  isCarsayoEmployee: z.boolean().default(false),
  organization: z
    .string({ message: '소속 딜러사 또는 대리점명을 기입해주세요' })
    .min(1, {
      message: '소속 딜러사 또는 대리점명을 기입해주세요',
    }),

  tradingCertificate_term_start: z.date().optional(),
  tradingCertificate_term_finish: z.date().optional(),
  region: z.number({ message: '근무지역을 선택해 주세요' }).min(1, {
    message: '근무지역을 선택해 주세요',
  }),
  joinReason: z.string({ message: '자기 소개를 입력해 주세요' }).min(1, {
    message: '자기 소개를 입력해 주세요',
  }),
  leaseRentType: z.string().optional(),
});

export default function DealerSignupAdditionalInfo() {
  const navigate = useNavigate();
  const { signupInfo, setSignupStore } = signupStore();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isDatePickerOpen2, setIsDatePickerOpen2] = useState(false);
  const [isRegionOpen, setRegionOpen] = useState(false);

  const handleDateChange = (date: Date) => {
    if (date) {
      form.setValue('tradingCertificate_term_start', date);
    }
  };
  const handleDateChange2 = (date: Date) => {
    if (date) {
      form.setValue('tradingCertificate_term_finish', date);
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      isCarsayoEmployee: false,
      experenceYear: '1',
    },
  });

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    if (signupInfo?.dealer?.type === 'usedcar') {
      if (
        !data.tradingCertificate_term_start ||
        !data.tradingCertificate_term_finish
      ) {
        form.setError('tradingCertificate_term_start', {
          message: '매매업종 사업증 유효기간을 입력해주세요',
        });
        return;
      } else if (
        data.tradingCertificate_term_start > data.tradingCertificate_term_finish
      ) {
        form.setError('tradingCertificate_term_start', {
          message: '매매업종 사업증 시작일을 확인해주세요',
        });
        return;
      }
    }

    if (signupInfo?.dealer?.type === 'leaseRent') {
      if (!data.leaseRentType) {
        form.setError('leaseRentType', {
          message: '회원 유형을 선택해 주세요',
        });
        return;
      }
    }

    setSignupStore({
      ...signupInfo,
      dealer: {
        ...signupInfo?.dealer,
        email: data.email,
        experenceYear: Number(data.experenceYear),
        isCarsayoEmployee: false,
        organization: data.organization,
        tradingCertificate_term_start: data.tradingCertificate_term_start,
        tradingCertificate_term_finish: data.tradingCertificate_term_finish,
        workingSido: data.region as SidoId,
        joinReason: data.joinReason,
        leaseRentType: data.leaseRentType as 'lease' | 'rent' | 'leaseRent',
      },
    });

    navigate('/auth/signup/dealer/handled');
  };

  return (
    <div className='flex min-h-full grow flex-col p-4 pb-10'>
      <div className='text-lg font-semibold mb-6'>
        카 매니저님의
        <br />
        <span className='text-primary'>추가정보를</span> 입력해주세요.
      </div>
      <div className='p-4 border border-[#E5E5EC]'>
        <p className='font-[600] mb-[12px]'>안내사항</p>
        <ul className='text-[13px] font-[400] mb-[12px]'>
          <li className='mb-2 relative pl-[10px] after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
            빠른 승인을 위해 정확하게 기입해주세요.
          </li>
        </ul>
        <div className='text-[13px] p-2 border-y-[1px] border-[#F1F1F5] bg-[#F7F7FB] flex justify-start items-center gap-[12px]'>
          <img
            src={SpeakerIcon}
            alt='확성기아이콘'
            className='w-4 h-4 object-contain'
          />
          <p>카 매니저님의 정보는 외부에 공개되지 않습니다.</p>
        </div>
      </div>
      <div className='my-6'>
        <Form {...form}>
          <form
            id='signupForm'
            onSubmit={form.handleSubmit(onSubmit)}
            className='space-y-6'
          >
            {signupInfo?.dealer?.type === 'leaseRent' && (
              <FormField
                control={form.control}
                name='leaseRentType'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>회원 유형</FormLabel>
                    <FormControl className='z-[999]'>
                      <Select onValueChange={(value) => field.onChange(value)}>
                        <SelectTrigger>
                          <SelectValue placeholder='회원 유형을 선택해 주세요.' />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value={'lease'}>리스 매니저</SelectItem>
                          <SelectItem value={'rent'}>
                            장기렌트 매니저
                          </SelectItem>
                          <SelectItem value={'leaseRent'}>전체</SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>이메일 주소</FormLabel>
                  <FormControl>
                    <Input
                      className='text-sm'
                      placeholder='이메일 입력'
                      {...field}
                    ></Input>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='region'
              render={({ field }) => (
                <FormItem className='w-full '>
                  <FormLabel>근무 지역</FormLabel>
                  <FormControl className='w-full'>
                    <div className='w-full !z-0'>
                      <Input
                        onPointerDown={() => {
                          setRegionOpen(!isRegionOpen);
                        }}
                        placeholder='선택하세요'
                        value={
                          form.getValues('region')
                            ? `${parseSidoId(form.getValues('region') as SidoId)?.name ?? ''}`
                            : '선택하세요'
                        }
                        className='text-sm'
                      ></Input>
                      <SelectSido
                        isOpen={isRegionOpen}
                        setIsOpen={setRegionOpen}
                        selectSido={(value) => {
                          value
                            ? field.onChange(value)
                            : field.onChange(undefined);
                        }}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='experenceYear'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>경력 연수</FormLabel>
                  <FormControl className='z-[999]'>
                    <Select onValueChange={(value) => field.onChange(value)}>
                      <SelectTrigger>
                        <SelectValue placeholder='1년' />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value={'1'}>1년</SelectItem>
                        <SelectItem value={'2'}>2년</SelectItem>
                        <SelectItem value={'3'}>3년</SelectItem>
                        <SelectItem value={'4'}>4년</SelectItem>
                        <SelectItem value={'5'}>5년</SelectItem>
                        <SelectItem value={'6'}>6년</SelectItem>
                        <SelectItem value={'7'}>7년</SelectItem>
                        <SelectItem value={'8'}>8년</SelectItem>
                        <SelectItem value={'9'}>9년</SelectItem>
                        <SelectItem value={'10'}>10년</SelectItem>
                        <SelectItem value={'11'}>11년</SelectItem>
                        <SelectItem value={'12'}>12년</SelectItem>
                        <SelectItem value={'13'}>13년</SelectItem>
                        <SelectItem value={'14'}>14년</SelectItem>
                        <SelectItem value={'15'}>15년</SelectItem>
                        <SelectItem value={'16'}>16년</SelectItem>
                        <SelectItem value={'17'}>17년</SelectItem>
                        <SelectItem value={'18'}>18년</SelectItem>
                        <SelectItem value={'19'}>19년</SelectItem>
                        <SelectItem value={'20'}>20년</SelectItem>
                        <SelectItem value={'21'}>21년</SelectItem>
                        <SelectItem value={'22'}>22년</SelectItem>
                        <SelectItem value={'23'}>23년</SelectItem>
                        <SelectItem value={'24'}>24년</SelectItem>
                        <SelectItem value={'25'}>25년</SelectItem>
                        <SelectItem value={'26'}>26년</SelectItem>
                        <SelectItem value={'27'}>27년</SelectItem>
                        <SelectItem value={'28'}>28년</SelectItem>
                        <SelectItem value={'29'}>29년</SelectItem>
                        <SelectItem value={'30'}>30년</SelectItem>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='isCarsayoEmployee'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>소속</FormLabel>
                  <FormControl>
                    <>
                      <div className='flex gap-4'>
                        {/* <Select
                          onValueChange={(value) => {
                            if (value === '내부') {
                              form.setValue('organization', '카사요');
                              form.clearErrors('organization');
                            } else {
                              form.setValue('organization', '');
                            }
                            field.onChange(value);
                          }}
                        >
                          <SelectTrigger className='w-[120px]'>
                            <SelectValue placeholder='외부' />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value='외부'>외부</SelectItem>
                            <SelectItem value='내부'>내부</SelectItem>
                          </SelectContent>
                        </Select> */}

                        <FormField
                          control={form.control}
                          name='organization'
                          render={({ field }) => (
                            <FormItem className='flex-auto'>
                              <FormControl>
                                <Input
                                  // disabled={
                                  //   form.getValues('isCarsayoEmployee') ===
                                  //   '내부'
                                  // }
                                  className='text-sm'
                                  placeholder='소속 딜러사 혹은 대리점'
                                  {...field}
                                ></Input>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>

                      {/* <div className='space-y-2.5 bg-[#F7F7FB] p-4 text-desc text-[#767676]'>
                        <div className='flex'>
                          <div className='inline-flex h-[18px] w-2.5 items-center justify-center'>
                            <span className='h-0.5 w-0.5 rounded-full bg-[#767676]'></span>
                          </div>
                          <div>외부 : 카사요 임직원이 아닌 경우</div>
                        </div>
                        <div className='flex'>
                          <div className='inline-flex h-[18px] w-2.5 items-center justify-center'>
                            <span className='h-0.5 w-0.5 rounded-full bg-[#767676]'></span>
                          </div>
                          <div>
                            내부 : 카사요 임직원일 경우 (소속명 수정 불가)
                          </div>
                        </div>
                      </div> */}
                    </>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {signupInfo?.dealer?.type === 'usedcar' && (
              <div className='flex items-center gap-2'>
                <FormField
                  control={form.control}
                  name='tradingCertificate_term_start'
                  render={({ field }) => (
                    <FormItem className='w-full'>
                      <FormLabel>매매업종 사업증 유효기간</FormLabel>
                      <div className='flex'>
                        <FormControl className='w-5'>
                          {/* <DatePicker
                            onChange={(date) => {
                              if (date)
                                form.setValue(
                                  'tradingCertificate_term_start',
                                  date,
                                );
                            }}
                            placeholder='시작일'
                          ></DatePicker> */}
                          <CarsayoDatePicker2
                            isOpen={isDatePickerOpen}
                            onOpen={() => setIsDatePickerOpen(true)}
                            onCancel={() => setIsDatePickerOpen(false)}
                            onChange={handleDateChange}
                            className='w-[42.2vw] pr-[15px !z-0'
                            value={
                              form.getValues('tradingCertificate_term_start') ??
                              undefined
                            }
                            placeholder='시작일'
                          />
                        </FormControl>
                        <div className='px-2 pt-4 flex-none'>-</div>
                        <FormField
                          control={form.control}
                          name='tradingCertificate_term_finish'
                          render={({ field }) => (
                            <FormItem className='w-full'>
                              <FormControl>
                                {/* <DatePicker
                                  onChange={(date) => {
                                    if (date)
                                      form.setValue(
                                        'tradingCertificate_term_finish',
                                        date,
                                      );
                                  }}
                                  placeholder='종료일'
                                ></DatePicker> */}
                                <CarsayoDatePicker2
                                  isOpen={isDatePickerOpen2}
                                  onOpen={() => setIsDatePickerOpen2(true)}
                                  onCancel={() => setIsDatePickerOpen2(false)}
                                  onChange={handleDateChange2}
                                  className='pr-[15px] !z-0'
                                  value={
                                    form.getValues(
                                      'tradingCertificate_term_finish',
                                    ) ?? undefined
                                  }
                                  placeholder='종료일'
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            )}
            <FormField
              control={form.control}
              name='joinReason'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>자기 소개</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder='고객님에게 보여주는 자기소개 문구를 작성해 주세요'
                      {...field}
                    ></Textarea>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
        {/* <DateRangePicker /> */}
      </div>

      <Button className='mt-auto' type='submit' form='signupForm'>
        다음
      </Button>

      <Confirm
        title='본인 인증 실패'
        desc='개인 정보를 다시 확인해주세요.'
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
}
