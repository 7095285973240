import { ReactNode } from 'react';
import { InsuranceCard } from './card';

export type InsuranceCompanyInfo = {
  id: InsuranceCompany;
  data: {
    url: string;
    mUrl: string;
    banner: {
      title: string;
      subtitle: string;
    };
    description: {
      title?: string;
      content: string | ReactNode;
    };
    interestFree?: {
      [key in InsuranceCard]?: {
        title: string;
        description: { content: string; depth?: string[] }[];
      }[];
    };
    footer: {
      content: string;
    };
  };
  design: {
    color: {
      /** Tailwind background 형식 */
      bg: string;
    };
    logo: {
      svg: string;
      // sm: string;
      // bg: string;
    };
  };
};

export type InsuranceCompany =
  | '캐롯손해보험'
  | 'KB손해보험'
  | '메리츠화재'
  | 'DB손해보험'
  | '현대해상'
  | '하나손해보험'
  | 'AXA 손해보험'
  | '한화손해보험'
  | '삼성화재';
export const InsuranceCompanyData: Record<
  InsuranceCompany,
  InsuranceCompanyInfo
> = {
  KB손해보험: {
    id: 'KB손해보험',
    data: {
      url: 'https://direct.kbinsure.co.kr/websquare/promotion.jsp?pid=1090049&code=9607&page=step1',
      mUrl: 'https://mdirect.kbinsure.co.kr/websquare/mobilePromotion.jsp?pid=1090049&code=9607&page=m_step1',
      banner: {
        title: 'KB손해보험',
        subtitle: 'KB 다이렉트 자동차보험',
      },
      description: {
        // title: '최대 3만원 카드결제 혜택 제공',
        content: (
          <div>
            자사 오프라인 대비 평균 18.1% 더 저렴하게!
            <br />
            <span className='font-semibold'>최대 3만원 카드결제 혜택</span>{' '}
            <span className='text-[12px]'>(보험료 30만원 이상 결제 시)</span>
          </div>
        ),
      },
      // interestFree: {
      //   KB국민카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부 (5만원 이상 결제 시)' },
      //         {
      //           content:
      //             '개인신용카드에 한함 (체크, 기업회원, 비씨카드, 선불카드는 대상에서 제외)',
      //         },
      //       ],
      //     },
      //     {
      //       title: 'KB Pay 무이자할부',
      //       description: [
      //         { content: 'KB Pay 2~5개월 무이자할부 (5만원 이상 결제 시)' },
      //         {
      //           content:
      //             'KB Pay 앱 내 KB국민카드로 등록후 KB Pay 결제시 2~5개월 무이자 적용 (다이렉트 고객 전용)',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             '6개월 부분 무이자할부 (5만원 이상 결제 시): 1~3회차 할부수수료 고객부담',
      //         },
      //         {
      //           content:
      //             '10개월 부분 무이자할부 (5만원 이상 결제 시): 1~5회차 할부수수료 고객부담',
      //         },
      //         {
      //           content:
      //             '12개월 부분 무이자할부 (5만원 이상 결제 시): 1~5회차 할부수수료 고객부담',
      //         },
      //         {
      //           content:
      //             '간편결제 (네이버페이, 카카오페이 등) 12개월 부분 무이자 제외',
      //         },
      //       ],
      //     },
      //   ],
      //   삼성카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부 (5만원 이상 결제 시)' },
      //         { content: '법인/체크/선불/충전/기프트카드 제외' },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             '7개월 부분 무이자할부 (5만원 이상 결제 시): 1~3회차 할부수수료 고객부담',
      //         },
      //         {
      //           content:
      //             '11개월 부분 무이자할부 (5만원 이상 결제 시): 1~5회차 할부수수료 고객부담',
      //         },
      //       ],
      //     },
      //   ],
      //   신한카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~3개월 무이자할부 (5만원 이상 결제 시)' },
      //         { content: '법인/체크/선불/기프트카드/BC카드 제외' },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             '10개월 부분 무이자할부 (5만원 이상 결제 시): 1~4회차 할부수수료 고객부담',
      //         },
      //         {
      //           content:
      //             '12개월 부분 무이자할부 (5만원 이상 결제 시): 1~5회차 할부수수료 고객부담',
      //         },
      //       ],
      //     },
      //   ],
      //   농협카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~6개월 무이자할부 (5만원 이상 결제 시)' },
      //         {
      //           content:
      //             'NH농협 개인신용카드 (채움, 비씨). 단, 기업(개인사업자)카드 및 체크카드 제외',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             '7~10개월 부분 무이자할부 (5만원 이상 결제 시): 1~3회차 할부수수료 고객부담',
      //         },
      //         {
      //           content:
      //             '12개월 부분 무이자할부 (5만원 이상 결제 시): 1~4회차 할부수수료 고객부담',
      //         },
      //         {
      //           content:
      //             '18개월 부분 무이자할부 (5만원 이상 결제 시): 1~5회차 할부수수료 고객부담',
      //         },
      //       ],
      //     },
      //   ],
      //   하나카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~6개월 무이자할부 (5만원 이상 결제 시)' },
      //         { content: '법인, 선불, GIFT, 체크, BC, 개인사업자카드 제외' },
      //         {
      //           content:
      //             '카카오뱅크, 토스뱅크 등 타은행에서 발급받은 카드 제외',
      //         },
      //         {
      //           content:
      //             '간편결제건 제외 (네이버페이, 카카오페이, 토스페이 등)',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             '10개월 부분 무이자할부 (5만원 이상 결제 시): 1~4회차 할부수수료 고객부담',
      //         },
      //         {
      //           content:
      //             '12개월 부분 무이자할부 (5만원 이상 결제 시): 1~5회차 할부수수료 고객부담',
      //         },
      //       ],
      //     },
      //   ],
      //   현대카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부 (5만원 이상 결제 시)' },
      //         { content: '법인/체크/선불/기프트카드/하이브리드카드 제외' },
      //       ],
      //     },
      //   ],
      //   롯데카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부 (5만원 이상 결제 시)' },
      //         { content: '법인/체크/선불/기프트카드 제외' },
      //       ],
      //     },
      //   ],
      //   BC카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~3개월 무이자할부 (5만원 이상 결제 시)' },
      //         {
      //           content:
      //             '카드전면에 비씨마크가 있는 경우에 한함. 개인사업자/법인/체크/기프트카드 제외',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             '10개월 부분 무이자할부 (5만원 이상 결제 시): 1~3회차 할부수수료 고객부담',
      //         },
      //         {
      //           content:
      //             '12개월 부분 무이자할부 (5만원 이상 결제 시): 1~4회차 할부수수료 고객부담',
      //         },
      //         { content: '단, sh수협은행은 부분무이자 제외' },
      //       ],
      //     },
      //   ],
      //   우리카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~3개월 무이자할부 (5만원 이상 결제 시)' },
      //         {
      //           content:
      //             '개인신용카드에 한함 (체크, 법인, 기프트카드는 대상에서 제외)',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             '10개월 부분 무이자할부 (5만원 이상 결제 시): 1~3회차 할부수수료 고객부담',
      //         },
      //         {
      //           content:
      //             '12개월 부분 무이자할부 (5만원 이상 결제 시): 1~4회차 할부수수료 고객부담',
      //         },
      //       ],
      //     },
      //   ],
      // },
      footer: {
        content:
          'KB손해보험 준법감시인심의필 제2024-0025호 (2024.01.10 ~ 2025.01.09)',
      },
    },
    design: {
      color: {
        bg: 'bg-[#FFCC00]',
      },
      logo: {
        svg: '/assets/images/static/company/kb.svg',
      },
    },
  },
  메리츠화재: {
    id: '메리츠화재',
    data: {
      url: 'https://store.meritzfire.com/DAP2306CA000002/southeaster.do',
      mUrl: 'https://store.meritzfire.com/DAP2306CA000002/southeaster.do',
      banner: {
        title: '메리츠화재',
        subtitle: '메리츠 다이렉트 자동차보험',
      },
      description: {
        // title: '최대 3만원 카드결제 혜택 제공',
        content: (
          <div>
            마일리지 특약 38% 할인{' '}
            <span className='text-[12px]'>
              (연간 주행거리 3천km이하 주행 시)
            </span>
            <br />
            최근 3년간 무사고 고객 최대 20% 할인
            <br />
            자녀할인 특약 7% 할인{' '}
            <span className='text-[12px]'>
              (만 7세이하(태아포함) 부부 및 1인 한정)
            </span>
            <br />
            블랙박스 장치특약 5~8% 할인{' '}
            <span className='text-[12px]'>(24.05.26일 개시 건부터)</span>
            <br />
            차선이탈경고장치특약 8% 할인{' '}
            <span className='text-[12px]'>(승용차 & 연식 4년 이하 기준)</span>
            <br />
            <span className='font-semibold'>
              카드 결제 혜택 최대 3만원
            </span>{' '}
            제공
          </div>
        ),
      },
      // interestFree: {
      //   KB국민카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부 (5만원 이상 카드승인 시)' },
      //         {
      //           content:
      //             'KB국민체크/기업카드(법인 및 개인사업자)/BC/선불카드 제외',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             '6개월 부분 무이자할부: 할부수수료 1~3회차 고객부담 (4회~카드사 부담)',
      //         },
      //         {
      //           content:
      //             '10/12개월 부분 무이자할부: 할부수수료 1~5회차 고객부담 (6회~카드사 부담)',
      //         },
      //       ],
      //     },
      //   ],

      //   삼성카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부 (5만원 이상 카드승인 시)' },
      //         {
      //           content:
      //             '법인 및 개인사업자/체크/선불/올앳/충전/기프트카드 제외',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             '7개월 부분 무이자할부: 1~3회차 고객부담 (4회~카드사 부담)',
      //         },
      //         {
      //           content:
      //             '11개월 부분 무이자할부: 1~5회차 고객부담 (6회~카드사 부담)',
      //         },
      //       ],
      //     },
      //   ],

      //   신한카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부 (5만원 이상 카드승인 시)' },
      //         {
      //           content: '법인 및 개인사업자/체크/선불/신한BC/기프트카드 제외',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             '10개월 부분 무이자할부: 1~4회차 고객부담 (5회~카드사 부담)',
      //         },
      //         {
      //           content:
      //             '12개월 부분 무이자할부: 1~5회차 고객부담 (6회~카드사 부담)',
      //         },
      //       ],
      //     },
      //   ],

      //   BC카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~3개월 무이자할부' },
      //         {
      //           content:
      //             '법인 및 개인사업자/체크/기프트/카드 앞, 뒷면에 BC로고가 없는 카드 (NON_BC) 제외',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             '10개월 부분 무이자할부 (5만원 이상 결제 시): 1~3회차 고객부담 (4회~카드사 부담)',
      //         },
      //         {
      //           content:
      //             '12개월 부분 무이자할부 (5만원 이상 결제 시): 1~4회차 고객부담 (5회~카드사 부담)',
      //         },
      //       ],
      //     },
      //   ],

      //   현대카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~3개월 무이자 할부 (5만원 이상 결제 시)' },
      //         {
      //           content:
      //             '법인 및 개인사업자/체크/GIFT/선불/하이브리드카드 제외',
      //         },
      //       ],
      //     },
      //   ],
      //   롯데카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부 (5만원 이상 결제 시)' },
      //         { content: '법인 및 개인사업자/체크/선불/올앳/기프트카드 제외' },
      //       ],
      //     },
      //   ],
      // },
      footer: {
        content:
          '메리츠화재 준법감시인심의필 제2023-광고-1427호 (2023.07.14 ~ 2024.07.13)',
      },
    },
    design: {
      color: {
        bg: 'bg-[#EF3B24]',
      },
      logo: {
        svg: '/assets/images/static/company/meritz.svg',
      },
    },
  },
  DB손해보험: {
    id: 'DB손해보험',
    data: {
      url: 'https://www.directdb.co.kr/product/at/pvuatarc/step1/formStepPre.do?partner_code=C522',
      mUrl: 'https://www.directdb.co.kr/product/at/pvuatarc/step1/formStepPre.do?partner_code=C522',
      banner: {
        title: 'DB손해보험',
        subtitle: 'DB다이렉트 자동차보험',
      },
      description: {
        // title: '최대 3만원 카드결제 혜택 제공',
        content: (
          <div>
            개인용 보험료 2.5% 인하{' '}
            <span className='text-[12px]'>
              (보험 시작일 24년 2월 16일 이후)
            </span>
            <br />
            <span className='font-semibold'>
              최대 3만원 카드 결제 혜택
            </span>{' '}
            <span className='text-[12px]'>(캐시백 or 주유권)</span>
          </div>
        ),
      },
      // interestFree: {
      //   KB국민카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~3개월 무이자할부' },
      //         { content: '자동차보험료 계약 건당 5만원 이상 (국민비씨 제외)' },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         { content: '6개월 부분 무이자할부: 1~3회차 이자 고객부담' },
      //         { content: '10개월 부분 무이자할부: 1~5회차 이자 고객부담' },
      //         { content: '12개월 부분 무이자할부: 1~5회차 이자 고객부담' },
      //         { content: '자동차보험료 계약 건당 5만원 이상 (국민비씨 제외)' },
      //       ],
      //     },
      //   ],

      //   삼성카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~6개월 무이자할부' },
      //         { content: '자동차보험료 계약 건당 5만원 이상' },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         { content: '7개월 부분 무이자 할부: 1~3회차 이자 고객부담' },
      //         { content: '11개월 부분 무이자 할부: 1~5회차 이자 고객부담' },
      //         { content: '자동차보험료 계약 건당 5만원 이상' },
      //       ],
      //     },
      //   ],

      //   신한카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부' },
      //         { content: '자동차보험 계약 건당 5만원 이상 (신한비씨 제외)' },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         { content: '10개월 부분 무이자 할부: 1~4회차 이자 고객부담' },
      //         { content: '12개월 부분 무이자 할부: 1~5회차 이자 고객부담' },
      //         { content: '자동차보험 계약 건당 5만원 이상 (신한비씨 제외)' },
      //       ],
      //     },
      //   ],

      //   농협카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~6개월 무이자할부' },
      //         { content: '자동차보험 계약 건당 5만원 이상 (농협비씨 제외)' },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         { content: '7~10개월 부분 무이자할부: 1~3회차 수수료 고객부담' },
      //         { content: '12개월 부분 무이자할부: 1~4회차 수수료 고객부담' },
      //         { content: '18개월 부분 무이자할부: 1~5회차 수수료 고객부담' },
      //         { content: '24개월 부분 무이자할부: 1~6회차 수수료 고객부담' },
      //         { content: '자동차보험 계약 건당 5만원 이상 (농협비씨 제외)' },
      //       ],
      //     },
      //   ],

      //   현대카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2, 3, 6개월 무이자할부' },
      //         { content: '자동차보험료 계약 건당 5만원 이상 (슬림 할부 없음)' },
      //       ],
      //     },
      //   ],

      //   하나카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '6개월 무이자할부' },
      //         { content: '자동차보험료 계약 건당 5만원 이상 (하나비씨 제외)' },
      //       ],
      //     },
      //   ],
      // },
      footer: {
        content: '손해보험협회 심의필 제116618호(2024.02.21 ~ 2025.02.19)',
      },
    },
    design: {
      color: {
        bg: 'bg-[#2FA752]',
      },
      logo: {
        svg: '/assets/images/static/company/db.svg',
      },
    },
  },
  현대해상: {
    id: '현대해상',
    data: {
      url: 'https://direct.hi.co.kr/service.do?m=28680681ee&cnc_no=605&media_no=B430&companyId=605',
      mUrl: 'https://direct.hi.co.kr/service.do?m=28680681ee&cnc_no=605&media_no=B430&companyId=605',
      banner: {
        title: '현대해상',
        subtitle: '현대해상 다이렉트 자동차보험',
      },
      description: {
        // title: '최대 3만원 카드결제 혜택 제공',
        content: (
          <div>
            자동차 보험 신규 가입 <br />
            <span className='font-semibold'>카드결제 혜택 최대 3만원!</span>
          </div>
        ),
      },
      // interestFree: {
      //   BC카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~3개월 무이자할부' },
      //         { content: '5만원 이상 결제 시 (단일 결제 승인건 기준)' },
      //         { content: '법인(기업)/체크/선불/GIFT 카드 제외' },
      //         {
      //           content:
      //             '간편결제건 제외 (네이버페이, 카카오페이, 토스페이 등)',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             '10개월 부분 무이자할부(5만원 이상 결제 시): 1~3회차 할부수수료 고객부담',
      //         },
      //         {
      //           content:
      //             '12개월 부분 무이자할부(5만원 이상 결제 시): 1~4회차 할부수수료 고객부담',
      //         },
      //         { content: '5만원 이상 결제 시 (단일 결제 승인건 기준)' },
      //         { content: '법인(기업)/체크/선불/GIFT 카드 제외' },
      //         {
      //           content:
      //             '간편결제건 제외 (네이버페이, 카카오페이, 토스페이 등)',
      //         },
      //       ],
      //     },
      //   ],

      //   삼성카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부' },
      //         { content: '5만원 이상 결제 시 (단일 결제 승인건 기준)' },
      //         { content: '법인(기업)/체크/선불/GIFT/충전 카드 제외' },
      //         {
      //           content:
      //             '간편결제건 제외 (네이버페이, 카카오페이, 토스페이 등)',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         { content: '7개월 부분 무이자 할부: 1~3회차 이자 고객 부담' },
      //         { content: '11개월 부분 무이자 할부: 1~5회차 이자 고객 부담' },
      //         { content: '5만원 이상 결제 시 (단일 결제 승인건 기준)' },
      //         { content: '법인(기업)/체크/선불/GIFT/충전 카드 제외' },
      //         {
      //           content:
      //             '간편결제건 제외 (네이버페이, 카카오페이, 토스페이 등)',
      //         },
      //       ],
      //     },
      //   ],

      //   KB국민카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부' },
      //         { content: '자동차보험료 계약 건당 5만원 이상 (국민비씨 제외)' },
      //         { content: '5만원 이상 결제 시 (단일 결제 승인건 기준)' },
      //         {
      //           content:
      //             '법인(기업)/체크/선불/GIFT/충전/개인사업자카드/BC카드 제외',
      //         },
      //         {
      //           content:
      //             '간편결제건 제외 (네이버페이, 카카오페이, 토스페이 등)',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         { content: '6개월 부분 무이자할부: 1~3회차 이자 고객 부담' },
      //         { content: '10개월 부분 무이자할부: 1~5회차 이자 고객 부담' },
      //         { content: '12개월 부분 무이자할부: 1~5회차 이자 고객 부담' },
      //         { content: '5만원 이상 결제 시 (단일 결제 승인건 기준)' },
      //         {
      //           content:
      //             '법인(기업)/체크/선불/GIFT/충전/개인사업자카드/BC카드 제외',
      //         },
      //         {
      //           content:
      //             '간편결제건 제외 (네이버페이, 카카오페이, 토스페이 등)',
      //         },
      //       ],
      //     },
      //   ],

      //   농협카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~6개월 무이자할부' },
      //         { content: '5만원 이상 결제 시(단일 결제 승인건 기준)' },
      //         { content: '법인(기업)/체크/선불/GIFT/개인사업자 카드 제외' },
      //         {
      //           content: '간편결제건 제외(네이버페이, 카카오페이, 토스페이 등)',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         { content: '7~10개월 부분 무이자할부: 1~3회차 이자 고객부담' },
      //         { content: '5만원 이상 결제 시(단일 결제 승인건 기준)' },
      //         { content: '법인(기업)/체크/선불/GIFT/개인사업자 카드 제외' },
      //         {
      //           content: '간편결제건 제외(네이버페이, 카카오페이, 토스페이 등)',
      //         },
      //       ],
      //     },
      //   ],

      //   신한카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부' },
      //         { content: '5만원 이상 결제 시(단일 결제 승인건 기준)' },
      //         {
      //           content:
      //             '법인(기업)/체크/선불/GIFT/개인사업자카드/BC 카드 제외',
      //         },
      //         {
      //           content: '간편결제건 제외(네이버페이, 카카오페이, 토스페이 등)',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         { content: '10개월 부분 무이자할부: 1~4회차 이자 고객부담' },
      //         { content: '12개월 부분 무이자할부: 1~5회차 이자 고객부담' },
      //         { content: '5만원 이상 결제 시(단일 결제 승인건 기준)' },
      //         {
      //           content:
      //             '법인(기업)/체크/선불/GIFT/개인사업자카드/BC 카드 제외',
      //         },
      //         {
      //           content: '간편결제건 제외(네이버페이, 카카오페이, 토스페이 등)',
      //         },
      //       ],
      //     },
      //   ],

      //   하나카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~3개월 무이자할부' },
      //         { content: '5만원 이상 결제 시(단일 결제 승인건 기준)' },
      //         {
      //           content:
      //             '법인(기업)/체크/선불/GIFT/개인사업자카드/BC 카드 제외',
      //         },
      //         {
      //           content: '간편결제건 제외(네이버페이, 카카오페이, 토스페이 등)',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         { content: '6개월 부분 무이자할부: 1~3회차 이자 고객부담' },
      //         { content: '10개월 부분 무이자할부: 1~4회차 이자 고객부담' },
      //         { content: '12개월 부분 무이자할부: 1~5회차 이자 고객부담' },
      //         { content: '5만원 이상 결제 시(단일 결제 승인건 기준)' },
      //         {
      //           content:
      //             '법인(기업)/체크/선불/GIFT/개인사업자카드/BC 카드 제외',
      //         },
      //         {
      //           content: '간편결제건 제외(네이버페이, 카카오페이, 토스페이 등)',
      //         },
      //       ],
      //     },
      //   ],

      //   현대카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~3개월 무이자할부' },
      //         { content: '5만원 이상 결제 시(단일 결제 승인건 기준)' },
      //         { content: '법인(기업)/체크/선불/GIFT/하이브리드 카드 제외' },
      //         {
      //           content: '간편결제건 제외(네이버페이, 카카오페이, 토스페이 등)',
      //         },
      //       ],
      //     },
      //   ],
      //   우리카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~3개월 무이자할부' },
      //         { content: '5만원 이상 결제 시(단일 결제 승인건 기준)' },
      //         { content: '법인(기업)/체크/선불/GIFT/개인사업자 카드 제외' },
      //         {
      //           content: '간편결제건 제외(네이버페이, 카카오페이, 토스페이 등)',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             '6개월 부분 무이자할부(5만원 이상 결제 시): 1~3회차 고객부담',
      //         },
      //         {
      //           content:
      //             '10개월 부분 무이자할부(5만원 이상 결제 시): 1~4회차 고객부담',
      //         },
      //         { content: '5만원 이상 결제 시(단일 결제 승인건 기준)' },
      //         { content: '법인(기업)/체크/선불/GIFT/개인사업자 카드 제외' },
      //         {
      //           content: '간편결제건 제외(네이버페이, 카카오페이, 토스페이 등)',
      //         },
      //       ],
      //     },
      //   ],

      //   롯데카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부' },
      //         { content: '5만원 이상 결제 시(단일 결제 승인건 기준)' },
      //         { content: '법인(기업)/체크/선불/GIFT 카드 제외' },
      //         {
      //           content: '간편결제건 제외(네이버페이, 카카오페이, 토스페이 등)',
      //         },
      //       ],
      //     },
      //   ],
      // },
      footer: {
        content:
          '현대해상 준법감시인확인필 제20240121호 (2024.01.12 ~ 2025.01.11)',
      },
    },
    design: {
      color: {
        bg: 'bg-[#F18800]',
      },
      logo: {
        svg: '/assets/images/static/company/hyundai.svg',
      },
    },
  },
  하나손해보험: {
    id: '하나손해보험',
    data: {
      url: 'https://www.hanainsure.co.kr/ext/getCal?j=mobilcar&t=car',
      mUrl: 'https://www.hanainsure.co.kr/ext/getCal?j=mobilcar&t=car',
      banner: {
        title: '하나손해보험',
        subtitle: '하나 다이렉트 자동차보험',
      },
      description: {
        // title: '자사 오프라인 대비 평균 15.2% 더 저렴하게',
        content: (
          <div>
            자사 오프라인대비 평균 15.2% 더 저렴하게!
            <br />
            다이렉트로 가입
          </div>
        ),
      },
      // interestFree: {
      //   BC카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2, 3개월 무이자할부' },
      //         { content: '체크카드, non-BC카드, 기프트카드, 법인카드 제외' },
      //         { content: '개인법인카드/개인사업자카드 제외' },
      //       ],
      //     },
      //   ],

      //   삼성카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부' },
      //         { content: '법인/선불/체크/올앳/기프트카드 제외' },
      //       ],
      //     },
      //   ],

      //   KB국민카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부' },
      //         { content: 'KB국민체크카드, 기업카드(법인 및 개인사업자) 제외' },
      //         { content: 'BC카드 및 선불카드 제외' },
      //       ],
      //     },
      //   ],

      //   농협카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~6개월 무이자할부' },
      //         {
      //           content:
      //             '법인/체크/기프트카드/개인법인카드/개인사업자카드 제외',
      //         },
      //         {
      //           content: '할부개월 변경 및 일시불에서 전환시 무이자 적용 불가',
      //         },
      //       ],
      //     },
      //   ],

      //   신한카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부' },
      //         { content: '법인/선불/체크/신한BC/기프트카드 제외' },
      //       ],
      //     },
      //   ],

      //   현대카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2, 3개월 무이자할부' },
      //         { content: '법인/선불/기프트카드/선불카드/하이브리드카드 제외' },
      //         { content: '무이자 할부시 적립 및 할인 제외' },
      //       ],
      //     },
      //   ],

      //   롯데카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부' },
      //         { content: '법인/체크/선불/올앳/기프트카드 제외' },
      //       ],
      //     },
      //   ],
      // },
      footer: {
        content: '손해보험협회심의필 제130768호 (2024.08.20 ~ 2025.08.19)',
      },
    },
    design: {
      color: {
        bg: 'bg-[#298478]',
      },
      logo: {
        svg: '/assets/images/static/company/hana.svg',
      },
    },
  },
  'AXA 손해보험': {
    id: 'AXA 손해보험',
    data: {
      url: 'https://www.axa.co.kr/index_email_relay.jsp?sm_flag=N&site_acq_src=0103196&cmpid=co_indirect_dis_sb_auto&relay_lang_type=ko&go_url=/ActionControler.action?screenID=SHAI0000&actionID=I01&utm_source=indirect&utm_medium=affiliate&utm_campaign=auto&cnc_no=carsayo',
      mUrl: 'https://m.axa.co.kr/indexm_email_relay.jsp?sm_flag=N&site_acq_src=0810406&cmpid=co_buycar_dis_sb_auto&relay_lang_type=ko&utm_source=buycar&utm_medium=affiliate&utm_campaign=auto&go_url=/ActionControler.action?screenID=SHAI0000&actionID=I01&utm_source=buycar&utm_medium=affiliate&utm_campaign=auto&cnc_no=carsayo',
      banner: {
        title: 'AXA 손해보험',
        subtitle: 'AXA 다이렉트 자동차보험',
      },
      description: {
        // title: 'GS 주유권 최대 3만원 제공',
        content: (
          <div>
            자사 상담원 가입 채널 대비 평균 9.4% 더 저렴하게!
            <br />
            다이렉트로 가입
          </div>
        ),
      },
      // interestFree: {
      //   KB국민카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부' },
      //         {
      //           content:
      //             '간편결제 시 신용카드 무이자 할부는 카카오페이, 네이버페이, 페이코 등에서 제공하는 기준에 따라 적용됩니다.',
      //         },
      //         {
      //           content:
      //             '본 신용카드 무이자할부 정책과 상이할 수 있으니 간편결제 창에서 확인 후 진행하시기 바랍니다.',
      //         },
      //         {
      //           content:
      //             '상기 서비스는 카드사의 사정에 따라 서비스 기간 중도에 변경되거나 종료될 수 있습니다.',
      //         },
      //         {
      //           content:
      //             '보험료 5만원 이상 결제 시 상기 혜택 적용이 가능합니다. (단, 개인신용카드만 가능)',
      //         },
      //         {
      //           content:
      //             '국민BC, 체크카드, 기업카드, 개인사업자카드, 선불카드, 기프트카드, 하이브리드카드, 올앳카드는 무이자할부에서 제외 대상입니다.',
      //         },
      //         { content: '무이자할부건에 대해 포인트 및 마일리지 혜택 제외' },
      //         {
      //           content:
      //             '간편결제(N Pay, 카카오페이, 스마일페이 등)에 등록하여 간편결제를 통해 사용된 내역은 무이자할부 제외',
      //         },
      //       ],
      //     },
      //     {
      //       title: 'KB Pay(구 앱카드) 무이자할부',
      //       description: [
      //         { content: '2~5개월 무이자할부' },
      //         {
      //           content:
      //             '간편결제 시 신용카드 무이자 할부는 카카오페이, 네이버페이, 페이코 등에서 제공하는 기준에 따라 적용됩니다.',
      //         },
      //         {
      //           content:
      //             '본 신용카드 무이자할부 정책과 상이할 수 있으니 간편결제 창에서 확인 후 진행하시기 바랍니다.',
      //         },
      //         {
      //           content:
      //             '상기 서비스는 카드사의 사정에 따라 서비스 기간 중도에 변경되거나 종료될 수 있습니다.',
      //         },
      //         {
      //           content:
      //             '보험료 5만원 이상 결제 시 상기 혜택 적용이 가능합니다. (단, 개인신용카드만 가능)',
      //         },
      //         {
      //           content:
      //             '체크카드, 기업카드, 개인사업자카드, 선불카드, 기프트카드, 하이브리드카드, 올앳카드는 무이자할부에서 제외 대상입니다.',
      //         },
      //         { content: '무이자할부건에 대해 포인트 및 마일리지 혜택 제외' },
      //         {
      //           content:
      //             '간편결제(N Pay, 카카오페이, 스마일페이 등)에 등록하여 간편결제를 통해 사용된 내역은 무이자할부 제외',
      //         },
      //       ],
      //     },
      //     {
      //       title: '국민카드 다이어트할부',
      //       description: [
      //         { content: '6개월 다이어트할부: 1~3회차 이자 고객부담' },
      //         { content: '10개월 다이어트할부: 1~5회차 이자 고객부담' },
      //         {
      //           content:
      //             '간편결제 시 신용카드 무이자 할부는 카카오페이, 네이버페이, 페이코 등에서 제공하는 기준에 따라 적용됩니다.',
      //         },
      //         {
      //           content:
      //             '본 신용카드 무이자할부 정책과 상이할 수 있으니 간편결제 창에서 확인 후 진행하시기 바랍니다.',
      //         },
      //         {
      //           content:
      //             '상기 서비스는 카드사의 사정에 따라 서비스 기간 중도에 변경되거나 종료될 수 있습니다.',
      //         },
      //         {
      //           content:
      //             '보험료 5만원 이상 결제 시 상기 혜택 적용이 가능합니다. (단, 개인신용카드만 가능)',
      //         },
      //         {
      //           content:
      //             '체크카드, 기업카드, 개인사업자카드, 선불카드, 기프트카드, 하이브리드카드, 올앳카드는 무이자할부에서 제외 대상입니다.',
      //         },
      //         { content: '무이자할부건에 대해 포인트 및 마일리지 혜택 제외' },
      //         {
      //           content:
      //             '간편결제(N Pay, 카카오페이, 스마일페이 등)에 등록하여 간편결제를 통해 사용된 내역은 무이자할부 제외',
      //         },
      //       ],
      //     },
      //   ],

      //   삼성카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부' },
      //         {
      //           content:
      //             '간편결제 시 신용카드 무이자 할부는 카카오페이, 네이버페이, 페이코 등에서 제공하는 기준에 따라 적용됩니다.',
      //         },
      //         {
      //           content:
      //             '본 신용카드 무이자할부 정책과 상이할 수 있으니 간편결제 창에서 확인 후 진행하시기 바랍니다.',
      //         },
      //         {
      //           content:
      //             '상기 서비스는 카드사의 사정에 따라 서비스 기간 중도에 변경되거나 종료될 수 있습니다.',
      //         },
      //         {
      //           content:
      //             '보험료 5만원 이상 결제 시 상기 혜택 적용이 가능합니다. (단, 개인신용카드만 가능)',
      //         },
      //         {
      //           content:
      //             '체크카드, 기업카드, 개인사업자카드, 선불카드, 기프트카드, 하이브리드카드, 올앳카드는 모두 무이자할부에서 제외 대상입니다.',
      //         },
      //         { content: '무이자할부건에 대해 포인트 및 마일리지 혜택 제외' },
      //         {
      //           content:
      //             '간편결제(N Pay, 카카오페이, 스마일페이 등)에 등록하여 간편결제를 통해 사용된 내역은 무이자할부 제외',
      //         },
      //       ],
      //     },
      //     {
      //       title: '다이어트 할부',
      //       description: [
      //         { content: '7개월 부분 다이어트할부: 1~3회차 이자 고객부담' },
      //         { content: '11개월 부분 다이어트할부: 1~5회차 이자 고객부담' },
      //         {
      //           content:
      //             '간편결제 시 신용카드 무이자 할부는 카카오페이, 네이버페이, 페이코 등에서 제공하는 기준에 따라 적용됩니다.',
      //         },
      //         {
      //           content:
      //             '상기 서비스는 카드사의 사정에 따라 서비스 기간 중도에 변경되거나 종료될 수 있습니다.',
      //         },
      //         {
      //           content:
      //             '보험료 5만원 이상 결제 시 상기 혜택 적용이 가능합니다. (단, 개인신용카드만 가능)',
      //         },
      //         {
      //           content:
      //             '체크카드, 기업카드, 개인사업자카드, 선불카드, 기프트카드, 하이브리드카드, 올앳카드는 모두 무이자할부에서 제외 대상입니다.',
      //         },
      //         { content: '무이자할부건에 대해 포인트 및 마일리지 혜택 제외' },
      //         {
      //           content:
      //             '간편결제(N Pay, 카카오페이, 스마일페이 등)에 등록하여 간편결제를 통해 사용된 내역은 무이자할부 제외',
      //         },
      //       ],
      //     },
      //   ],

      //   신한카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부 (신한BC 제외)' },
      //         {
      //           content:
      //             '간편결제 시 신용카드 무이자 할부는 카카오페이, 네이버페이, 페이코 등에서 제공하는 기준에 따라 적용됩니다.',
      //         },
      //         {
      //           content:
      //             '본 신용카드 무이자할부 정책과 상이할 수 있으니 간편결제 창에서 확인 후 진행하시기 바랍니다.',
      //         },
      //         {
      //           content:
      //             '상기 서비스는 카드사의 사정에 따라 서비스 기간 중도에 변경되거나 종료될 수 있습니다.',
      //         },
      //         {
      //           content:
      //             '보험료 5만원 이상 결제 시 상기 혜택 적용이 가능합니다. (단, 개인신용카드만 가능)',
      //         },
      //         {
      //           content:
      //             '신한BC, 체크카드, 기업카드, 개인사업자카드, 선불카드, 기프트카드, 하이브리드카드, 올앳카드는 모두 무이자할부에서 제외 대상입니다.',
      //         },
      //         { content: '무이자할부건에 대해 포인트 및 마일리지 혜택 제외' },
      //         {
      //           content:
      //             '간편결제(N Pay, 카카오페이, 스마일페이 등)에 등록하여 간편결제를 통해 사용된 내역은 무이자할부 제외',
      //         },
      //       ],
      //     },
      //     {
      //       title: '신한다이어트할부',
      //       description: [
      //         { content: '10개월 다이어트할부: 1~4회차 할부수수료 고객부담' },
      //         { content: '12개월 다이어트할부: 1~5회차 할부수수료 고객부담' },
      //         {
      //           content:
      //             '간편결제 시 신용카드 무이자 할부는 카카오페이, 네이버페이, 페이코 등에서 제공하는 기준에 따라 적용됩니다.',
      //         },
      //         {
      //           content:
      //             '상기 서비스는 카드사의 사정에 따라 서비스 기간 중도에 변경되거나 종료될 수 있습니다.',
      //         },
      //         {
      //           content:
      //             '보험료 5만원 이상 결제 시 상기 혜택 적용이 가능합니다. (단, 개인신용카드만 가능)',
      //         },
      //         {
      //           content:
      //             '체크카드, 기업카드, 개인사업자카드, 선불카드, 기프트카드, 하이브리드카드, 올앳카드는 모두 무이자할부에서 제외 대상입니다.',
      //         },
      //         { content: '무이자할부건에 대해 포인트 및 마일리지 혜택 제외' },
      //         {
      //           content:
      //             '간편결제(N Pay, 카카오페이, 스마일페이 등)에 등록하여 간편결제를 통해 사용된 내역은 무이자할부 제외',
      //         },
      //       ],
      //     },
      //   ],
      //   하나카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         {
      //           content: '10개월 다이어트할부 (1~3회차 이자 고객부담)',
      //         },
      //         {
      //           content: '12개월 다이어트할부 (1~4회차 이자 고객부담)',
      //         },
      //         {
      //           content:
      //             '간편결제 시 신용카드 무이자 할부는 카카오페이, 네이버페이, 페이코 등에서 제공하는 기준에 따라 적용됩니다.',
      //         },
      //         {
      //           content:
      //             '본 신용카드 무이자할부 정책과 상이할 수 있으니 간편결제 창에서 확인 후 진행하시기 바랍니다.',
      //         },
      //         {
      //           content:
      //             '상기 서비스는 카드사의 사정에 따라 서비스 기간 중도에 변경되거나 종료될 수 있습니다.',
      //         },
      //         {
      //           content:
      //             '보험료 5만원 이상 결제 시 상기 혜택 적용이 가능합니다. (단, 개인신용카드만 가능)',
      //         },
      //         {
      //           content:
      //             '체크카드, 기업카드, 개인사업자카드, 선불카드, 기프트카드, 하이브리드카드, 올앳카드는 모두 무이자할부에서 제외 대상입니다.',
      //         },
      //         { content: '무이자할부건에 대해 포인트 및 마일리지 혜택 제외' },
      //         {
      //           content:
      //             '간편결제(N Pay, 카카오페이, 스마일페이 등)에 등록하여 간편결제를 통해 사용된 내역은 무이자할부 제외',
      //         },
      //       ],
      //     },
      //     {
      //       title: '하나 다이어트할부',
      //       description: [
      //         {
      //           content: '10개월 부분 무이자할부: 1~4회차 이자 고객부담',
      //         },
      //         {
      //           content: '12개월 부분 무이자할부: 1~5회차 이자 고객부담',
      //         },
      //         {
      //           content:
      //             '간편결제 시 신용카드 무이자 할부는 카카오페이, 네이버페이, 페이코 등에서 제공하는 기준에 따라 적용됩니다.',
      //         },
      //         {
      //           content:
      //             '상기 서비스는 카드사의 사정에 따라 서비스 기간 중도에 변경되거나 종료될 수 있습니다.',
      //         },
      //         {
      //           content:
      //             '보험료 5만원 이상 결제 시 상기 혜택 적용이 가능합니다. (단, 개인신용카드만 가능)',
      //         },
      //         {
      //           content:
      //             '체크카드, 기업카드, 개인사업자카드, 선불카드, 기프트카드, 하이브리드카드, 올앳카드는 모두 무이자할부에서 제외 대상입니다.',
      //         },
      //         { content: '무이자할부건에 대해 포인트 및 마일리지 혜택 제외' },
      //         {
      //           content:
      //             '간편결제(N Pay, 카카오페이, 스마일페이 등)에 등록하여 간편결제를 통해 사용된 내역은 무이자할부 제외',
      //         },
      //       ],
      //     },
      //   ],
      //   현대카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~3개월 무이자할부' },
      //         {
      //           content:
      //             '간편결제 시 신용카드 무이자 할부는 카카오페이, 네이버페이, 페이코 등에서 제공하는 기준에 따라 적용됩니다.',
      //         },
      //         {
      //           content:
      //             '본 신용카드 무이자할부 정책과 상이할 수 있으니 간편결제 창에서 확인 후 진행하시기 바랍니다.',
      //         },
      //         {
      //           content:
      //             '상기 서비스는 카드사의 사정에 따라 서비스 기간 중도에 변경되거나 종료될 수 있습니다.',
      //         },
      //         {
      //           content:
      //             '보험료 5만원 이상 결제 시 상기 혜택 적용이 가능합니다. (단, 개인신용카드만 가능)',
      //         },
      //         {
      //           content:
      //             '체크카드, 기업카드, 개인사업자카드, 선불카드, 기프트카드, 하이브리드카드, 올앳카드는 모두 무이자할부에서 제외 대상입니다.',
      //         },
      //         { content: '무이자할부건에 대해 포인트 및 마일리지 혜택 제외' },
      //         {
      //           content:
      //             '간편결제(N Pay, 카카오페이, 스마일페이 등)에 등록하여 간편결제를 통해 사용된 내역은 무이자할부 제외',
      //         },
      //       ],
      //     },
      //   ],
      //   롯데카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부' },
      //         {
      //           content:
      //             '간편결제 시 신용카드 무이자 할부는 카카오페이, 네이버페이, 페이코 등에서 제공하는 기준에 따라 적용됩니다.',
      //         },
      //         {
      //           content:
      //             '본 신용카드 무이자할부 정책과 상이할 수 있으니 간편결제 창에서 확인 후 진행하시기 바랍니다.',
      //         },
      //         {
      //           content:
      //             '상기 서비스는 카드사의 사정에 따라 서비스 기간 중도에 변경되거나 종료될 수 있습니다.',
      //         },
      //         {
      //           content:
      //             '보험료 5만원 이상 결제 시 상기 혜택 적용이 가능합니다. (단, 개인신용카드만 가능)',
      //         },
      //         {
      //           content:
      //             '체크카드, 기업카드, 개인사업자카드, 선불카드, 기프트카드, 하이브리드카드, 올앳카드는 모두 무이자할부에서 제외 대상입니다.',
      //         },
      //         { content: '무이자할부건에 대해 포인트 및 마일리지 혜택 제외' },
      //         {
      //           content:
      //             '간편결제(N Pay, 카카오페이, 스마일페이 등)에 등록하여 간편결제를 통해 사용된 내역은 무이자할부 제외',
      //         },
      //       ],
      //     },
      //   ],

      //   BC카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~3개월 무이자할부' },
      //         {
      //           content:
      //             '간편결제 시 신용카드 무이자 할부는 카카오페이, 네이버페이, 페이코 등에서 제공하는 기준에 따라 적용됩니다.',
      //         },
      //         {
      //           content:
      //             '본 신용카드 무이자할부 정책과 상이할 수 있으니 간편결제 창에서 확인 후 진행하시기 바랍니다.',
      //         },
      //         {
      //           content:
      //             '상기 서비스는 카드사의 사정에 따라 서비스 기간 중도에 변경되거나 종료될 수 있습니다.',
      //         },
      //         {
      //           content:
      //             '보험료 5만원 이상 결제 시 상기 혜택 적용이 가능합니다. (단, 개인신용카드만 가능)',
      //         },
      //         {
      //           content:
      //             '체크카드, 기업카드, 개인사업자카드, 선불카드, 기프트카드, 하이브리드카드, 올앳카드는 모두 무이자할부에서 제외 대상입니다.',
      //         },
      //         { content: '무이자할부건에 대해 포인트 및 마일리지 혜택 제외' },
      //         {
      //           content:
      //             '간편결제(N Pay, 카카오페이, 스마일페이 등)에 등록하여 간편결제를 통해 사용된 내역은 무이자할부 제외',
      //         },
      //         {
      //           content:
      //             '적용회원사: 우리카드, SC제일은행, 하나카드, NH농협카드, IBK기업은행, KB국민카드, DGB대구은행, BNK부산은행, BNK경남은행, 한국씨티은행, 신한카드에서 발행된 BC카드 및 BC바로카드',
      //         },
      //       ],
      //     },
      //     {
      //       title: '다이어트 할부',
      //       description: [
      //         {
      //           content: '10개월 다이어트할부 (1~3회차 이자 고객부담)',
      //         },
      //         {
      //           content: '12개월 다이어트할부 (1~4회차 이자 고객부담)',
      //         },
      //         {
      //           content:
      //             '간편결제 시 신용카드 무이자 할부는 카카오페이, 네이버페이, 페이코 등에서 제공하는 기준에 따라 적용됩니다.',
      //         },
      //         {
      //           content:
      //             '본 신용카드 무이자할부 정책과 상이할 수 있으니 간편결제 창에서 확인 후 진행하시기 바랍니다.',
      //         },
      //         {
      //           content:
      //             '상기 서비스는 카드사의 사정에 따라 서비스 기간 중도에 변경되거나 종료될 수 있습니다.',
      //         },
      //         {
      //           content:
      //             '보험료 5만원 이상 결제 시 상기 혜택 적용이 가능합니다. (단, 개인신용카드만 가능)',
      //         },
      //         {
      //           content:
      //             '체크카드, 기업카드, 개인사업자카드, 선불카드, 기프트카드, 하이브리드카드, 올앳카드는 모두 무이자할부에서 제외 대상입니다.',
      //         },
      //         { content: '무이자할부건에 대해 포인트 및 마일리지 혜택 제외' },
      //         {
      //           content:
      //             '간편결제(N Pay, 카카오페이, 스마일페이 등)에 등록하여 간편결제를 통해 사용된 내역은 무이자할부 제외',
      //         },
      //         {
      //           content:
      //             '단, 수협은 다이어트할부 적용되지 않으며 무이자할부 2~3개월만 가능합니다.',
      //         },
      //       ],
      //     },
      //   ],
      // },
      footer: {
        content: '손해보험협회 심의필 제128948호 (2024.07.24 ~ 2025.07.23)',
      },
    },
    design: {
      color: {
        bg: 'bg-[#00008F]',
      },
      logo: {
        svg: '/assets/images/static/company/axa.svg',
      },
    },
  },
  한화손해보험: {
    id: '한화손해보험',
    data: {
      url: 'https://mall.hwgeneralins.com/landing.do?cmpgId=1000001675',
      mUrl: 'https://mall.hwgeneralins.com/landing.do?cmpgId=1000001675',
      banner: {
        title: '한화손해보험',
        subtitle: '한화 다이렉트 자동차보험',
      },
      description: {
        // title: '자사 오프라인 대비 평균 15.2% 더 저렴하게',
        content: (
          <div>
            자사 오프라인 대비 평균 15% 더 저렴하게!
            <br />
            다이렉트로 가입
          </div>
        ),
      },
      // interestFree: {
      //   BC카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '5만원 이상 결제시 2~3개월 무이자할부' },
      //         {
      //           content:
      //             '제외카드 : 사업자/기프트/비씨외타사발급카드(실물카드에 BC로고 없는 카드)',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         { content: '10개월 부분무이자: 1~3회차 고객부담' },
      //         { content: '12개월 부분무이자: 1~4회차 고객부담' },
      //         {
      //           content:
      //             '제외카드 : 사업자/기프트/비씨외타사발급카드(실물카드에 BC로고 없는 카드) 단 sh수협은행 부분무이자 제외',
      //         },
      //       ],
      //     },
      //   ],

      //   삼성카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부' },
      //         { content: '법인/선불/체크/충전/기프트카드 제외' },
      //         { content: '5만원이상 결제시' },
      //       ],
      //     },
      //     {
      //       title: '다이어트 할부',
      //       description: [
      //         { content: '7개월 : 1~3개월 고객부담' },
      //         { content: '11개월 : 1~5개월 고객부담' },
      //       ],
      //     },
      //   ],

      //   KB국민카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부' },
      //         { content: '5만원 이상 결제시' },
      //         {
      //           content:
      //             '체크/사업자/비씨/선불카드/타사발급카드(전북은행 외..) 제외',
      //         },
      //         {
      //           content:
      //             '간편결제(N페이, 카카오페이, 스마일페이 등) 로 등록된 가맹점 무이자 기간동일',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         { content: '6개월 부분무이자 : 1~3회차 고객부담' },
      //         { content: '10개월 부분무이자 : 1~5회차 고객부담' },
      //         {
      //           content:
      //             '체크/사업자/비씨/선불카드/타사발급카드(전북은행 외..) 제외',
      //         },
      //         {
      //           content:
      //             '간편결제(N페이, 카카오페이, 스마일페이 등) 로 등록된 가맹점 부분무이자 기간동일',
      //         },
      //       ],
      //     },
      //   ],
      //   농협카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         {
      //           content:
      //             'NH농협카드(채움,BC) 5만원 이상 결제시 2~6개월 무이자할부',
      //         },
      //         { content: '체크/사업자(기업,개인포함)/기프트카드 제외' },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             'NH농협카드(채움,BC) 7개월 부분무이자 : 1~3회차 고객부담',
      //         },
      //         {
      //           content:
      //             'NH농협카드(채움,BC) 10개월 부분무이자 : 1~3회차 고객부담',
      //         },
      //         { content: '체크/사업자(기업,개인포함)/기프트카드 제외' },
      //       ],
      //     },
      //   ],
      //   신한카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부' },
      //         { content: '사업자 선불 체크 기프트 신한BC 제외' },
      //         { content: '5만원 이상 결제시' },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         { content: '10개월 부분무이자(1~4회차 고객부담)' },
      //         { content: '12개월 부분무이자(1~5회차 고객부담)' },
      //         { content: '사업자 선불 체크 기프트 신한BC 제외' },
      //       ],
      //     },
      //   ],
      //   현대카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '5만원 이상 할부 결제시 2~3개월 무이자' },
      //         { content: '사업자/체크/GIFT/선불/하이브리드 카드제외' },
      //       ],
      //     },
      //   ],

      //   롯데카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '5만원 이상 결제시 2~5개월 무이자' },
      //         { content: '사업자,체크,기프트,선불카드제외' },
      //       ],
      //     },
      //   ],

      //   하나카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '6개월 무이자할부' },
      //         { content: '5만원 이상 결제시' },
      //         { content: '하나BC/토스/우체국/체크/사업자/기프트카드 제외' },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         { content: '10개월 부분 무이자할부 : 1~4회차 고객부담' },
      //         { content: '12개월 부분 무이자할부 : 1~5회차 고객부담' },
      //         { content: '하나BC/토스/우체국/체크/사업자/기프트카드 제외' },
      //       ],
      //     },
      //   ],
      // },
      footer: {
        content:
          '심의필-제2024-다이렉트영업지원-배너01151C-전사 (24.03.14 ~ 25.03.13)',
      },
    },
    design: {
      color: {
        bg: 'bg-[#F37321]',
      },
      logo: {
        svg: '/assets/images/static/company/hanwha.svg',
      },
    },
  },
  캐롯손해보험: {
    id: '캐롯손해보험',
    data: {
      url: 'https://www.carrotins.com/mobile/calculation/car/personal/?afccd=PA913',
      mUrl: 'https://www.carrotins.com/mobile/calculation/car/personal/?afccd=PA913',
      banner: {
        title: '캐롯손해보험',
        subtitle: '캐롯 퍼마일 자동차보험',
      },
      description: {
        // title: '신세계 상품권 최대 3만원 제공',
        content: (
          <div>
            적게 타면 적게 내고, 매월 후불로(월정상형 특약) 합리적인 보험료!
            신규 가입 시{' '}
            <span className='font-semibold'>신세계 상품권 최대 3만원 제공</span>
          </div>
        ),
      },
      // interestFree: {
      //   KB국민카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         {
      //           content: '2~5개월 무이자 할부 (5만원 이상 결제 시)',
      //         },
      //         {
      //           content:
      //             '개인 신용카드에 한함 (체크, 기업회원, 비씨카드, 선불카드는 대상에서 제외)',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             '6개월 부분 무이자할부 (5만원 이상 결제 시) : 1~3회차 고객부담',
      //         },
      //         {
      //           content:
      //             '10개월/12개월 부분 무이자할부 (5만원 이상 결제 시) : 1~5회차 고객부담',
      //         },
      //         {
      //           content:
      //             '[개인 신용카드에 한하며 체크, 기업, 비씨, 선불카드는 대상에서 제외]',
      //         },
      //       ],
      //     },
      //   ],
      //   삼성카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자 할부 (5만원 이상 결제 시)' },
      //         {
      //           content:
      //             '개인신용카드에 한해 제공 (법인/체크/선불/충전/기프트카드는 제외)',
      //         },
      //       ],
      //     },
      //     {
      //       title: '다이어트 할부',
      //       description: [
      //         {
      //           content:
      //             '7개월 부분 다이어트할부 (5만원 이상 결제 시): 1~3회차 고객부담',
      //         },
      //         {
      //           content:
      //             '11개월 부분 다이어트할부 (5만원 이상 결제 시): 1~5회차 고객부담',
      //         },
      //         {
      //           content:
      //             '개인신용카드에 한해 제공 (법인/체크/선불/충전/기프트카드는 제외)',
      //         },
      //       ],
      //     },
      //   ],
      //   신한카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부 (5만원 이상 결제 시)' },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 슬림할부',
      //       description: [
      //         {
      //           content:
      //             '10개월 부분 무이자슬림할부 (5만원 이상 결제 시): 1~4회차 고객부담',
      //         },
      //         {
      //           content:
      //             '12개월 부분 무이자슬림할부 (5만원 이상 결제 시): 1~5회차 고객부담',
      //         },
      //       ],
      //     },
      //   ],
      //   농협카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~6개월 무이자할부 (5만원 이상 결제 시)' },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             '7~10개월 부분 무이자할부 (5만원 이상 결제 시): 1~3회차 수수료 고객부담',
      //         },
      //         {
      //           content:
      //             '12개월 부분 무이자할부 (5만원 이상 결제 시): 1~4회차 수수료 고객부담',
      //         },
      //         {
      //           content:
      //             '18개월 부분 무이자할부 (5만원 이상 결제 시): 1~5회차 수수료 고객부담',
      //         },
      //         {
      //           content:
      //             '24개월 부분 무이자할부 (5만원 이상 결제 시): 1~6회차 수수료 고객부담',
      //         },
      //       ],
      //     },
      //   ],
      //   하나카드: [
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             '6개월 부분 무이자할부 (5만원 이상 결제 시): 1~3회차 고객부담',
      //         },
      //         {
      //           content:
      //             '10개월 부분 무이자할부 (5만원 이상 결제 시): 1~4회차 고객부담',
      //         },
      //         {
      //           content:
      //             '12개월 부분 무이자할부 (5만원 이상 결제 시): 1~5회차 고객부담',
      //         },
      //         {
      //           content: '개인신용카드에 한해 제공 (하나BC/토스카드 제외)',
      //         },
      //       ],
      //     },
      //   ],
      //   현대카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~3개월 무이자 할부 (5만원 이상 결제시)' },
      //       ],
      //     },
      //   ],
      //   롯데카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부 (5만원 이상 결제 시)' },
      //         { content: '법인/체크/선불/기프트카드 제외' },
      //       ],
      //     },
      //   ],
      //   BC카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~3개월 무이자할부' },
      //         {
      //           content:
      //             '[사업자/기프트/비씨 외 타사발급카드(광주, 전북, 제주은행 등) 제외, 수협의 경우 무이자할부 적용가능, 부분 무이자할부 적용 불가]',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             '10개월 부분 무이자할부 (5만원 이상 결제 시): 1~3회차 고객부담',
      //         },
      //         {
      //           content:
      //             '12개월 부분 무이자할부 (5만원 이상 결제 시): 1~4회차 고객부담',
      //         },
      //         {
      //           content:
      //             '[사업자/기프트/비씨 외 타사발급카드(광주, 전북, 제주은행 등) 제외, 수협의 경우 부분 무이자할부 적용 불가]',
      //         },
      //       ],
      //     },
      //   ],
      //   우리카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~3개월 무이자할부 (5만원 이상 결제 시)' },
      //         {
      //           content:
      //             '개인신용카드에 한함 (체크, 법인, 기프트카드는 대상에서 제외)',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         {
      //           content:
      //             '10개월 부분 무이자할부 (5만원 이상 결제 시): 1~3회차 고객부담',
      //         },
      //         {
      //           content:
      //             '12개월 부분 무이자할부 (5만원 이상 결제 시): 1~4회차 고객부담',
      //         },
      //       ],
      //     },
      //   ],
      // },
      footer: {
        content: '손해보험협회 심의필 제117350호(2024.03.06 ~ 2025.03.05)',
      },
    },
    design: {
      color: {
        bg: 'bg-[#FF4600]',
      },
      logo: {
        svg: '/assets/images/static/company/carrot.svg',
      },
    },
  },
  삼성화재: {
    id: '삼성화재',
    data: {
      url: 'https://direct.samsungfire.com/CR_MyAnycarWeb/overture_index.jsp?OTK=A2407AF0016',
      mUrl: 'https://direct.samsungfire.com/CR_MyAnycarWeb/overture_index.jsp?OTK=A2407AF0016',
      banner: {
        title: '삼성화재',
        subtitle: '삼성화재 다이렉트 자동차보험',
      },
      description: {
        // title: '조회 시 네이버페이 7,000원 지급',
        content: (
          <div>
            자사 오프라인 대비, 개인용 평균 21.1% 저렴하게! <br />
            보험료 확인만 해도 네이버 페이 7천원 지급!
          </div>
        ),
      },
      // interestFree: {
      //   삼성카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부' },
      //         { content: '6, 8, 9, 10, 12개월은 무이자 적용 제외' },
      //         {
      //           content:
      //             '무이자 및 부분 무이자할부는 개인신용카드만 가능합니다. (법인, 체크, 기프트, 올앳 카드 제외)',
      //         },
      //         {
      //           content:
      //             '삼성화재 다이렉트 자동차보험(이륜차 포함), 해외유학생보험 5만원 이상, 영업배상책임보험 20만원 이상 결제 시 혜택이 적용',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자할부',
      //       description: [
      //         { content: '7개월 부분 무이자(1~3회차 이자 고객 부담)' },
      //         { content: '11개월 부분 무이자(1~5회차 이자 고객 부담)' },
      //         {
      //           content:
      //             '무이자 및 부분 무이자할부는 개인신용카드만 가능합니다. (법인, 체크, 기프트, 올앳 카드 제외)',
      //         },
      //         {
      //           content:
      //             '삼성화재 다이렉트 자동차보험(이륜차 포함), 해외유학생보험 5만원 이상, 영업배상책임보험 20만원 이상 결제 시 혜택이 적용',
      //         },
      //       ],
      //     },
      //   ],

      //   현대카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~3개월 무이자할부' },
      //         {
      //           content:
      //             '무이자 및 부분 무이자할부는 개인신용카드만 가능합니다. (법인, 체크, 기프트, 올앳 카드 제외)',
      //         },
      //         {
      //           content:
      //             '삼성화재 다이렉트 자동차보험(이륜차 포함), 해외유학생보험 5만원 이상, 영업배상책임보험 20만원 이상 결제 시 혜택이 적용',
      //         },
      //       ],
      //     },
      //   ],

      //   신한카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부 (5만원 이상 결제 시)' },
      //         { content: '6, 7, 8, 9, 11개월은 무이자 적용 제외' },
      //         { content: '신한BC카드는 무이자/부분 무이자 적용 제외' },
      //         {
      //           content:
      //             '무이자 및 부분 무이자할부는 개인신용카드만 가능합니다. (법인, 체크, 기프트, 올앳 카드 제외)',
      //         },
      //         {
      //           content:
      //             '삼성화재 다이렉트 자동차보험(이륜차 포함), 해외유학생보험 5만원 이상, 영업배상책임보험 20만원 이상 결제 시 혜택이 적용',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         { content: '10개월 부분 무이자(1~4회차 이자 고객 부담)' },
      //         { content: '12개월 부분 무이자(1~5회차 이자 고객 부담)' },
      //         { content: '신한BC카드는 무이자/부분 무이자 적용 제외' },
      //         {
      //           content:
      //             '무이자 및 부분 무이자할부는 개인신용카드만 가능합니다. (법인, 체크, 기프트, 올앳 카드 제외)',
      //         },
      //         {
      //           content:
      //             '삼성화재 다이렉트 자동차보험(이륜차 포함), 해외유학생보험 5만원 이상, 영업배상책임보험 20만원 이상 결제 시 혜택이 적용',
      //         },
      //       ],
      //     },
      //   ],
      //   KB국민카드: [
      //     {
      //       title: '무이자 할부',
      //       description: [
      //         { content: '2~5개월 무이자할부' },
      //         { content: '7, 8, 9, 11개월은 무이자 적용 제외' },
      //         { content: 'KB국민, BC카드는 무이자/부분무이자 적용 제외' },
      //         {
      //           content:
      //             '무이자 및 부분 무이자할부는 개인신용카드만 가능합니다. (법인, 체크, 기프트, 올앳 카드 제외)',
      //         },
      //         {
      //           content:
      //             '삼성화재 다이렉트 자동차보험(이륜차 포함), 해외유학생보험 5만원 이상, 영업배상책임보험 20만원 이상 결제 시 혜택이 적용',
      //         },
      //       ],
      //     },
      //     {
      //       title: '부분 무이자 할부',
      //       description: [
      //         { content: '6개월 부분 무이자(1~3회차 이자 고객 부담)' },
      //         { content: '10개월 부분 무이자(1~5회차 이자 고객 부담)' },
      //         { content: '12개월 부분 무이자(1~5회차 이자 고객 부담)' },
      //         {
      //           content:
      //             '간편결제(삼성페이, 토스페이, 카카오페이, SSG페이 등) 이용 시 12개월 부분 무이자 적용 제외',
      //         },
      //         { content: 'KB국민, BC카드는 무이자/부분무이자 적용 제외' },
      //         {
      //           content:
      //             '무이자 및 부분 무이자할부는 개인신용카드만 가능합니다. (법인, 체크, 기프트, 올앳 카드 제외)',
      //         },
      //         {
      //           content:
      //             '삼성화재 다이렉트 자동차보험(이륜차 포함), 해외유학생보험 5만원 이상, 영업배상책임보험 20만원 이상 결제 시 혜택이 적용',
      //         },
      //       ],
      //     },
      //   ],
      // },
      footer: {
        content: '손해보험협회 심의필 제124011호(2024.05.30 ~ 2025.05.29)',
      },
    },
    design: {
      color: {
        bg: 'bg-[#0095FA]',
      },
      logo: {
        svg: '/assets/images/static/company/samsung.svg',
      },
    },
  },
};
