/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from 'components/ui/dialog';
import { cn } from 'utils';
import { Check } from 'lucide-react';
import { ChangeEvent, useRef, useState } from 'react';
import { ChevronRight } from 'lucide-react';
import { Textarea } from 'components/ui/textarea';
import useSellingStore from '../store';
import { tr } from 'date-fns/locale';

export default function StepComponent3({
  setIsAccidentHistoryDetailOpen,
}: {
  setIsAccidentHistoryDetailOpen: (value: boolean) => void;
}) {
  const { sellingOrderDTO, setSellingOrderDTO } = useSellingStore();

  const textRef = useRef<HTMLTextAreaElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [textCount, setTextCount] = useState<number>(0);

  const handleClickEvents = {
    판금교환: {
      submit: async () => {
        if (!sellingOrderDTO) return;
        setSellingOrderDTO({
          ...sellingOrderDTO,
          update: {
            additionalInfo: {
              ...sellingOrderDTO.orderData.additionalInfo,
              accident: {
                ...sellingOrderDTO.orderData.additionalInfo.accident,
                판금교환: {
                  selected: true,
                  content: textRef.current?.value,
                },
                모름: {
                  selected: false,
                },
                사고없음: { selected: false },
              },
            },
          },
        });
      },
    },
  };

  return (
    <div ref={ref} className='rounded border border-[#0075FF] bg-[#0075FF]/10'>
      <div className='flex h-[70px] items-center border-b border-[#0075FF] px-4 text-title font-semibold text-white'>
        사고 내역을 알려주세요
      </div>
      <div className='space-y-4 px-4 py-6'>
        <div className='flex items-center text-white'>
          <div onClick={() => setIsAccidentHistoryDetailOpen(true)}>
            보험 처리 전체보기
          </div>
          <ChevronRight className='h-4 w-4' strokeWidth='1.5' />
        </div>
        <label className='group flex h-[72px] items-center gap-2 border px-4 text-[#9EA3B2] transition has-[:checked]:text-white'>
          <div className='relative h-6 w-6'>
            <input
              type='checkbox'
              className='flex h-6 w-6 appearance-none items-center justify-center rounded-full bg-[#0075FF]/10'
              checked={
                sellingOrderDTO?.orderData.additionalInfo?.accident?.사고없음
                  .selected
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (sellingOrderDTO) {
                  setSellingOrderDTO({
                    ...sellingOrderDTO,
                    update: {
                      additionalInfo: {
                        ...sellingOrderDTO.orderData.additionalInfo,
                        accident: {
                          사고없음: {
                            selected: true,
                          },
                          판금교환: {
                            selected: false,
                          },
                          모름: {
                            selected: false,
                          },
                        },
                      },
                    },
                  });
                }
              }}
            />
            <Check className='absolute left-1.5 top-1.5 h-3 w-3 text-white opacity-0 transition group-has-[:checked]:opacity-100' />
          </div>
          <div className='text-base font-medium'>사고가 없습니다</div>
        </label>

        <Dialog>
          <div className='flex h-[72px] items-center gap-2 border border-white px-4'>
            <div className='relative h-6 w-6'>
              <input
                type='radio'
                name='distance'
                checked={
                  sellingOrderDTO?.orderData?.additionalInfo?.accident?.판금교환
                    ?.selected
                }
                className={cn(
                  'flex h-full w-6 appearance-none items-center justify-center rounded-full bg-[#0075FF]/10',
                  sellingOrderDTO?.orderData?.additionalInfo?.accident?.판금교환
                    ?.selected && 'text-white',
                )}
              />
              {sellingOrderDTO?.orderData?.additionalInfo?.accident?.판금교환
                ?.selected && (
                <Check className='absolute left-1.5 top-1.5 h-3 w-3 text-white' />
              )}
            </div>
            <DialogTrigger asChild>
              <div className='flex flex-col w-full pr-8'>
                <div
                  className={cn(
                    'text-base font-medium text-[#9EA3B2]',
                    sellingOrderDTO?.orderData?.additionalInfo?.accident
                      ?.판금교환?.selected && 'text-white',
                  )}
                >
                  판금 / 교환 부위가 있습니다
                </div>
                {sellingOrderDTO?.orderData?.additionalInfo?.accident?.판금교환
                  ?.content && (
                  <div className='leading-3 text-[12px] font-medium text-[#9EA3B2] w-full truncate overflow-hidden whitespace-nowrap'>
                    {
                      sellingOrderDTO?.orderData?.additionalInfo?.accident
                        ?.판금교환?.content
                    }
                  </div>
                )}
              </div>
            </DialogTrigger>
          </div>
          <DialogContent className='w-[311px] gap-0 border-0 p-0'>
            <div className='space-y-4 px-4 py-6 pb-8'>
              <div className='text-center font-semibold'>
                판금 /교환 부위를 알려주세요.
              </div>
              <div className='text-[#555555]'>
                알려주신 내용을 바탕으로 예상 사고 기준을 예상하고 진행해
                드립니다.
              </div>
              <div className='relative border bg-white'>
                <Textarea
                  ref={textRef}
                  onChange={(e) => {
                    setTextCount(
                      /** 한글은 3byte, 영문은 1byte */
                      e.target.value.replace(
                        /[\0-\x7f]|([0-\u07ff]|(.))/g,
                        '$&$1$2',
                      ).length,
                    );
                  }}
                  defaultValue={
                    sellingOrderDTO?.orderData?.additionalInfo?.accident
                      ?.판금교환?.content
                      ? sellingOrderDTO.orderData.additionalInfo.accident
                          .판금교환?.content
                      : ''
                  }
                  className='h-[158px] border-0 focus:border-0'
                  placeholder='내용을 입력해주세요'
                />
                <div className='absolute bottom-4 right-2.5 text-[#767676]'>
                  {textCount}
                  /1000
                </div>
              </div>
            </div>
            <div className='grid grid-cols-2'>
              <DialogClose className='h-12 rounded-none border-0 bg-[#F7F7FB] text-sm font-semibold'>
                취소
              </DialogClose>
              <DialogClose
                onClick={handleClickEvents.판금교환.submit}
                className='rounded-none border-0 bg-[#0075FF] text-sm font-semibold text-white'
              >
                수정하기
              </DialogClose>
            </div>
          </DialogContent>
        </Dialog>

        <label className='group flex h-[72px] items-center gap-2 border px-4 text-[#9EA3B2] transition has-[:checked]:text-white'>
          <div className='relative h-6 w-6'>
            <input
              type='checkbox'
              className='flex h-6 w-6 appearance-none items-center justify-center rounded-full bg-[#0075FF]/10'
              checked={
                sellingOrderDTO?.orderData.additionalInfo.accident?.모름
                  ?.selected
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (sellingOrderDTO) {
                  setSellingOrderDTO({
                    ...sellingOrderDTO,
                    update: {
                      additionalInfo: {
                        ...sellingOrderDTO.orderData.additionalInfo,
                        accident: {
                          사고없음: {
                            selected: false,
                          },
                          판금교환: {
                            selected: false,
                          },
                          모름: {
                            selected: true,
                          },
                        },
                      },
                    },
                  });
                }
              }}
            />
            <Check className='absolute left-1.5 top-1.5 h-3 w-3 text-white opacity-0 transition group-has-[:checked]:opacity-100' />
          </div>
          <div className='text-base font-medium'>잘 모르겠습니다</div>
        </label>
      </div>
    </div>
  );
}
