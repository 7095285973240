import { SidoData, SigunguData } from '@carsayo/types';

export const getRegion = (sidoId: number) => {
  let result = '';

  if (sidoId > 0) {
    const sido = SidoData.find((sidoItem) => {
      return (
        sidoItem.id ===
        SigunguData.find((sigunguItem) => {
          return sigunguItem.id === sidoId;
        })?.sidoId
      );
    })?.name;

    const sigungu = SigunguData.find((el) => {
      return el.id === sidoId;
    })?.name;

    if (sido) {
      result = sido + ' ';

      if (sigungu) {
        result = result + sigungu;
      }
    }
  }

  return result;
};
