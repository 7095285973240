import React from 'react';

interface MyComponentProps {
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
}

const CalculatorIcon: React.FC<MyComponentProps> = ({
  fill,
  stroke,
  width,
  height,
}) => {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox='0 0 20 20'
      fill={fill || 'none'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='3.3335'
        y='3.33337'
        width='13.3333'
        height='13.3333'
        stroke={stroke || '#7273F9'}
        strokeWidth='1.25'
        strokeLinejoin='round'
      />
      <path
        d='M14.1668 5.83337L5.8335 14.1667'
        stroke={stroke || '#7273F9'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.0835 5.83337V8.33337'
        stroke={stroke || '#7273F9'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.3335 7.08337L5.8335 7.08337'
        stroke={stroke || '#7273F9'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.1665 14.1666L11.6665 14.1666'
        stroke={stroke || '#7273F9'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export default CalculatorIcon;
