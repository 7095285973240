import { useNavigate } from 'react-router-dom';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import useSystemStore from 'store/useSystemStore';
import PurchasingCarInfo from './components/carInfo';
import usePurchaseStore from './store';
import getPrice_PurchaseOrderDTO from 'utils/price/getPrice_PurchaseOrderDTO';
import { HalbooForm } from './components/HalbooForm';
import { IlsibullForm } from './components/IlsibullForm';
import { RentForm } from './components/RentForm';
import { LeaseForm } from './components/LeaseForm';

export default function Method() {
  const navigate = useNavigate();
  const { purchaseOrderDTO, setPurchaseOrderDTO } = usePurchaseStore();

  const { setTopTabbarTitle } = useSystemStore();

  const goBack = () => {
    navigate('/purchase/option', { replace: true });
  };

  const setData = async () => {
    if (!purchaseOrderDTO) return;
    await setPurchaseOrderDTO({
      id: purchaseOrderDTO.id,
      type: purchaseOrderDTO.type,
      update: {
        progress: 70,
        currentPath: '/purchase/method',
        additionalRequest: null,
        otherCompanyEstimateFileId: null,
      },
    });
  };

  useEffect(() => {
    window.native.onBackPressed = goBack;
  }, []);

  const purchasePrice: number = useMemo(() => {
    if (!purchaseOrderDTO) return 0;
    return getPrice_PurchaseOrderDTO(purchaseOrderDTO);
  }, [purchaseOrderDTO]);

  useLayoutEffect(() => {
    setTopTabbarTitle('구매 방법 선택');
    setData();
  }, []);

  return (
    <div className='flex flex-col h-full'>
      {purchaseOrderDTO?.orderData && (
        <>
          <div className='flex-auto overflow-y-scroll'>
            <PurchasingCarInfo></PurchasingCarInfo>
            <div className='bg-white'>
              <div className='px-4 pt-6'>
                <div className='text-lg font-semibold'>구매 방법</div>
                <div className='mt-4 grid grid-cols-2 gap-x-4 gap-y-4'>
                  {/* 대표님 요청사항에 의한 수정: 신차 구매 도중에도 리스 및 장기렌트가 선택 가능하게 */}
                  <label className='relative flex h-[52px] items-center justify-center border transition has-[:checked]:border-primary has-[:checked]:font-semibold has-[:checked]:text-primary'>
                    <input
                      type='radio'
                      name='method'
                      checked={
                        purchaseOrderDTO.orderData.additionalInfo.method ===
                        '리스'
                      }
                      onChange={() => {}}
                      onClick={async () => {
                        if (!purchaseOrderDTO.orderData) return;
                        setPurchaseOrderDTO({
                          id: purchaseOrderDTO.id,
                          type: purchaseOrderDTO.type,
                          update: {
                            additionalInfo: {
                              ...purchaseOrderDTO.orderData.additionalInfo,
                              method: '리스',
                            },
                          },
                        });
                      }}
                      className='absolute w-0'
                    />
                    리스
                  </label>
                  <label className='relative flex h-[52px] items-center justify-center border transition has-[:checked]:border-primary has-[:checked]:font-semibold has-[:checked]:text-primary'>
                    <input
                      type='radio'
                      name='method'
                      onChange={() => {}}
                      checked={
                        purchaseOrderDTO.orderData.additionalInfo.method ===
                        '렌트'
                      }
                      onClick={async () => {
                        if (!purchaseOrderDTO.orderData) return;
                        setPurchaseOrderDTO({
                          id: purchaseOrderDTO.id,
                          type: purchaseOrderDTO.type,
                          update: {
                            additionalInfo: {
                              ...purchaseOrderDTO.orderData.additionalInfo,
                              method: '렌트',
                            },
                          },
                        });
                      }}
                      className='absolute w-0'
                    />
                    장기렌트
                  </label>
                  {purchaseOrderDTO.type === 'newCar' && (
                    <>
                      <label className='relative flex h-[52px] items-center justify-center border transition has-[:checked]:border-primary has-[:checked]:font-semibold has-[:checked]:text-primary'>
                        <input
                          type='radio'
                          name='method'
                          onChange={() => {}}
                          checked={
                            purchaseOrderDTO.orderData.additionalInfo.method ===
                            '할부'
                          }
                          onClick={async () => {
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  method: '할부',
                                },
                              },
                            });
                          }}
                          className='absolute w-0'
                        />
                        할부
                      </label>
                      <label className='relative flex h-[52px] items-center justify-center border transition has-[:checked]:border-primary has-[:checked]:font-semibold has-[:checked]:text-primary'>
                        <input
                          type='radio'
                          name='method'
                          onChange={() => {}}
                          checked={
                            purchaseOrderDTO.orderData.additionalInfo.method ===
                            '일시불'
                          }
                          onClick={async () => {
                            if (!purchaseOrderDTO.orderData) return;
                            setPurchaseOrderDTO({
                              id: purchaseOrderDTO.id,
                              type: purchaseOrderDTO.type,
                              update: {
                                additionalInfo: {
                                  ...purchaseOrderDTO.orderData.additionalInfo,
                                  method: '일시불',
                                },
                              },
                            });
                          }}
                          className='absolute w-0'
                        />
                        일시불
                      </label>
                    </>
                  )}
                </div>
              </div>

              {purchaseOrderDTO.orderData.additionalInfo.method === '리스' && (
                <LeaseForm purchasePrice={purchasePrice} />
              )}
              {purchaseOrderDTO.orderData.additionalInfo.method === '렌트' && (
                <RentForm purchasePrice={purchasePrice} />
              )}
              {purchaseOrderDTO.orderData.additionalInfo.method === '할부' && (
                <HalbooForm purchasePrice={purchasePrice} />
              )}
              {purchaseOrderDTO.orderData.additionalInfo.method ===
                '일시불' && <IlsibullForm purchasePrice={purchasePrice} />}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
