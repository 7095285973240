import { FileInfo } from '@carsayo/types';
import SentryErrorReturns from 'utils/sentryErrorReturns';

export default (fileInfo: FileInfo) => {
  fetch(fileInfo.url, {
    credentials: 'include',
  })
    .then((response) => response.blob())
    .then((blob) => {
      const fileName = fileInfo.name + '.' + fileInfo.extension;

      // 안드로이드일 경우 브릿지 메소드를 통해 Base64를 직접 전달하여 파일 다운로드합니다
      if (window?.android?.getBase64FromBlobData) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          window.android.getBase64FromBlobData(reader.result, fileName);
        };
        return;
      }

      const url = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName || 'download';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
    .catch((error) => {
      console.error('파일 다운로드 오류:', error);
      SentryErrorReturns(error.response);
    });
};
