/* eslint-disable @typescript-eslint/no-unused-vars */
import { getInitPayment } from 'apis/authentication';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import path from 'path-browserify';
import { NicepaymentInitData } from '@carsayo/types';

type InitNicePayment = {
  /** 결제 상품명 */
  name: string;

  /** 결제 금액 */
  amount: string;

  /**
   * 결제 확인 후 리다이렉트할 Path
   * 해당 페이지에서 결제가 성공했는지, 실패했는지 확인 후 서비스를 재개합니다
   */
  redirectPath: string;

  /**
   * 결제 도중 에러가 발생하면 리다이렉트할 Path
   * 해당 URL에서 에러를 클라이언트에게 보여줍니다
   * 정의되지 않을 경우 글로벌 에러 페이지로 리다이렉트합니다
   */
  failedPath?: string;
};

interface PaymentState {
  nicepaymentInitData: NicepaymentInitData | null;
}
interface PaymentAction {
  initPayment: (initNicePaymentDTO: InitNicePayment) => Promise<void>;
  resetPayment: () => void;
}

const usePayment = create<PaymentState & PaymentAction>()(
  devtools(
    immer((set) => ({
      nicepaymentInitData: null,
      initPayment: async (initNicePayment: InitNicePayment) => {
        const initPaymentData = await getInitPayment({
          amount: Number(initNicePayment.amount),
          name: initNicePayment.name,
          redirectUrl: path.join(
            window.location.origin,
            initNicePayment.redirectPath,
          ),
          failedUrl: initNicePayment.failedPath
            ? path.join(window.location.origin, initNicePayment.failedPath)
            : undefined,
        });
        set((state) => {
          state.nicepaymentInitData = initPaymentData;
        });
        return;
      },
      resetPayment: () => {
        set((state) => {
          state.nicepaymentInitData = null;
        });
      },
    })),
  ),
);

export default usePayment;
