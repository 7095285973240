import {
  DutyFreeCondition,
  BuyerType,
  OrderState,
  OrderType,
  SellCarBoughtMethod,
  OrderTagCarState,
  OrderTagCarOwnership,
} from '@carsayo/types';
import dayjs from 'dayjs';
// 오늘까지 지난 시간 계산
export const checkDate = (createdAt: Date) => {
  const today = new Date();
  const getDay = new Date(createdAt);
  if (getDay < today) {
    // 시간 차이 계산 (밀리초 단위)
    const timeDifference = today.getTime() - getDay.getTime();
    let timeString;

    if (timeDifference < 60 * 60 * 1000) {
      // 1시간 안쪽인 경우
      const minutes = Math.floor(timeDifference / (60 * 1000)); // 분 단위로 변환
      timeString = `${dateToString2(createdAt).slice(14, 23)} (${minutes}분 전)`;
    } else if (
      timeDifference > 60 * 60 * 1000 &&
      timeDifference < 24 * 60 * 60 * 1000
    ) {
      // 1시간 이상인 경우
      const hours = Math.floor(timeDifference / (60 * 60 * 1000)); // 시간 단위로 변환
      timeString = `${dateToString2(createdAt).slice(14, 23)} (${hours}시간 전)`;
    } else if (
      timeDifference > 24 * 60 * 60 * 1000 &&
      timeDifference < 48 * 60 * 60 * 1000
    ) {
      // 하루 이상, 48시간 이하인 경우
      const hours = Math.floor(timeDifference / (60 * 60 * 1000)); // 시간 단위로 변환
      timeString = `${dateToString2(createdAt).slice(14, 23)} (${hours}시간 전)`;
    } else {
      timeString = dateToString(createdAt);
    }
    return timeString;
  } else {
    const formattedDate = dateToString(createdAt).slice(0, 10);
    return formattedDate;
  }
};

export const stringDateAddDot = (dateString: string) => {
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);

  return `${year}.${month}.${day}`;
};

export const dateToString = (date: Date) => {
  const originDate = dayjs(date);
  const formatDate = originDate.format('YYYY.MM.DD (HH:mm)');
  return formatDate;
};

export const dateToString2 = (date: Date) => {
  const originDate = dayjs(date);
  const hour = originDate.format('H');
  const period = Number(hour) < 12 ? '오전' : '오후';
  const formattedHour = originDate.format('h');
  const formatDate = originDate.format(
    `YYYY년 MM월 DD일 ${period} ${formattedHour}시 mm분`,
  );
  return formatDate;
};

export const dateToStringMMDD = (date: Date) => {
  const originDate = dayjs(date);
  const formatDate = originDate.format('MM.DD');
  return formatDate;
};

export const dateToStringHHMM = (date: Date) => {
  const originDate = dayjs(date);
  const formatDate = originDate.format('HH:mm');
  return formatDate;
};

// 48시간 남은 시간 체크
export const remainingTime = (startDate: Date) => {
  const getDay = new Date(startDate);
  const endDate = new Date(getDay.getTime() + 48 * 60 * 60 * 1000);
  const today = new Date();

  if (today < endDate) {
    // 현재 시간이 종료일보다 이전인 경우
    const timeDifference = endDate.getTime() - today.getTime(); // 종료일과 현재 시간의 차이 (밀리초 단위)

    const hours = Math.floor(timeDifference / (60 * 60 * 1000)); // 시간 단위로 변환
    const minutes = Math.floor(
      (timeDifference % (60 * 60 * 1000)) / (60 * 1000),
    ); // 분 단위로 변환

    const hoursString = hours < 10 ? `0${hours}` : `${hours}`;
    const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;

    return `${hoursString}:${minutesString}분 남음`;
  } else {
    return '종료';
  }
};

// 면세조건
export const DutyFreeConditionType: {
  [key in DutyFreeCondition | 'undefined']: string;
} = {
  isAgentOrangeDisabled: '장애인 (고엽제)',
  isMay18Disabled: '장애인 (5.18 민주화)',
  isMildDisability: '경증 장애 (시각 4급)',
  isNationalMerit: '국가유공자',
  isSevereDisability: '중증 장애 (1~3급)',
  isVisualDisability: '시각 장애 (일반 4급)',
  manyChild: '다자녀 (취득세 감면)',
  normal: '일반인 (해당없음)',
  undefined: '알 수 없음',
} as const;

// 견적 구매자 타입
export const convertBuyType: {
  [key in BuyerType | 'undefined']: string;
} = {
  individual: '개인',
  sole_proprietor: '개인 사업자',
  corporation: '법인 사업자',
  undefined: '알 수 없음',
} as const;

// 견적함 주문상태
export const convertOrderStatus: {
  [key in OrderState | 'undefined']: string;
} = {
  submitted: '입찰중',
  progressing: '진행중',
  holded: '보류',
  canceled: '취소',
  finished: '계약완료',
  failBid: '유찰',
  nonAccepted: '입찰종료',
  deleted: '삭제',
  undefined: '알수없음',
} as const;

// 견적 주문 타입
export const convertDealerType: {
  [key in OrderType | 'undefined']: string;
} = {
  lease: '리스',
  rent: '장기렌트',
  newCar: '신차 구매',
  sellCar: '내차 팔기',
  undefined: '알 수 없음',
} as const;

// 내차필기 중고차 구매방식
export const convertBoughtType: {
  [key in SellCarBoughtMethod | 'undefined']: string;
} = {
  cash: '현금',
  capitalLease: '금융 리스',
  operatingLease: '운용 리스',
  undefined: '알 수 없음',
} as const;

// 금액단위 만원단위,처리
export const slicePrice = (price: number) => {
  const result = price
    ? `${Math.floor(price / 10000).toLocaleString()}만원`
    : '금액없음';
  return result;
};
// 금액단위 한글
export const slicePriceToString = (price: number) => {
  if (typeof price === 'number' && price > 0) {
    if (price >= 100000000) {
      // 억 단위
      return price / 100000000 + '억원';
    } else if (price >= 10000000) {
      // 천만 단위
      return price / 10000000 + '천만원';
    } else if (price >= 10000) {
      // 만 단위
      return price / 10000 + '만원';
    } else {
      // 만 단위 이하
      return price + '원';
    }
  } else {
    return '금액없음';
  }
};

// 태그 - 판매차량상태
export const orderCarStateConvert: {
  [key in OrderTagCarState | 'undefined']: string;
} = {
  none: '없음',
  fullNoAccident: '완무',
  noAccident: '무사고',
  justExchange: '단순교환',
  undefined: '알 수 없음',
} as const;
// 태그 - 판매차량주변경여부
export const orderCarOwnerConvert: {
  [key in OrderTagCarOwnership | 'undefined']: string;
} = {
  none: '없음',
  noChanged: '1인소유 ',
  undefined: '알 수 없음',
} as const;

export const parseStringPhoneNumber = (phone: string) => {
  const parsePhone = phone.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3');
  return parsePhone;
};
