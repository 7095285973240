import { DomesticBrandList, ImportedBrandList } from 'mocks/carMakerList';
import { useLayoutEffect, useState } from 'react';
import useSystemStore from 'store/useSystemStore';
import SpeakerIcon from 'assets/images/v2/sound.svg';
import { Button } from 'components/ui/button';
import signupStore from '../store';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { useNavigate } from 'react-router-dom';
import { CarMakerId } from '@carsayo/types';

const HandleBrand = () => {
  const { setTopTabbarState, setLoadingDim, setTopTabbarTitle } =
    useSystemStore();
  const { signupInfo, setSignupStore } = signupStore();
  const navigate = useNavigate();
  const [selectedCarMaker, setSelectedCarMaker] = useState<CarMakerId[]>([]);

  const handleClickEvents = {
    maker: (makerId: CarMakerId) => {
      setLoadingDim(true);

      let updatedMakerList = [...selectedCarMaker];

      if (updatedMakerList.includes(makerId)) {
        updatedMakerList = updatedMakerList.filter((el) => {
          return el !== makerId;
        });
      } else {
        updatedMakerList = updatedMakerList.concat([makerId]);
      }
      setSelectedCarMaker(updatedMakerList);
      setLoadingDim(false);
    },
    next: () => {
      console.info(selectedCarMaker);
      if (selectedCarMaker && signupInfo?.dealer) {
        setSignupStore({
          ...signupInfo,
          dealer: {
            ...signupInfo.dealer,
            selectedCarMakerList: selectedCarMaker,
          },
        });

        navigate('/auth/signup/dealer/fileUpload');
      } else {
        CarsayoToast.error('취급 브랜드를 선택해주세요');
        return false;
      }
    },
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: 'back',
    });
    setTopTabbarTitle('카매니저 가입');

    return () => {
      setLoadingDim(false);
    };
  }, []);

  return (
    <div className='space-y-3 bg-white'>
      {signupInfo?.dealer?.type === 'usedcar' ||
      signupInfo?.dealer?.type === 'leaseRent' ? (
        <>
          <div className='bg-white p-4'>
            <div className='text-lg font-semibold mb-6'>
              카 매니저님의
              <br />
              <span className='text-primary'>취급 대상을</span> 설정해주세요.
            </div>
            <div className='p-4 border border-[#E5E5EC]'>
              <p className='font-[600] mb-[12px]'>안내사항</p>
              <ul className='text-[13px] font-[400] mb-[12px]'>
                <li className='mb-2 relative pl-[10px] after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
                  설정하신 유형의 차량만 거래하실 수 있습니다.
                </li>
                <li className='mb-2 relative pl-[10px] after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
                  푸시 알림에 동의하셨을 경우, 설정하신 유형의 실시간 견적 요청
                  알림을 수신하실 수 있습니다.
                </li>
              </ul>
              <div className='text-[13px] p-2 border-y-[1px] border-[#F1F1F5] bg-[#F7F7FB] flex justify-start items-center gap-[12px]'>
                <img
                  src={SpeakerIcon}
                  alt='확성기아이콘'
                  className='w-4 h-4 object-contain'
                />
                <p>카 매니저님의 정보는 외부에 공개되지 않습니다.</p>
              </div>
            </div>
          </div>
          <div className='mt-[6.15vh] space-y-2 p-4'>
            <div className='flex flex-col gap-[2.15vh] w-full mb-10'>
              <label className='relative w-full border border-[#E5E5EC] transition has-[:checked]:border-primary rounded-md p-4 flex flex-row justify-start items-center gap-[6.4vw]'>
                <div className='bg-[#FFD14233] w-[16vw] h-[16vw] p-[2.6vw] rounded-full flex justify-center items-center'>
                  <img
                    src='/assets/images/v2/sellCar.svg'
                    alt='렌트카아이콘'
                    className='object-contain w-[80%] h-[80%]'
                  />
                </div>
                <div className='text-[#505050]'>
                  <p>
                    <strong className='inline-block text-[#111111] text-[18px] font-[600]'>
                      전체 (국산/수입차)
                    </strong>
                  </p>
                </div>
                <input
                  type='radio'
                  name='car'
                  value='whole'
                  checked={signupInfo.dealer.selectedCarFrom === 'whole'}
                  className='hidden'
                  onChange={(e) => {
                    if (!signupInfo.dealer) return;
                    setSignupStore({
                      ...signupInfo,
                      dealer: {
                        ...signupInfo.dealer,
                        selectedCarFrom: 'whole',
                      },
                    });
                    return;
                  }}
                />
              </label>
              <label className='relative w-full border border-[#E5E5EC] transition has-[:checked]:border-primary rounded-md p-4 flex flex-row justify-start items-center gap-[6.4vw]'>
                <div className='bg-[#7273F933] w-[16vw] h-[16vw] p-[2.6vw] rounded-full flex justify-center items-center'>
                  <img
                    src='/assets/images/v2/rental.svg'
                    alt='렌트카아이콘'
                    className='object-contain w-[80%] h-[80%]'
                  />
                </div>
                <div className='text-[#505050]'>
                  <p>
                    <strong className='inline-block text-[#111111] text-[18px] font-[600]'>
                      국산차
                    </strong>
                  </p>
                </div>
                <input
                  type='radio'
                  name='car'
                  value='domestic'
                  checked={signupInfo.dealer.selectedCarFrom === 'domestic'}
                  className='hidden'
                  onChange={(e) => {
                    if (!signupInfo.dealer) return;
                    setSignupStore({
                      ...signupInfo,
                      dealer: {
                        ...signupInfo.dealer,
                        selectedCarFrom: 'domestic',
                      },
                    });
                    return;
                  }}
                />
              </label>
              <label className='relative w-full border border-[#E5E5EC] transition has-[:checked]:border-primary rounded-md p-4 flex flex-row justify-start items-center gap-[6.4vw]'>
                <div className='bg-[#CFEDD3] w-[16vw] h-[16vw] p-[2.6vw] rounded-full flex justify-center items-center'>
                  <img
                    src='/assets/images/v2/sellCar.svg'
                    alt='렌트카아이콘'
                    className='object-contain w-[80%] h-[80%]'
                  />
                </div>
                <div className='text-[#505050]'>
                  <p>
                    <strong className='inline-block text-[#111111] text-[18px] font-[600]'>
                      수입차
                    </strong>
                  </p>
                </div>
                <input
                  type='radio'
                  name='car'
                  checked={signupInfo.dealer.selectedCarFrom === 'imported'}
                  value='imported'
                  className='hidden'
                  onChange={(e) => {
                    if (!signupInfo.dealer) return;
                    setSignupStore({
                      ...signupInfo,
                      dealer: {
                        ...signupInfo.dealer,
                        selectedCarFrom: 'imported',
                      },
                    });
                    return;
                  }}
                />
              </label>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='bg-white p-4'>
            <div className='text-lg font-semibold mb-6'>
              카 매니저님의
              <br />
              <span className='text-primary'>취급 브랜드를</span> 설정해주세요.
            </div>
            <div className='p-4 border border-[#E5E5EC]'>
              <p className='font-[600] mb-[12px]'>안내사항</p>
              <ul className='text-[13px] font-[400] mb-[12px]'>
                <li className='mb-2 relative pl-[10px] after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
                  설정하신 브랜드의 차량만 거래하실 수 있습니다.
                </li>
                <li className='mb-2 relative pl-[10px] after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
                  푸시 알림에 동의하셨을 경우, 설정하신 브랜드의 실시간 견적
                  요청 알림을 수신하실 수 있습니다.
                </li>
              </ul>
              <div className='text-[13px] p-2 border-y-[1px] border-[#F1F1F5] bg-[#F7F7FB] flex justify-start items-center gap-[12px]'>
                <img
                  src={SpeakerIcon}
                  alt='확성기아이콘'
                  className='w-4 h-4 object-contain'
                />
                <p>카 매니저님의 정보는 외부에 공개되지 않습니다.</p>
              </div>
            </div>
          </div>
          {/* 국산 브랜드 */}
          <div className='bg-white px-4 py-6 pb-8'>
            <div className='text-xl font-semibold'>국산 브랜드</div>

            <div className='brandAllCheck mt-4 grid grid-cols-3 gap-x-[21.5px] gap-y-4'>
              {DomesticBrandList.map((brand) => (
                <label
                  key={brand.name_en}
                  className='group relative h-[88px] rounded-xl border bg-[#F7F7FB] py-4 has-[:checked]:border-primary'
                >
                  <input
                    type='checkbox'
                    className='absolute h-0 w-0 hidden'
                    checked={selectedCarMaker.includes(brand.id)}
                    onClick={() => handleClickEvents.maker(brand.id)}
                    onChange={() => {}}
                  />
                  <div className='mx-auto h-9 w-9'>
                    <img
                      src={`/assets/images/v2/brand/${brand.name_en}.png`}
                      alt={brand.name_en}
                    />
                  </div>
                  <div className='mt-2 text-center text-xs font-semibold'>
                    {brand.name}
                  </div>
                </label>
              ))}
            </div>
          </div>
          {/* 수입 브랜드 */}
          <div className='bg-white px-4 py-6 pb-8'>
            <div className='text-xl font-semibold'>수입 브랜드</div>

            <div className='incBrandAllCheck mt-4 grid grid-cols-3 gap-x-[21.5px] gap-y-4'>
              {ImportedBrandList.map((brand) => (
                <label
                  key={brand.name_en}
                  className='group relative h-[88px] rounded-xl border bg-[#F7F7FB] py-4 has-[:checked]:border-primary'
                >
                  <input
                    type='checkbox'
                    className='absolute h-0 w-0 hidden'
                    checked={selectedCarMaker.includes(brand.id)}
                    onClick={() => handleClickEvents.maker(brand.id)}
                  />
                  <div className='mx-auto h-9 w-9'>
                    <img
                      src={`/assets/images/v2/brand/${brand.name_en}.png`}
                      alt={brand.name_en}
                    />
                  </div>
                  <div className='mt-2 text-center text-xs font-semibold'>
                    {brand.name}
                  </div>
                </label>
              ))}
            </div>
          </div>
        </>
      )}
      <div className='mt-auto w-full px-4 pb-6'>
        <Button
          onClick={handleClickEvents.next}
          disabled={
            signupInfo?.dealer?.selectedCarFrom === undefined &&
            (selectedCarMaker === undefined || selectedCarMaker.length < 1)
          }
        >
          다음
        </Button>
      </div>
    </div>
  );
};
export default HandleBrand;
