import { setFcmToken } from 'apis/authentication';
import { Button } from 'components/ui/button';
import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';

export default function Complete() {
  const navigate = useNavigate();
  const { setTopTabbarTitle } = useSystemStore();

  useLayoutEffect(() => {
    setTopTabbarTitle('카 매니저 가입 완료');
    setFcmToken();
    return () => {
      navigate('/main');
    };
  }, []);

  return (
    <div className='flex min-h-full grow flex-col p-4'>
      <div className='text-lg font-semibold'>
        카 매니저님의
        <br />
        <span className='text-primary'>가입 신청 접수가 완료</span> 되었습니다.
      </div>
      <div className='mt-20 text-center'>
        <div>
          <img
            src='/assets/images/v2/signup-complete.svg'
            alt=''
            className='mx-auto'
          />
        </div>
        <div className='mt-2 text-base font-semibold'>가입 신청 접수 완료</div>
        <div className='mt-4 text-[#767676]'>
          가입 승인은
          <br /> 영업일 기준 최대 2일이 소요됩니다.
        </div>
        <div className='mx-auto mt-6 grid w-[176px] grid-cols-1 justify-center gap-4'>
          <Button size='lg' variant='black' onClick={() => navigate('/main')}>
            메인으로
          </Button>
          {/* <Button size='lg' variant='outline'>
            문의하기
          </Button> */}
        </div>
      </div>
    </div>
  );
}
