import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { api } from '../../../apis';
import { useEffect, useState } from 'react';
import useMemberStore from '../../../store/useMemberStore';
import dayjs from 'dayjs';
import { PurchaseItemData, PurchaseResult } from '@carsayo/types';

export default function RedirectPurchase() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isFailed, setIsFailed] = useState(false);
  const [data, setData] = useState<PurchaseResult>();

  const targetItem = PurchaseItemData.find((el) => {
    return el.id === Number.parseInt(id as string);
  });

  const { refreshLoginMember } = useMemberStore();

  const handleClick = () => {
    navigate('/main');
  };

  const fetchPurchase = async () => {
    try {
      const res = await api.post('purchase', {
        termCategoryId: id,
        paymentId: searchParams.get('paymentId'),
      });
      refreshLoginMember();

      if (res.data.state === 'virtualAccountWaiting') {
        setData(res.data);
      } else if (res.data.state === 'bought') {
        navigate('/main');
        if (targetItem) {
          navigate(
            `/${targetItem.id === 1000100 ? 'purchase/brand' : 'selling/landing'}`,
            {
              replace: true,
            },
          );
        }
      }
    } catch (error: any) {
      setIsFailed(true);
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchPurchase();
  }, []);

  return (
    <>
      {isFailed && (
        <div className='w-screen h-screen flex flex-col text-center align-center pt-16 px-4'>
          <div className='pb-8'>
            <p className='text-[1rem] font-semibold text-[#111] pb-3'>
              정상적으로 주문이 완료되지 않았습니다.
            </p>
            <p className='text-[0.875rem] font-semibold text-[#767676]'>
              잠시 후 다시 이용해 주세요.
            </p>
          </div>
          <button
            onClick={handleClick}
            className='w-full py-4 px-7 mt-6 flex justify-center items-center bg-[#111111] text-[#fff] rounded-[6px] disabled:cursor-not-allowed disabled:opacity-50 disabled:pointer-events-none'
          >
            홈으로
          </button>
        </div>
      )}
      {!isFailed && data && data.state === 'virtualAccountWaiting' && (
        <div className='w-screen h-screen flex flex-col text-center align-center pt-16 px-4'>
          <div className='pb-8'>
            <p className='text-[1rem] font-semibold text-[#111] pb-3'>
              가상계좌 입금 대기
            </p>
            <p className='text-[0.875rem] font-semibold text-[#767676]'>
              아래 가상계좌로 입금해 주시면
              <br />
              정상적으로 주문 처리가 완료됩니다.
            </p>
          </div>
          <div className='border-t'>
            <div className='flex h-[52px] items-center border-b'>
              <div className='w-[100px] shrink-0 bg-[#F7F7FB] p-4'>
                입금은행
              </div>
              <div className='p-4 text-[#767676]'>
                {data.virtualBank?.bankName}
              </div>
            </div>
            <div className='flex h-[52px] items-center border-b'>
              <div className='w-[100px] shrink-0 bg-[#F7F7FB] p-4'>
                계좌번호
              </div>
              <div className='p-4 text-[#767676]'>
                {data.virtualBank?.accountNumber}
              </div>
            </div>
            <div className='flex h-[52px] items-center border-b'>
              <div className='w-[100px] shrink-0 bg-[#F7F7FB] p-4'>
                입금금액
              </div>
              <div className='p-4 text-[#767676]'>
                {data.price.toLocaleString()}원
              </div>
            </div>
            <div className='flex h-[52px] items-center border-b'>
              <div className='w-[100px] shrink-0 bg-[#F7F7FB] p-4'>
                입금기한
              </div>
              <div className='p-4 text-[#767676]'>
                {`${dayjs(data.virtualBank?.expireDate).tz().format('YYYY년 MM월 DD일')} ${dayjs(data.virtualBank?.expireTime).tz().format('HH:mm')}`}
              </div>
            </div>
          </div>
          <button
            onClick={handleClick}
            className='w-full py-4 px-7 mt-6 flex justify-center items-center bg-[#111111] text-[#fff] rounded-[6px] disabled:cursor-not-allowed disabled:opacity-50 disabled:pointer-events-none'
          >
            홈으로
          </button>
        </div>
      )}
    </>
  );
}
