/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from 'components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { Textarea } from 'components/ui/textarea';
import { X } from 'lucide-react';

export default function Cancel({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) {
  return (
    <Dialog open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
      <DialogContent className='w-[311px] gap-0 border-0 p-0'>
        <div className='flex h-14 items-center justify-center text-center text-xl font-semibold'>
          계약 취소
        </div>
        <div className='px-4 py-2 pb-6'>
          <div>
            진행 과장에서 어떤 문제가 있으셨나요?
            <br />
            불편함이 있다면 카사요가 도와 드리겠습니다.
          </div>
          <div className='mt-4 text-[#6100FF]'>취소 사유를 입력해주세요.</div>
          {/* <Select>
            <SelectTrigger className='mt-4'>
              <SelectValue placeholder='선택해주세요'></SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectItem value='1'>카 매니저와의 갈등</SelectItem>
              <SelectItem value='2'>개인적인 사유</SelectItem>
              <SelectItem value='3'>타사에서 구매 예정</SelectItem>
              <SelectItem value='4'>단순 변심</SelectItem>
            </SelectContent>
          </Select> */}
          <div className='relative mt-6 border bg-white'>
            <Textarea
              className='h-[158px] border-0 p-4 focus:border-0'
              placeholder='취소 사유를 입력해주세요'
            ></Textarea>
            <div className='absolute bottom-4 right-2.5 text-[#767676]'>
              0/1000
            </div>
          </div>
          {/* <div className='mt-6 h-[1px] bg-[#F1F1F5]'></div> */}
          {/* <div className='mt-3 flex h-[66px] items-center gap-4 border bg-white px-4'>
            <div className='h-4 w-4'>
              <img src='/images/link.svg' alt='' />
            </div>
            <div>(선택) 첨부파일</div>
            <div className='ml-auto'>
              <Button variant='outline' size='xs' className='w-auto'>
                업로드
              </Button>
            </div>
          </div> */}
        </div>
        <DialogFooter className='w-full flex flex-row justify-stretch items-center'>
          <Button
            className='h-[48px] rounded-none border-none bg-[#F7F7FB] text-[#111111]'
            onClick={() => setIsOpen(false)}
          >
            취소
          </Button>
          <Button
            className='h-[48px] rounded-none border-none  bg-[#111111]'
            onClick={() => setIsOpen(false)}
          >
            확인
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
