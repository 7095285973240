import imageCompression from 'browser-image-compression';

export const encodeFileToBase64 = (fileBlob: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    const maxSize = 10 * 1024 * 1024;

    reader.readAsDataURL(fileBlob);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      }
      reject(new Error('알 수 없는 파일입니다.'));
    };

    if (fileBlob.size > maxSize) {
      return reject(new Error('파일 사이즈는 10mb 이하로 업로드 가능합니다.'));
    }
  });
};

export const base64ToFile = ({
  base64String,
  fileName,
}: {
  base64String: string;
  fileName: string;
}) => {
  // base64 문자열을 Blob 객체로 변환합니다.
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: 'image/png' }); // 이미지 타입에 따라 변경 가능

  // Blob 객체를 File 객체로 변환합니다.
  const file = new File([blob], fileName, { type: 'image/png' }); // 이미지 타입에 따라 변경 가능

  return file;
};

export const blobToFile = async ({
  blob,
  fileName,
}: {
  blob: Blob;
  fileName: string;
}): Promise<File> => {
  return new File([blob], fileName, {
    type: blob.type,
  });
};

export const compressFile = async (file: File) => {
  try {
    const compressedFile = await imageCompression(file, {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    });
    const convertFile = new File([compressedFile], compressedFile.name, {
      type: compressedFile.type,
    });
    return convertFile;
  } catch (error) {
    console.error(error);
  }
};
