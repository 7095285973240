import { SellingOrderDTO, SellingInitDTO } from '@carsayo/types';
import { api } from 'apis';

/** 구매 진행중인 주문 정보 가져오기
 * @notice null: 구매 진행중인 정보가 없음
 */
export const getSelling = async (): Promise<SellingOrderDTO[]> => {
  try {
    const response = await api.get('order/selling');
    const data: SellingOrderDTO[] = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

/** 구매 새로 시작
 * @notice 기존의 작성중인 구매 주문을 제거합니다
 */
export const initSelling = async (
  reqData: SellingInitDTO,
): Promise<SellingOrderDTO> => {
  try {
    const response = await api.get(
      `order/selling/init?usedCarInfoId=${reqData.usedCarInfoId}&accidentHistoryId=${reqData.accidentHistoryId}`,
    );
    const data: SellingOrderDTO = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

/** 구매 진행 업데이트
 */
export const updateSelling = async (
  reqData: SellingOrderDTO,
): Promise<SellingOrderDTO> => {
  try {
    const response = await api.post('order/selling/update', reqData);
    const data: SellingOrderDTO = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

/** 구매 진행 최종 제출 */
export const submitSelling = async (
  reqData: SellingOrderDTO,
): Promise<SellingOrderDTO> => {
  try {
    const response = await api.post('order/selling/submit', reqData);
    const data: SellingOrderDTO = response.data;
    return data;
  } catch (error: any) {
    const errorMessage: string = error?.message?.response?.data?.message
      ? `${error?.message?.response?.data?.message}`
      : '잠시 후 다시 시도해 주세요.';
    throw new Error(errorMessage);
  }
};
