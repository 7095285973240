import React from 'react';

interface MyComponentProps {
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
}

const HeartIcon: React.FC<MyComponentProps> = ({
  fill,
  stroke,
  width,
  height,
}) => {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox='0 0 20 20' // 기본 좌표계 설정
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='xMidYMid meet' // 비율 유지 및 중앙 정렬
    >
      <path
        d='M10 16.6667L3.71974 10.8059C2.09342 9.28745 2.09342 6.82403 3.71974 5.30554C5.34605 3.78704 7.98684 3.78704 9.61316 5.30554L10 5.66586L10.3868 5.30554C12.0132 3.78704 14.6539 3.78704 16.2803 5.30554C17.9066 6.82403 17.9066 9.28745 16.2803 10.8059L10 16.6667Z'
        fill={fill || 'transparent'}
        stroke={stroke || '#111111'}
        strokeWidth='1.25'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export default HeartIcon;
