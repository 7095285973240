export interface CharacterSubDataInterface {
  id: '01' | '02' | '03';
  title: string;
  titleColor: string;
  description: string;
  bg: string;
  nextText: string;
  nextColor: string;
  nextBg: string;
  imgArray: Array<string>;
}

export const CharacterDataArray: CharacterSubDataInterface[] = [
  {
    id: '01',
    title: '카키',
    titleColor: '#303056',
    description: '분석적이며 깐깐한 성격으로 계산 능력까지 뛰어난',
    bg: 'linear-gradient(245deg,rgba(102, 126, 234, 1) 0%,rgba(118, 75, 162, 1) 100%)',
    nextText: '카집사 보기',
    nextColor: '#fff',
    nextBg: '#6365D9',
    imgArray: [
      '/assets/images/svg/character01_01.svg',
      '/assets/images/svg/character01_02.svg',
      '/assets/images/svg/character01_03.svg',
      '/assets/images/svg/character01_04.svg',
      '/assets/images/svg/character01_05.svg',
      '/assets/images/svg/character01_06.svg',
      '/assets/images/svg/character01_07.svg',
      '/assets/images/svg/character01_08.svg',
    ],
  },
  {
    id: '02',
    title: '카집사',
    titleColor: '#FF5B79',
    description: '항상 웃음을 잃지 않는 감성적이면서 따뜻한 마음',
    bg: 'linear-gradient(180deg,rgba(46, 9, 22, 0.97) 0%,rgba(32, 10, 43, 0.69) 100%)',
    nextText: '카공이 보기',
    nextColor: '#fff',
    nextBg: '#FF5B79',
    imgArray: [
      '/assets/images/svg/character02_01.svg',
      '/assets/images/svg/character02_02.svg',
      '/assets/images/svg/character02_03.svg',
      '/assets/images/svg/character02_04.svg',
      '/assets/images/svg/character02_05.svg',
      '/assets/images/svg/character02_06.svg',
      '/assets/images/svg/character02_07.svg',
      '/assets/images/svg/character02_08.svg',
    ],
  },
  {
    id: '03',
    title: '카공이',
    titleColor: '#fff',
    description: '차량의 모든 부분을 세밀하게 알고 있는',
    bg: 'linear-gradient(180deg,rgba(91, 135, 75, 0.97) 0%,rgba(12, 55, 32, 0.87) 100%)',
    nextText: '카키 보기',
    nextColor: '#303056',
    nextBg: '#A7DE93',
    imgArray: [
      '/assets/images/svg/character03_01.svg',
      '/assets/images/svg/character03_02.svg',
      '/assets/images/svg/character03_03.svg',
      '/assets/images/svg/character03_04.svg',
      '/assets/images/svg/character03_05.svg',
      '/assets/images/svg/character03_06.svg',
      '/assets/images/svg/character03_07.svg',
      '/assets/images/svg/character03_08.svg',
    ],
  },
];
