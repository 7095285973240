import dayjs from 'dayjs';

const getTermToastMessage = (params: {
  /** 동의 여부 */
  state: boolean;
  termTitle: string;
}) => {
  return `[${dayjs(new Date()).format('YYYY.MM.DD')}] ${params.termTitle}에 ${params.state ? '동의' : '철회'}하였습니다.`;
};

export default getTermToastMessage;
