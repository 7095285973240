/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { MenuItem } from '@mui/material';
import { forwardRef } from 'react';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgb(240, 240, 244)',
    fontSize: 14,
    color: 'rgb(17, 17, 17)',
    padding: '12px 26px 12px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  '& .li': {
    color: 'red',
  },
}));

export const CustomizedMenuItems = styled(MenuItem)(({ theme }) => ({
  fontSize: 14,
  color: 'rgb(17, 17, 17)',
  padding: '12px 26px 12px 12px',
  '&:hover': {
    backgroundColor: 'rgba(0, 123, 255, 0.1)',
  },
}));

const CustomizedSelects = forwardRef(function CustomizedSelects(
  {
    value,
    onValueChange,
    disabled,
    placeholder,
    children,
    classes,
    name,
  }: {
    value: string;
    onValueChange?: (value: string) => void | Promise<void>;
    placeholder?: string;
    disabled?: boolean;
    children: React.ReactNode;
    classes?: string;
    name?: string;
  },
  ref,
) {
  const handleChange = (event: { target: { value: string } }) => {
    if (onValueChange) onValueChange(event.target.value);
  };

  return (
    <>
      <FormControl variant='standard' className='w-full mt-2'>
        <Select
          labelId='customized-select-label'
          id='customized-select'
          value={value ? value : ''}
          className={classes}
          name={name}
          onChange={handleChange}
          input={<BootstrapInput />}
          ref={ref}
          disabled={disabled}
          displayEmpty={true}
          renderValue={
            value !== '' && value !== undefined
              ? undefined
              : () => <span>{placeholder}</span>
          }
        >
          {children}
        </Select>
      </FormControl>
    </>
  );
});

export default CustomizedSelects;
