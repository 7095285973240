import { Button } from 'components/ui/button';
import { Ellipsis, MessageCircleMore, ThumbsUp } from 'lucide-react';

export default function Detail() {
  return (
    <div className='bg-[#F1F1F5]'>
      <div className='relative flex h-[82px] items-end gap-4 bg-[#7273F9] px-5 py-2'>
        <div className='-mb-6'>
          <img
            src='/assets/images/v2/casayo-logo.svg'
            alt=''
            className='h-[64px] w-[64px]'
          />
        </div>
        <div>
          <div className='text-desc font-semibold'>카사요 후기</div>
          <div className='mt-1 flex gap-2 text-desc'>
            <div className='text-white'>심야에 라디오 들으며</div>
            <div className='text-[#D9D9D9]'>· 05.27(월)</div>
          </div>
        </div>
      </div>
      <div className='bg-white px-5 py-6'>
        <div className='mt-6 text-lg font-semibold'>
          만원의 기적! 오직 카사요에서만
        </div>
        <div className='mt-3 text-title'>
          타사 견적보다 더 저렴하지 않다면 환불해 드립니다.
        </div>
        <div className='mt-2 text-[#767676]'>
          지난달 실적 산정 시 가족카드 이용실적도 동일한 기준으로 본인카드
          이용실적에 합산 실적 조건, 한도 제한 없이 모든 가맹점 1% 결제일 할인
          주유 건당 5,000원 할인
        </div>
        <div className='mt-6 flex h-[250px] items-center justify-center overflow-hidden'>
          <img
            src='/assets/images/v2/car2.png'
            alt=''
            className='object-cover'
          />
        </div>
      </div>
      <div className='mt-3'>
        <div className='flex h-[50px] items-center justify-between bg-white px-5'>
          <div className='text-desc'>정지영의 스윗뮤직박스</div>
          <div className='text-desc'>3시간 전</div>
        </div>
        <div className='px-5 py-4'>
          <div className='border-b pb-4'>
            <div>
              만원의 기적으로 그렇게 받기가 쉽지 않을텐데 카사요 정말 대단한거
              같아요
            </div>
            <div className='mt-4 flex items-center justify-between'>
              <div className='flex items-center gap-4'>
                <div className='flex items-center gap-1'>
                  <ThumbsUp className='h-5 w-5' strokeWidth={1.5} />
                  <div className='text-desc text-[#767676]'>좋아요</div>
                </div>
                <div className='flex items-center gap-1'>
                  <MessageCircleMore className='h-5 w-5' strokeWidth={1.5} />
                  <div className='text-desc text-[#767676]'>좋아요</div>
                </div>
              </div>
              <Ellipsis className='h-5 w-5' strokeWidth={1.5} />
            </div>
          </div>
          <div className='p-4'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-2.5'>
                <div className='flex h-5 w-5 items-center justify-center'>
                  <img src='/assets/images/v2/icon-l2.svg' alt='' />
                </div>
                <div className='text-[#767676]'>새벽 작업 애청자</div>
              </div>
              <div className='text-xs text-[#767676]'>3시간 전</div>
            </div>
            <div className='pt-4'>
              정말 그렇게 저렴한가요? 구매가 떙기네요.. 이건 뭐... 다른 곳에서
              도저히 나오지 않는 견적이..
            </div>
            <div className='mt-4 flex items-center justify-between'>
              <div className='flex items-center gap-4'>
                <div className='flex items-center gap-1'>
                  <ThumbsUp className='h-5 w-5' strokeWidth={1.5} />
                  <div className='text-desc text-[#767676]'>좋아요</div>
                </div>
              </div>
              <Ellipsis className='h-5 w-5' strokeWidth={1.5} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='flex h-[50px] items-center justify-between bg-white px-5'>
          <div className='text-desc'>정지영의 스윗뮤직박스</div>
          <div className='text-desc'>3시간 전</div>
        </div>
        <div className='px-5 py-4'>
          <div className='border-b pb-4'>
            <div>
              만원의 기적으로 그렇게 받기가 쉽지 않을텐데 카사요 정말 대단한거
              같아요
            </div>
            <div className='mt-4 flex items-center justify-between'>
              <div className='flex items-center gap-4'>
                <div className='flex items-center gap-1'>
                  <ThumbsUp className='h-5 w-5' strokeWidth={1.5} />
                  <div className='text-desc text-[#767676]'>좋아요</div>
                </div>
                <div className='flex items-center gap-1'>
                  <MessageCircleMore className='h-5 w-5' strokeWidth={1.5} />
                  <div className='text-desc text-[#767676]'>좋아요</div>
                </div>
              </div>
              <Ellipsis className='h-5 w-5' strokeWidth={1.5} />
            </div>
          </div>
          <div className='p-4'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-2.5'>
                <div className='flex h-5 w-5 items-center justify-center'>
                  <img src='/assets/images/v2/icon-l2.svg' alt='' />
                </div>
                <div className='text-[#767676]'>새벽 작업 애청자</div>
              </div>
              <div className='text-xs text-[#767676]'>3시간 전</div>
            </div>
            <div className='pt-4'>
              정말 그렇게 저렴한가요? 구매가 떙기네요.. 이건 뭐... 다른 곳에서
              도저히 나오지 않는 견적이..
            </div>
            <div className='mt-4 flex items-center justify-between'>
              <div className='flex items-center gap-4'>
                <div className='flex items-center gap-1'>
                  <ThumbsUp className='h-5 w-5' strokeWidth={1.5} />
                  <div className='text-desc text-[#767676]'>좋아요</div>
                </div>
              </div>
              <Ellipsis className='h-5 w-5' strokeWidth={1.5} />
            </div>
          </div>
        </div>
      </div>
      <div className='bg-white p-4 py-6'>
        <Button variant='outline' className='rounded-none'>
          목록으로
        </Button>
      </div>
    </div>
  );
}
