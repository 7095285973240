import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';

/** App ViewPoint
 * @notice 뒤로가기로 페이지가 전환되었을 때 스크롤 위치를 기억하여 복구합니다
 */
const ViewPoint = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const { isBottomTabbar } = useSystemStore();
  const { setViewRef } = useSystemStore();
  const viewRef = useRef<HTMLDivElement | null>(null);

  const [currentScroll, setCurrentScroll] = useState(0);
  const [scrollHistory, setScrollHistory] = useState<
    { key: string; scroll?: number }[]
  >([{ key: location.key }]);

  useLayoutEffect(() => {
    if (scrollHistory.length === 1 && scrollHistory[0].key === location.key) {
      return;
    }

    // 뒤로가기로 들어온 경우
    if (location.key === scrollHistory?.[scrollHistory.length - 2]?.key) {
      const beforeScrollHeight =
        scrollHistory?.[scrollHistory.length - 2]?.scroll;
      scrollHistory.pop();
      setScrollHistory(scrollHistory);

      if (beforeScrollHeight !== undefined)
        viewRef.current?.scrollTo({
          top: beforeScrollHeight,
        });
      return;
    }
    // 다음 페이지로 넘어간 경우
    else if (scrollHistory.length > 0) {
      scrollHistory[scrollHistory.length - 1].scroll = currentScroll;
      setScrollHistory([...scrollHistory, { key: location.key }]);
      setCurrentScroll(0);
      viewRef.current?.scrollTo({
        top: 0,
      });
      return;
    }
  }, [location.pathname]);

  useEffect(() => {
    viewRef.current && setViewRef(viewRef.current);
    const handleScroll = () => {
      if (viewRef.current) {
        setCurrentScroll(viewRef.current.scrollTop);
      }
    };

    const viewDiv = viewRef.current;
    if (viewDiv) {
      viewDiv.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (viewDiv) {
        viewDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  // scrollHistory 고장좀 납니다 확인요함

  return (
    <div
      ref={viewRef}
      className={cn(
        'flex-auto h-auto w-full overflow-y-auto',
        isBottomTabbar
          ? 'pb-[var(--bodyBottomPadding)]'
          : 'pb-[var(--safeAreaBottomPadding)]',
      )}
    >
      {children}
    </div>
  );
};
export default ViewPoint;
