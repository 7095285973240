interface StarInterface {
  width?: string;
  height?: string;
  fill?: string;
}

const CarsayoCI = ({ width, height, fill }: StarInterface) => {
  return (
    <svg
      width={width || '96'}
      height={height || '16'}
      viewBox='0 0 96 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M80.5838 1.55165C80.5838 0.707591 79.9236 0 79.0483 0C78.6332 0 78.1481 0.197115 77.863 0.555965L73.9299 5.2769C73.8425 5.38192 73.6811 5.38185 73.5937 5.27675L70.3617 0.601453C70.0316 0.202169 69.5715 0.00505422 69.0913 0.00505422C68.191 0.00505422 67.4658 0.712646 67.4658 1.64768C67.4658 2.02674 67.5558 2.38054 67.8409 2.73434L71.8044 8.30369C71.9672 8.49994 72.0562 8.74689 72.0562 9.00186V14.3035C72.0562 15.2132 72.8015 15.9663 73.7017 15.9663C74.602 15.9663 75.3472 15.2132 75.3472 14.3035V8.84512C75.3472 8.59019 75.4363 8.34326 75.599 8.14703L80.2387 2.55238C80.4788 2.26429 80.5888 1.95598 80.5888 1.5567L80.5838 1.55165Z'
        fill={fill || 'white'}
      />
      <path
        d='M87.9167 0C83.4486 0 79.9336 3.52391 79.9336 7.98315C79.9336 12.4424 83.4486 15.9663 87.9167 15.9663C92.3849 15.9663 95.8999 12.4424 95.8999 7.98315C95.8999 3.52391 92.3849 0 87.9167 0ZM87.9167 12.7508C85.375 12.7508 83.3629 10.6425 83.3629 7.98315C83.3629 5.32378 85.375 3.2155 87.9167 3.2155C90.4584 3.2155 92.4706 5.32378 92.4706 7.98315C92.4706 10.6425 90.4584 12.7508 87.9167 12.7508Z'
        fill={fill || 'white'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.101562 7.98315C0.101562 3.53544 3.62753 0 8.08471 0C9.84859 0 11.4793 0.555894 12.8017 1.51577C13.5349 2.04794 13.6978 3.0737 13.1657 3.80688C12.6335 4.54006 11.6078 4.70302 10.8746 4.17085C10.1067 3.61349 9.14927 3.28075 8.08471 3.28075C5.44183 3.28075 3.38231 5.34495 3.38231 7.98315C3.38231 10.6213 5.44183 12.6856 8.08471 12.6856C9.14927 12.6856 10.1067 12.3528 10.8746 11.7954C11.6078 11.2633 12.6335 11.4262 13.1657 12.1594C13.6978 12.8926 13.5349 13.9184 12.8017 14.4505C11.4793 15.4104 9.84859 15.9663 8.08471 15.9663C3.62753 15.9663 0.101562 12.4309 0.101562 7.98315Z'
        fill={fill || 'white'}
      />
      <path
        d='M49.0846 15.9663C46.2033 15.9663 44.4635 14.7044 43.9112 13.111C43.7916 12.7901 43.7363 12.4586 43.7363 12.1378C43.7363 11.1539 44.3439 10.4909 45.2 10.4909C45.8535 10.4909 46.2954 10.7583 46.728 11.5282C47.1791 12.4907 48.072 12.8971 49.1398 12.8971C50.4562 12.8971 51.3122 12.2127 51.3122 11.2716C51.3122 10.4161 50.7507 9.9776 49.1766 9.62469L47.805 9.32526C45.1631 8.74778 43.7824 7.23991 43.7824 4.88721C43.7824 1.91424 46.0008 0 49.057 0C51.5976 0 53.4571 1.13357 54.1014 3.0906C54.1935 3.35795 54.2395 3.61461 54.2395 3.93543C54.2395 4.84443 53.6688 5.45399 52.8219 5.46469C52.0855 5.46469 51.6252 5.15456 51.2294 4.40597C50.7967 3.4435 50.0603 3.06921 49.0846 3.06921C47.8603 3.06921 47.1423 3.71085 47.1423 4.61985C47.1423 5.4326 47.7406 5.88176 49.1858 6.20258L50.5482 6.50201C53.3834 7.13297 54.6721 8.46973 54.6721 10.8973C54.6721 13.9986 52.5826 15.9663 49.0846 15.9663Z'
        fill={fill || 'white'}
      />
      <path
        d='M20.8415 0.945292C21.2243 0.126144 22.2003 -0.228254 23.0214 0.153721C23.8425 0.535696 24.1977 1.5094 23.8148 2.32855L17.729 15.021C17.3461 15.8402 16.3701 16.1946 15.5491 15.8126C14.728 15.4306 14.3728 14.4569 14.7556 13.6377L20.8415 0.945292Z'
        fill={fill || 'white'}
      />
      <path
        d='M23.8156 0.945292C23.4327 0.126144 22.4567 -0.228254 21.6357 0.153721C20.8146 0.535696 20.4594 1.5094 20.8422 2.32855L26.9281 15.021C27.3109 15.8402 28.2869 16.1946 29.108 15.8126C29.9291 15.4306 30.2843 14.4569 29.9014 13.6377L23.8156 0.945292Z'
        fill={fill || 'white'}
      />
      <path
        d='M23.7892 12.6855H28.5348V15.9663H23.7892C22.9102 15.9663 22.192 15.2338 22.192 14.3259C22.192 13.4232 22.9052 12.6855 23.7892 12.6855Z'
        fill={fill || 'white'}
      />
      <path
        d='M61.4577 0.945292C61.8405 0.126144 62.8165 -0.228254 63.6376 0.153721C64.4587 0.535696 64.8139 1.5094 64.431 2.32855L58.3452 15.021C57.9623 15.8402 56.9864 16.1946 56.1653 15.8126C55.3442 15.4306 54.989 14.4569 55.3719 13.6377L61.4577 0.945292Z'
        fill={fill || 'white'}
      />
      <path
        d='M64.4318 0.945292C64.0489 0.126144 63.073 -0.228254 62.2519 0.153721C61.4308 0.535696 61.0756 1.5094 61.4585 2.32855L67.5443 15.021C67.9272 15.8402 68.9031 16.1946 69.7242 15.8126C70.5453 15.4306 70.9005 14.4569 70.5176 13.6377L64.4318 0.945292Z'
        fill={fill || 'white'}
      />
      <path
        d='M64.4054 12.6855H69.151V15.9663H64.4054C63.5265 15.9663 62.8082 15.2338 62.8082 14.3259C62.8082 13.4232 63.5214 12.6855 64.4054 12.6855Z'
        fill={fill || 'white'}
      />
      <path
        d='M33.0197 15.9618C33.9152 15.9618 34.6565 15.1983 34.6565 14.2759V3.93578C34.6565 3.45274 35.0482 3.06116 35.5313 3.06116H37.4519C38.412 3.06116 39.2517 3.82143 39.2517 4.81039C39.2517 5.68178 38.7627 6.34287 37.8878 6.45036C37.9972 6.58382 40.3453 8.85801 40.3453 8.85801C41.5443 8.36609 42.5761 7.08159 42.5761 4.94481C42.5761 2.22389 40.5264 0 37.5563 0H33.0147C32.1192 0 31.3779 0.7635 31.3779 1.68585V14.2708C31.3779 15.1931 32.1192 15.9566 33.0147 15.9566L33.0197 15.9618Z'
        fill={fill || 'white'}
      />
      <path
        d='M39.4375 7.20877L40.3453 8.85801L41.1108 10.4984L42.5761 13.5604C43.0215 14.3316 42.7466 15.324 41.962 15.7768C41.1774 16.2297 40.1803 15.9716 39.7349 15.2003L36.5963 8.84867C36.1642 8.10041 36.4454 7.12282 37.2317 6.66901C38.0135 6.21778 39.0029 6.45623 39.4375 7.20877Z'
        fill={fill || 'white'}
      />
    </svg>
  );
};
export default CarsayoCI;
