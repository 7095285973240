import React from 'react';

interface MyComponentProps {
  fill?: string;
}

const TelIcon: React.FC<MyComponentProps> = ({ fill }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.3082 15.275C18.3082 15.575 18.2415 15.8833 18.0998 16.1833C17.9582 16.4833 17.7748 16.7667 17.5332 17.0333C17.1248 17.4833 16.6748 17.8083 16.1665 18.0167C15.6665 18.225 15.1248 18.3333 14.5415 18.3333C13.6915 18.3333 12.7832 18.1333 11.8248 17.725C10.8665 17.3167 9.90817 16.7667 8.95817 16.075C7.99984 15.375 7.0915 14.6 6.22484 13.7417C5.3665 12.875 4.5915 11.9667 3.89984 11.0167C3.2165 10.0667 2.6665 9.11666 2.2665 8.17499C1.8665 7.22499 1.6665 6.31666 1.6665 5.44999C1.6665 4.88332 1.7665 4.34166 1.9665 3.84166C2.1665 3.33332 2.48317 2.86666 2.92484 2.44999C3.45817 1.92499 4.0415 1.66666 4.65817 1.66666C4.8915 1.66666 5.12484 1.71666 5.33317 1.81666C5.54984 1.91666 5.7415 2.06666 5.8915 2.28332L7.82484 5.00832C7.97484 5.21666 8.08317 5.40832 8.15817 5.59166C8.23317 5.76666 8.27484 5.94166 8.27484 6.09999C8.27484 6.29999 8.2165 6.49999 8.09984 6.69166C7.9915 6.88332 7.83317 7.08332 7.63317 7.28332L6.99984 7.94166C6.90817 8.03332 6.8665 8.14166 6.8665 8.27499C6.8665 8.34166 6.87484 8.39999 6.8915 8.46666C6.9165 8.53332 6.9415 8.58332 6.95817 8.63332C7.10817 8.90832 7.3665 9.26666 7.73317 9.69999C8.10817 10.1333 8.50817 10.575 8.9415 11.0167C9.3915 11.4583 9.82484 11.8667 10.2665 12.2417C10.6998 12.6083 11.0582 12.8583 11.3415 13.0083C11.3832 13.025 11.4332 13.05 11.4915 13.075C11.5582 13.1 11.6248 13.1083 11.6998 13.1083C11.8415 13.1083 11.9498 13.0583 12.0415 12.9667L12.6748 12.3417C12.8832 12.1333 13.0832 11.975 13.2748 11.875C13.4665 11.7583 13.6582 11.7 13.8665 11.7C14.0248 11.7 14.1915 11.7333 14.3748 11.8083C14.5582 11.8833 14.7498 11.9917 14.9582 12.1333L17.7165 14.0917C17.9332 14.2417 18.0832 14.4167 18.1748 14.625C18.2582 14.8333 18.3082 15.0417 18.3082 15.275Z'
        stroke={fill ? fill : '#292D32'}
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
    </svg>
  );
};
export default TelIcon;
