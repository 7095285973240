/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { InterestFreeSubpageState } from '../data/type';
import { AnimatePresence, motion } from 'framer-motion';
import {
  InsuranceCompany,
  InsuranceCompanyData,
  InsuranceCompanyInfo,
} from '../data/company';
import { InsuranceCard, InsuranceCardData } from '../data/card';
import { Button } from 'components/ui/button';
import InsuranceBanner from '../component/banner';
import { cn } from 'utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import splitArray from 'utils/splitArray';
import SwiperCore from 'swiper';

export default function InterestFree({
  interestFreeSubpageState,
  setInterestFreeSubpageState,
}: {
  interestFreeSubpageState: InterestFreeSubpageState | undefined;
  setInterestFreeSubpageState: Dispatch<
    SetStateAction<InterestFreeSubpageState | undefined>
  >;
}) {
  const companySelecteRef = useRef<null[] | HTMLDivElement[]>([]);

  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);

  const targetCompany: InsuranceCompanyInfo | undefined = useMemo(() => {
    if (!interestFreeSubpageState) return undefined;
    return InsuranceCompanyData[interestFreeSubpageState.company];
  }, [interestFreeSubpageState]);

  const handleClickEvents = {
    redirectExternalPage: () => {
      if (!targetCompany) return;
      console.info('url', targetCompany.data.url);
    },
  };

  const targetCardList: InsuranceCard[][] = useMemo(() => {
    if (!targetCompany?.data.interestFree) return [];
    const targetCardNameList = Object.keys(
      targetCompany.data.interestFree,
    ) as InsuranceCard[];
    return splitArray(targetCardNameList, 6);
  }, [targetCompany]);

  useEffect(() => {
    if (!interestFreeSubpageState?.company) return;

    const targetIndex = Object.keys(InsuranceCompanyData).indexOf(
      interestFreeSubpageState.company,
    );

    companySelecteRef.current[targetIndex]?.scrollIntoView({
      inline: 'center',
      block: 'center',
      behavior: 'smooth',
    });
  }, [interestFreeSubpageState?.company]);

  // 선택 보험사 변경 시 선택되어 있는 카드가 스와이퍼에 나타나있는지 확인
  useEffect(() => {
    if (!swiperInstance || !targetCardList || !interestFreeSubpageState) return;

    let existIndex: number = 0;
    for (let i = 0; i < targetCardList.length; i++) {
      if (targetCardList[i].includes(interestFreeSubpageState.card)) {
        existIndex = i;
      }
    }
    swiperInstance.slideTo(existIndex);
  }, [targetCompany]);

  return (
    <AnimatePresence>
      {interestFreeSubpageState && targetCompany && (
        <motion.div
          className='flex flex-col fixed w-full h-[calc(100%-56px)] bg-[#F2F2F2]'
          initial={{ x: '100%' }}
          exit={{ x: '100%' }}
          animate={{
            x: 0,
            boxShadow: '10px 10px 0 rgba(0, 0, 0, 0.2)',
          }}
        >
          <div className='bg-white flex flex-none pt-2 pb-4 h-fit overflow-x-auto max-w-full'>
            {Object.keys(InsuranceCompanyData).map((el, index) => {
              const targetCompanyName = el as InsuranceCompany;
              const targetCompanyData = InsuranceCompanyData[targetCompanyName];
              return (
                <div
                  ref={(el) => (companySelecteRef.current[index] = el)}
                  key={targetCompanyName}
                  onClick={() => {
                    if (!targetCompanyData.data.interestFree) return;
                    const currentCard = interestFreeSubpageState.card;
                    const initCard = Object.keys(
                      targetCompanyData.data.interestFree,
                    )?.[0] as InsuranceCard | undefined;

                    if (!initCard) return;
                    setInterestFreeSubpageState({
                      card: targetCompanyData.data.interestFree[currentCard]
                        ? currentCard
                        : initCard,
                      company: targetCompanyName,
                    });
                  }}
                  className={cn(
                    'flex flex-none cursor-pointer hover:bg-gray-50 h-fit gap-2.5 mx-1 px-4 py-2.5 rounded-full border border-gray-300 flex-shrink-0',
                    interestFreeSubpageState.company === targetCompanyName &&
                      'border-black font-semibold',
                  )}
                >
                  <img
                    className='w-5 h-5'
                    src={targetCompanyData.design.logo.svg}
                    alt='보험사 로고'
                  />
                  <div>{targetCompanyName}</div>
                </div>
              );
            })}
          </div>

          <section className='flex items-center flex-col py-6 px-4 bg-[#F2F2F2] gap-4 flex-auto overflow-y-auto'>
            <div className='bg-white p-4 w-full rounded-2xl shadow-xl'>
              <InsuranceBanner
                className='w-full'
                companyInfo={targetCompany}
              ></InsuranceBanner>

              {/* 카드사 선택 영역 */}
              {targetCompany.data.interestFree && (
                <div className='py-6 border-b'>
                  <Swiper
                    onSwiper={setSwiperInstance}
                    modules={[Pagination]}
                    pagination={true}
                    spaceBetween={0}
                    slidesPerView={1}
                    className={cn(
                      'bullet-bt',
                      targetCardList.length > 1 && 'pb-4',
                    )}
                  >
                    {targetCardList.map((el1, index) => {
                      return (
                        <SwiperSlide
                          key={`${targetCompany.id}_${index}`}
                          className='py-0.5 min-h-[84px] grid grid-cols-3 justify-center text-[14px] gap-2 text-gray-500'
                        >
                          {el1.map((el2) => {
                            return (
                              <div
                                key={el2}
                                className={cn(
                                  'w-full h-[36px] flex cursor-pointer justify-center items-center py-2',
                                  el2 === interestFreeSubpageState.card &&
                                    'text-[15px] text-black font-semibold',
                                )}
                                onClick={() => {
                                  setInterestFreeSubpageState({
                                    ...interestFreeSubpageState,
                                    card: el2,
                                  });
                                }}
                              >
                                {el2}
                              </div>
                            );
                          })}
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              )}

              <div className='flex flex-col items-center py-4'>
                <img
                  className='py-4 w-[210px]'
                  src={InsuranceCardData[interestFreeSubpageState.card].src}
                  alt='카드사 카드 이미지'
                />

                {Object.keys(targetCompany.data.interestFree!)
                  .filter((el) => {
                    const targetCardName = el as InsuranceCard;

                    if (targetCardName !== interestFreeSubpageState.card)
                      return false;
                    else return true;
                  })
                  .map((el) => {
                    const targetCardName = el as InsuranceCard;
                    const targetData =
                      targetCompany.data.interestFree![targetCardName]!;

                    return targetData.map((el2) => {
                      return (
                        <div
                          key={el2.title}
                          className='pt-5 flex flex-col gap-2 w-full'
                        >
                          <span className='text-[18px] font-medium tracking-tight'>
                            {el2.title}
                          </span>
                          <ul className='mt-2 list-outside list-disc bg-[#F7F7FB] p-4 py-3 pl-8 text-[#767676] tracking-tighter break-keep'>
                            {el2.description.map((el3, index) => {
                              return (
                                <li key={index}>
                                  {el3.content}

                                  {el3.depth &&
                                    el3.depth.map((el4, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className='list-none flex items-center px-1 text-[#767676] break-keep'
                                        >
                                          <svg
                                            className='w-1.5 h-1.5 mr-1'
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='5'
                                            height='5'
                                            viewBox='0 0 5 5'
                                            fill='none'
                                          >
                                            <path
                                              d='M1 0V4H5'
                                              stroke='#767676'
                                            />
                                          </svg>
                                          <span>{el4}</span>
                                        </div>
                                      );
                                    })}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    });
                  })}
              </div>
            </div>
          </section>

          <section className='flex-none px-4 pt-6 pb-8 bg-white'>
            <Button
              className='rounded-none bg-[#6100FF]'
              onClick={handleClickEvents.redirectExternalPage}
            >
              자동차 보험 가입하기
            </Button>
          </section>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
