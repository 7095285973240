/** 카사요 어드민에서 등록한 팝업을 관련 전역상태를 통해 띄웁니다 */

import { BannerContent, BannerCategoryId } from '@carsayo/types';
import { api } from 'apis';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type BannerCache = {
  [key: number]: BannerContent[];
};
interface CarsayoBannerState {
  bannerCache: BannerCache;
}

interface CarsayoBannerAction {
  getBannerContent: (
    bannerCategoryId: BannerCategoryId,
  ) => Promise<BannerContent[]>;
}

const getBannerContentRequest = async (
  bannerCategoryId: BannerCategoryId,
): Promise<BannerContent[]> => {
  try {
    const response = await api.get(`banner?categoryId=${bannerCategoryId}`);
    const data: BannerContent[] = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

const initialBannerCache: BannerCache = {};

const useCarsayoBannerStore = create<
  CarsayoBannerState & CarsayoBannerAction
>()(
  devtools(
    immer((set, get) => ({
      bannerCache: initialBannerCache,
      getBannerContent: async (
        bannerCategoryId: BannerCategoryId,
      ): Promise<BannerContent[]> => {
        const getCurrentState = get();
        if (getCurrentState.bannerCache[bannerCategoryId]) {
          return getCurrentState.bannerCache[bannerCategoryId];
        } else {
          const getBannerInfo = await getBannerContentRequest(bannerCategoryId);
          set((state) => {
            state.bannerCache = {
              ...get().bannerCache,
              [bannerCategoryId]: getBannerInfo,
            };
          });
          return getBannerInfo;
        }
      },
    })),
    {
      name: 'carsayoPopupStore',
    },
  ),
);

export default useCarsayoBannerStore;
