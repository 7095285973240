import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface OnResumeState {
  onResumeList: {
    [path: string]: () => void | Promise<void>;
  };
}
interface OnResumeAction {
  runOnResume: () => void;
  setOnResume: (hook: () => void | Promise<void>) => void;
}

const useOnResumeStore = create<OnResumeState & OnResumeAction>()(
  devtools(
    immer((set, get) => ({
      onResumeList: {},
      runOnResume: () => {
        const getCurrentState = get();
        const pathname = window.location.pathname;

        if (getCurrentState.onResumeList[pathname]) {
          console.info('Run onResume', pathname);
          getCurrentState.onResumeList[pathname]();
        }
        if (window.native.refreshMember) {
          window.native.refreshMember();
        }
      },
      /** 네이티브단에서 앱이 재개되거나, 푸시 알림이 발생했을 경우 돌릴 메소드를 정의해주세요. */
      setOnResume: (hook: () => void | Promise<void>) => {
        set((state) => {
          state.onResumeList = {
            ...state.onResumeList,
            [window.location.pathname]: hook,
          };
        });
      },
    })),
  ),
);

export default useOnResumeStore;
