import React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
  borderRadius: '50%',
  minWidth: '32px',
  minHeight: '32px',
  '&.Mui-selected': {
    backgroundColor: 'transparent',
    fontWeight: 'bold',
  },
}));

interface CarsayoPaginationProps {
  totalCount: number;
  page: number;
  setPage: (page: number) => void;
}

const CarsayoPagination: React.FC<CarsayoPaginationProps> = ({
  totalCount,
  page,
  setPage,
}) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  return (
    <Stack
      spacing={2}
      direction='row'
      justifyContent='center'
      alignItems='center'
    >
      <Pagination
        count={
          totalCount ? (totalCount < 6 ? 1 : Math.ceil(totalCount / 5)) : 5
        }
        page={page}
        onChange={handleChange}
        showFirstButton
        showLastButton
        siblingCount={0} // 현재 페이지 양쪽에 표시할 페이지 수
        boundaryCount={0} // 첫 페이지와 마지막 페이지 근처에 표시할 페이지 수
        renderItem={(item) => <StyledPaginationItem {...item} />}
        sx={{
          '& .MuiPaginationItem-root': {
            borderRadius: '50%',
            width: '8vw',
            height: '8vw',
            maxWidth: '50px',
            maxHeight: '50px',
            margin: '0 4px',
            border: '1px solid #e0e0e0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '14px',
            borderColor: '#E5E5EC',
            color: '#111111',
          },
          '& .MuiPaginationItem-previousNext, & .MuiPaginationItem-firstLast': {
            color: '#9e9e9e',
          },
          '& .MuiPaginationItem-ellipsis': {
            color: '#9e9e9e',
          },
        }}
      />
    </Stack>
  );
};

export default CarsayoPagination;
