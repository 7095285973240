import { useLayoutEffect } from 'react';
import useSystemStore from 'store/useSystemStore';

const BannedPage = () => {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();

  useLayoutEffect(() => {
    setTopTabbarState({
      mode: 'subpage',
      state: 'hidden',
    });
    setIsBottomTabbar(false);
  }, []);

  return (
    <div className='flex min-h-full grow flex-col p-4'>
      <div className='mt-10 text-xl text-center font-semibold'>
        이용 중지 안내
      </div>
      <div className='mt-20 text-center'>
        <div>
          <img src='/assets/images/v2/banIcon.svg' alt='' className='mx-auto' />
        </div>
        <div className='mt-8 text-base font-semibold'>규정 위반이 확인되어</div>
        <div className='text-base font-semibold'>
          카사요 서비스 이용이 중지되었습니다.
        </div>
        <div
          onClick={() => {
            const telUrl = 'mailto:info@carsayo.net';
            window.location.href = telUrl;
          }}
          className='mt-8 w-full text-[#111111] bg-[#F7F7FB] py-4 px-4 text-center justify-start'
        >
          문의 이메일:{' '}
          <span className='font-[500] underline'>info@carsayo.net</span>
        </div>
      </div>
    </div>
  );
};

export default BannedPage;
