/* eslint-disable @typescript-eslint/no-unused-vars */
import { getTerm, getTermCategoryInfo } from 'apis/setting';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import ReactHtmlParser from 'react-html-parser';
import { ChevronDown } from 'lucide-react';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { X } from 'lucide-react';
import { Button } from 'components/ui/button';
import useMemberStore from 'store/useMemberStore';
import {
  TermCategoryData,
  TermCategoryDetailInfo,
  TermCategoryId,
  TermDetail,
} from '@carsayo/types';

function formatNumber(num: number) {
  // If the number is an integer, format it to one decimal place
  if (Number.isInteger(num)) {
    return num.toFixed(1);
  }
  // Otherwise, return the number as it is
  return num.toString();
}

export default function TermDetailPage() {
  const { setTopTabbarState } = useSystemStore();
  const { loginMember } = useMemberStore();
  const { id } = useParams();

  const [termData, setTermData] = useState<TermDetail>();
  const [termCategory, setTermCategory] = useState<TermCategoryDetailInfo>();
  const [targetTermId, setTargetTermId] = useState<string | undefined>();
  const [dealerWarning, setDealerWarning] = useState<string>();

  const [isOpen, setIsOpen] = useState(false);

  const handleVoidEvents = {
    setTermCategoryData: async () => {
      const targetId =
        id && TermCategoryId.includes(Number(id) as TermCategoryId)
          ? (Number(id) as TermCategoryId)
          : undefined;
      if (targetId)
        setTermCategory(
          await getTermCategoryInfo({ termCategoryId: targetId }),
        );
    },
    setTermData: async () => {
      const targetId =
        id && TermCategoryId.includes(Number(id) as TermCategoryId)
          ? (Number(id) as TermCategoryId)
          : undefined;
      if (targetId)
        setTermData(
          await getTerm({ termCategoryId: targetId, termId: targetTermId }),
        );
    },
    setDealerWarningData: () => {
      const htmlURL =
        loginMember?.dealerInfo?.type === 'newcar'
          ? '/assets/dealerWarning/dealer_warning_newcar.htm'
          : loginMember?.dealerInfo?.type === 'usedcar'
            ? '/assets/dealerWarning/dealer_warning_usedcar.htm'
            : loginMember?.dealerInfo?.type === 'leaseRent'
              ? '/assets/dealerWarning/dealer_warning_leaserentcar.htm'
              : undefined;

      if (htmlURL) {
        fetch(htmlURL)
          .then((response) => response.text())
          .then((data) => setDealerWarning(data))
          .catch((error) =>
            console.error('Error fetching the HTML file:', error),
          );
      } else {
        setDealerWarning(undefined);
      }
    },
  };

  useEffect(() => {
    if (!targetTermId) return;
    handleVoidEvents.setTermData();
  }, [targetTermId]);

  useEffect(() => {
    if (!termData) return;
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: TermCategoryData.find((el) => {
        return el.id == Number(id);
      })?.title,
      rightElement: (
        <>
          {termData?.version && (
            <div
              onClick={() => setIsOpen(true)}
              className='flex ml-[-2px] px-1 w-auto h-8 border text-[#555555] border-[#E5E5EC] text-center justify-center items-center'
            >
              <div className='text-[14px] font-normal px-1'>
                V{formatNumber(termData.version)}
              </div>
              <ChevronDown className='w-6 h-6' />
            </div>
          )}
        </>
      ),
      leftElement: 'back',
    });
  }, [termData]);

  useLayoutEffect(() => {
    if (id === 'warning') {
      handleVoidEvents.setDealerWarningData();
      setTopTabbarState({
        state: 'fixed',
        mode: 'subpage',
        title: '경고 및 계정 정지 정책',
        rightElement: 'home',
        leftElement: 'back',
      });
    } else {
      handleVoidEvents.setTermData();
      handleVoidEvents.setTermCategoryData();
    }
  }, []);

  return (
    <div className='relative w-full flex-1 bg-white px-[24px] overflow-y-auto pb-[30px] pt-[35px]'>
      {termData &&
        ReactHtmlParser(termData?.content_html ? termData.content_html : '')}
      {dealerWarning && ReactHtmlParser(dealerWarning)}

      <Drawer open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
        <DrawerContent className='flex flex-col'>
          <DrawerHeader className='relative'>
            <DrawerTitle>버전 선택</DrawerTitle>
            <DrawerClose asChild>
              <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
                <X className='h-7 w-7' />
              </Button>
            </DrawerClose>
          </DrawerHeader>
          {termData?.version && (
            <div className='px-4 pb-4'>
              <div className='border-t'>
                {termCategory?.termList.map((el) => {
                  return (
                    <div
                      onClick={() => {
                        setTargetTermId(el.id);
                        setIsOpen(false);
                      }}
                      className='mt-4 flex h-[52px] w-full items-center justify-center border text-base font-medium'
                    >
                      V{formatNumber(el.version)}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </DrawerContent>
      </Drawer>
    </div>
  );
}
