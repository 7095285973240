import { cn } from 'utils';
import { Check } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { SelectSigungu } from 'components/view/buy/selectSigungu';
import useSellingStore from '../store';
import { parseSigunguId } from 'utils/typeConvert';

export default function StepComponent4() {
  const [isOpen, setIsOpen] = useState(false);
  const { sellingOrderDTO, setSellingOrderDTO } = useSellingStore();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div ref={ref} className='rounded border border-[#0075FF] bg-[#0075FF]/10'>
      <div className='flex h-[70px] items-center border-b border-[#0075FF] px-4 text-title font-semibold text-white'>
        판매 지역을 알려주세요
      </div>
      <div className='space-y-4 px-4 py-6'>
        <div
          className='flex h-[72px] items-center gap-2 border border-white px-4'
          onClick={() => setIsOpen(true)}
        >
          <div className='relative h-6 w-6'>
            <input
              type='radio'
              name='region'
              onClick={() => {
                setIsOpen(true);
              }}
              className={cn(
                'flex h-6 w-6 appearance-none items-center justify-center rounded-full bg-[#0075FF]/10',
                sellingOrderDTO?.orderData.sellRegionId && 'text-white',
              )}
            />
            {sellingOrderDTO?.orderData.sellRegionId && (
              <Check className='absolute left-1.5 top-1.5 h-3 w-3 text-white' />
            )}
          </div>
          <div
            className={cn(
              'text-base font-medium text-[#9EA3B2]',
              sellingOrderDTO?.orderData.sellRegionId && 'text-white',
            )}
          >
            {sellingOrderDTO?.orderData.sellRegionId
              ? `${parseSigunguId(sellingOrderDTO?.orderData.sellRegionId)?.sido.name} ${parseSigunguId(sellingOrderDTO?.orderData.sellRegionId)?.name ? parseSigunguId(sellingOrderDTO?.orderData.sellRegionId)?.name : ''}`
              : '입력하기'}
          </div>
        </div>
      </div>
      <SelectSigungu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setRegion={(value) => {
          if (!sellingOrderDTO) return;

          setSellingOrderDTO({
            ...sellingOrderDTO,
            update: {
              sellRegionId: value,
            },
          });
        }}
      />
    </div>
  );
}
