import React from 'react';

interface MyComponentProps {
  fill?: string;
}

const ArrowRight: React.FC<MyComponentProps> = ({ fill }) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4 2L8 6L4 10'
        stroke={fill ?? '#7273F9'}
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export default ArrowRight;
