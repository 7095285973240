/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import usePurchaseStore from './store';
import useFileUploader from 'store/useFileUploader';
import download from 'utils/download';
import { submitPurchase } from 'apis/purchase';
import Confirm from 'components/ui/confirm';
import getPrice_PurchaseOrderDTO from 'utils/price/getPrice_PurchaseOrderDTO';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { ga4_event } from 'hooks/ga4';

export default function Estimate() {
  const navigate = useNavigate();

  const { setTopTabbarTitle, setLoadingDim } = useSystemStore();
  const { purchaseOrderDTO, setPurchaseOrderDTO } = usePurchaseStore();

  const { fileUpload } = useFileUploader();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [textCount, setTextCount] = useState<number>(0);

  const textInputRef1 = useRef<HTMLTextAreaElement>(null);
  const textInputRef2 = useRef<HTMLTextAreaElement>(null);

  const submit = async () => {
    if (!purchaseOrderDTO) return;
    setLoadingDim(true);

    setTimeout(async () => {
      try {
        ga4_event({
          category: 'order_request',
          action: '구매 신청 접수',
          label: purchaseOrderDTO.id,
        });

        await setPurchaseOrderDTO({
          id: purchaseOrderDTO.id,
          type: purchaseOrderDTO.type,
          update: {
            additionalRequest: textInputRef1.current
              ? textInputRef1.current.value
              : undefined,
            otherCompanyEstimateContent: textInputRef2.current
              ? textInputRef2.current.value
              : undefined,
          },
        });
        await submitPurchase(purchaseOrderDTO);
        goNext();
      } catch (e) {
        setLoadingDim(false);
      }
      setLoadingDim(false);
    }, 4000);
  };

  const setData = async () => {
    if (!purchaseOrderDTO) return;
    await setPurchaseOrderDTO({
      id: purchaseOrderDTO.id,
      type: purchaseOrderDTO.type,
      update: {
        progress: 90,
        currentPath: '/purchase/estimate',
      },
    });
  };

  const goBack = () => {
    navigate('/purchase/method', { replace: true });
  };
  const goNext = () => {
    navigate('/purchase/complete', { replace: true });
  };

  const purchasePrice: number = useMemo(() => {
    if (!purchaseOrderDTO) return 0;
    return getPrice_PurchaseOrderDTO(purchaseOrderDTO);
  }, [purchaseOrderDTO]);

  useEffect(() => {
    window.native.onBackPressed = goBack;
  }, []);

  useLayoutEffect(() => {
    setTopTabbarTitle('견적 신청');
    setData();
  }, []);

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextCount(
      /** 한글은 3byte, 영문은 1byte */
      e.target.value.replace(/[0-\x7f]|([0-\u07ff]|(.))/g, '$&$1$2').length,
    );
  };

  return (
    <div className='flex flex-col h-full'>
      <div className='flex-auto overflow-y-scroll'>
        <div className='relative z-10 px-4 py-8 shadow-[0_5px_10px_0_rgba(0,0,0,.06)]'>
          <div className='text-xl font-semibold'>
            {purchaseOrderDTO?.orderData?.carMaker?.name}{' '}
            {purchaseOrderDTO?.orderData?.carModel?.name}
          </div>
          <div className='mt-1.5'>
            {purchaseOrderDTO?.orderData?.carGrade?.name}
          </div>
          <div className='mt-4 flex items-center gap-6'>
            {/* <div className='flex h-8 flex-1 items-center justify-center rounded border'>
              차량 이미지
            </div>
            <div className='flex h-8 flex-1 items-center justify-center rounded border'>
              차량 제원
            </div> */}
          </div>
          <div className='mx-auto mt-4 h-[120px] w-[186px]'>
            <img
              src={purchaseOrderDTO?.orderData?.carModel?.image_url}
              alt=''
            />
          </div>
          {/* 차량 가격 안내 파트 */}
          {purchasePrice > 0 && (
            <div className='mt-4'>
              <div className='border-b pb-2'>
                <div className='flex items-center justify-between pb-2 pt-2'>
                  <div className='text-[#555555]'>차량 기본가</div>
                  <div>
                    {purchaseOrderDTO?.orderData?.carGrade?.price.toLocaleString()}
                    원
                  </div>
                </div>
                {purchaseOrderDTO?.orderData?.carColor?.price !== undefined &&
                  purchaseOrderDTO.orderData.carColor.price > 0 && (
                    <div className='flex items-center justify-between pb-2 pt-2'>
                      <div className='text-[#555555]'>색상 가격</div>
                      <div>
                        {purchaseOrderDTO?.orderData?.carColor?.price.toLocaleString()}
                        원
                      </div>
                    </div>
                  )}
                {purchaseOrderDTO?.orderData?.carOptionList &&
                  purchaseOrderDTO.orderData.carOptionList.length > 0 && (
                    <div className='flex items-center justify-between pb-2 pt-2'>
                      <div className='text-[#555555]'>옵션 가격</div>
                      <div>
                        {purchaseOrderDTO.orderData.carOptionList
                          .map((el) => {
                            return el.price;
                          })
                          .reduce((prev, curr) => {
                            return prev + curr;
                          })
                          .toLocaleString()}
                        원
                      </div>
                    </div>
                  )}
              </div>
              <div className='mt-4 flex items-center justify-between'>
                <div className='text-[#555555]'>총 예상 금액</div>
                {purchaseOrderDTO && (
                  <div className='font-semibold text-[#7273F9]'>
                    {purchasePrice.toLocaleString()}원
                  </div>
                )}
              </div>
              <div className='mt-4 text-[#767676]'>
                · 차량 기본가는 개별 소비세가 포함된 가격입니다.
              </div>
            </div>
          )}
        </div>
        {/* 타사 견적서 업로드 - 리스렌트 */}
        <div className='px-4 pt-2'>
          {/* 타사 견적서 업로드 - 리스렌트 */}
          {(purchaseOrderDTO?.orderData?.additionalInfo.method === '리스' ||
            purchaseOrderDTO?.orderData?.additionalInfo.method === '렌트') &&
            (purchaseOrderDTO?.orderData.isSuperCar === true ? (
              // 슈퍼카 브랜드일 경우
              <div className='mt-4 bg-[#F7F7FB] p-4'>
                <div className='text-base font-semibold'>
                  구매 계약서를 업로드 해 주세요.
                </div>
                <div className='mt-3 text-[#767676]'>
                  · 카사요는 [대출성 금융상품 판매대리·중개업자 등록증]을 보유한
                  플랫폼으로 리스 및 장기렌트 서비스가 가능합니다.
                </div>
                <div className='text-[#767676]'>
                  · 구매하실 계약서를 업로드 해 주시면 카사요 금융 카매니저님이
                  합리적인 금융 서비스를 제안합니다.
                </div>
                <div className='mt-3 flex h-12 items-center gap-4 border bg-white px-4'>
                  <div className='h-4 w-4'>
                    <img src='/assets/images/v2/file.svg' alt='' />
                  </div>

                  {purchaseOrderDTO?.orderData?.otherCompanyEstimateFile !==
                  undefined ? (
                    <div
                      onClick={() => {
                        if (
                          purchaseOrderDTO.orderData
                            ?.otherCompanyEstimateFile === undefined
                        )
                          return;
                        download(
                          purchaseOrderDTO.orderData.otherCompanyEstimateFile,
                        );
                      }}
                    >
                      {purchaseOrderDTO.orderData?.carMaker?.name} 계약서
                    </div>
                  ) : (
                    <div>계약서 업로드</div>
                  )}
                  <div className='ml-auto'>
                    {purchaseOrderDTO?.orderData?.otherCompanyEstimateFile !==
                    undefined ? (
                      <Button
                        onClick={() => {
                          if (!purchaseOrderDTO) return;
                          setPurchaseOrderDTO({
                            id: purchaseOrderDTO.id,
                            type: purchaseOrderDTO.type,
                            update: {
                              otherCompanyEstimateFileId: null,
                            },
                          });
                        }}
                        variant='outline'
                        size='xs'
                        className='w-auto'
                      >
                        제거
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          fileUpload(
                            {
                              acceptFile: ['image', 'pdf'],
                              fileType: 'superCarContract',
                              fileName: `${purchaseOrderDTO.orderData?.carMaker?.name} 계약서`,
                              isPublic: false,
                            },
                            async (fileInfo, file) => {
                              if (!purchaseOrderDTO) return;
                              await setPurchaseOrderDTO({
                                id: purchaseOrderDTO.id,
                                type: purchaseOrderDTO.type,
                                update: {
                                  otherCompanyEstimateFileId: fileInfo.id,
                                },
                              });
                            },
                          );
                        }}
                        variant='outline'
                        size='xs'
                        className='w-auto'
                      >
                        업로드
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              // 일반 브랜드일 경우
              <div className='mt-4 bg-[#F7F7FB] p-4'>
                <div className='text-base font-semibold'>
                  이미 보유하고 계신 견적서가 있으신가요?
                </div>
                <div className='mt-3 text-[#767676]'>
                  · 카사요는 [대출성 금융상품 판매대리·중개업자 등록증]을 보유한
                  플랫폼으로 더 좋은 조건의 견적을 직접 제공해 드릴 수 있습니다.
                </div>
                <div className='text-[#767676]'>
                  · 받아보신 견적서와 혜택 내용을 함께 기재해 주시면 더욱 상세한
                  견적을 제안해 드리겠습니다.
                </div>
                <div className='mt-3'>타사 혜택 내용 (선택)</div>
                <div className='relative mt-2'>
                  <textarea
                    ref={textInputRef2}
                    onChange={(e) => {
                      setTextCount(
                        /** 한글은 3byte, 영문은 1byte */
                        e.target.value.replace(
                          /[\0-\x7f]|([0-\u07ff]|(.))/g,
                          '$&$1$2',
                        ).length,
                      );
                    }}
                    className='h-[158px] w-full resize-none rounded border bg-white p-4'
                    placeholder='제공받기로 한 추가 혜택을 알려주세요.'
                  />
                  <div className='absolute bottom-4 right-4 text-[#767676]'>
                    {textCount}/200
                  </div>
                </div>

                <div className='mt-3 flex h-12 items-center gap-4 border bg-white px-4'>
                  <div className='h-4 w-4'>
                    <img src='/assets/images/v2/file.svg' alt='' />
                  </div>

                  {purchaseOrderDTO?.orderData?.otherCompanyEstimateFile !==
                  undefined ? (
                    <div
                      onClick={() => {
                        if (
                          purchaseOrderDTO.orderData
                            ?.otherCompanyEstimateFile === undefined
                        )
                          return;
                        download(
                          purchaseOrderDTO.orderData.otherCompanyEstimateFile,
                        );
                      }}
                    >
                      타사 견적서
                    </div>
                  ) : (
                    <div>파일 업로드 (선택)</div>
                  )}
                  <div className='ml-auto'>
                    {purchaseOrderDTO?.orderData?.otherCompanyEstimateFile !==
                    undefined ? (
                      <Button
                        onClick={() => {
                          if (!purchaseOrderDTO) return;
                          setPurchaseOrderDTO({
                            id: purchaseOrderDTO.id,
                            type: purchaseOrderDTO.type,
                            update: {
                              otherCompanyEstimateFileId: null,
                            },
                          });
                        }}
                        variant='outline'
                        size='xs'
                        className='w-auto'
                      >
                        제거
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          fileUpload(
                            {
                              acceptFile: ['image', 'pdf'],
                              fileType: 'otherCompanyEstimate',
                              fileName: '타사 견적서',
                              isPublic: false,
                            },
                            async (fileInfo, file) => {
                              if (!purchaseOrderDTO) return;
                              await setPurchaseOrderDTO({
                                id: purchaseOrderDTO.id,
                                type: purchaseOrderDTO.type,
                                update: {
                                  otherCompanyEstimateFileId: fileInfo.id,
                                },
                              });
                            },
                          );
                        }}
                        variant='outline'
                        size='xs'
                        className='w-auto'
                      >
                        업로드
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className='px-4 py-4'>
          <div>요청내용 (선택)</div>
          <div className='relative mt-2'>
            <textarea
              ref={textInputRef1}
              onChange={handleTextAreaChange}
              className='h-[158px] w-full resize-none rounded border bg-white p-4'
              placeholder='할인금액, 출고 서비스, 맞춤 조건 시 계약 여부 등 매니저님에게 전달하고 싶은 내용을 적어주세요.'
              maxLength={200}
            />
            <div className='absolute bottom-4 right-4 text-[#767676]'>
              {textCount}/200
            </div>
          </div>
        </div>
      </div>
      <div className='flex-none grid grid-cols-2 bg-[#F7F7FB] px-4 py-6'>
        <Button
          variant='outline'
          className='rounded-r-none bg-white'
          onClick={goBack}
        >
          이전
        </Button>
        <Button
          className='rounded-l-none'
          onClick={() => {
            if (!purchaseOrderDTO) return;
            /** 슈퍼카 매니저는 견적서 업로드가 필수입니다. */
            if (
              purchaseOrderDTO.orderData?.isSuperCar === true &&
              !purchaseOrderDTO.orderData.otherCompanyEstimateFile
            ) {
              CarsayoToast.error('구매 계약서를 업로드 해 주세요.');
              return;
            }

            setIsConfirmOpen(true);
          }}
        >
          주문 제출
        </Button>
      </div>
      <Confirm
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
        isCloseButton={true}
        onConfirm={submit}
        title='주문 제출하기'
        desc={'정말로 주문을 진행하시겠습니까?'}
      ></Confirm>
    </div>
  );
}
