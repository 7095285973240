export type InsuranceCardInfo = {
  /** 이미지 파일 경로 */
  src: string;
};

export type InsuranceCard =
  | 'KB국민카드'
  | '삼성카드'
  | '신한카드'
  | '농협카드'
  | '하나카드'
  | '현대카드'
  | '롯데카드'
  | 'BC카드'
  | '우리카드';
export const InsuranceCardData: Record<InsuranceCard, InsuranceCardInfo> = {
  KB국민카드: {
    src: '/assets/images/static/card/kb_card.png',
  },
  삼성카드: {
    src: '/assets/images/static/card/samsung_card.png',
  },
  신한카드: {
    src: '/assets/images/static/card/shinhan_card.png',
  },
  농협카드: {
    src: '/assets/images/static/card/nh_card.png',
  },
  하나카드: {
    src: '/assets/images/static/card/hana_card.png',
  },
  현대카드: {
    src: '/assets/images/static/card/hyundai_card.png',
  },
  롯데카드: {
    src: '/assets/images/static/card/lotte_card.png',
  },
  BC카드: {
    src: '/assets/images/static/card/bc_card.png',
  },
  우리카드: {
    src: '/assets/images/static/card/woori_card.png',
  },
};
