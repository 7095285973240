/* eslint-disable @typescript-eslint/no-unused-vars */
// 카사요 어드민에서 설정한 팝업과 연동되는 모듈입니다
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import useSystemStore, { TabbarState } from 'store/useSystemStore';
import dayjs from 'dayjs';
import ReactHtmlParser from 'react-html-parser';
import useCarsayoPopupStore from 'store/useCarsayoPopup';
import { useNavigate } from 'react-router-dom';

// TODO: 드래그 이벤트 추가
const CarsayoPopup = () => {
  const { isBottomTabbar, setIsBottomTabbar } = useSystemStore();
  const navigate = useNavigate();

  const { isPopupOpen, currentPopupData, closeCarsayoPopup } =
    useCarsayoPopupStore();

  const [beforeBottomTabbarMode, setBeforeBottomTabbarMode] = useState<
    boolean | null
  >(null);
  const [isClosing, setIsClosing] = useState<boolean>(false);

  useEffect(() => {
    if (currentPopupData?.type === 'middle') return;

    if (isPopupOpen && isBottomTabbar) {
      setBeforeBottomTabbarMode(isPopupOpen);
      setIsBottomTabbar(false);
    } else if (!isPopupOpen && beforeBottomTabbarMode !== null) {
      setIsBottomTabbar(beforeBottomTabbarMode);
      setBeforeBottomTabbarMode(null);
    }
  }, [isPopupOpen]);

  const setNotShowToday = () => {
    if (!currentPopupData) return;
    localStorage.setItem(
      `notShowPopupToday_[${currentPopupData.id}]`,
      dayjs().add(1, 'day').toString(),
    );
    closeTabber();
  };

  const closeTabber = () => {
    // 바텀 탭바일 경우 사라지는 애니메이션과 함께 끕니다
    if (currentPopupData && currentPopupData.type === 'bottom') {
      setIsClosing(true);

      setTimeout(() => {
        closeCarsayoPopup();
      }, 400);
    } else {
      closeCarsayoPopup();
    }
  };

  const moveToRedirectPath = () => {
    if (currentPopupData?.redirectPath) {
      closeCarsayoPopup();
      navigate(currentPopupData.redirectPath);
    }
  };

  return (
    currentPopupData &&
    (currentPopupData.type === 'bottom' ? (
      <div
        className={classNames(
          'wrapper inset-0 bg-black/70 z-[6000] !overflow-y-hidden',
          {
            fixed: isPopupOpen,
            hidden: !isPopupOpen,
          },
        )}
        style={{
          paddingBottom: 'env(safe-area-inset-bottom, 0)',
        }}
        onClick={closeTabber}
      >
        <div
          className={classNames(
            'max-w-[400px] flex flex-col fixed overflow-hidden',
            'h-auto w-auto my-0 mx-auto',
            'bottom-[0px] z-[602] left-0 right-0',
            'rounded-t-[20px] bg-[#fff]',
            {
              'animate-sheet-up': isPopupOpen && !isClosing,
              'animate-sheet-down': isClosing,
            },
          )}
          onClick={(e) => e.stopPropagation()}
        >
          {currentPopupData.title && (
            <>
              <div className='flex pt-4 pb-3.5 rounded-t-[20px]'>
                <div className='flex-auto'></div>
                <h3 className='inline-block font-medium text-[18px] text-[#333]'>
                  {currentPopupData.title}
                </h3>
                <div className='flex-auto'></div>
              </div>

              <button
                className={classNames(
                  'absolute m-[18px] flex self-end -indent-view-out text-right w-[1rem] h-[1rem] text-transparent',
                  'px-0 py-0 after:content-[""] after:block after:w-[100%] after:h-[100%] after:absolute after:right-0 after:top-0',
                  'after:bg-close-icon after:bg-center after:bg-no-repeat after:bg-content-fit after:box-content',
                )}
                onClick={closeTabber}
              ></button>
            </>
          )}

          <div className='content-box flex max-h-[50dvh h-[auto] w-full'>
            <div
              className='flex flex-col w-full max-h-[50dvh]'
              onClick={moveToRedirectPath}
            >
              {ReactHtmlParser(currentPopupData.htmlContent)}
            </div>
          </div>

          <h3 className='flex px-6 py-4 bg-white font-NotoSansKR font-[400] text-left text-[13px]'>
            <div className='text-[#767676]' onClick={setNotShowToday}>
              오늘 하루 보지 않기
            </div>
            <div className='flex-auto'></div>
            <div onClick={closeTabber}>닫기</div>
          </h3>
        </div>
      </div>
    ) : (
      <div
        className={classNames(
          'flex items-center justify-center wrapper inset-0 bg-black/70 z-[6000] !overflow-y-hidden',
          {
            fixed: isPopupOpen,
          },
        )}
        onClick={closeTabber}
      >
        <div
          className={classNames(
            'max-w-[400px] flex flex-col overflow-hidden',
            'm-6 h-fit w-auto',
            'z-[602] left-0 right-0',
            'rounded-[20px] bg-[#fff]',
          )}
          onClick={(e) => e.stopPropagation()}
        >
          {currentPopupData.title && (
            <>
              <div className='flex pt-4 pb-3.5 rounded-t-[20px]'>
                <div className='flex-auto'></div>
                <h3 className='inline-block font-medium text-[18px] text-[#333]'>
                  {currentPopupData.title}
                </h3>
                <div className='flex-auto'></div>
              </div>

              <button
                className={classNames(
                  'absolute m-[18px] flex self-end -indent-view-out text-right w-[1rem] h-[1rem] text-transparent',
                  'px-0 py-0 after:content-[""] after:block after:w-[100%] after:h-[100%] after:absolute after:right-0 after:top-0',
                  'after:bg-close-icon after:bg-center after:bg-no-repeat after:bg-content-fit after:box-content',
                )}
                onClick={closeTabber}
              ></button>
            </>
          )}

          <div className='content-box flex max-h-[50dvh h-[auto] w-full'>
            <div
              className='flex flex-col w-full max-h-[50dvh]'
              onClick={moveToRedirectPath}
            >
              {ReactHtmlParser(currentPopupData.htmlContent)}
            </div>
          </div>

          <h3 className='flex px-6 py-4 bg-white font-NotoSansKR font-[400] text-left text-[13px]'>
            <div className='text-[#767676]' onClick={setNotShowToday}>
              오늘 하루 보지 않기
            </div>
            <div className='flex-auto'></div>
            <div onClick={closeTabber}>닫기</div>
          </h3>
        </div>
      </div>
    ))
  );
};
export default CarsayoPopup;
