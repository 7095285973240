/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/media-has-caption */
import { updateDescription } from 'apis/setting';
import { Button } from 'components/ui/button';
import { DialogFooter } from 'components/ui/dialog';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { Textarea } from 'components/ui/textarea';
import { X } from 'lucide-react';
import { useRef } from 'react';
import useMemberStore from 'store/useMemberStore';

function DiscriptionEditor({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const { loginMember, setLoginMember } = useMemberStore();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const submit = async () => {
    if (textAreaRef?.current?.value) {
      const updateValue = textAreaRef.current.value;
      const updatedMember = await updateDescription({
        description: updateValue,
      });
      setLoginMember(updatedMember);
    }

    setIsOpen(false);
  };

  return (
    <Drawer open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col !pt-4'>
        <DrawerHeader className='relative flex justify-between items-center'>
          <DrawerTitle className='text-center text-xl leading-[34px] font-semibold grow'>
            자기소개 수정
          </DrawerTitle>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            className='absolute right-5 top-0 bottom-[3px] my-auto h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'
          >
            <X className='h-7 w-7' />
          </Button>
        </DrawerHeader>
        <div className='px-4 pb-8 mt-4'>
          <Textarea
            ref={textAreaRef}
            className='mb-4'
            defaultValue={loginMember?.description ?? ''}
            maxLength={30}
            placeholder='자기 소개를 입력해 주세요'
          ></Textarea>

          <DialogFooter className='h-[48px]'>
            <Button
              className='h-[48px] rounded-none border-none bg-[#F7F7FB] text-[#111111]'
              onClick={() => setIsOpen(false)}
            >
              취소
            </Button>
            <Button
              className='h-[48px] rounded-none border-none bg-[#111111]'
              onClick={() => {
                submit();
              }}
            >
              확인
            </Button>
          </DialogFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default DiscriptionEditor;
