/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode, useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate, useSearchParams } from 'react-router-dom';

/** static */
const subpageKey = 'csySubpage';

/** Subpage for app
 * @notice 현재 Subpage는 하나만 존재할 수 있습니다.
 */
const SubPage = ({
  isOpen,
  setIsOpen,
  children,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: ReactNode;
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoadingDone, setIsLoadingDone] = useState<boolean>(false);

  useEffect(() => {
    const isSubpageParam = searchParams.get(subpageKey);
    if (!isSubpageParam && isOpen) {
      setIsOpen(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!isOpen) {
      const isSubpageParam = searchParams.get(subpageKey);
      if (isSubpageParam) {
        navigate(-1);
      }
      return;
    }
    setSearchParams({
      [subpageKey]: 'true',
    });
  }, [isOpen]);

  useEffect(() => {
    setIsLoadingDone(true);
  }, []);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={isLoadingDone ? 'collapsed' : false}
          animate='open'
          exit='collapsed'
          variants={{
            open: { opacity: 1, width: '100%' },
            collapsed: { opacity: 0, width: 0 },
          }}
          className='top-0 bg-white fixed right-0 flex flex-col h-full z-[5000] truncate'
          style={{
            paddingTop: 'env(safe-area-inset-top, 0)',
            paddingBottom: 'env(safe-area-inset-bottom, 0)',
          }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default SubPage;
